import { useQuery } from 'react-query';
import { getFavoriteCompanies } from '../api/favorite';
import { getSingleCompany } from '../api/company';

export const useFavoriteCompanies = () => useQuery('favorite', getFavoriteCompanies, {
  retry: 0,
  // should be refetched in the background every 8 hours
  staleTime: 1000 * 60 * 60 * 2,
});

export const useGetSingleCompany = (companyID: string) => useQuery(['company', companyID], () => getSingleCompany(companyID), {
  retry: 0,
  // should be refetched in the background every 24 hours
  staleTime: 1000 * 60 * 60 * 24,
});
