import { Accordion } from '@/components/accordion/accordion';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { TipModalProps } from '../types';
import '../tracker-tip.scss';
import { useAnalytics } from '@/services/hooks/use-analytics';

const ApplyingTipModal = ({ openTip, university }: TipModalProps) => {
  const tabs = ['At a glance', 'Resume Deep Dive', 'Cover Letter Deep Dive'];
  const { trackEvent } = useAnalytics();
  const handleResumeClick = () => {
    trackEvent('Applying Stage: Resume Click');
  };

  const handleCoverLetterClick = () => {
    trackEvent('Applying Stage: Cover Letter Click');
  };
  return (
    <div className="company-saved-info-modal">
      <div className="company-saved-info-modal">
        <TabsHeader
          tabs={tabs}
          defaultTab={tabs.includes(openTip.tab) ? openTip.tab : tabs[0]}
          withBorder
        />
        <TabsContent>
          <div data-tab="At a glance">
            <div className="tip-modal-accordions">
              <Accordion title="Understand the role requirements" copiable>
                <div>
                  <p>
                    Take a moment to really understand the job description.
                    Which key words appear several times? Which skills are
                    mentioned over and over again?
                  </p>
                  <p>
                    Often, job requirements might seem unreasonably high. In
                    such cases, don’t despair! If you meet 60%-70% of the job
                    requirements and are excited about the company, we encourage
                    you to still apply. It makes the following steps even more
                    important.
                  </p>
                </div>
              </Accordion>
              <Accordion title="Prepare your career story" copiable>
                <div>
                  <p>
                    You will want to tell a compelling and credible story about your
                    career so far and your goals. We recommend using our
                    {' '}
                    <strong>PASSion Model</strong>
                    {' '}
                    to
                    demonstrate your personal motivations.
                  </p>
                  <p>
                    PASSion stands for:
                    <ul>
                      <li>
                        <strong>P</strong>
                        ersonal and Professional Interest: start by showcasing a personal or professional interest in the field you are targeting (e.g. “I am fascinated by…”)
                      </li>
                      <li>
                        <strong>A</strong>
                        ptitude and Affinity: provide evidence of your interest in this field and share examples of how you have engaged with it (e.g. “I am actively involved in…”)
                      </li>
                      <li>
                        <strong>S</strong>
                        kill Set: highlight your skills and how they align with the role or industry (e.g. “I acquired XYZ skill during…”)
                      </li>
                      <li>
                        <strong>S</strong>
                        torytelling: craft a compelling story that ties all of the above together (e.g. “This background has prepared me to…”)
                      </li>
                    </ul>

                  </p>

                  <p>
                    Here is an example story using the PASSion Model:
                    <br />
                    “I have always had a deep personal interest (P - Personal Interest) in technology and innovation, even when I was a kid. While other kids were playing sports, I was fixing up computers  or coding mini programs on our school’s computers (A - Aptitude and Affinity). I then became proficient in multiple coding languages during my Computer Science degree (S - Skill Set). My fascination with technology and my constant following of the newest developments give me confidence that I can quickly gain expertise on any software product I might build or improve in the future (S - Storytelling).”

                  </p>
                </div>
              </Accordion>
              <Accordion isOpened={openTip.tipName === 'Customize your Resume'} title="Customize your Resume" copiable>
                <div>
                  <p>
                    With the information from the job description, customize
                    your Resume:
                  </p>
                  <ul>
                    <li>
                      Tailor your Resume bullet points to highlight skills that
                      are particularly relevant for the job
                    </li>
                    <li>
                      Use strong action verbs for your bullet points and show
                      tangible results
                    </li>
                    <li>
                      Spell check your CV and have a friend or your career
                      advisor double-check it
                    </li>
                  </ul>
                </div>
              </Accordion>
              <Accordion copiable isOpened={openTip.tipName === 'Customize your Cover Letter'} title="Customize your Cover Letter">
                <div>
                  <ul>
                    <li>
                      If a Cover Letter is optional, we still recommend
                      submitting a tailored Cover Letter. It signals that you
                      have done your homework and are truly interested in the
                      position and company.
                    </li>
                    <li>
                      Research news about the company that can be used in the
                      opening paragraph.
                    </li>
                    <li>
                      Mention interesting insights that you have gained from
                      your networking coffee chats and name the employees that
                      you have talked to
                    </li>
                  </ul>
                </div>
              </Accordion>
            </div>
          </div>
          <div data-tab="Resume Deep Dive">
            <div className="tip-modal-accordions">
              <Accordion title="Use strong action words" copiable>
                <div>
                  <p>
                    In your Resume bullets, use action-oriented verbs that help
                    recruiters understand the role that you played during a
                    particular experience.
                  </p>
                  <p>
                    <strong>👍 Better example:</strong>
                    <br />
                    “Led organizational restructuring by creating more efficient operational processes and increasing clarity of goals across the division; reduced operating cost by €15m annually”
                  </p>
                  <p>
                    <strong>👎 Bad example:</strong>
                    <br />
                    “Responsible for organizational restructuring to improve cost structure of the division”
                  </p>

                  <br />
                  <p>Some of the most common strong action verbs are:</p>
                  <ul>
                    <li>Developed</li>
                    <li>Led</li>
                    <li>Managed</li>
                    <li>Analyzed</li>
                    <li>Created</li>
                    <li>Collaborated</li>
                    <li>Conducted</li>
                    <li>Performed</li>
                    <li>Negotiated</li>
                    <li>Established</li>
                    <li>Built</li>
                  </ul>
                </div>
              </Accordion>
              <Accordion copiable title="Show results / Use STAR method">
                <div>
                  <p>
                    For every bullet on your Resume: quantify the impact and scope of your work.
                  </p>
                  <p>
                    Example: “Re-negotiated crucial supplier contracts to reduce COGS by 25% during an economic downturn”
                  </p>
                  <p>
                    Need a refresher on the STAR method? We’ve got your back:Situation - Task - Action - Result
                  </p>
                </div>
              </Accordion>
              <Accordion copiable isOpened={openTip.tipName === 'Check out our Resume templates'} title="Check out our Resume templates">
                <div>
                  <div className="downloads">
                    {university === 'HEC'
                      ? (
                        <a onClick={handleResumeClick} href="https://docs.google.com/document/d/1lHPUXO2CKNj51sx1A_e9Zm3U5ZA0ljkY/edit" target="_blank" className="downloads__link" rel="noreferrer">HEC Style Resume Template</a>
                      ) : (
                        <>
                          <a onClick={handleResumeClick} href="https://docs.google.com/document/d/1HFOrsVFMoPmG3tpQ6cqwVXzbIJLUUHcc/edit?usp=sharing&ouid=114964910298181472322&rtpof=true&sd=true" target="_blank" className="downloads__link" rel="noreferrer">European Style Resume Template</a>
                          <a onClick={handleResumeClick} href="https://docs.google.com/document/d/1IMJrn_auZUGuPKZTq5_e9SJq-fu-UqPc/edit?usp=sharing&ouid=114964910298181472322&rtpof=true&sd=true" className="downloads__link" target="_blank" rel="noreferrer">U.S. Style Resume Template</a>
                        </>
                      )}
                  </div>
                </div>
              </Accordion>
            </div>
          </div>
          <div data-tab="Cover Letter Deep Dive">
            <div className="tip-modal-accordions">
              <Accordion copiable title="Keep it short and interesting">
                <div>
                  <p>Cover Letters are a great way to highlight your personal motivation to work in a new company or role and to display your unique perspective and skills beyond your Resume. They typically consist of three or four paragraphs with the following structure:</p>
                  <p>
                    <strong>Paragraph 1</strong>
                    {' '}
                    answers basic questions:
                  </p>
                  <ul>
                    <li>Who are you?</li>
                    <li>What do you want?</li>
                    <li> Why this company, specifically and with evidence?</li>
                    <li> Why you, specifically?</li>
                  </ul>
                  <p>
                    You can spice up this part by opening with a “punch line”: why this job is exciting and what you bring to the table.
                  </p>
                  <p>For example, you could write: “I’m a Social Impact fundraising professional with more than 5 years of experience looking for an opportunity to apply my skills in new ways, and I’d love to bring my expertise and enthusiasm to your growing development team.”</p>

                  <p>
                    <strong>Paragraph 2</strong>
                    {' '}
                    contains the main message you want to convey, your “theme”. This could be:
                  </p>
                  <ul>
                    <li> Highlighting skills that are not visible from your resume </li>
                    <li> Highlighting how transferable skills are relevant to this new company and role</li>
                    <li> Your specific, unique motivation to work for this company and/or in this role</li>
                    <li> Why you, specifically?</li>
                  </ul>
                  <p>As with your Resume, showcasing specific results can enhance your message.</p>
                  <p>
                    <strong>Paragraph 3</strong>
                    , your last paragraph, should list another compelling reason why you want to be in this company/role.
                  </p>
                  <p>Finish by thanking the reader for their consideration and “looking forward to the next steps”.</p>
                  <p><strong>Don’t write more than one page.</strong></p>
                </div>
              </Accordion>
              <Accordion copiable title="Use the PASSion Model">
                <div>
                  <p>
                    Apply the previously described PASSion Model (Personal Interest - Aptitude and Affinity - Skill Set - Storytelling) to construct compelling arguments for your personal motivation to work for this company.
                  </p>
                  <p>
                    Example:
                    <br />
                    <br />
                    You want to credibly demonstrate that you are passionate about the Tech industry:
                    <br />
                  </p>
                  <p>“I have always had a deep personal interest (P - Personal Interest) in technology and innovation, even when I was a kid. While other kids were playing sports, I was fixing up computers  or coding mini programs on our school’s computers (A - Aptitude and Affinity). I then became proficient in multiple coding languages during my Computer Science degree (S - Skill Set). My fascination with technology and my constant following of the newest developments give me confidence that I can quickly gain expertise on any software product I might build or improve in the future (S - Storytelling).”</p>
                </div>
              </Accordion>
              <Accordion copiable isOpened={openTip.tipName === 'Check out our Cover Letter templates'} title="Check out our Cover Letter templates">
                <div>
                  <p className="downloads">
                    <a onClick={handleCoverLetterClick} href="https://docs.google.com/document/d/1wAwSQ5K9m44sBdQFdT7G0i2Y1E635Sbz/edit?usp=sharing&ouid=114964910298181472322&rtpof=true&sd=true" className="downloads__link" target="_blank" rel="noreferrer">Cover Letter Example for a Consulting job</a>
                    <a onClick={handleCoverLetterClick} href="https://docs.google.com/document/d/1x681SVdBnFOs8_IWp6zyqMjs6swHYbw0/edit?usp=sharing&ouid=114964910298181472322&rtpof=true&sd=true" className="downloads__link" target="_blank" rel="noreferrer">Cover Letter Example for Strategy</a>
                    <a onClick={handleCoverLetterClick} href="https://docs.google.com/document/d/1I-VvXdxc4YRHWn-GC3jcVA4K75MYNMDWnGQf5piCEn4/edit?usp=sharing" className="downloads__link" target="_blank" rel="noreferrer">Cover Letter Example for Product Management</a>
                  </p>
                </div>
              </Accordion>
            </div>
          </div>
        </TabsContent>
      </div>
    </div>
  );
};
export default withTabs(ApplyingTipModal);
