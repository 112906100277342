import { useRef } from 'react';

export const useScroll = () => {
  const scrollContainer: any = useRef(null);

  const handleVerticalScroll = (direction = 'right') => {
    scrollContainer.current.scrollTo({
      top: 0,
      left: direction === 'right' ? scrollContainer.current.scrollLeft + 500 : scrollContainer.current.scrollLeft - 500,
      behavior: 'smooth',
    });
  };

  return [scrollContainer, handleVerticalScroll];
};
