import { useState } from 'react';
import './use-ai-button.scss';
import { getTemplate } from '@/services/api/ai';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useSelfUniversity } from '@/services/queries/user';
import { Button } from '@/components/button/Button';

type Option = {
  name: string;
  type: string
};
type Props = {
  options: Option[]
  handleResult: (message: string) => void;
  metaData: any;
  type: string;
  size?: 'small' | 'medium' | 'large';
};

export const UseAIButton = ({
  options,
  handleResult,
  metaData,
  type,
  size = 'large',
}: Props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [poolingMessages, setPoolingMessages] = useState(false);
  const { trackEvent } = useAnalytics();
  const { data: university } = useSelfUniversity();
  const handleButtonClick = () => {
    setShowOptions(!showOptions);
  };

  const handleTemplateFetch = async (name: string, outreachType: string) => {
    setPoolingMessages(true);
    const payload = {
      type,
      outreach_type: outreachType,
      name,
      to_contact_id: metaData.contactID,
      character_limit: metaData.characterLimit ?? 500,
    };
    const response = await getTemplate(payload);
    if (response.response) {
      handleResult(response.response);
      setPoolingMessages(false);
    }
  };

  const handleOptionClick = (option: string, outreactType: string) => {
    trackEvent('AI Button clicked', {}, {
      type: outreactType,
      option,
      university: university.name,
      cohort: university.cohort,
    });
    if (type === 'linkedin' || type === 'email') {
      setShowOptions(!showOptions);
      handleTemplateFetch(option, outreactType);
    }
  };
  return (
    <div className="use-ai-button-wrapper">
      {showOptions && (
        <div className={`use-ai-button-options use-ai-button-options--${size}`}>
          {options.map((option, index) => (
            <div className="use-ai-button-options__option">
              <Button
                label={`${index + 1}. ${option.name}`}
                onClick={() => {
                  handleOptionClick(option.name, option.type);
                  setShowOptions(false);
                }}
                mode="special"
                size="medium"
              />
            </div>
          ))}
        </div>
      )}
      <div className="use-ai-button">
        <Button
          label={size === 'large' ? 'Generate Message' : 'AI'}
          icon="bi bi-stars"
          onClick={handleButtonClick}
          mode="special"
          size="medium"
          isLoading={poolingMessages}
        />
      </div>
    </div>
  );
};
