import { Link } from 'react-router-dom';
import './tracker-info-tab.scss';
import DOMPurify from 'dompurify';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { prettifyHTML } from '@/services/helpers/processHtml';

type Props = {
  aboutCompany: string;
  logoUrl: string;
  name: string;
  website: string;
  location: string;
  industry: string;
};

export const TrackerInfoTab = ({
  aboutCompany,
  logoUrl,
  name,
  website,
  location,
  industry,
}: Props) => (
  <div className="company-info-tab">
    <div className="company-info-tab__header">
      <DefaultLogo size={40} source={logoUrl} name={name} type="company" className="company-info-tab__logo" />
      <h2 className="company-info-tab__title">{name}</h2>
    </div>
    <div className="company-info-tab__details">
      <div className="company-info-tab__details-item">
        <i className="bi bi-pin-map-fill" />
        <span className="company-info-tab__label">{location}</span>
      </div>
      <div className="company-info-tab__details-item">
        <i className="bi bi-building" />
        <span className="company-info-tab__label">{industry}</span>
      </div>
      <div className="company-info-tab__details-item">
        <i className="bi bi-link" />
        <Link to={website} target="_blank" className="company-info-tab__link">
          Website
        </Link>
      </div>
    </div>
    <div className="company-info-tab__content">
      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prettifyHTML(aboutCompany)) }} />
    </div>
  </div>
);
