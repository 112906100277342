import './contact-card.scss';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Button } from '@/components/button/Button';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { StatusTag } from '../status-tags/status-tag';
import { ConnectionStatus } from '../../types/contact';

interface ContactCardProps {
  id: string;
  imageURL: string;
  contactName: string;
  contactPosition: string;
  linkedInUrl?: string;
  isPreview?: boolean;
  handleSave?: () => Promise<void>;
  networkingStatus?: ConnectionStatus;
  linkedInStatus?: ConnectionStatus;
}

/**
 * Primary UI component for company card
 */
export const ContactCard = ({
  imageURL,
  id,
  contactName,
  contactPosition,
  isPreview = false,
  linkedInUrl,
  networkingStatus,
  linkedInStatus,
  handleSave,
}: ContactCardProps) => {
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const goToContact = () => {
    if (isPreview && linkedInUrl) {
      trackEvent('Linkedin Suggested Contact Clicked');
      window.open(linkedInUrl, '_blank');
    } else {
      navigate(`/app/inbox/${id}`);
    }
  };

  const handleButtonClick = async () => {
    if (handleSave) {
      setIsLoading(true);
      await handleSave();
      setIsLoading(false);
      return;
    }

    goToContact();
  };

  const buttonLabel = isPreview ? 'Save Contact' : 'Message';
  const buttonIcon = isPreview ? 'bi bi-bookmark' : 'bi bi-arrow-up-right';
  const iconPosition = isPreview ? 'left' : 'right';
  const isButtonOutlined = isPreview;

  return (
    <div className="contact-card">
      <DefaultLogo
        type="contact"
        source={imageURL}
        handleClick={goToContact}
        className="contact-card__image"
        name={contactName}
        size={48}
      />
      <div className="contact-card__main">
        <div className="contact-card__info">
          <h4 onClick={goToContact} className="contact-card__name">{contactName}</h4>
          <span className="contact-card__label">{contactPosition}</span>
        </div>
        {networkingStatus && linkedInStatus && (
          <div className="contact-card__statuses">
            <StatusTag status={networkingStatus} type="networking" />
            <StatusTag status={linkedInStatus} type="linkedin" withTooltip />
          </div>
        )}
      </div>
      {isLoading ? (
        <div className="contact-card__loading" />
      ) : (
        <Button
          handleClick={handleButtonClick}
          label={buttonLabel}
          icon={buttonIcon}
          outlined={isButtonOutlined}
          mode="primary"
          size="medium"
          iconPosition={iconPosition}
        />
      )}
      {/* <div className="contact-card__delete" onClick={() => handleDeleteContact(id)}>
        <img src={DeleteIcon} alt="" />
      </div> */}
    </div>
  );
};
