import { Accordion } from '@/components/accordion/accordion';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { TipModalProps } from '../types';
import '../tracker-tip.scss';
import { useAnalytics } from '@/services/hooks/use-analytics';

const InterviewingTipModal = ({ openTip }: TipModalProps) => {
  const tabs = ['Interview Do’s', 'Interview Don’ts', 'Check your tech'];
  const { trackEvent } = useAnalytics();

  const trackCopiedToClipboard = () => {
    trackEvent('Tracker Tip: Copied to Cliboard');
  };

  return (
    <div className="company-saved-info-modal">
      <div className="company-saved-info-modal">
        <TabsHeader
          tabs={['Interview Do’s', 'Interview Don’ts', 'Check your tech']}
          defaultTab={tabs.includes(openTip.tab) ? openTip.tab : tabs[0]}
          withBorder
        />
        <TabsContent>
          <div data-tab="Interview Do’s">
            <div className="tip-modal-accordions">
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Active listening">
                <div>
                  <p>
                    Make sure you listen to the interviewer’s questions and give answers that are relevant to the question.
                  </p>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Prepare for typical behavioral interview questions">
                <div>
                  <p>
                    Practice and prepare your STAR answers for typical interview questions:
                  </p>
                  <ul>
                    <li>How do you give feedback to colleagues?</li>

                    <li>Tell me about something you could struggle with at your current or previous company.</li>

                    <li>Tell me about a situation where you failed.</li>

                    <li>What are you proud of?</li>

                    <li>When have you contributed to a team’s success?</li>

                    <li>How do you make people buy into something?</li>

                    <li>When have you gone out of your way to help someone?</li>

                    <li>When have you used metrics in your job?</li>

                    <li>When have you peeled and peeled to discover an issue?</li>

                    <li>Give me a situation when you have put the customer above all.</li>

                    <li>Describe a situation when you had to overcome a business challenge?</li>

                    <li>Explain a situation when you improved a process.</li>

                    <li>Describe a situation when you made radical changes and why you consider them radical.</li>

                    <li>What’s the biggest challenge you had in your previous job? How did you handle it?</li>

                    <li>Give me an example of your leadership.</li>

                    <li>Do you have any project management experience?</li>

                    <li>How will you communicate with your boss when you find he is wrong?</li>

                    <li>Give me an example of when you used your analytical skills to solve a problem.</li>

                    <li>Give me an example of when you solved a problem in an innovative way.</li>
                  </ul>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Take a moment to structure your answers">
                <div>
                  <p>
                    Often, our intuition is to answer a question right away. In job interviews, it can it can be helpful to pause and structure your answer before diving right in. Don’t forget to use the PASSion, GROW and STAR frameworks to structure your answers.
                  </p>
                  <p>The frameworks:</p>
                  <ul>
                    <li>
                      PASSion: Personal Interest - Aptitude and Affinity - Skill Set - Storytelling
                    </li>
                    <li>
                      GROW: Grounding Experience - Realizations and Reflections - Opportunity Pursued - Why
                    </li>
                    <li>STAR: Situation - Task - Action - Result</li>
                  </ul>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable isOpened={openTip.tipName === 'Ask your own questions'} title="Ask your own questions">
                <div>
                  <p>
                    At the end of the interview, ask the interviewer questions that you actually care about. Focus more on the interviewer’s opinions rather than trying to confirm facts that you already know.
                  </p>
                  <p>You can use the CAREER Compass framework to find inspiration for such questions. Quick Reminder: Current Trends, Acquiring Insights, Requesting Advice, Exploring Resources, Engaging Assignments. Below are some examples:</p>
                  <ul>
                    <li>
                      “How do you think this job will be different 5 years from now?”
                    </li>
                    <li>
                      “What are some of the challenges you face often working for this company? What are some of the highlights?”
                    </li>
                    <li>“What skill or trait has helped you the most in your work here?”</li>
                    <li>“How do you define success in this company / for a project?”</li>
                    <li>“What new initiatives are on the horizon for this company and how would I contribute to these initiatives?”</li>
                    <li>“What goals or KPIs will my performance be measured against?”</li>
                    <li>
                      “What is the most important thing I should accomplish in the first 6 months in order for you to say that I had a successful start?”
                    </li>
                  </ul>
                </div>
              </Accordion>
            </div>
          </div>
          <div data-tab="Interview Don’ts">
            <div className="tip-modal-accordions">
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Don’t speak poorly about former employers or colleagues">
                <div>
                  <p>
                    The (business) world is small, and this might backfire on you.
                  </p>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Don’t falsify information">
                <div>
                  <p>
                    Answer questions truthfully and to the point.
                  </p>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Don’t be disrespectful">
                <div>
                  <ul>
                    <li>Do not interrupt the interviewer.</li>
                    <li>Do not be too informal with the interviewer.</li>
                    <li>Remember that this is still a professional conversation.</li>
                  </ul>
                </div>
              </Accordion>
            </div>
          </div>
          <div data-tab="Check your tech">
            <div className="tip-modal-accordions">
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Check your camera and microphone quality">
                <div>
                  <p>
                    Make sure there are no echos. If you have a busy video background, consider turning on the “blur background” function of the conferencing tool.
                  </p>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Check your lighting and camera angle">
                <div>
                  <p>
                    Ideally, your camera is leveled with your eyes so that you neither look down on the camera nor up at the camera. Make sure there is enough lighting in front of you. If your room is very dark, consider purchasing a ring light to improve lighting.
                  </p>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Check your video conferencing tool">
                <div>
                  <p>
                    If possible, try out the company’s video conferencing tool (e.g. Zoom, Google Meet) to check your access, camera angle, microphone quality etc.
                  </p>
                </div>
              </Accordion>
            </div>
          </div>
        </TabsContent>
      </div>
    </div>
  );
};

export default withTabs(InterviewingTipModal);
