import React from 'react';
import { trackerTabs } from '@/domains/core/tracker-data';

export type TrackerContextType = {
  openTracker: (id: string, tab?: typeof trackerTabs[number], additionalParams?: { [key: string]: string }) => void;
  closeTracker: () => void;
  isTrackerModal: boolean;
  companyID?: string;
  trackerTab?: typeof trackerTabs[number];
};

// eslint-disable-next-line import/no-mutable-exports
let TrackerContext: any;
// eslint-disable-next-line no-multi-assign
const { Provider } = (TrackerContext = React.createContext<any>(null));

export { TrackerContext, Provider };
