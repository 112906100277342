import { useContext } from 'react';
import { useRevalidator } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ConnectionStatus,
} from '@/domains/core/contact/types';
import {
  sentConnection,
  updateContactConnectionStatus,
} from '@/services/api/contact';
import { Button } from '@/components/button/Button';
import { ModalContext } from '@/components/modal/modal-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useSelf, useSelfUniversity } from '@/services/queries/user';
import { ConnectionStatusPending } from '../connection-status-pending/connection-status-pending';
import { InboxContextType, useInboxContext } from '@/pages/context/inbox';
import './chat-not-available.scss';
import { ConnectionBox } from '../connection-box/connection-box';

type ChatNotAvailableProps = {
  industry: string;
  jobTitle: string;
  connectionStatus: ConnectionStatus;
  handleEmailClick: () => void;
  onContactUpdate: () => void;
};

export const ChatNotAvailable = ({
  connectionStatus,
  handleEmailClick,
  onContactUpdate,
  jobTitle,
  industry,
}: ChatNotAvailableProps) => {
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const { currentContact: contact } = useInboxContext() as InboxContextType;
  const { data: university } = useSelfUniversity();
  const { data: self } = useSelf();

  const revalidator = useRevalidator();

  const { openModal, closeModal } = useContext(ModalContext) as any;

  const contFirstName = contact.first_name || '[Contact first name]';
  const studentFirstName = user?.given_name || self?.first_name || '[first name]';
  const studentUniversity = university?.name || '[university]';
  const companyName = contact.current_position.company;

  const sendConnectionMessage = async () => {
    trackEvent('Linkedin Connection Sent', user, {
      source: 'Inbox',
    });
    trackEvent('Message sent via Linkedin', user);
    await sentConnection(contact.id);
    await updateContactConnectionStatus(contact, 'pending');
    onContactUpdate();
  };

  const handleMessageSentSuccess = () => {
    revalidator.revalidate();
    closeModal();
  };

  const handleClick = (prefilledMessage?: string) => {
    openModal(
      <ConnectionBox
        contactId={contact.id}
        toUserLinkedInUrn={contact.linkedin_urn}
        handleConnectionSent={sendConnectionMessage}
        handleSentMesage={handleMessageSentSuccess}
        contactPlaceholders={{
          FirstName: contact.first_name,
          LastName: contact.last_name,
          Company: contact.current_position.company,
        }}
        messageTemplate={prefilledMessage}
        reopenBox={handleClick}
        regenerateData={{
          studentName: studentFirstName,
          contactName: contFirstName,
          universityName: studentUniversity,
          channel: 'connection',
          companyName,
          industry,
          jobTitle,
        }}
      />,
      {
        closeOnClickOutside: false,
      },
    );
  };

  return (
    <div className="chat-not-available">
      {connectionStatus === 'pending' ? (
        <ConnectionStatusPending />
      ) : (
        <>
          <h2 className="chat-not-available__title">
            You are not connected with this contact yet
          </h2>
          <div className="chat-not-available__actions">
            <Button
              onClick={() => handleClick()}
              mode="primary"
              size="medium"
              label="Send LinkedIn Connection"
              icon="bi bi-linkedin"
              outlined
            />
            <Button
              onClick={handleEmailClick}
              mode="primary"
              size="medium"
              label="Send an Email"
              icon="bi bi-envelope"
            />
          </div>
        </>
      )}
    </div>
  );
};
