import React from 'react';
import { Input } from '../input/input';

interface JobSearchBarProps {
  value: string;
  placeholder: string;
  onChange: (query: string) => void;
}

export const FilterSearchBar: React.FC<JobSearchBarProps> = ({
  value,
  placeholder,
  onChange,
}) => {
  const handleInputChange = (val: string) => {
    onChange(val);
  };

  return (
    <div className="filter-search-bar">
      <div className="filter-search-bar__field">
        <Input
          value={value}
          handleValueChange={handleInputChange}
          placeholder={placeholder}
          label=""
          name="filter-search-bar"
          id="filter-search-bar"
          data-testid="filter-search-bar"
          icon="bi bi-search"
          isClearable
        />
      </div>
    </div>
  );
};
