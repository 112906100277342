import './info-box.scss';

interface ContactInfoBoxProps {
  title: string;
  subtitle: string;
  period: string;
  location?: string
  icon?: 'job' | 'education';
}

/**
 * Primary UI component for User Information Box
 */
export const ContactInfoBox = ({
  title, subtitle, period, location = '', icon = 'job',
}: ContactInfoBoxProps) => (
  <div
    className="contact-info-box"
  >
    <i className={`contact-info-box__icon bi ${icon === 'job' ? 'bi-briefcase-fill' : 'bi-mortarboard-fill'}`} />

    <div className="contact-info-box__column">
      <div className="contact-info-box__title">{title}</div>
      <div className="contact-info-box__subtitle">{subtitle}</div>
      {period !== '- ()' && (
        <div className="contact-info-box__period">{period}</div>
      )}
      <div className="contact-info-box__location">{location}</div>
    </div>
  </div>
);
