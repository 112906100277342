import { ContactPlaceholders } from '@/domains/core/netwoking/types';

export const prettifyHTML = (message: string) => {
  if (!message) {
    return '';
  }
  const messageWithLineBreak = message.replace(/\n/g, '<br />');
  const messageWithHighlightedText = messageWithLineBreak.replace(/\[[^[\]]*\]/g, '<mark>$&</mark>');
  const messageWithBold = messageWithHighlightedText.replace(/\*\*([^*]*)\*\*/g, '<strong>$1</strong>');
  const messageWithLink = messageWithBold.replace(
    /((?:https?:\/\/|www\.)[^\s<>]+)(?![^<]*>|[^<>]*<\/a>)/g,
    '<a href="$1" target="_blank">$1</a>',
  );

  return messageWithLink;
};

export const stripHTML = (htmlString: any) => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.body.textContent || '';
};

export const resolveTemplatePlaceholdersWithContactData = (html: string, contactPlaceholders: ContactPlaceholders) => {
  let output = html;

  Object.entries(contactPlaceholders).forEach(([placeholder, value]) => {
    // Create a regex to match the placeholder wrapped in [ ] and various tags
    const regex = new RegExp(`<(${['span', 'u', 'em', 'strong'].join('|')})[^>]*>\\[${placeholder}\\]</\\1>|<(${['span', 'u', 'em', 'strong'].join('|')})[^>]*>\\[${placeholder}\\]</\\2>|\\[${placeholder}\\]`, 'g');
    output = output.replace(regex, value);
  });

  return output;
};

export function addBlankTargetToLinks(html: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const anchors = doc.querySelectorAll('a');
  anchors.forEach((anchor) => {
    anchor.setAttribute('target', '_blank');
    anchor.setAttribute('rel', 'noopener noreferrer');
  });

  return doc.body.innerHTML;
}
