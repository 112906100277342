import { useState } from 'react';
import { Button } from '@/components/button/Button';

import './send-buuton-with-options.scss';
import { MessageType } from '@/domains/core/netwoking/types';

type Props = {
  handleSendButtonClick: (messageType: MessageType) => void;
  emailFeatureEnabled: boolean;
};

export const SendButtonWithOptions = ({ handleSendButtonClick, emailFeatureEnabled }: Props) => {
  const [isListOpened, setIsListOpened] = useState(false);

  const handleClick = (messageType: MessageType) => {
    setIsListOpened(false);
    handleSendButtonClick(messageType);
  };

  return (
    <div data-testid="send-button-with-options" className="send-button-with-options">
      <Button
        mode="primary"
        size="medium"
        label="Send Message"
        iconPosition="right"
        icon="bi bi-chevron-down"
        handleClick={() => setIsListOpened(!isListOpened)}
      />
      {(isListOpened) && (
      <ul className="send-button-with-options__list">
        {emailFeatureEnabled && (
          <li
            className="send-button-with-options__item"
            onClick={() => handleClick('email')}
          >
            <i className="send-button-with-options__item-icon bi bi-envelope" />
            <span>Email</span>
          </li>
        )}
        <li
          className="send-button-with-options__item"
          onClick={() => handleClick('linkedin')}
        >
          <i className="send-button-with-options__item-icon bi bi-send" />
          <span>LinkedIn</span>
        </li>
      </ul>
      )}
    </div>
  );
};
