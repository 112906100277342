import './info-box2.scss';

type Props = {
  children: string | JSX.Element;
};

export const InfoBox2 = ({ children }: Props) => (
  <div className="info-box2">
    <div className="info-box2__icon">
      <i data-testid="info-box2-icon" className="bi bi-arrow-right" />
    </div>
    <div className="info-box2__content">
      {children}
    </div>
  </div>
);
