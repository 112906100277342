import { Loader } from '@/components/loader/loader';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import './styles/tour.scss';

function TourPage() {
  return (
    <div className="tour-page" />
  );
}

export default CustomWithAuthenticationRequired(TourPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
