import Benefit from '../benefit/benefit';
import Organization from '@/assets/images/icons/organization.svg';
import AiIcon from '@/assets/images/icons/ai-icon.svg';
import NetworkingIcon from '@/assets/images/icons/networking-icon.svg';
import CareerGuidance from '@/assets/images/icons/career-guidance.svg';
import './benefits-list.scss';

export const BenefitsList = () => (
  <div className="benefits-list">
    <Benefit
      icon={Organization}
      topic="Organization"
      text="Organize your entire job search in one intuitive dashboard"
    />
    <Benefit
      icon={NetworkingIcon}
      topic="Integrated Networking"
      text="Combine suggested contacts with Linkedin and email networking all in one easy-to-use app"
    />
    <Benefit
      icon={AiIcon}
      topic="AI Message Templates"
      text="Network like a pro with our tailored AI outreach messages"
    />
    <Benefit
      icon={CareerGuidance}
      topic="Career Guidance"
      text="Receive useful tips & tricks for every stage of your career journey"
    />
  </div>
);
