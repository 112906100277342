import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from 'react-query';
import useConnectEmail from '@/services/hooks/use-connect-email';
import { Checkbox } from '@/components/checkbox/checkbox';
import SmirkFace from '@/assets/images/icons/emojis/smirk-face.png';
import './sidebar-empty-state.scss';
import { useAnalytics } from '@/services/hooks/use-analytics';

type Props = {
  hasExtension: boolean;
  hasEmail: boolean;
  hasSavedContacts: boolean;
  setInterval: any;
};

export const InboxSidebarEmpty = ({
  hasExtension,
  hasEmail,
  hasSavedContacts,
  setInterval,
}: Props) => {
  const { user } = useAuth0();
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const successCallback = () => {
    queryClient.invalidateQueries('identities');
    trackEvent('Email Inbox connected', user, {
      source: 'Inbox',
    });
  };

  const { handleConnectEmail } = useConnectEmail(
    successCallback,
  );

  const handleSavedContacts = () => {
    trackEvent('Save Contacts Clicked');
  };

  const innerHandleContactEmailChanged = () => {
    if (hasEmail) return;
    handleConnectEmail();
  };

  const handleExtensionClick = () => {
    if (!hasExtension) {
      trackEvent('Inbox Extension Install Clicked');
      setInterval();
    }
  };

  const helpLink = `https://share.hsforms.com/1XspFVBkwT2S7dMKLWzrKqg55xla?${user?.email}`;

  return (
    <div className="sidebar-empty">
      <div className="sidebar-empty__icon">
        <img src={SmirkFace} alt="Smirk Face" />
      </div>
      <div className="sidebar-empty__title">Almost ready for takeoff!</div>
      <div className="sidebar-empty__subtitle">
        You’re just a few steps away from
        <b> unlocking </b>
        the Inbox! Check off all the criteria below to reveal the magic.
      </div>
      <div className="sidebar-empty__checkbox-container">
        <Checkbox
          label="Install the Chrome Extension"
          checked={hasExtension}
          onClick={handleExtensionClick}
          onChange={() => {}}
          labelLink="https://chromewebstore.google.com/detail/careeros-save-jobs-contac/ahkmpgpdcneppdhhdgmmmcgicgfcfmka?hl=en-GB"
          target="_blank"
        />
        <Checkbox
          label="Connect your email inbox"
          checked={hasEmail}
          onChange={() => {}}
          onClick={innerHandleContactEmailChanged}
        />
        <Checkbox
          label="Have at least 1 contact saved"
          checked={hasSavedContacts}
          onClick={handleSavedContacts}
          onChange={() => {}}
          labelLink="/app/contacts"
        />
      </div>
      <div className="sidebar-empty__help-link">
        Can&apos;t complete the criteria?
        {' '}
        <a href={helpLink} target="_blank" rel="noreferrer">
          Reach out to us and we can help
        </a>
      </div>
    </div>
  );
};
