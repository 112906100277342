import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DOMPurify from 'dompurify';
import * as Sentry from '@sentry/react';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { Button } from '@/components/button/Button';
import { Tag, TagColors } from '@/components/tag/tag';
import { getTagIcons } from '../../helpers/getTagIcons';
import { JobFeedJob } from '../../types/job';
import { ModalContext } from '@/components/modal/modal-provider';
import { InfoModal } from '@/domains/generic/modals';
import {
  addCompanyToFavorites,
  changeApplicationStatus,
  TrackerContext,
  TrackerContextType,
  TrackerInfoTab,
} from '@/domains/core/company';
import { getSingleCompany } from '@/services/api/company';
import { applyForJob } from '@/services/api/job';
import { sortTags } from '@/services/helpers/job-feed';
import { EmptySavedDescription } from '../empty-saved-description/empty-saved-description';
import { useTabs } from '@/components/tabs/TabsContext';
import { useAnalytics } from '@/services/hooks/use-analytics';
import './job-description.scss';
import { Company } from '@/domains/core/company/types';

type Props = {
  job: JobFeedJob;
  isSaved: boolean;
  setAllJobsTab: () => void;
  handleSaveAction: (jobId: string) => void;
  handleUnsaveAction: (jobId: string) => void;
};

const JobDescription = ({
  job,
  isSaved,
  setAllJobsTab,
  handleSaveAction,
  handleUnsaveAction,
}: Props) => {
  const tabs = ['Job Description', 'About Company'];
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [company, setCompany] = useState<Company | null>(null);
  const sortedTags = useMemo(() => (job && job.tags ? sortTags(job.tags) : []), [job?.tags]);
  const { setCurrentTab } = useTabs();
  const { trackEvent } = useAnalytics();
  const descriptionScrollRef = useRef<HTMLDivElement>(null);

  const fetchCompany = async () => {
    if (job && job.company_id) {
      const companyData = await getSingleCompany(job.company_id);
      setCompany(companyData);
    }
  };

  useEffect(() => {
    fetchCompany();
  }, [job?.company_id]);

  useEffect(() => {
    setCurrentTab(tabs[0]);
  }, [job]);

  useEffect(() => {
    if (descriptionScrollRef.current) {
      descriptionScrollRef.current.scrollTop = 0;
    }
  }, [job]);

  if (!job) {
    return <EmptySavedDescription setAllJobsTab={setAllJobsTab} />;
  }

  if (!company) {
    return null;
  }

  const handleYesClick = async () => {
    try {
      if (!isSaved) {
        handleSaveAction(job.id);
      }
      await applyForJob(job.id, true);
      closeModal();
      trackEvent('Applied to job', {
        company_name: job.company_name,
        job_title: job.title,
        location: job.tags[1],
        full_time: job.tags[0],
        on_site: job.tags[2],
        industry: job.tags[4],
        size: job.tags[3],
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleNoClick = async () => {
    try {
      if (!isSaved) {
        handleSaveAction(job.id);
      }
      await applyForJob(job.id, false);
      closeModal();
      trackEvent('Did not apply to job');
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const rememberSkipModal = (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberApplyUser', 'true');
    }
  };

  const handleOpenJobLink = () => {
    const utmUrl = `${job.linkedin_url}${job.linkedin_url.includes('?') ? '&' : '?'}utm_source=CareerOS`;
    window.open(utmUrl, '_blank');
    openModal(
      <InfoModal
        icon="bi bi-send"
        title="Did you apply?"
        description="Just let us know, and we'll help you keep tabs on your application!"
        buttonLabel="Yes, I did! 🤩"
        handleButtonClick={handleYesClick}
        secondaryButtonLabel="No, not yet 🙈"
        handleSecondaryButtonClick={handleNoClick}
      />,
    );
  };

  const handleHelpNetwork = async () => {
    await addCompanyToFavorites(company.id);
    const updated = await getSingleCompany(company.id);
    setCompany(updated);
    if (updated.application_id) {
      await changeApplicationStatus(updated.application_id, 'networking', 0);
      openTracker(updated.id);
    }
    if (!isSaved) {
      handleSaveAction(job.id);
    }
    closeModal();
    trackEvent('Help me network clicked');
  };

  const confirmApply = (rememberAction: boolean) => {
    rememberSkipModal(rememberAction);
    handleOpenJobLink();
  };

  const handleApply = () => {
    const rememberAction = localStorage.getItem('rememberApplyUser');
    if (rememberAction || (company.application_status && company.application_status !== 'saved')) {
      handleOpenJobLink();
    } else {
      openModal(
        <InfoModal
          icon="bi bi-sign-stop"
          title="Are you sure?"
          description="You have not networked with this company, yet. Are you sure you want to apply?"
          buttonLabel="Yes, I’m sure"
          handleButtonClick={confirmApply}
          secondaryButtonLabel="Help me network"
          handleSecondaryButtonClick={handleHelpNetwork}
          rememberText="Don't show this again"
        />,
      );
    }
    trackEvent('Apply button clicked');
  };

  return (
    <div className="job-description">
      {job.has_badge && (
        <div className="job-description__actions-message">
          <i className="job-description__actions-message-icon bi bi-check-circle-fill" />
          You applied to this job
        </div>
      )}
      <div className="job-description__top">
        <div className="job-description__actions">
          <Button
            data-testid="save-button"
            size="medium"
            mode="primary"
            label={isSaved ? 'Saved' : 'Save'}
            icon="bi bi-bookmark-heart"
            handleClick={isSaved ? () => handleUnsaveAction(job.id) : () => handleSaveAction(job.id)}
          />
          <Button
            size="medium"
            mode="primary"
            outlined
            iconPosition="right"
            label="Apply"
            icon="bi bi-send"
            handleClick={handleApply}
          />
        </div>
        <div className="job-description__company">
          <DefaultLogo
            source={job.linkedin_logo_url}
            type="company"
            name={job.company_name}
            className="job-description__company-logo"
            data-testid="job-logo"
            size={48}
          />
          <div className="job-description__job">
            <div className="job-description__job-title" data-testid="job-title">{job.title}</div>
            <div className="job-description__job-company">
              <div className="job-description__job-company-name">{job.company_name}</div>
              {'\u25CF'}
              <div>{formatDateDeltaFromNow(job.posted_on)}</div>
            </div>
          </div>
        </div>
        <div className="job-description__tags">
          {sortedTags.map((tag) => (
            <Tag
              key={tag.tag_type}
              color={TagColors.Grey}
              label={tag.tag_content}
              image={getTagIcons(tag.tag_type)}
            />
          ))}
        </div>
      </div>
      <div className="job-description__bottom">
        <TabsHeader withBorder tabs={tabs} containerName="description" defaultTab={tabs[0]} />
        <div className="job-description__tabs-content" ref={descriptionScrollRef}>
          <TabsContent>
            <div
              className="job-description__description"
              data-tab="Job Description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(job.description),
              }}
            />
            <div
              className="job-description__description"
              data-tab="About Company"
            >
              <TrackerInfoTab
                aboutCompany={company.overview}
                name={company.name}
                logoUrl={company.logo_url}
                website={company.company_url}
                industry={company.industry || company.careeros_industry}
                location={company.hq_location}
              />
            </div>
          </TabsContent>
        </div>
      </div>
    </div>
  );
};

export default withTabs(JobDescription);
