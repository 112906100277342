import { useEffect, useContext, useState } from 'react';
import { useRevalidator } from 'react-router';
import { useQueryClient } from 'react-query';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastFunction, AddToastType } from '@/domains/generic/toasts/types';
import { getConfig } from '@/config';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { createWebSocketService } from '@/services/websocket/websocket';
import { QUEST_SHORT_TITLE } from '@/domains/core/student/components/quests/quests-data';
import { router } from '@/main';
import { QuestType } from '@/domains/core/student/types';

const config = getConfig();
const websocketUrl = `${config.websocketUrl}/ws`;

type DataObject = {
  type: string
  payload: any
};

type MessageHandler = (dataObj: DataObject, addToast: AddToastFunction, trackEvent: any, queryClient?: any) => void;

const messageHandlers: Record<string, MessageHandler> = {
  questCreated: (dataObj: DataObject, addToast: AddToastFunction, trackEvent: any, queryClient?: any) => {
    const questType = dataObj.payload?.type;
    const companyID = dataObj.payload?.company_id;
    const questTitle = QUEST_SHORT_TITLE[questType] || 'Quest';
    const message = `New Quest: ${questTitle}`;
    const additionalMessage = 'Click here to view your new quest.';
    addToast({
      type: 'quest', message, additionalMessage, autoClose: true, handleClick: () => router.navigate(`/app/dashboard?tracker-companyID=${companyID}&company-tracker-tab=Contacts`),
    });
    trackEvent('Quest created', {}, { questType, questTitle });
    queryClient.invalidateQueries('quests');
  },
  questCompleted: (dataObj: DataObject, addToast: AddToastFunction, trackEvent: any, queryClient: any) => {
    const questType = dataObj.payload?.type;
    const questTitle = QUEST_SHORT_TITLE[questType] || 'Quest';
    const message = `Quest Completed: ${questTitle}`;
    const additionalMessage = 'Click here to smash more quests.';
    addToast({
      type: 'completed', message, additionalMessage, autoClose: true, handleClick: () => router.navigate('/app/dashboard'),
    });
    trackEvent('Quest completed', {}, { questType, questTitle });
    queryClient.invalidateQueries('quests');
  },
  documentScanned: (dataObj: DataObject, addToast: AddToastFunction, trackEvent: any) => {
    const { status } = dataObj.payload;
    const toastType = status === 'safe' ? 'success' : 'error';
    const message = status === 'safe'
      ? 'Document was successfully scanned.'
      : 'Document was put in quarantine due to a detected issue.';
    addToast({
      type: toastType, message, autoClose: true, handleClick: () => router.navigate('/app/documents'),
    });
    trackEvent('Document scanned', { status });
  },
};

const WebSocketHandler = () => {
  const { addToast }: AddToastType = useContext(ToastContext);
  const [isTabActive, setIsTabActive] = useState(true);
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();
  const revalidator = useRevalidator();
  const [pendingToasts, setPendingToasts] = useState<DataObject[]>([]);
  const isMobile = window.innerWidth <= 768;
  const handleWebSocketMessage = (event: any) => {
    if (!event.data) return;

    const dataObj: DataObject = JSON.parse(event.data);

    if (isTabActive && !isMobile) {
      setPendingToasts((prevToasts) => [...prevToasts, dataObj]);
      if (dataObj?.payload?.type !== QuestType.SaveContact) {
        revalidator.revalidate();
      }
    } else {
      console.log(`No handler for event type: ${dataObj.type}`);
    }
  };

  useEffect(() => {
    const token = window.localStorage.getItem('token');

    if (token) {
      const wsService = createWebSocketService(websocketUrl, token);

      wsService.onMessage(handleWebSocketMessage);

      return () => {
        wsService.close();
      };
    }

    return () => {};
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (pendingToasts.length > 0 && document.visibilityState === 'visible') {
      const uniqueToasts = pendingToasts.reduce((acc, dataObj) => {
        const exists = acc.find(toast => toast.payload?.type === dataObj.payload?.type);
        if (!exists) {
          acc.push(dataObj);
        }
        return acc;
      }, [] as DataObject[]);

      uniqueToasts.forEach((dataObj) => {
        const handler = messageHandlers[dataObj.type];
        if (handler) {
          handler(dataObj, addToast, trackEvent, queryClient);
        }
      });
      setPendingToasts([]);
    }
  }, [pendingToasts, addToast, trackEvent, queryClient]);

  return null;
};

export default WebSocketHandler;
