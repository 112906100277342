import mixpanel from 'mixpanel-browser';

const envCheck = import.meta.env.VITE_ENV === 'prod';

if (envCheck) {
  mixpanel.init(import.meta.env.VITE_MIXPANEL_ID);
}
const actions = {
  identify: (id: any) => {
    if (envCheck) mixpanel.identify(id);
  },
  alias: (id: any) => {
    if (envCheck) mixpanel.alias(id);
  },
  track: (name: string, props: any) => {
    if (envCheck) mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      if (envCheck) mixpanel.people.set_once(props);
    },
  },
};

export const Mixpanel = actions;
