import './empty-saved-description.scss';

type Prop = {
  setAllJobsTab: () => void
};

export const EmptySavedDescription = ({ setAllJobsTab }: Prop) => {
  const handleClick = () => {
    setAllJobsTab();
  };
  return (
    <div className="empty-saved-description">
      <div className="empty-saved-description__container">
        <div className="empty-saved-description__image">🧑‍💻</div>
        <div className="empty-saved-description__title">
          Looks like you haven’t saved any jobs yet
        </div>
        <div className="empty-saved-description__subtitle">
          Head over to
          {' '}
          <span className="empty-saved-description__link" onClick={handleClick}>
            “All Jobs”
          </span>
          {' '}
          to see our job feed, updated daily for maximum freshness!
        </div>
      </div>
    </div>
  );
};
