import { useMemo } from 'react';
import './contact-tile-step.scss';

type Props = {
  name: string;
  date?: string;
  icon: string;
  isActive?: boolean;
};

const getDayDiff = (date1: Date, date2: Date) => {
  const diffTime = date2.getTime() - date1.getTime();
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const ContactTileStep = ({
  name,
  date,
  icon,
  isActive = false,
}: Props) => {
  const isOverdue = useMemo(() => {
    if (!date || !isActive) {
      return false;
    }
    const dateObj = new Date(date);
    const today = new Date();

    return getDayDiff(today, dateObj) < 0;
  }, [date]);
  const formattedDateString = useMemo(() => {
    if (!date) {
      return '';
    }
    const dateObj = new Date(date);
    const today = new Date();

    const dayDiff = getDayDiff(today, dateObj);

    if (dayDiff < 0 && isActive) {
      return `due ${Math.abs(dayDiff)} day(s) ago`;
    }

    if (dayDiff < 0) {
      return `${Math.abs(dayDiff)} day(s) ago`;
    }

    if (dayDiff === 0) {
      return 'Today';
    }

    return `in ${dayDiff} day(s)`;
  }, [date, isActive]);

  return (
    <div className="contact-tile-step">
      <div className="contact-tile-step__icon">
        <i className={icon} />
      </div>
      <div className={`contact-tile-step__name ${isActive ? 'contact-tile-step__name--active' : ''}`}>
        {name}
      </div>
      {date && (
        <div className={`contact-tile-step__date ${isOverdue ? 'contact-tile-step__date--overdue' : ''} ${isActive ? 'contact-tile-step__date--active' : ''}`}>
          {formattedDateString}
        </div>
      )}
    </div>
  );
};
