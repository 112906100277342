import { LinkedinMessageSkeleton } from './linkedin-message-skeleton';
import './linkedin-message-skeleton-container.scss';

export const LinkedinMessageSkeletonContainer = () => (
  <div className="linkedin-message-skeleton-container">
    <LinkedinMessageSkeleton />
    <LinkedinMessageSkeleton />
    <LinkedinMessageSkeleton />
    <LinkedinMessageSkeleton />
    <LinkedinMessageSkeleton />
  </div>
);
