import DefaultCover from '@/assets/images/default-cover.svg';
import { TabsHeader } from '@/components/tabs';
import './company-header.scss';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { Button } from '@/components/button/Button';

interface CompanyHeaderProps {
  name: string;
  logo: string;
  cover?: string;
  tabs: string[];
}

/**
 * Primary UI component for company header
 */
export const CompanyHeader = ({
  name,
  logo,
  tabs = ['Overview', 'Jobs'],
  cover,
}: CompanyHeaderProps) => {
  const backButtonClicked = () => {
    // eslint-disable-next-line no-restricted-globals
    history.back();
  };
  return (
    <div className="company-header">
      <div className="company-header__cover">
        <img src={cover || DefaultCover} alt="" />
      </div>
      <div className="company-header__tabs">
        <TabsHeader tabs={tabs} defaultTab="Overview" />
      </div>
      <div className="company-header__logo">
        <DefaultLogo
          source={logo}
          type="company"
          name={name}
          className=""
          size={192}
        />
      </div>
      <h2 className={`company-header__name ${cover && 'company-header__name--white'}`}>{name}</h2>
      <div
        className="company-header__back-button"
      >
        <Button
          mode="invisible"
          icon="bi bi-arrow-left"
          size="medium"
          label="Back"
          handleClick={backButtonClicked}
        />
      </div>
    </div>
  );
};
