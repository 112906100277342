import React from 'react';
import { useToast } from '@/services/hooks/use-toast';
// eslint-disable-next-line import/no-cycle
import ToastContainer from './toast-container';

// eslint-disable-next-line import/no-mutable-exports
let ToastContext: any;
// eslint-disable-next-line no-multi-assign
const { Provider } = (ToastContext = React.createContext<any>(null));

const ToastProvider = ({ children } : any) => {
  const {
    toasts, removeToast, addToast,
  } = useToast();
  return (
    <Provider value={{
      toasts, removeToast, addToast,
    }}
    >
      <ToastContainer />
      {children}
    </Provider>
  );
};

export { ToastContext, ToastProvider };
