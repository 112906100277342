/* eslint-disable no-restricted-syntax */
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';

import {
  useEffect, useState,
} from 'react';
import { Loader } from '@/components/loader/loader';
import '@/pages/styles/dashboard.scss';
import {
  StudentResumeTableColumns,
} from '@/domains/core/advisor';

import { Input } from '@/components/input/input';
import AnalyticsImage from '@/assets/images/analitic-man.svg';
import { router } from '@/main';
import Table from '@/components/table/table';
import { TableRow } from '@/components/table/table-types';
import { getUserRoles } from '@/services/helpers/user';
import {
  mapStudentsToResumeTableData,
} from '@/services/helpers/advisor';
import { useSelfUniversity } from '@/services/queries/user';
import { useGetCohortsByUniversityID, useGetStudentsResumesByCohort } from '@/services/queries/student';
import { Button } from '@/components/button/Button';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';
import { Cohort, Student } from '@/domains/core/advisor/types';

function StudentsResumesPage() {
  const [students, setStudents] = useState<Student[]>([]);
  const [parsedStudents, setParsedStudents] = useState<TableRow[]>([]);
  const [searchStudentsValue, setSearchStudentsValue] = useState('');
  const { universityID, cohortID } = useParams();
  const { data: university } = useSelfUniversity();
  const [isLoading, setIsLoading] = useState(true);
  const { data: cohorts, isLoading: isCohortsLoading } = useGetCohortsByUniversityID(universityID || university?.id, {
    enabled: !!universityID || !!university?.id, // Only fetch when a valid universityID is available
  });
  const firstCohortId = cohorts?.[0]?.id ?? '';
  const { data: studentsResponse, isLoading: isStudentsLoading } = useGetStudentsResumesByCohort(cohortID || firstCohortId, {
    enabled: !!firstCohortId || !!cohortID, // Only fetch when a valid cohortID is available
  });
  const { user } = useAuth0();
  const searchStudentByName = (name: string) => {
    const pStudents: TableRow[] = mapStudentsToResumeTableData(students);
    if (name === '') {
      setParsedStudents(pStudents);
    } else {
      const filteredStudents = students
        .filter((student: any) => {
          const fullName = `${student.first_name} ${student.last_name}`;
          return (fullName.toLowerCase().includes(name.toLowerCase().trim()) || student.email.toLowerCase().includes(name.toLowerCase().trim()));
        }).filter(Boolean);
      const filteredParsedStudents = mapStudentsToResumeTableData(filteredStudents);
      setParsedStudents(filteredParsedStudents);
    }
    setSearchStudentsValue(name);
  };

  const parseStudents = (unParsedStudents: any) => {
    const pStudents = mapStudentsToResumeTableData(unParsedStudents);
    setParsedStudents(pStudents);
  };

  const fetchData = async () => {
    let studentsData: any[] = [];

    if (!studentsResponse || !Array.isArray(studentsResponse)) {
      return;
    }
    setIsLoading(true);
    studentsData = studentsResponse.map((student: any) => ({
      ...student,
      name: `${student.first_name} ${student.last_name}`,
      universityID: universityID || university.id,
      cohortID: cohortID || firstCohortId,
    }));

    setStudents(studentsData);
    parseStudents(studentsData);
    setIsLoading(false);
  };

  useEffect(() => {
    if (user && user.email) {
      const roles = getUserRoles(user);
      if (!roles.includes('Admin') && !roles.includes('CareerAdvisor')) {
        router.navigate('/app/dashboard');
      }
    }
  }, [user]);

  useEffect(() => {
    if (studentsResponse && !isCohortsLoading && !isStudentsLoading) {
      fetchData();
    }
  }, [studentsResponse, isCohortsLoading, isStudentsLoading, cohortID, universityID]);

  if (isCohortsLoading || isStudentsLoading || isLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="advisor-dashboard">
      <div className="advisor-dashboard__top">
        {cohorts && cohorts.length > 0 && cohorts.map((cohort: Cohort) => (
          <Button
            key={cohort.id}
            onClick={() => router.navigate(`/app/resume-os/students/${cohort.university_id}/${cohort.id}`)}
            mode="primary"
            outlined={cohort.id !== cohortID}
            size="medium"
            label={cohort.name}
          />
        ))}
      </div>

      <div className="white-wrapper">
        {parseStudents.length > 0 ? (
          <div className="career-os-grid">
            <div className="width-24/24">
              <Input
                icon="bi bi-search"
                value={searchStudentsValue}
                id="search-students"
                label=""
                placeholder="Search Students by Name or Email"
                handleValueChange={searchStudentByName}
              />
              <div className="student__table">
                <Table
                  data={parsedStudents}
                  columns={StudentResumeTableColumns}
                  withRowNumber
                />
              </div>
            </div>
          </div>
        )
          : (
            <div className="no-students">
              <div className="advisor-dashboard__image">
                <div className="coming-soon-box">
                  Coming soon
                </div>
                <img className="advisor-dashboard__image" src={AnalyticsImage} alt="" />
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(StudentsResumesPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
