import { TagType } from '@/components/tag/tag';
import { http } from '@/services/api/http';

const baseURL = import.meta.env.VITE_APP_URL;
export const addComment = (comment: string, applicationID: string) => http.post(`/applications/${applicationID}/comment`, { text: comment, url: `${baseURL}/app/overview#openComments_${applicationID}` });

export const getUniversityTags = (universityID: string) => http.get(`/university/${universityID}/tags`);

export const addTagToStudent = (tag: TagType, studentID: string, universityID: string) => http.post(`/university/${universityID}/students/${studentID}/tag`, { ...tag });

export const createTag = (name: string, color: string, universityID: string) => http.post(`/university/${universityID}/tag`, { label: name, color });

export const removeTagFromStudent = (tag: TagType, studentID: string, universityID: string) => http.delete(`/university/${universityID}/students/${studentID}/tag/${tag.id}`);

export const addResumeComment = (comment: string, resumeID: string, payload: any) => http.post(`/resume/${resumeID}/comment`, { text: comment, ...payload });
