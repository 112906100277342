import { useContext, useState } from 'react';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { regenerateMessage } from '@/services/api/ai';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { IconButton } from '@/components/icon-button/icon-button';
import { TemplateRequestPayload } from '@/domains/core/netwoking/types';

type Props = {
  handleResult: (message: string) => void;
  payload: TemplateRequestPayload;
  buttonRef?: any;
  maxCharacters?: number;
};

function escapeHtml(text: string): string {
  const map: { [key: string]: string } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;',
  };
  return text.replace(/[&<>"']/g, (m) => map[m] ?? m);
}

function formatMessageForHtml(message: string): string {
  return escapeHtml(message)
    .replace(/\n\n/g, '<br><br>')
    .replace(/\n/g, '<br>');
}

export const RegenerateAIButton = ({
  handleResult,
  payload,
  maxCharacters = 300,
  buttonRef,
}: Props) => {
  const [poolingMessages, setPoolingMessages] = useState(false);
  const { trackEvent } = useAnalytics();
  const { addToast }: AddToastType = useContext(ToastContext);

  const fetchMessage = async () => {
    setPoolingMessages(true);
    const payloadWithMaxCharacters = { ...payload, maxCharacters };
    const response = await regenerateMessage(payloadWithMaxCharacters);
    if (response.response) {
      handleResult(formatMessageForHtml(response.response));
      addToast({
        type: 'success',
        message: 'A new message was generated',
        additionalMessage: 'Please change the [placeholders] to personalize the message.',
      });
    } else {
      addToast({
        type: 'error',
        message: 'Could not regenerate message',
        additionalMessage: 'Please refresh the page and try again',
      });
      trackEvent('Toast Error Shown', {}, {
        message: 'Could not regenerate message',
        error: response.error,
      });
    }
    setPoolingMessages(false);
    trackEvent('Regenerate AI Button clicked');
  };

  return (
    <div className="regenerate-ai-button">
      <IconButton
        label="Regenerate message"
        icon="bi bi-arrow-clockwise"
        onClick={fetchMessage}
        mode="special"
        size="large"
        outlined
        iconRef={buttonRef}
        isLoading={poolingMessages}
      />
    </div>
  );
};
