import { useRevalidator } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Timestamp } from 'firebase/firestore';
import {
  useMemo, useRef, useState,
} from 'react';
import { SingleMessage } from '../message/message';
import './message-history.scss';
import { EmailBox } from '../email-box/email-box';
import { MessageBox } from '../message-box/message-box';
import { ConnectionStatus, ContactActivityEvent } from '../../types/contact';
import { SendButtonWithOptions } from '../send-button-with-options/send-button-with-options';
import { QuestType } from '@/domains/core/student/types';
import { HistoryEvent } from '../history-event/history-event';
import { useIdentities, useSelfUniversity } from '@/services/queries/user';
import { MessageBanner } from '../message-banner/message-banner';
import { getMessageTemplate } from '@/services/helpers/contact';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import { useActiveEmail } from '@/services/hooks/use-active-email';
import { MessageType } from '@/domains/core/netwoking/types';

interface Props {
  linkedInMessages: any[];
  emailMessages: any[];
  contactFirstName: string;
  contactImage: string;
  primaryEmail: string;
  secondaryEmail: string;
  contactUrn: string;
  contactID: string;
  handleSendEmail: (message: string, subject: string, to: string[], conversationID?: string) => Promise<boolean>;
  handleConnectionSent: () => void;
  checkEmailPermissions: () => void;
  contactConnectionStatus: ConnectionStatus;
  checkAbilityToSendLinkedIn: () => Promise<boolean>;
  activeQuests: QuestType[];
  companyName: string;
  activityHistory: ContactActivityEvent[];
  companyID: string;
  lastCompletedQuests: QuestType[];
  industry: string | null;
  jobTitle: string | null;
}

export const MessageHistory = ({
  linkedInMessages,
  contactFirstName,
  emailMessages,
  contactImage,
  primaryEmail,
  secondaryEmail,
  contactUrn,
  handleSendEmail,
  handleConnectionSent,
  checkAbilityToSendLinkedIn,
  checkEmailPermissions,
  contactConnectionStatus,
  contactID,
  activeQuests,
  companyName,
  activityHistory,
  companyID,
  lastCompletedQuests,
  industry,
  jobTitle,
}: Props) => {
  const { user } = useAuth0();
  const revalidator = useRevalidator();
  const boxRef = useRef<HTMLDivElement>(null);
  const [messageFieldType, setMessageFieldType] = useState<MessageType>();
  const [replyEmail, setReplyEmail] = useState<string>();
  const [replySubject, setReplySubject] = useState<string>();
  const [replyMessageID, setReplyMessageID] = useState<string>();
  const [prefilledMessage, setPrefilledMessage] = useState<any>('');
  const [secondPrefilledMessage, setSecondPrefilledMessage] = useState<any>('');
  const { data: university } = useSelfUniversity();
  const { data: identities } = useIdentities();
  const activeEmail = useActiveEmail();

  const messages = useMemo(() => (
    linkedInMessages.map(msg => ({ ...msg, type: 'linkedin' }))
      .concat(emailMessages.map(msg => ({ ...msg, createdAt: msg.sentDateTime, type: 'email' })))
  ), [linkedInMessages, emailMessages]);

  const allSortedActivity: any = useMemo(() => {
    const combined = activityHistory.map(act => ({ ...act, type: 'activity' })).concat(messages);
    return combined.sort((a, b) => {
      const dateA = a.createdAt instanceof Timestamp ? a.createdAt.toDate() : new Date(a.createdAt);
      const dateB = b.createdAt instanceof Timestamp ? b.createdAt.toDate() : new Date(b.createdAt);
      return dateB.getTime() - dateA.getTime();
    });
  }, [messages, activityHistory]);

  const isItFirstOutreach = !allSortedActivity.some((msg :any) => !!msg.fromUserID) && !allSortedActivity.some((msg :any) => !!msg.fromContactID);

  const contFirstName = contactFirstName || '[Contact first name]';
  const studentFirstName = user?.given_name || '[first name]';
  const studentUniversity = university?.name || '[university]';

  const {
    getPremiumStatus,
  } = useExtensionMessaging();

  const handleMessageSentSuccess = () => {
    setMessageFieldType(undefined);
    revalidator.revalidate();
  };

  const scrollToTop = () => {
    if (boxRef.current) {
      boxRef.current.scrollTo(0, 0);
    }
  };

  const checkIfPermissionsGranted = async () => identities && identities?.length > 0;

  const resetReplyFields = () => {
    setReplyEmail(undefined);
    setReplySubject(undefined);
    setReplyMessageID(undefined);
  };

  const handleOpenEmail = async (subject?: string, messageID?: string, from?: string, message?: string) => {
    const granted = await checkIfPermissionsGranted();

    if (!granted) {
      checkEmailPermissions();
    }

    setReplySubject(subject);
    setReplyMessageID(messageID);
    setReplyEmail(from);
    setMessageFieldType('email');
    setPrefilledMessage(message);
  };

  const handleOpenLinkedInMessage = async (message?: string, alternativeMessage?: string) => {
    const isAbleToSendLinkedInMessages = await checkAbilityToSendLinkedIn();

    if (!isAbleToSendLinkedInMessages) {
      return;
    }

    setMessageFieldType('linkedin');
    setPrefilledMessage(message);
    if (alternativeMessage) {
      setSecondPrefilledMessage(alternativeMessage);
    }
    resetReplyFields();
  };

  const handleEmailReply = (subject?: string, messageID?: string, from?: string, message?: string) => {
    handleOpenEmail(subject, messageID, from, message);
    scrollToTop();
  };

  const handleMessageReply = (message?: string) => {
    handleOpenLinkedInMessage(message);
    scrollToTop();
  };

  const handleSentEmail = async (message: string, subject: string, to: string[]) => {
    resetReplyFields();
    handleMessageSentSuccess();
    handleSendEmail(message, subject, to, replyMessageID);
  };

  const getPreffiledEmailMessage = () => {
    if (isItFirstOutreach) {
      return getMessageTemplate.firstEmailTemplate(contFirstName, studentFirstName, studentUniversity, industry || '[industry]', companyName, jobTitle || '[job title]');
    }
    return '';
  };

  const getPreffiledLinkedinMessage = async () => {
    if (isItFirstOutreach) {
      if (contactConnectionStatus === 'notConnected') {
        let isPremium = false;
        try {
          isPremium = await getPremiumStatus();
        } catch {
          isPremium = false;
        }
        if (isPremium) {
          return getMessageTemplate.linkedinConnectionPremiumTemplate(contFirstName, studentFirstName, studentUniversity, companyName, industry || '[industry]');
        }
        return getMessageTemplate.linkedinConnectionTemplate(contFirstName, studentUniversity, companyName, industry || '[industry]');
      }
      return getMessageTemplate.firstMessageTemplate(contFirstName, studentFirstName, companyName, industry || '[industry]');
    }
    return '';
  };

  const handleReplyChoice = async (messageType: MessageType, subject?: string, id?: string, from?: string) => {
    if (messageType === 'email') {
      const msgToPrefill = getPreffiledEmailMessage();
      handleEmailReply(subject, id, from, msgToPrefill);
    } else {
      const msgToPrefill = await getPreffiledLinkedinMessage();
      handleMessageReply(msgToPrefill);
    }
  };

  return (
    <div ref={boxRef} data-testid="message-history" className="message-history">
      <div className="message-history__container">
        <div className="message-history__header">
          <h2 className="message-history__title">
            Your Contact History
          </h2>
          <SendButtonWithOptions
            handleSendButtonClick={handleReplyChoice}
            emailFeatureEnabled={!!primaryEmail}
          />
        </div>
        <MessageBanner
          activeQuests={activeQuests}
          noMessageHistory={allSortedActivity?.filter((msg: any) => msg.type !== 'activity').length === 0}
          hasUserSentMessage={allSortedActivity.some((msg :any) => !!msg.fromUserID)}
          hasContactResponded={allSortedActivity.some((msg :any) => !!msg.fromContactID)}
          lastCompletedQuests={lastCompletedQuests}
          companyName={companyName}
          contactName={contactFirstName}
          companyID={companyID}
          contactConnectionStatus={contactConnectionStatus}
        />
        {messageFieldType === 'email' && (
          <div className="message-history__message-box">
            <EmailBox
              contactFirstName={contactFirstName}
              emailAddresses={[primaryEmail, secondaryEmail]}
              prefilledSubject={replySubject}
              prefilledMessage={prefilledMessage}
              replyEmail={replyEmail}
              handleSendEmail={handleSentEmail}
              primaryEmail={primaryEmail}
              contactID={contactID}
              regenerateData={
                {
                  studentName: studentFirstName,
                  contactName: contFirstName,
                  universityName: studentUniversity,
                  channel: 'email',
                  activeQuest: activeQuests[0],
                  companyName,
                  jobTitle: jobTitle || '[job title]',
                  industry: industry || '[industry]',
                }
              }
            />
          </div>
        )}
        {messageFieldType === 'linkedin' && (
          <div className="message-history__message-box">
            <MessageBox
              toUserID={contactID}
              toUserLinkedInUrn={contactUrn}
              handleConnectionSent={handleConnectionSent}
              isConnection={contactConnectionStatus === 'notConnected'}
              handleSentMesage={handleMessageSentSuccess}
              prefilledMessage={prefilledMessage}
              premiumPrefilledMessage={secondPrefilledMessage}
              regenerateData={
                {
                  studentName: studentFirstName,
                  contactName: contFirstName,
                  universityName: studentUniversity,
                  channel: 'linkedin',
                  activeQuest: activeQuests[0],
                  companyName,
                  jobTitle: jobTitle || '[job title]',
                  industry: industry || '[industry]',
                }
              }
            />
          </div>
        )}

        {allSortedActivity.length !== 0 && (
          <>
            {allSortedActivity.map((activity: any, index: number) => (
              <div
                data-testid="message-history-single-message"
                className="message-history__item"
                key={activity.createdAt instanceof Timestamp ? activity.createdAt.toDate().getTime() : (new Date(activity.createdAt).getTime() + index)}
              >
                {activity.type === 'activity' ? (
                  <HistoryEvent contactName={contactFirstName} type={activity.questType} date={activity.createdAt} />
                ) : (
                  <SingleMessage
                    handleReplyMessage={(subject, id, from) => handleReplyChoice(activity.type, subject, id, from)}
                    message={activity}
                    contactFirstName={contactFirstName}
                    contactImage={contactImage}
                    isRepliable={activity.from === activeEmail || activity.to === activeEmail}
                  />
                )}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
