import './company-card.scss';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Tag } from '@/components/tag/tag';
import { Tooltip } from '@/components/tooltip/tooltip';
import { statuses } from '@/domains/core/tracker-data';
import { ModalContext } from '@/components/modal/modal-provider';
import { changeApplicationStatus, addCompanyToFavorites } from '@/domains/core/company';
import { getSingleCompany } from '@/services/api/company';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { InfoModal } from '@/domains/generic/modals';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { useSelfUniversity } from '@/services/queries/user';
import { TrackerContextType, TrackerContext } from '../tracker-provider/tracker-context';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { useGetQuests } from '@/services/queries/quest';
import { QuestTileType, QuestType } from '@/domains/core/student/types';
import { QUESTSTATUS } from '@/domains/core/student/components/quests/quest-service/quest.service';
import { changeQuestStatus } from '@/services/api/student';
import { formatRange } from '@/services/helpers/company';
import { useApplicationsNoCache, useArchivedCompanies } from '@/services/queries/application';
import { Company, CompanyOfficeLocation } from '../../types';

type ApplicationStatus = 'saved' | 'archived' | 'unsaved';

interface CompanyCardProps {
  id: string;
  imageURL: string;
  companyName: string;
  companyIndustry: string;
  companySize: string;
  companyDescription: string;
  tags: string[];
  companyApplicationId: string;
  fakeUniversityData?: any;
  hasSavedAnimation?: boolean;
  // we will remove it in the next iteration
  isLocationHidden?: boolean;
  setHasNotSavedCompanies?: (value: boolean) => void;
  onArchiveCompany?: () => void;
  officeLocations: CompanyOfficeLocation[];
}

// TODO: handle hasSavedAnimation state using state management instead of component props

/**
 * Primary UI component for company card
 */
export const CompanyCard = ({
  imageURL,
  id,
  companyName,
  companyIndustry,
  companySize,
  companyDescription,
  tags,
  companyApplicationId,
  fakeUniversityData,
  officeLocations,
  hasSavedAnimation = false,
  isLocationHidden = false,
  setHasNotSavedCompanies,
  onArchiveCompany,
}: CompanyCardProps) => {
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { addToast } = useContext(ToastContext) as AddToastType;
  const { trackEvent } = useAnalytics();
  const { data: university } = fakeUniversityData || useSelfUniversity();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { data: questsResponse } = useGetQuests();
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [isLoading, setIsLoading] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState<ApplicationStatus>('unsaved');
  const [applicationId, setApplicationID] = useState(companyApplicationId);
  const [isNameOverflow, setIsNameOverflow] = useState(false);
  const [showSavedAnimation, setShowSavedAnimation] = useState(false);
  const nameElementRef = useRef<HTMLDivElement>(null);
  const { data: applications = [] } = useApplicationsNoCache();
  const { data: archivedCompanies = [] } = useArchivedCompanies();
  const queryClient = useQueryClient();
  const companyHQLocation = useMemo(() => {
    const location = officeLocations.find((officeLocation) => officeLocation.is_hq);
    if (!location) {
      return undefined;
    }
    return location.city ? `${location.city}, ${location.country_name}` : location.country_name;
  }, [officeLocations]);

  const companySavedStatus = (companyID: string) => {
    const isApplication = Array.isArray(applications) && applications?.some((application) => application.company_id === companyID);
    if (!isApplication) {
      return 'unsaved';
    }
    const isArchive = Array.isArray(archivedCompanies) && archivedCompanies?.some((company: any) => company.company_id === companyID);
    if (isArchive) {
      return 'archived';
    }
    return 'saved';
  };

  const goToCompanyTracker = () => {
    openTracker(id);
  };

  const updateApplicationId = async () => {
    const updatedCompany: Company = await getSingleCompany(id);
    setApplicationID(updatedCompany.application_id || '');
  };

  const restoreApplication = async () => {
    try {
      await changeApplicationStatus(applicationId, statuses['Company Saved'], 0);
      setApplicationStatus('saved');
      await updateApplicationId();

      addToast({
        type: 'success',
        message: 'Company Saved to your Overview Board!',
        additionalMessage: 'Click here to check out next steps for this company.',
        handleClick: goToCompanyTracker,
      });
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Error occurred while restoring company.',
      });
    }
  };

  const archiveCompany = async (rememberAction: boolean) => {
    closeModal();
    if (rememberAction) {
      localStorage.setItem('rememberArchiveCompany', 'true');
    }
    try {
      await changeApplicationStatus(applicationId, statuses.Archived, 0);
      setApplicationStatus('archived');
      await updateApplicationId();

      onArchiveCompany?.();

      addToast({
        type: 'success',
        message: 'Company Archived!',
        additionalMessage: 'Click here to view where we’ve parked this company for you.',
        handleClick: () => navigate('/app/archive'),
      });

      await queryClient.invalidateQueries(['archive']);
      await queryClient.invalidateQueries(['applications-no-cache']);
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Error occurred while archiving company.',
      });
      trackEvent('Toast Error Shown', user, {
        message: 'Error occurred while archiving company',
        error,
        companyID: id,
        companyName,
        companyIndustry,
        university: university?.name,
        cohort: university?.cohort ?? '',
      });
    }
  };

  const openAreYouSure = () => {
    const rememberAction = localStorage.getItem('rememberArchiveCompany');
    if (!rememberAction) {
      openModal(
        <InfoModal
          handleButtonClick={archiveCompany}
          handleSecondaryButtonClick={closeModal}
          icon="bi bi-exclamation-diamond"
          title="Are you sure?"
          description={`Archiving will hit pause on your progress with ${companyName} and take it off your Overview Board. Are you sure you want to continue?`}
          buttonLabel="Archive"
          secondaryButtonLabel="Cancel"
          isButtonDanger
          rememberText="Don’t ask me again"
        />,
      );
    } else {
      archiveCompany(true);
    }
  };

  const updateQuestForCompany = async () => {
    if (questsResponse.quests && questsResponse.quests.length > 0) {
      const saveFirstCompanyQuest: QuestTileType | undefined = questsResponse.quests.find((quest: QuestTileType) => quest.type === QuestType.SaveFirstCompany);
      if (saveFirstCompanyQuest) {
        await changeQuestStatus(saveFirstCompanyQuest.id, QUESTSTATUS.DONE, saveFirstCompanyQuest.type);
      }
    }
  };

  const handleSaveCompany = async () => {
    setIsLoading(true);
    trackEvent('Saved Company - Explore Companies', user, {
      companyID: id,
      companyName,
      companyIndustry,
      university: university?.name,
      cohort: university?.cohort ?? '',
    });

    try {
      await addCompanyToFavorites(id);
      setApplicationStatus('saved');
      await updateApplicationId();

      addToast({
        type: 'success',
        message: 'Company Saved to your Overview Board!',
        additionalMessage: 'Click here to check out next steps for this company.',
        handleClick: goToCompanyTracker,
      });

      if (hasSavedAnimation) {
        setHasNotSavedCompanies?.(false);
        setShowSavedAnimation(true);
        updateQuestForCompany();
        setTimeout(() => setShowSavedAnimation(false), 5000);
      }
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Error occurred while saving company.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveCompanyAction: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();

    switch (applicationStatus) {
      case 'unsaved':
        handleSaveCompany();
        break;
      case 'saved':
        openAreYouSure();
        break;
      case 'archived':
        restoreApplication();
        break;
      default:
        break;
    }

    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications-no-cache']);
  };
  const handleTagClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, tag: string) => {
    e.stopPropagation();
    navigate(`/app/companies?tags=${JSON.stringify([encodeURIComponent(tag || '')])}`);
  };
  const goToCompany = () => {
    navigate(`/app/companies/company/${id}`);
  };
  const handleTileClick = () => {
    if (applicationStatus === 'unsaved' || applicationStatus === 'archived') {
      goToCompany();
    } else {
      goToCompanyTracker();
    }
  };

  const renderSaveButton = () => {
    if (applicationStatus === 'unsaved' || applicationStatus === 'archived') {
      return (
        <button className="company-card__action" onClick={handleSaveCompanyAction} type="button" aria-label="Save this company">
          <Tooltip label="Save Company" withArrow position="top">
            <i className="company-card__icon bi bi-bookmark-heart" />
          </Tooltip>
        </button>
      );
    }

    return (
      <button className="company-card__action" onClick={handleSaveCompanyAction} type="button" aria-label="Archive this company">
        <Tooltip label="Archive Company" withArrow position="top">
          <i className="company-card__icon bi bi-bookmark-heart-fill" />
        </Tooltip>
      </button>
    );
  };

  useEffect(() => {
    if (nameElementRef.current) {
      setIsNameOverflow(nameElementRef.current.scrollWidth > nameElementRef.current.clientWidth);
    }
  }, [nameElementRef.current]);

  useEffect(() => {
    setApplicationStatus(companySavedStatus(id));
  }, [applications, archivedCompanies, id]);

  return (
    <div className={`company-card ${showSavedAnimation ? 'company-card--with-animation' : ''}`} onClick={handleTileClick}>
      <div className="company-card__top">
        <DefaultLogo
          source={imageURL}
          type="company"
          name={companyName}
          className="company-card__image"
          data-testid="company-card-image"
          size={80}
        />
        <Tooltip label={companyName} position="top" disableHoverListener={!isNameOverflow}>
          <div
            onClick={handleTileClick}
            data-testid="company-card-name"
            className="company-card__name"
            ref={nameElementRef}
          >
            {companyName}
          </div>
        </Tooltip>
        <div
          data-testid="company-card-industry"
          className="company-card__industry"
        >
          {companyIndustry}
        </div>
        <div data-testid="company-card-location" className="company-card__info">
          {(!isLocationHidden && companyHQLocation) && (
            <div className="company-card__column">
              <i className="company-card__info-icon bi bi-building" />
              {companyHQLocation}
            </div>
          )}
          <div className="company-card__column">
            <i className="company-card__info-icon bi bi-people" />
            {`${formatRange(companySize)} employees`}
          </div>
        </div>
      </div>
      <div className="company-card__action-buttons">
        {isLoading ? <div className="loader-5 center"><span /></div> : renderSaveButton()}
      </div>
      <div className="company-card__description-container">
        <p className="company-card__description">{companyDescription}</p>
      </div>
      {!!tags?.length && (
        <div className="company-card__tags">
          {tags.map((tag) => (
            <Tag
              key={tag}
              handleClick={(e) => handleTagClick(e, tag)}
              label={tag}
            />
          ))}
        </div>
      )}
    </div>
  );
};
