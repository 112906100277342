import { useEffect, useState } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import StarIcon from '@/assets/images/icons/star.svg';
import './goal-progress.scss';
import { Button } from '@/components/button/Button';

interface GradientSVGProps {
  startColor: string;
  endColor: string;
  idCSS: string;
  rotation: string;
}

const GradientSVG = ({
  startColor, endColor, idCSS, rotation,
}: GradientSVGProps) => {
  const gradientTransform = `rotate(${rotation})`;
  return (
    <svg style={{ height: 0, position: 'absolute' }}>
      <defs>
        <linearGradient id={idCSS} gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor={startColor} />
          <stop offset="100%" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

const ProgressProvider = ({ valueStart, valueEnd, children }: any) => {
  const [value, setValue] = useState(valueStart);

  useEffect(() => {
    setValue(valueEnd);
  }, [valueEnd]);

  return children(value);
};

interface CircularProgressProps {
  currentProgress: number;
  goal: number;
  text: string;
  linkLabel: string;
  link: string;
}

export const GoalProgress = ({
  currentProgress, goal, text, linkLabel, link,
}: CircularProgressProps) => {
  const goalReached = currentProgress >= goal;
  const rounderProgressInPercent = () => {
    const progress = (currentProgress / goal) * 100;
    switch (true) {
      case progress >= 0 && progress < 20:
        return 0;
      case progress >= 20 && progress < 40:
        return 20;
      case progress >= 40 && progress < 60:
        return 40;
      case progress >= 60 && progress < 80:
        return 60;
      case progress >= 80 && progress < 100:
        return 80;
      default:
        return 100;
    }
  };

  return (
    <div className={`circular-progress circular-progress--${rounderProgressInPercent()}${goalReached ? ' circular-progress--completed' : ''}`}>
      <ProgressProvider valueStart={0} valueEnd={currentProgress}>
        {(value: number) => (
          <CircularProgressbarWithChildren
            value={value}
            maxValue={goal}
          >
            {goalReached && <img src={StarIcon} alt="" className="circular-progress__star" />}
            <span className="circular-progress__score">
              {currentProgress}
              /
              {goal}
            </span>
            <span className="circular-progress__description">
              {text}
            </span>
          </CircularProgressbarWithChildren>

        )}
      </ProgressProvider>
      {goalReached && (
        <span className="circular-progress__goal-reached">Goal reached</span>
      )}
      <Button
        handleClick={() => window.open(link, '_self')}
        label={goalReached ? 'Keep crushing it!' : linkLabel}
        mode="invisible"
        size="small"
      />
      <GradientSVG
        startColor="#57CC99"
        endColor="#2DA546"
        rotation="-90deg"
        idCSS="100percentPath"
      />
      <GradientSVG
        startColor="#D5432E"
        endColor="#FC5F47"
        rotation="-90deg"
        idCSS="0percentPath"
      />
      <GradientSVG
        startColor="#FF9B3E"
        endColor="#FFAC5F"
        rotation="-90deg"
        idCSS="20percentPath"
      />
      <GradientSVG
        startColor="#FFAA5B"
        endColor="#FFCEA0"
        rotation="-90deg"
        idCSS="40percentPath"
      />
      <GradientSVG
        startColor="#F6C136"
        endColor="#FFDD7A"
        rotation="-90deg"
        idCSS="60percentPath"
      />
      <GradientSVG
        startColor="#B5E48C"
        endColor="#A1DD6D"
        rotation="-90deg"
        idCSS="80percentPath"
      />
    </div>
  );
};
