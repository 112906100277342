import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DOMPurify from 'dompurify';
import { PopupWindowWrapper } from '@/domains/generic';
import { Button } from '@/components/button/Button';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { JobDeadlineEdit } from '../job-deadline-edit/job-deadline-edit';
import './tracker-job-tile.scss';

type JobTileProps = {
  title: string;
  location: string;
  linkedInUrl: string;
  id: string;
  description: string;
  applicationDeadline: string;
  isDateRolling: boolean;
  companyLogo: string;
  companyName: string;
  deleteJob: () => void;
  revalidate: () => void;
};

export const TrackerJobTile = ({
  location,
  title,
  linkedInUrl,
  description,
  id,
  isDateRolling,
  applicationDeadline,
  companyLogo,
  companyName,
  deleteJob,
  revalidate,
}: JobTileProps) => {
  const [showDescription, setShowDescription] = useState(false);
  const [isJobDeadlineEditing, setIsJobDeadlineEditing] = useState(false);
  const jobContainer = useRef<HTMLDivElement>(null);
  const formatDate = (date: string) => new Date(date).toLocaleDateString('en-US', { month: 'short', year: 'numeric', day: 'numeric' });
  const applicationDeadlineText = useMemo(() => (applicationDeadline && !isDateRolling
    ? formatDate(applicationDeadline)
    : 'Rolling'), [applicationDeadline, isDateRolling]);

  const goToJob = () => {
    window.open(linkedInUrl, '_blank');
  };

  const closeJobDeadlineEditing = () => {
    revalidate();
    setIsJobDeadlineEditing(false);
  };

  const openJobDeadlineEditing = () => setIsJobDeadlineEditing(true);

  const handleDeleteClick = () => {
    deleteJob();
  };

  useEffect(() => {
    if (!jobContainer.current) {
      return;
    }
    const newSearchParams = new URLSearchParams(window.location.search);

    if (newSearchParams.has('jobTitle')) {
      const isCurrentJobFromParams = newSearchParams.get('jobTitle') === title;

      setShowDescription(isCurrentJobFromParams);
      if (isCurrentJobFromParams) {
        // waiting for the tracker to be opened so that the animation and scroll are smooth
        setTimeout(() => {
          if (!jobContainer.current) {
            return;
          }
          jobContainer.current.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
        }, 500);
      }
    }
  }, [jobContainer.current]);

  return (
    <div ref={jobContainer} className="tracker-job-tile">
      <div className="tracker-job-tile__header">
        <DefaultLogo
          type="company"
          source={companyLogo}
          className="tracker-job-tile__logo"
          name={companyName}
          size={48}
        />
        <div className="tracker-job-tile__header-text">
          <h4 data-testid="tracker-job-tile-title" className="tracker-job-tile__title">{title}</h4>
          <span data-testid="tracker-job-tile-location" className="tracker-job-tile__location">{location}</span>
        </div>
        <div className="tracker-job-tile__buttons">
          {linkedInUrl && (
            <Button
              handleClick={goToJob}
              mode="primary"
              size="medium"
              icon="bi bi-send-arrow-up"
              label="Apply"
              data-testid="tracker-job-tile-apply-button"
            />
          )}
          <Button
            handleClick={handleDeleteClick}
            mode="dangerous"
            outlined
            size="medium"
            icon="bi bi-trash3"
            label="Delete"
            data-testid="tracker-job-tile-delete-button"
          />
        </div>
      </div>
      <div className="tracker-job-tile__details">
        <div className="tracker-job-tile__deadline-container">
          {isJobDeadlineEditing && (
          <PopupWindowWrapper handleClose={closeJobDeadlineEditing}>
            <JobDeadlineEdit
              isDateRolling={isDateRolling}
              deadlineDate={applicationDeadline?.split('T')[0]}
              handleClose={closeJobDeadlineEditing}
              jobID={id}
              isEditing={(isDateRolling || !!applicationDeadline)}
            />
          </PopupWindowWrapper>
          )}
          {(isDateRolling || !!applicationDeadline) ? (
            <div data-testid="tracker-job-tile-deadline" className="tracker-job-tile__deadline">
              <span>
                Application deadline:
                {' '}
                {applicationDeadlineText}
              </span>
              <i onClick={openJobDeadlineEditing} className="tracker-job-tile__deadline-icon bi bi-pencil-fill" />
            </div>
          ) : (
            <div className="tracker-job-tile__deadline">
              <Button
                label="Add application deadline"
                mode="text"
                size="small"
                handleClick={openJobDeadlineEditing}
                icon="bi bi-plus-circle-fill"
                iconPosition="left"
                data-testid="tracker-job-tile-add-deadline"
              />
            </div>
          )}
        </div>

        {showDescription && (
        <p className="tracker-job-tile__description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
        )}
        <Button
          label={showDescription ? '...see less' : '...see more'}
          mode="text"
          size="small"
          handleClick={() => setShowDescription(!showDescription)}
          data-testid="tracker-job-tile-see-more-button"
        />
      </div>

    </div>
  );
};
