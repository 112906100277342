import { useEffect, useState } from 'react';

export function useSidebar() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [sidebarContent, setSidebarContent] = useState('');
  const { pathname } = new URL(window.location.href);

  const openSidebar = (content: any) => {
    setShowSidebar(!showSidebar);
    if (content) {
      setSidebarContent(content);
    }
  };

  const closeSidebar = () => {
    // eslint-disable-next-line no-restricted-globals
    history.replaceState({}, document.title, window.location.href.split('#')[0]);
    setShowSidebar(false);
  };

  useEffect(() => {
    setShowSidebar(false);
  }, [pathname]);

  return {
    showSidebar, openSidebar, sidebarContent, closeSidebar,
  };
}
