import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef, useState } from 'react';
import { FilterOption } from '@/domains/core/company/types';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { MultiSelectOption } from './multi-select-option/multi-select-option';
import './multi-select.scss';

type Props = {
  options: FilterOption[];
  placeholder: string;
  handleFiltering: (options: FilterOption[]) => void;
  selected: FilterOption[];
  withSelectAllOption?: boolean;
  size?: 'small' | 'medium' | 'large' | 'full';
  labelType: 'list' | 'number' | 'filterAndOptions';
  width?: 'full' | 'fit-box';
};

export const MultiSelect = ({
  placeholder,
  options,
  handleFiltering,
  labelType,
  selected,
  size = 'medium',
  withSelectAllOption = false,
  width = 'full',
}: Props) => {
  const { trackEvent } = useAnalytics();
  const { user } = useAuth0();
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>([]);
  const [isListOpened, setIsListOpened] = useState(false);
  const container = useRef<HTMLDivElement>(null);

  const handleFilterClick = () => {
    setIsListOpened(!isListOpened);

    trackEvent(`${placeholder} Filter clicked`, user);
  };

  const handleSelectAll = () => {
    if (selectedOptions.length === options.length) {
      handleFiltering([]);
      setSelectedOptions([]);
    } else {
      handleFiltering(options);
      setSelectedOptions(options);
    }
  };

  const handleItemSelect = (option: FilterOption) => {
    let newSelectedOptions: FilterOption[] = [];
    if (selectedOptions.map(o => o.value).includes(option.value)) {
      newSelectedOptions = selectedOptions.filter((item) => item.value !== option.value);
    } else {
      newSelectedOptions = [...selectedOptions, option];
    }
    handleFiltering(newSelectedOptions);
    setSelectedOptions(newSelectedOptions);
  };

  const handleBlur = (e: any) => {
    if (!container.current?.contains(e.target)) {
      setIsListOpened(false);
    }
  };

  const renderSwitchLabel = () => {
    switch (labelType) {
      case 'number':
        return (
          <>
            {placeholder}
            <span className="kanban-filter__number">{selectedOptions.length}</span>
          </>
        );
      case 'filterAndOptions':
        return `${placeholder} (${selectedOptions.map(o => o.name).join(', ')})`;
      case 'list':
      default:
        return selectedOptions.map(o => o.name).join(', ');
    }
  };

  useEffect(() => {
    setSelectedOptions(selected);
  }, [selected.length]);

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);

    return () => document.removeEventListener('mousedown', handleBlur);
  }, []);

  return (
    <div className={`kanban-filter kanban-filter--${size}`} ref={container}>
      <div
        data-testid="kanban-filter-field"
        className={`kanban-filter__field ${!selectedOptions.length ? 'kanban-filter__field--placeholder' : ''} ${isListOpened ? 'kanban-filter__field--focused' : ''}`}
        onClick={handleFilterClick}
      >
        <span className="kanban-filter__name">{selectedOptions.length ? renderSwitchLabel() : placeholder}</span>
        <i
          className={`kanban-filter__icon ${isListOpened ? 'kanban-filter__icon--opened' : ''} bi bi-caret-down-fill`}
        />
      </div>
      {isListOpened && (
        <div className="kanban-filter__dropdown">
          <ul className="kanban-filter__list">
            {withSelectAllOption && (
              <li
                className="kanban-filter__item kanban-filter__item--fixed"
                onClick={handleSelectAll}
              >
                <div className={`kanban-filter__checkbox ${selectedOptions.length === options.length ? 'kanban-filter__checkbox--minus' : ''}`} />
                <span>
                  {selectedOptions.length === options.length ? 'Deselect all' : 'Select all'}
                </span>
              </li>
            )}
            {options.map((option) => (
              <li
                key={option.value.toString()}
                className="kanban-filter__item"
                onClick={() => handleItemSelect(option)}
              >
                {/* <div className={`kanban-filter__checkbox ${selectedOptions.map(o => o.value).includes(option.value) ? 'kanban-filter__checkbox--checked' : ''}`} />
                <span className="kanban-filter__item-name">
                  <Tooltip label={option.name.toString()} position="top">
                    {option.name}
                  </Tooltip>
                </span> */}
                <MultiSelectOption width={width} isSelected={selectedOptions.map(o => o.value).includes(option.value)} name={option.name} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
