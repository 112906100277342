import { useAuth0 } from '@auth0/auth0-react';
import {
  useContext,
  useDeferredValue, useEffect, useState,
} from 'react';
import { useQueryClient } from 'react-query';
import Table from '@/components/table/table';
import { Column, TableRow } from '@/components/table/table-types';
import { EmptyContactsList } from '@/domains/core/contact';
import { DefaultLogo } from '@/components/default-logo/default-logo';

import './styles/contacts.scss';
import { router } from '@/main';
import { Input } from '@/components/input/input';
import { useSelf } from '@/services/queries/user';
import { ConnectionStatus, Contact } from '@/domains/core/contact/types';
import { StatusTag } from '@/domains/core/contact/components/status-tags/status-tag';
import { useNetworkingStatuses } from '@/services/hooks/use-networking-statuses';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';
import { ContactsKanbanBoard } from '@/domains/core/contact/components';
import { updateContactPositionInKanban } from '@/services/api/contact';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { ToggleViews } from '@/components/toggle-views/toggle-views';
import { useGetContacts } from '@/services/queries/contact';
import { Loader } from '@/components/loader/loader';
import { IconButton } from '@/components/icon-button/icon-button';
import { Tooltip } from '@/components/tooltip/tooltip';

const columns: string[] = [
  'Contact Saved',
  '1st Outreach',
  '2nd Outreach',
  'Response Received',
  'Coffee Chat',
  'Nurturing',
  'Golden Referral',
];
type ColumnName = typeof columns[number];

type KanbanBoard = {
  [K in ColumnName]: Contact[];
};
const ContactsTableColumns: Column[] = [
  {
    label: 'Name', accessor: 'full_name', sortable: true, type: 'link', isCentered: false,
  },
  {
    label: 'Company', accessor: 'company', sortable: true, type: 'link', sortbyOrder: 'asc', isCentered: false,
  },
  {
    label: 'Job Title', accessor: 'job_title', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Email', accessor: 'email', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'LinkedIn Status', accessor: 'linkedin_status', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Networking Status', accessor: 'networking_status', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Actions', accessor: 'actions', type: 'actions', sortable: false, isCentered: false,
  },
];

const ContactsTableColumnsWithoutStatuses: Column[] = ContactsTableColumns.filter((column) => column.label !== 'Networking Status' && column.label !== 'LinkedIn Relationship');

type ContactWithNetworkingStatus = Contact & {
  networking_status?: ConnectionStatus;
};

const calculateNetworkingStatusValue = (status: ConnectionStatus) => {
  const statusValues = {
    connected: 3,
    pending: 2,
    notConnected: 1,
  };

  return statusValues[status];
};

const mapContacts: (contacts: ContactWithNetworkingStatus[], openTracker: (id: string, tab?: string | undefined, additionalParams?: {
  [key: string]: string;
}) => void) => TableRow[] = (contacts, openTracker) => (Array.isArray(contacts) ? contacts.map((contact) => ({
  id: contact.id,
  handleRowClick: () => router.navigate(`/app/inbox/${contact.id}`),
  items: {
    full_name: {
      value: `${contact.first_name} ${contact.last_name}`,
      label: `${contact.first_name} ${contact.last_name}`,
      image: <DefaultLogo
        type="contact"
        source={contact.career_summary.basics.image}
        className="contacts-page__table-image"
        name={`${contact.first_name} ${contact.last_name}`}
        size={24}
      />,
      type: 'link',
      to: `/app/inbox/${contact.id}`,
    },
    company: {
      value: contact.current_position.company,
      label: contact.current_position.company,
      image: <DefaultLogo
        type="company"
        source={contact.current_position.company_logo_url}
        className="contacts-page__table-image"
        name={contact.current_position.company}
        size={24}
      />,
      type: 'link',
      handleClick: () => openTracker(encodeURIComponent(contact.current_position.company_id)),
    },
    job_title: {
      type: 'text',
      label: contact.current_position.title,
      value: contact.current_position.title,
    },
    email: {
      type: 'text',
      label: contact.email ? (
        <span className="contacts-page__contact-email">
          {[contact.email, contact.secondary_email].filter(Boolean).join(', ')}
        </span>
      ) : (
        <span className="contacts-page__table-placeholder">no email found</span>
      ),
      value: [contact.email, contact.secondary_email].filter(Boolean).join(', ') || '',
    },
    networking_status: {
      type: 'text',
      label: (
        <div className="contacts-page__statuses">
          <StatusTag status={contact.networking_status || 'notConnected'} type="networking" />
        </div>
      ),
      value: calculateNetworkingStatusValue(contact.networking_status || 'notConnected'),
    },
    linkedin_status: {
      type: 'text',
      label: (
        <div className="contacts-page__statuses">
          <StatusTag status={contact.linkedin_connection_status} type="linkedin" withTooltip />
        </div>
      ),
      value: calculateNetworkingStatusValue(contact.linkedin_connection_status),
    },
    actions: {
      label: (
        <div className="contacts-page__actions">
          <div className="contacts-page__action">
            <Tooltip label="View profile" position="top" withArrow>
              <IconButton
                icon="bi bi-linkedin"
                mode="rounded"
                size="small"
                outlined
                handleClick={() => window.open(contact.linkedin_url, '_blank')}
                hoverIcon="bi bi-box-arrow-up-right"
              />
            </Tooltip>
          </div>
        </div>
      ),
      value: calculateNetworkingStatusValue(contact.networking_status || 'notConnected'),
    },
  },
})) : []);

const ContactsPage = () => {
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { trackEvent } = useAnalytics();
  const { data: contacts, isLoading: areContactsLoading } = useGetContacts();
  const [selectedView, setSelectedView] = useState<'list' | 'kanban'>('kanban');
  const [preparedContacts, setPreparedContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [tableData, setTableData] = useState<TableRow[]>(mapContacts(filteredContacts, openTracker));
  const [searchQuery, setSearchQuery] = useState('');
  const { addToast }: AddToastType = useContext(ToastContext);
  const { user } = useAuth0();
  const queryClient = useQueryClient();
  const { data: self } = useSelf();
  const deferredQuery = useDeferredValue(searchQuery);
  const { mapAllConnectionStatuses, isLoading } = useNetworkingStatuses();
  const [kanbanLayout, setKanbanLayout] = useState<KanbanBoard | null>(null);

  const filterContacts = (queryString: string) => {
    setFilteredContacts(preparedContacts.filter((contact) => {
      const fullName = `${contact.first_name} ${contact.last_name}`;
      return fullName.toLowerCase().includes(queryString.toLowerCase())
        || contact.current_position.company.toLowerCase().includes(queryString.toLowerCase())
        || contact.current_position.title.toLowerCase().includes(queryString.toLowerCase());
    }));
  };

  const handleViewChange = (value: number) => {
    setSelectedView(value === 0 ? 'kanban' : 'list');
  };

  const trackStatusChange = (status: string, newStatus: string) => {
    if (status === newStatus) {
      return;
    }

    if (newStatus !== 'Contact Saved') {
      trackEvent(`Kanban Contact moved to ${newStatus}`, user);
    }
  };

  const cardChangeHandler = async (cardInfo: any, newStatus: string) => {
    const { id, status, dropPosition } = cardInfo;
    const newKanban: any = { ...kanbanLayout };
    const oldKanban: any = { ...kanbanLayout };

    const contactCard = newKanban[status].find((contact: any) => contact.id === id);
    newKanban[status] = newKanban[status].filter((contact: any) => contact.id !== id);

    newKanban[newStatus].splice(dropPosition, 0, contactCard);

    setKanbanLayout(newKanban);
    trackStatusChange(status, newStatus);
    const droppedColumn = columns.indexOf(newStatus);

    const response = await updateContactPositionInKanban(id, droppedColumn, dropPosition);
    // Handle the API response and possible error states
    if (response && response.message === 'The position has been updated') {
      addToast({
        type: 'success',
        message: 'Contact status updated',
        additionalMessage: 'The contact status has been successfully updated.',
      });
    } else {
      addToast({
        type: 'error',
        message: 'Failed to update contact status',
        additionalMessage: "We're looking into the issue. Please try again later.",
      });
      trackEvent('Toast Error Shown', user, {
        message: 'Failed to update company status',
        error: response.error,
      });
      oldKanban[newStatus] = oldKanban[newStatus].filter((contact: any) => contact.id !== id);
      setKanbanLayout(oldKanban);
    }
    await queryClient.invalidateQueries(['applications']);
  };

  const sortByPosition = (unsortedTiles: Contact[]): Contact[] => unsortedTiles.sort((a, b) => {
    if (a.kanban_position.row > b.kanban_position.row) {
      return 1;
    } if (a.kanban_position.row < b.kanban_position.row) {
      return -1;
    }
    return 1;
  });
  const computeKanbanLayout = (allContacts: Contact[]) => {
    const kanban = {
      'Contact Saved': sortByPosition(allContacts.filter((contact) => contact.kanban_position.column === 0)),
      '1st Outreach': sortByPosition(allContacts.filter((contact) => contact.kanban_position.column === 1)),
      '2nd Outreach': sortByPosition(allContacts.filter((contact) => contact.kanban_position.column === 2)),
      'Response Received': sortByPosition(allContacts.filter((contact) => contact.kanban_position.column === 3)),
      'Coffee Chat': sortByPosition(allContacts.filter((contact) => contact.kanban_position.column === 4)),
      Nurturing: sortByPosition(allContacts.filter((contact) => contact.kanban_position.column === 5)),
      'Golden Referral': sortByPosition(allContacts.filter((contact) => contact.kanban_position.column === 6)),
    };
    setKanbanLayout(kanban);
  };

  useEffect(() => {
    if (deferredQuery) {
      filterContacts(deferredQuery.trim());
    } else {
      setFilteredContacts(preparedContacts);
    }
  }, [deferredQuery]);

  useEffect(() => {
    if (preparedContacts.length) {
      setFilteredContacts(preparedContacts);
      computeKanbanLayout(preparedContacts);
    }
  }, [preparedContacts]);

  useEffect(() => {
    setTableData(mapContacts(filteredContacts, openTracker));
  }, [filteredContacts]);

  useEffect(() => {
    if (self && !areContactsLoading && contacts) {
      computeKanbanLayout(contacts);
      setFilteredContacts(contacts);
      mapAllConnectionStatuses(contacts, self.id)
        .then((contactsWithStatus) => setPreparedContacts(contactsWithStatus));
    }
  }, [contacts?.length, self, areContactsLoading]);

  useEffect(() => {
    if (filteredContacts?.length) {
      computeKanbanLayout(filteredContacts);
    }
  }, [filteredContacts]);

  if (areContactsLoading || !kanbanLayout) {
    return (
      <div id="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="contacts-page" id="main">
      {Array.isArray(contacts) && kanbanLayout && contacts.length > 0 ? (
        <>
          <div className="contacts-page__top-bar">
            <ToggleViews
              labels={['Kanban View', 'List View']}
              size="small"
              selectedValue={selectedView === 'kanban' ? 0 : 1}
              emitSelectedValue={handleViewChange}
              icons={['bi-kanban', 'bi-grid-3x3']}
              iconsSelected={['bi-kanban-fill', 'bi-table']}
            />
            <div className="contacts-page__search">
              <Input
                icon="bi bi-search"
                inputSize="medium"
                placeholder="Type by contact name, company or job title"
                value={searchQuery}
                id="contacts-search"
                label=""
                handleValueChange={setSearchQuery}
              />
            </div>
          </div>
          {selectedView === 'list'
            ? <Table columns={isLoading ? ContactsTableColumnsWithoutStatuses : ContactsTableColumns} data={tableData} isRowClickable />
            : (
              <ContactsKanbanBoard
                onChange={cardChangeHandler}
                columns={columns}
                layout={kanbanLayout}
              />
            )}
        </>
      ) : (
        <EmptyContactsList />
      )}
    </div>
  );
};

export default CustomWithAuthenticationRequired(ContactsPage);
