import { TableRow } from '@/components/table/table-types';
import { TagType } from '@/components/tag/tag';
import { addTagToStudent, removeTagFromStudent } from '../api/advisor';

function findLengthByUserID(data: any, userID: string) {
  // Iterate over each sub-array
  // eslint-disable-next-line no-restricted-syntax
  for (const subArray of data) {
    // Check if the sub-array is an array and contains objects with the specified userID
    if (Array.isArray(subArray)) {
      const filteredData = subArray.filter(item => item.userID === userID);
      if (filteredData.length > 0) {
        // Return the length of the first matching sub-array
        return filteredData.length;
      }
    }
  }

  // Return 0 if no matching sub-array is found
  return 0;
}

export const mapCompaniesSavedByStudentsToTableData = (companies: any[], cohortID: string): TableRow[] => companies.map((company: any) => ({
  id: company.company_id,
  items: {
    company_name: {
      label: company.company_name,
      value: company.company_name,
    },
    industry: {
      label: company.industry,
      value: company.industry,
    },
    count: {
      label: company.count,
      value: company.count,
    },
    company_id: {
      label: company.company_id,
      value: company.company_id,
    },
    logo: {
      label: '',
      value: '',
      type: 'image',
      src: company.logo_url,
      alt: 'Networking Icon',
    },
    actions: {
      label: '',
      value: '',
      type: 'link-icon',
      icon: 'bi bi-eye',
      tooltip: 'See Engagement',
      to: `/app/company-overview/${company.company_id}/cohort/${cohortID}`,
    },
  },
}));

const handleAddTag = async (tag: TagType, student: any) => {
  await addTagToStudent(tag, student.id, student.universityID);
};

const handleRemoveTag = async (tag: TagType, student: any) => {
  await removeTagFromStudent(tag, student.id, student.universityID);
};

export const mapStudentsToResumeTableData = (students: any[]): TableRow[] => students.map((student: any) => ({
  id: student.id,
  items: {
    full_name: {
      label: student.name,
      value: student.name,
    },
    base_resumes: {
      label: student.base_resume_count,
      value: student.base_resume_count,
    },
    tailored_resumes: {
      label: student.tailored_resume_count,
      value: student.tailored_resume_count,
    },
    resumes: {
      label: student.base_resume_count + (student.tailored_resume_count),
      value: student.base_resume_count + (student.tailored_resume_count),
    },
    actions: {
      type: 'link-icon',
      icon: 'bi bi-eye',
      tooltip: 'See Student Resumes',
      to: `/app/resume-os/student-resumes/${student.id}`,
      state: { name: student.name, message: 'Hello from Home!' },
      label: '',
      value: '',
    },
  },
}));

function sumCountOfContacts(data: any, targetUserID:string) {
  // Flatten the nested array structure
  const flattenedData = data.flat();

  // Filter companies by userID
  const userCompanies = flattenedData.filter((company: any) => company.user_id === targetUserID);

  // Sum the count_of_contacts
  const totalContacts = userCompanies.reduce((sum: number, company: any) => sum + company.count_of_contacts, 0);

  return totalContacts;
}

export const mapStudentsToTableData = (students: any[], tiles: any, tags: TagType[]): TableRow[] => students.map((student: any) => ({
  id: student.id,
  items: {
    full_name: {
      label: student.name,
      value: student.name,
    },
    networking: {
      type: 'icon',
      src: student.messagesSent > 0 || student.messagesReceived > 0 ? 'bi bi-check-circle-fill' : 'bi bi-x-circle',
      alt: 'Networking Icon',
      label: 'networking',
      value: student.messagesSent + student.messagesReceived,
    },
    companies: {
      label: findLengthByUserID(tiles, student.id),
      value: findLengthByUserID(tiles, student.id),
    },
    contacts: {
      label: sumCountOfContacts(tiles, student.id),
      value: sumCountOfContacts(tiles, student.id),
    },
    actions: {
      type: 'link-icon',
      icon: 'bi bi-eye',
      tooltip: 'See Overview Board',
      to: `/app/student-overview/${student.id}/university/${student.universityID}`,
      label: '',
      value: '',
    },
    documents: {
      type: 'link-icon',
      icon: 'bi bi-file-earmark',
      tooltip: 'See Documents',
      to: `/app/student-overview/${student.id}/university/${student.universityID}#Documents`,
      label: '',
      value: '',
    },
    tags: {
      type: 'tags',
      tags: student.tags,
      allTags: tags,
      addTag: (tag) => handleAddTag(tag, student),
      removeTag: (tag) => handleRemoveTag(tag, student),
      student,
      label: '',
      value: '',
      id: student.id,
    },
  },
}));
