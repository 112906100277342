import { useContext } from 'react';
import { useRevalidator } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Button } from '@/components/button/Button';
import { ModalContext } from '@/components/modal/modal-provider';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { Document, DocumentType } from '../../types/document';
import { InfoModal } from '@/domains/generic/modals';
import { deleteDocument } from '@/services/api/documents';
import { useAnalytics } from '@/services/hooks/use-analytics';
import './document-table.scss';

interface DocumentTableProps {
  documents: Document[];
  accessType?: 'student' | 'advisor';
}

export const DocumentTable = ({
  documents,
  accessType = 'student',
}: DocumentTableProps) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { trackEvent } = useAnalytics();
  const revalidator = useRevalidator();
  const getDocumentTypeClass = (type: DocumentType) => {
    switch (type) {
      case 'Resume':
        return 'resume';
      case 'Cover Letter':
        return 'cl';
      case 'Other':
        return 'test';
      case 'Link':
        return 'link';
      case 'Personality Test Results':
        return 'test';
      default:
        return '';
    }
  };

  const getFileType = (fileName: string) => {
    const extensioRegExp = /(?:\.([^.]+))?$/;
    const fileExtension = extensioRegExp.exec(DOMPurify.sanitize(fileName));

    return fileExtension ? fileExtension[1] : '';
  };

  const isFileSafe = (link: string) => {
    const fileExtension = getFileType(link);
    const allowedExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
    return allowedExtensions.includes(fileExtension);
  };

  const handleDeleteDocument = async (documentID: string) => {
    try {
      await deleteDocument(documentID);
      trackEvent('Document Deleted');
      revalidator.revalidate();
      closeModal();
    } catch (error) {
      console.error('Error uploading document:', error);
    }
  };

  const handleRemoveClick = (documentName: string, documentID: string) => {
    openModal(
      <InfoModal
        handleButtonClick={() => handleDeleteDocument(documentID)}
        handleSecondaryButtonClick={closeModal}
        buttonLabel="Delete"
        secondaryButtonLabel="Cancel"
        description={`Are you sure you want to remove ${documentName} from your documents list? `}
        title="Delete Document?"
        isButtonDanger
      />,
    );
  };

  const getIcon = (link: string) => {
    const extension = getFileType(link);
    if (extension === 'pdf') return 'bi bi-filetype-pdf';
    if (extension === 'xls' || extension === 'xlsx') return 'bi bi-filetype-xls';

    return 'bi bi-filetype-doc';
  };

  if (!documents.length) {
    return (
      <div className="document-table document-table--empty">
        <div className="document-table__image document-table__image--no-docs">🧑‍💻</div>
        <div className="document-table__title document-table__title--no-docs">
          {accessType === 'student' ? 'You haven’t uploaded any documents yet' : 'This student has not uploaded any documents yet'}
        </div>
      </div>
    );
  }

  return (
    <table className="document-table">
      <thead className="document-table__head">
        <tr className="document-table__head-tr">
          <th className="document-table__th">Title</th>
          <th className="document-table__th">Uploaded</th>
          <th className="document-table__th">Document Type</th>
          <th className="document-table__th">Actions</th>
        </tr>
      </thead>
      <tbody className="document-table__body">
        {documents.map((document) => (
          <tr className="document-table__body-tr" key={document.id}>
            <td className="document-table__td document-table__title">
              {document.type !== 'Link'
                ? (
                  <span>
                    {isFileSafe(document.link) && (
                      <>
                        <i className={getIcon(document.link)} />
                        <a className={document.status === 'safe' ? '' : 'disabled'} href={DOMPurify.sanitize(document.link)} target="_blank" rel="noreferrer">
                          <div className="document-table__title-name">{DOMPurify.sanitize(document.name)}</div>
                        </a>
                      </>
                    )}
                  </span>
                )
                : (
                  <a href={DOMPurify.sanitize(document.link)} target="_blank" rel="noreferrer">
                    <i className="bi bi-camera-video" />
                    <div className="document-table__title-name">{DOMPurify.sanitize(document.name)}</div>
                  </a>
                )}
            </td>
            <td className="document-table__td">{formatDateDeltaFromNow(document.created_at)}</td>
            <td className="document-table__td">
              <span className={`document-table__type document-table__type--${getDocumentTypeClass(document.type)}`}>
                {document.type}
              </span>
            </td>
            <td className="document-table__td">
              <div className="document-table__actions">
                <div className="document-table__action-download">
                  {document.type !== 'Link' ? (
                    <>
                      <i className="bi bi-download" />
                      <a className={`document-table__download-link ${isFileSafe(document.link) ? '' : 'disabled'}`} href={document.link} download>
                        Download
                      </a>
                    </>
                  ) : (
                    <>
                      <i className="bi bi-camera-video" />
                      <a className="document-table__download-link" href={document.link} target="_blank" rel="noreferrer">View</a>
                    </>
                  )}
                </div>
                {accessType === 'student' && (
                  <div className="document-table__action-delete">
                    <i className="bi bi-trash3" />
                    <Button disabled={document.status === 'scanning'} mode="reset" label="Remove" size="medium" handleClick={() => handleRemoveClick(document.name, document.id)} />
                  </div>
                )}
              </div>
              {/* {accessType === 'student' ? (
                <>
                  <Button mode="text" label="Edit" size="medium" handleClick={() => handleEditClick(document)} />
                  <Button mode="text" label="View Comments" size="medium" />
                  <Button mode="text" label="Remove" size="medium" handleClick={() => handleRemoveClick(document.name, document.id)} />
                </>
              ) : (
                <>
                  <Button mode="text" label="View" size="medium" handleClick={() => viewDocument(document.link)} />
                  <Button mode="text" label="Comment" size="medium" />
                </>
              )} */}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
