import {
  AiPayloadSchema,
  BaseResumeListingResponse,
  BaseResumeScoreResponse,
  ResumeComment,
  ResumeResponse,
  ResumeSchema,
  ResumeTemplates,
  TailoredResumeListingResponse,
} from '@/domains/core/resume/types';
import { http } from './http';
import { ParamsObject } from '@/domains/core/resume/helpers/resume-rules';

export const uploadBaseResume = async (resume: FormData): Promise<ResumeResponse> => http.post('/resume/base', resume);

export const createBaseResume = async (resume: ResumeSchema): Promise<any> => http.post('/resume/base/empty', resume);

export const getBaseResumes = async (): Promise<BaseResumeListingResponse[]> => http.get('/resume/base');

export const getTailoredResumes = async (): Promise<TailoredResumeListingResponse[]> => http.get('/resume/tailored');

export const getBaseResume = async (id: string): Promise<ResumeResponse> => http.get(`/resume/base/${id}`);

export const getStudentBaseResume = async (studentID: string, resumeID: string): Promise<ResumeResponse> => http.get(`/resume/base/${resumeID}/student/${studentID}`);

export const getStudentTailoredResume = async (studentID: string, resumeID: string): Promise<ResumeResponse> => http.get(`/resume/tailored/${resumeID}/student/${studentID}`);

export const deleteBaseResume = async (id: string): Promise<boolean> => http.delete(`/resume/base/${id}`);

export const getBaseResumeScore = async (resume: ResumeSchema, scoreParams?: ParamsObject): Promise<BaseResumeScoreResponse> => http.post('/resume/score', {
  ...resume,
  params: scoreParams || {},
});

export const putBaseResume = async (id: string, resume: ResumeSchema, userID: string, resumeTemplate: ResumeTemplates, title: string, url?: string, pdfURL?: string): Promise<any> => http.put(`/resume/base/${id}`, {
  resume,
  user_id: userID,
  resume_template: resumeTemplate,
  title,
  preview_url: url || '',
  source_url: pdfURL || '',
});

export const getBaseResumeAiSuggestions = async (resume: ResumeSchema): Promise<any> => http.patch('/ai/assistant/resume/base', resume);
export const getJobResumeAiSuggestions = async (aiPayload: AiPayloadSchema): Promise<AiPayloadSchema> => http.patch('/ai/assistant/resume/tailored', aiPayload);

// Job Tailored

export const createJobTailoredResume = async (baseResumeID: string, jobID: string): Promise<any> => http.post('/resume/tailored', {
  base_resume_id: baseResumeID,
  job_id: jobID,
});
export const updateJobTailoredResume = async (id: string, resume: ResumeSchema, userID: string, resumeTemplate: ResumeTemplates, title: string, url?: string, pdfURL?: string): Promise<any> => http.put(`/resume/tailored/${id}`, {
  resume,
  user_id: userID,
  resume_template: resumeTemplate,
  title,
  preview_url: url || '',
  source_url: pdfURL || '',
});
export const getTailoredResume = async (id: string): Promise<ResumeResponse> => http.get(`/resume/tailored/${id}`);
export const deleteTailoredResume = async (id: string): Promise<boolean> => http.delete(`/resume/tailored/${id}`);

export const getResumeTemplates = async (): Promise<ResumeTemplates[]> => http.get('/resume/templates');

export const uploadResumeImage = async (image:FormData): Promise<string> => http.post('/resume/preview', image);

export const uploadResumePDF = async (pdf:FormData): Promise<any> => http.post('/resume/source', pdf);

export const getResumeComments = async (resumeID: string): Promise<ResumeComment[]> => http.get(`/resume/${resumeID}/comments`);

export const resolveResumeComment = async (commentID: string): Promise<any> => http.patch(`/resume/comments/${commentID}`, {
  resolved: true,
});
