import { ReactNode } from 'react';
import './challenge-progress.scss';

interface ChallengeProps {
  completedText: string;
  progress: number;
  badge: string
  maxProgress: number;
  children: ReactNode;
}

export const ChallengeProgress = ({
  completedText,
  progress,
  badge,
  maxProgress,
  children,
}: ChallengeProps) => {
  const isChallengeCompleted = progress >= maxProgress;
  return (
    <div className={`challenge ${isChallengeCompleted ? 'challenge--completed' : ''}`}>
      <img src={badge} alt="" className="challenge__badge" />
      <p className="challenge__description">
        {isChallengeCompleted ? completedText : children}
      </p>
      {!isChallengeCompleted ? (
        <>
          <div className="challenge__progress-container">
            <div
              className="challenge__progress-bar"
              style={{ width: `${(progress / maxProgress) * 100}%` }}
            />
          </div>
          <span className="challenge__progress-meter">
            {progress}
            {' '}
            /
            {' '}
            {maxProgress}
          </span>
        </>
      ) : (
        <span className="challenge__progress-meter challenge__progress-meter--completed">
          {maxProgress}
          {' '}
          /
          {' '}
          {maxProgress}
        </span>
      )}
    </div>
  );
};
