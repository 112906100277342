/* eslint-disable no-bitwise */
import { useEffect, useState } from 'react';
import './badge.scss';

export type BadgeColor = 'light-purple' | 'light-blue' | 'light-green' | 'light-red' | 'yellow' | 'gradient' | 'grey' | 'purple-outline' |
'dark-grey' | 'black' | 'green' | 'red' | 'medium-grey' | 'low-percentage-color' | 'medium-percentage-color' | 'high-percentage-color' | 'dark-gradient' | 'blue-outline';

type Props = {
  color: BadgeColor;
  size?: 'medium' | 'large';
  label: string;
  icon?: string;
  iconPosition?: 'left' | 'right';
  iconColor?: string;
};

export const Badge = ({
  color,
  label,
  icon,
  size = 'medium',
  iconPosition = 'right',
  iconColor,
}: Props) => (
  <div className={`careerOS-badge careerOS-badge--${color} careerOS-badge--${size}`}>
    {icon && iconPosition === 'left' && (
      <i style={iconColor ? { color: iconColor } : {}} className={`careerOS-badge__icon ${icon}`} />
    )}
    <span className="careerOS-badge__label">{label}</span>
    {icon && iconPosition === 'right' && (
      <i style={iconColor ? { color: iconColor } : {}} className={`careerOS-badge__icon ${icon}`} />
    )}
  </div>
);

type RangeBadgeProps = {
  label: string;
  percentageOfCompletion: number;
  rangeColors: string[];
  isDisabled?: boolean;
};

export const RangeBadge = ({
  label,
  percentageOfCompletion,
  rangeColors = ['#2DA546', '#FBCF58', '#DE3730'],
  isDisabled = false,
}: RangeBadgeProps) => {
  const [backgroundColor, setBackgroundColor] = useState('');

  const hexToRgb = (hex: string): [number, number, number] => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };

  const rgbToHex = (r: number, g: number, b: number): string => `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;

  const interpolateColor = (color1: [number, number, number], color2: [number, number, number], factor: number): [number, number, number] => {
    const r = Math.round(color1[0] + factor * (color2[0] - color1[0]));
    const g = Math.round(color1[1] + factor * (color2[1] - color1[1]));
    const b = Math.round(color1[2] + factor * (color2[2] - color1[2]));
    return [r, g, b];
  };

  const calculateColor = (number: number): string => {
    if (number < 0 || number > 100) {
      throw new Error('Percentage of completion must be between 0 and 100');
    }

    const red = hexToRgb(rangeColors[0]);
    const yellow = hexToRgb(rangeColors[1]);
    const green = hexToRgb(rangeColors[2]);

    let interpolatedColor: [number, number, number];

    if (number <= 50) {
      const factor = number / 50;
      interpolatedColor = interpolateColor(red, yellow, factor);
    } else {
      const factor = (number - 50) / 50;
      interpolatedColor = interpolateColor(yellow, green, factor);
    }

    return rgbToHex(interpolatedColor[0], interpolatedColor[1], interpolatedColor[2]);
  };

  useEffect(() => {
    const color = calculateColor(percentageOfCompletion);
    setBackgroundColor(color);
  }, [percentageOfCompletion]);

  return (
    <div className={`careerOS-range-badge ${isDisabled ? 'careerOS-range-badge--disabled' : ''}`} style={isDisabled ? {} : { backgroundColor }}>
      <span className="careerOS-range-badge__label">{label}</span>
    </div>
  );
};
