import { useState } from 'react';
import { Button } from '@/components/button/Button';
import { DropdownList } from '@/components/dropdown-list/dropdown-list';

import './templates-button.scss';
import { Tooltip } from '@/components/tooltip/tooltip';

type Props = {
  isIconButton?: boolean;
  showOutreachButton: boolean;
  triggerOutreachTemplateButton: () => void;
  triggerCustomTemplateButton: () => void;
};

export const TemplatesButton = ({
  showOutreachButton,
  triggerOutreachTemplateButton,
  triggerCustomTemplateButton,
  isIconButton = false,
}: Props) => {
  const [isListOpened, setIsListOpened] = useState(false);
  const handleDropdownButtonClick = () => {
    setIsListOpened(!isListOpened);
  };

  const handleCustomTemplateOptionClick = () => {
    triggerCustomTemplateButton();
    setIsListOpened(false);
  };

  const handleOutreachTemplateOptionClick = () => {
    triggerOutreachTemplateButton();
    setIsListOpened(false);
  };

  return (
    <div className="tempaltes-button">
      {isListOpened && (
        <div className="tempaltes-button__dropdown">
          <DropdownList
            handleClose={() => setIsListOpened(false)}
            options={[
              {
                label: 'AI Generated First Outreach',
                onClick: handleOutreachTemplateOptionClick,
              },
              {
                label: 'Custom Template',
                onClick: handleCustomTemplateOptionClick,
              },
            ]}
          />
        </div>
      )}
      {showOutreachButton && (
        <Tooltip label="Change Template" disableHoverListener={!isIconButton || isListOpened} position="top">
          <Button
            mode="blue"
            outlined
            label={isIconButton ? '' : 'Change Template'}
            handleClick={handleDropdownButtonClick}
            size="medium"
            icon={isIconButton ? 'bi bi-pencil-square' : 'bi bi-chevron-up'}
            iconPosition="right"
          />
        </Tooltip>
      )}

      {!showOutreachButton && (
        <Tooltip label="Custom Templates" disableHoverListener={!isIconButton} position="top">
          <Button
            mode="blue"
            outlined
            label={isIconButton ? '' : 'Custom Templates'}
            handleClick={triggerCustomTemplateButton}
            size="medium"
            icon="bi bi-pencil-square"
          />
        </Tooltip>
      )}
    </div>
  );
};
