import { Column, TableRow } from './table-types';
import { TableCell } from './cell/table-cell';
import { TagsCell } from './tags-cell/tags-cell';

type TableBodyProps = {
  tableData: TableRow[];
  columns: Column[];
  withRowNumber?: boolean;
  isRowClickable?: boolean;
};

const TableBody = ({
  tableData,
  columns,
  withRowNumber = false,
  isRowClickable = false,
}: TableBodyProps) => {
  const handleRowClick = (data: TableRow) => {
    if (isRowClickable && data.handleRowClick) {
      data.handleRowClick();
    }
  };

  return (
    <tbody>
      {tableData.map((data: TableRow, index: number) => (
        <tr
          className={`table__row ${isRowClickable ? 'table__row--clickable' : ''}`}
          key={data.id}
          onClick={() => handleRowClick(data)}
        >
          {withRowNumber && <td key={`nr${data.id}`}>{index + 1}</td>}
          {columns.map(({ accessor, type }: Column) => (type === 'tags' ? (
            <TagsCell key={accessor} cellData={data.items[accessor]} />
          ) : (
            <TableCell key={accessor} cellData={data.items[accessor]} type={type} />
          )))}
        </tr>
      ))}
    </tbody>

  );
};

export default TableBody;
