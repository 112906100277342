/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import {
  Page, Text, View, Document, StyleSheet, Font, Image, Link,
} from '@react-pdf/renderer';
import { HighlightWords, ResumeSchema } from '../../types';

import Calibri from '@/assets/fonts/resume-builder/calibri-font-family/calibri-regular.ttf';
import CalibriBold from '@/assets/fonts/resume-builder/calibri-font-family/calibri-bold.ttf';
import CalibriItalic from '@/assets/fonts/resume-builder/calibri-font-family/calibri-italic.ttf';

import CakeIcon from '@/assets/images/resume-builder/ESMT/cake2.png';
import EmailIcon from '@/assets/images/resume-builder/ESMT/email.png';
import FlagIcon from '@/assets/images/resume-builder/ESMT/flag.png';
import PhoneIcon from '@/assets/images/resume-builder/ESMT/phone.png';
import MapIcon from '@/assets/images/resume-builder/ESMT/map.png';
import LinkedinIcon from '@/assets/images/resume-builder/ESMT/linkedin.png';
import { computeSkillsStyle, renderWorkHighlightedText } from './utils';
// import XIcon from '@/assets/images/resume-builder/ESMT/x.png';
// import TwitterIcon from '@/assets/images/resume-builder/ESMT/twitter.png';

function formatDate(dateString: string | undefined): string {
  if (!dateString) {
    return 'N/A';
  }
  const [month, year] = dateString.split('/');
  const date = new Date(2000 + parseInt(year, 10), parseInt(month, 10) - 1, 1);
  const monthName = date.toLocaleString('default', { month: 'short' });
  return `${monthName} ${year}`;
}

Font.register({ family: 'Calibri', src: Calibri, fonts: [{ src: Calibri, fontWeight: '400' }, { src: CalibriBold, fontWeight: '700' }, { src: CalibriItalic, fontStyle: 'italic' }] });

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    fontFamily: 'Calibri',
  },
  header: {
    backgroundColor: '#0A305B', // Specific blue color from ESMT CV
    color: 'white',
    padding: '16px',
    paddingBottom: '14px',
    flexDirection: 'column', // Changed to column to stack the header content
  },
  footer: {
    backgroundColor: '#0A305B',
    width: '100%',
    height: '12px',
  },
  headerText: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: 'Calibri',
    fontSize: 26,
    marginBottom: '15px',
    marginTop: '3px',
  },
  headerTextSmall: {
    fontFamily: 'Calibri',
    color: 'white',
    fontSize: 9,
    marginTop: 2,
  },
  contactInfo: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 0,
  },
  contactInfoRow: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '60%',
    marginBottom: '3px',
  },
  contactInfoRowColumn: {
    textAlign: 'left',
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: 5,
  },
  additionalHeaderInfo: {
    fontSize: 10,
    textAlign: 'center',
  },
  columnContainer: {
    flexDirection: 'row',
    marginTop: 0,
    flex: 1, // This makes the container take the remaining space
  },
  leftColumn: {
    width: '67.3%',
    paddingHorizontal: '16px',
    paddingVertical: '10px',
    height: '100%',
  },
  rightColumn: {
    width: '32.7%',
    backgroundColor: '#F2F2F2',
    paddingHorizontal: 10,
    paddingVertical: '10px',
    height: '100%',
  },
  sectionHeader: {
    fontSize: 16.5,
    color: '#0A305C',
    marginBottom: 5,
    marginTop: 0,
  },
  sectionHeaderWithMargin: {
    fontSize: 16.5,
    color: '#0A305C',
    marginBottom: 5,
    marginTop: 10,
  },
  normalText: {
    fontSize: 9,
  },
  normalTextSmall: {
    fontSize: 9,
    marginBottom: 5,
  },
  languagesText: {
    fontSize: 9,
  },
  SkillsGreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
    fontSize: 9,
    fontFamily: 'Calibri',
  },
  SkillsRedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
    fontSize: 9,
    fontFamily: 'Calibri',
  },
  SkillsSubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 9,
    fontFamily: 'Calibri',
  },
  SubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 9,
    fontFamily: 'Calibri',
  },
  SubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 9,
    fontFamily: 'Calibri',
  },
  SkillsSubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 9,
    fontFamily: 'Calibri',
  },
  normalTextBold: {
    fontSize: 9,
    fontWeight: 700,
  },
  row: {
    flexDirection: 'column',
    width: '100%',
    rowGap: 5,
  },
  bulletPoint: {
    width: 10,
    fontSize: 9,
  },
  educationBlock: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  Edinstitution: {
    fontSize: 9,
    fontFamily: 'Calibri',
    textAlign: 'left',
    color: '#000',
  },
  EdstudyType: {
    fontSize: 9,
    fontFamily: 'Calibri',
    fontWeight: 700,
    textAlign: 'left',
    color: '#000',
  },
  EdDates: {
    fontSize: 9,
    fontFamily: 'Calibri',
    textAlign: 'left',
    color: '#000',
  },
  langRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  normalTextSummary: {
    fontSize: 9,
    fontFamily: 'Calibri',
    textAlign: 'left',
    color: '#000',
    marginBottom: 10,
  },
  WorkBlock: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    columnGap: '10px',
    marginBottom: 5,
  },
  workColumnSmall: {
    flexDirection: 'column',
    width: '60px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  workColumnBig: {
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  workDates: {
    fontSize: 9,
    fontFamily: 'Calibri',
    textAlign: 'left',
    color: '#000',
  },
  workPosition: {
    fontSize: 9,
    fontFamily: 'Calibri',
    fontWeight: 700,
    textAlign: 'left',
    color: '#0A305B',
    marginBottom: 2,
  },
  workInstitution: {
    fontSize: 9,
    fontFamily: 'Calibri',
    textAlign: 'left',
    fontWeight: 700,
    color: '#000',
    marginBottom: 2,
  },
  summary: {
    fontSize: 9,
    fontFamily: 'Calibri',
    textAlign: 'left',
    fontStyle: 'italic',
    color: '#000',
    marginBottom: 2,
  },
  highlights: {
    marginTop: 1,
    paddingLeft: 0,
    width: '100%',
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  headerLinks: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    columnGap: 5,
    marginTop: '15px',
  },
  profilePictureContainer: {
    width: 127,
    height: 127,
    borderRadius: 63.5,
    backgroundColor: 'white',
    position: 'absolute',
    top: 21,
    right: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profilePicture: {
    width: 124,
    height: 124,
    borderRadius: 62,
    overflow: 'hidden',
  },
  highlightText: {
    fontSize: 9,
    color: '#333333',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: 'auto',
  },
  normalWord: {
    color: 'black',
  },
  GreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
  },
  RedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
  },
});

type ResumeSchemaProps = {
  resume: ResumeSchema,
  highlightedWords: HighlightWords | null,
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null
};

const ESMTTemplate = ({ resume, highlightedWords, activeField }: ResumeSchemaProps) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.headerText}>
          {resume.basics.name ? resume.basics.name.split(' ').map((part, index, array) => (index === array.length - 1 ? part.toUpperCase() : part)).join(' ') : ''}
        </Text>
        <View style={styles.contactInfo}>
          <View style={styles.contactInfoRow}>
            <View style={styles.contactInfoRowColumn}>
              {resume.basics.birth_date && (
              <>
                <Image src={CakeIcon} style={{ width: 16, height: 16 }} />
                <Text style={styles.headerTextSmall}>{resume.basics.birth_date || 'N/A'}</Text>
              </>
              )}
            </View>
            <View style={styles.contactInfoRowColumn}>
              <Image src={EmailIcon} style={{ width: 16, height: 16 }} />
              <Text style={styles.headerTextSmall}>{resume.basics.emails[0]}</Text>
            </View>
          </View>
          <View style={styles.contactInfoRow}>
            <View style={styles.contactInfoRowColumn}>
              {resume.basics.nationality && Array.isArray(resume.basics.nationality) && resume.basics.nationality.length > 0 && (
              <>
                <Image src={FlagIcon} style={{ width: 16, height: 16 }} />
                <Text style={styles.headerTextSmall}>
                  {resume.basics.nationality?.join(', ')}
                </Text>
              </>
              )}
            </View>
            <View style={styles.contactInfoRowColumn}>
              <Image src={PhoneIcon} style={{ width: 16, height: 16 }} />
              <Text style={styles.headerTextSmall}>{resume.basics.phone_numbers?.[0] ?? ''}</Text>
            </View>
          </View>
          <View style={styles.contactInfoRow}>
            <View style={styles.contactInfoRowColumn} />
            <View style={styles.contactInfoRowColumn}>
              <Image src={MapIcon} style={{ width: 15, height: 17 }} />
              <Text style={styles.headerTextSmall}>
                {resume.basics.location?.address}
                {' '}
                {resume.basics.location?.city}
                {' '}
              </Text>
            </View>
          </View>
          <View style={styles.headerLinks}>
            <Link href={resume.basics.url}><Image src={LinkedinIcon} style={{ width: 16, height: 16 }} /></Link>
          </View>
        </View>
        {resume.basics.profile_image && (
          <View style={styles.profilePictureContainer}>
            <View style={styles.profilePicture}>
              <Image
                src={resume.basics.profile_image}
                style={{
                  width: 124, height: 124,
                }}
              />
            </View>
          </View>
        )}
      </View>
      <View style={styles.columnContainer}>
        <View style={styles.leftColumn}>
          <Text style={styles.sectionHeader}>Professional Experience</Text>
          {resume.work?.map((item, index) => (
            <View key={`${item.name}-${index}`} style={styles.WorkBlock}>
              <View style={styles.workColumnSmall}>
                <Text style={styles.workDates}>
                  {formatDate(item.startDate)}
                  {' '}
                  -
                  {' '}
                  {formatDate(item.endDate)}
                </Text>
                <Text style={styles.workDates}>
                  {item.location}
                </Text>
              </View>
              <View style={styles.workColumnBig}>
                <Text style={styles.workPosition}>
                  {item.position}
                </Text>
                <Text style={styles.workInstitution}>
                  {item.name}
                </Text>
                {item.summary && (
                  <Text style={styles.summary}>
                    {item.summary}
                  </Text>
                ) }
                {item.highlights && item.highlights.length > 0 && (
                  <View style={styles.highlights}>
                    {item.highlights && item.highlights.length > 0 && (
                      <View style={styles.highlights}>
                        {item.highlights.map((highlight, highlightIndex) => {
                          const cleanedHighlight = highlight.replace(/^•\s/, '');
                          return (
                            <View key={`${highlight}-${highlightIndex}`} style={styles.listItem} wrap={false}>
                              <Text style={styles.bulletPoint}>•</Text>
                              <View style={styles.highlightText}>
                                {renderWorkHighlightedText(cleanedHighlight, highlightIndex, index, highlightedWords, activeField, styles)}
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    )}

                  </View>
                )}
              </View>
            </View>
          ))}
        </View>
        <View style={styles.rightColumn}>
          <Text style={styles.sectionHeader}>
            {resume.basics.summaryTitle || 'Summary'}
          </Text>
          <Text style={styles.normalTextSummary}>
            {resume.basics.summary || 'Section that summarizes your expertise described in the resume. Usually 2-3 sentences, tailored to the position/industry/company you target. Highlight your skills, years of work experience, achievements, and interests.'}
          </Text>
          <Text style={styles.sectionHeader}>Education</Text>
          {resume.education?.map((item) => (
            <View key={item.institution} style={styles.educationBlock}>
              <View style={styles.row}>
                <Text style={styles.EdstudyType}>
                  {item.area}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.Edinstitution}>
                  {item.institution}
                  {' '}
                  -
                  {' '}
                  {item.location}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.EdDates}>
                  {formatDate(item.startDate)}
                  {' '}
                  -
                  {' '}
                  {formatDate(item.endDate)}
                </Text>
              </View>
              {item.score && (
                <View style={styles.row}>
                  <Text style={styles.EdDates}>
                    GPA:
                    {' '}
                    {item.score}
                  </Text>
                </View>
              )}
            </View>
          ))}
          <Text style={styles.sectionHeader}>Languages</Text>
          {resume.languages.map((lang, index) => (
            <View style={styles.langRow}>
              <Text key={index} style={styles.normalTextBold}>
                {`${lang.language}: `}
              </Text>
              <Text key={index} style={styles.normalText}>
                {lang.fluency}
              </Text>
            </View>
          ))}
          {resume.skills && resume.skills?.length > 0 && (
          <Text style={styles.sectionHeaderWithMargin}>
            Technology
          </Text>
          )}
          <View style={styles.langRow}>
            <Text style={computeSkillsStyle(highlightedWords, activeField, styles)}>
              {resume.skills?.map((skill) => `${skill.name}`).join(', ')}
            </Text>
          </View>

          {resume.additional_information.order && resume.additional_information.order.map((section) => (section.type === 'heading' ? (
            <Text style={styles.sectionHeaderWithMargin}>
              {section.value}
            </Text>
          ) : (
            <Text style={styles.normalTextSmall}>
              {section.value}
            </Text>
          )))}

        </View>
      </View>
      <View style={styles.footer} />
    </Page>
  </Document>
);

export default ESMTTemplate;
