import { ConnectionStatus } from '@/domains/core/contact/types';
import './contact-tile.scss';
import { CommunicationChannel } from '@/domains/core/netwoking/types';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { StatusTag } from '@/domains/core/contact/components/status-tags/status-tag';
// import { IconButton } from '@/components/icon-button/icon-button';
import { Badge } from '@/components/badge/badge';
import { Tooltip } from '@/components/tooltip/tooltip';
import { ContactTileStep } from '../contact-tile-step/contact-tile-step';

type InteractionWithContact = {
  date?: string;
  name: string;
  type: 'add contact' | 'message' | 'outreach';
};

type Props = {
  name: string;
  image: string;
  companyName: string;
  linkedInConnectionStatus: ConnectionStatus;
  latestsActivities?: InteractionWithContact[];
  nextActivity?: InteractionWithContact;
  communicationChannel: CommunicationChannel;
  isArchved?: boolean;
  handleTileClick: () => void;
};

export const ContactKanbanTile = ({
  image,
  name,
  companyName,
  linkedInConnectionStatus,
  latestsActivities,
  nextActivity,
  communicationChannel,
  handleTileClick,
  isArchved = false,
}: Props) => (
  <div className="contact-kanban-tile" onClick={handleTileClick}>
    <div className="contact-kanban-tile__top">
      <div className="contact-kanban-tile__header">
        <div className="contact-kanban-tile__avatar">
          <DefaultLogo
            type="contact"
            source={image}
            className="contact-kanban-tile__avatar"
            name={name}
            size={48}
          />
          <div className="contact-kanban-tile__avatar-icon">
            <i className={`bi bi-${communicationChannel === 'email' ? 'envelope' : 'linkedin'}`} />
          </div>
        </div>
        <div className="contact-kanban-tile__info">
          <div className="contact-kanban-tile__name">{name}</div>
          <div className="contact-kanban-tile__company">{companyName}</div>
        </div>
      </div>

      <div className="contact-kanban-tile__status">
        {/* {handleArchive && !isArchved && (
          <IconButton
            icon="bi bi-archive"
            onClick={handleArchive}
            mode="rounded"
            outlined
            size="x-small"
          />
        )} */}
        {!isArchved && <StatusTag status={linkedInConnectionStatus} type="linkedin" size="small" withTooltip />}
        {isArchved && (
          <Tooltip position="top" withArrow label="Archived">
            <Badge color="grey" label="🗂️" />
          </Tooltip>
        )}
      </div>
    </div>

    {/* This is not used currently, needs backend */}
    {(latestsActivities || nextActivity) && (
      <div className="contact-kanban-tile__steps">
        {latestsActivities?.map((activity) => (
          <ContactTileStep key={activity.name} name={activity.name} date={activity.date} icon="bi bi-person-add" />
        ))}
        {nextActivity && (
          <ContactTileStep name={nextActivity.name} date={nextActivity.date} icon="bi bi-chat-left" isActive />
        )}
      </div>
    )}
  </div>
);
