import { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import './weekly-goals.scss';
import { GoalProgress } from '../goal-progress/goal-progress';
import { Button } from '@/components/button/Button';
import { EditGoals } from '../edit-goals/edit-goals';
import { PopupWindowWrapper } from '@/domains/generic';

export const WeeklyGoals = ({ goals }: any) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => setIsEditing(true);
  const handleEditClose = () => setIsEditing(false);

  return (
    <div className="weekly-goals">
      <div className="weekly-goals__header">
        <h2 className="weekly-goals__title">
          Weekly Goals
        </h2>
        <div className="weekly-goals__edit">
          <Button
            handleClick={handleEditClick}
            size="medium"
            label="Set own goals"
            mode="invisible"
            icon="bi bi-pencil-fill"
            iconPosition="left"
          />
          {isEditing && goals && (
            <PopupWindowWrapper handleClose={handleEditClose}>
              <EditGoals
                handleClose={handleEditClose}
                companies={goals?.saved_companies_target || 5}
                contacts={goals?.saved_contacts_target || 5}
                connections={goals?.sent_linkedin_invitations_target || 5}
              />
            </PopupWindowWrapper>
          )}
        </div>
      </div>
      {goals && (
      <div className="weekly-goals__goals">
        <div className="weekly-goals__goal">
          <GoalProgress
            currentProgress={goals?.saved_companies || 0}
            goal={goals?.saved_companies_target || 5}
            text="companies saved"
            linkLabel="Explore Companies"
            link="/app/companies/explore"
          />
        </div>
        <div className="weekly-goals__goal">
          <GoalProgress
            currentProgress={goals?.saved_contacts || 0}
            goal={goals?.saved_contacts_target || 5}
            text="contacts saved"
            linkLabel="Expand Network"
            link="/app/overview"
          />
        </div>
        <div className="weekly-goals__goal">
          <GoalProgress
            currentProgress={goals?.sent_linkedin_invitations || 0}
            goal={goals?.sent_linkedin_invitations_target || 5}
            text="invitations sent"
            link="/app/overview"
            linkLabel="Keep Networking"
          />
        </div>
      </div>
      )}
    </div>
  );
};
