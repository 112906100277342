import { useMemo, useState } from 'react';
import { Button } from '@/components/button/Button';
import { DropdownSearch } from '@/components/dropdown-search/dropdown-search';
import { FilterSearchBar } from '@/components/filter-search-bar/filter-search-bar';
import { IconButton } from '@/components/icon-button/icon-button';
import { MultiSelect } from '@/components/multi-select/multi-select';
import { JobsFilterState, JobsLocationsType } from '@/domains/core/job/types/job';
import { FilterOption } from '@/domains/core/company/types';
import './mobile-job-filters.scss';

type Props = {
  filters: JobsFilterState;
  staticOptions: Record<string, FilterOption[]>;
  jobsLocations: JobsLocationsType[];
  handleClose?: () => void;
  setFilters: (filters: JobsFilterState) => void;
};

export const MobileJobFilters = ({
  filters,
  staticOptions,
  jobsLocations,
  handleClose,
  setFilters,
}: Props) => {
  const [localFilters, setLocalFilters] = useState<JobsFilterState>(filters);
  const {
    locations,
    contract,
    postedDate,
    workType,
    industries,
    sizes,
  } = staticOptions;

  const onFilterChange = (
    name: keyof typeof filters,
    value: string | string [] | FilterOption | FilterOption[],
  ) => {
    setLocalFilters({
      ...localFilters,
      [name]: value,
    });
  };

  const locationOptions = useMemo(
    () => localFilters.locations?.reduce<string[]>((acc, location) => {
      if (typeof location.name === 'string') {
        acc.push(location.name);
      }
      return acc;
    }, []) || [],
    [localFilters.locations],
  );

  const mappedLocations = useMemo(() => locations
    ?.filter(location => !localFilters.locations.some(loc => loc.name === location.name))
    .map(location => ({
      name: typeof location.name === 'string' ? location.name : '',
      value: location.value,
    })), [locations, localFilters.locations]);

  const handleLocationSelect = (name: string, value: any) => {
    const selectedLocation = jobsLocations.find(location => (location.city ? `${location.city}, ${location.country}` === name : location.country === name));

    if (selectedLocation) {
      const locationExists = localFilters.locations.some(loc => loc.name === name);

      const newLocations = locationExists
        ? localFilters.locations.filter(loc => loc.name !== name)
        : [...localFilters.locations, { name, value: JSON.stringify(value) }];

      setLocalFilters(prevFilters => ({
        ...prevFilters,
        locations: newLocations,
      }));
    }
  };

  const handleReset = () => {
    setLocalFilters({
      query: '',
      sizes: [],
      job_types: [],
      work_modes: [],
      industries: [],
      locations: [],
      date_range: [],
    });
  };

  return (
    <div className="mobile-job-filters">
      <div className="mobile-job-filters__wrapper">
        <div className="mobile-job-filters__top">
          <div className="mobile-job-filters__header">All Filter</div>
          <div className="mobile-job-filters__content">
            <div className="mobile-job-filters__filters">
              <FilterSearchBar
                value={localFilters.query}
                placeholder="Search jobs"
                onChange={(value) => onFilterChange('query', value)}
              />

              <DropdownSearch
                selectedOption={locationOptions}
                options={mappedLocations}
                handleItemSelect={(item, value) => handleLocationSelect(item, JSON.parse(value))}
                placeholder="Search office locations"
                name="loaction-search"
                id="location-search"
                inputIcon="bi bi-geo-alt"
                size="medium"
                canAddItems={false}
                showIcon={false}
                withMultipleSelection
                openOnClick
                listWidth="full"
              />

              <MultiSelect
                labelType="list"
                handleFiltering={(value) => onFilterChange('industries', value)}
                placeholder="Industry"
                selected={localFilters.industries}
                options={industries}
              />

              <MultiSelect
                labelType="list"
                handleFiltering={(value) => onFilterChange('sizes', value)}
                placeholder="Company Size"
                selected={localFilters.sizes}
                options={sizes}
              />

              <MultiSelect
                labelType="list"
                handleFiltering={(value) => onFilterChange('job_types', value)}
                placeholder="Contract"
                selected={localFilters.job_types}
                options={contract}
                width="fit-box"
              />

              <MultiSelect
                labelType="list"
                handleFiltering={(value) => onFilterChange('work_modes', value)}
                placeholder="Work Type"
                selected={localFilters.work_modes}
                options={workType}
                width="fit-box"
              />

              <MultiSelect
                labelType="list"
                handleFiltering={(value) => onFilterChange('date_range', value)}
                placeholder="Date Posted"
                selected={filters.date_range}
                options={postedDate}
                width="fit-box"
              />
            </div>
          </div>
        </div>
        <div className="mobile-job-filters__bottom">
          <Button
            mode="primary"
            outlined
            size="fill"
            label="Reset"
            icon="bi bi-arrow-repeat"
            handleClick={handleReset}
          />
          <Button
            mode="primary"
            size="fill"
            label="Apply All"
            icon="bi bi-funnel"
            handleClick={() => {
              setFilters(localFilters);
              handleClose?.();
            }}
          />
        </div>
        <div className="mobile-job-filters__close-button">
          <IconButton
            mode="unset"
            icon="bi bi-x"
            size="medium"
            handleClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
};
