export class WebSocketService {
  private socket: WebSocket | null = null;

  private shouldReconnect: boolean = false;

  private messageListeners: ((event: MessageEvent) => void)[] = [];

  constructor(private url: string, private token: string) {
    this.connect();
  }

  private getAuthUrl(): string {
    return `${this.url}?token=${encodeURIComponent(this.token)}`;
  }

  private attachListeners(): void {
    this.messageListeners.forEach(listener => {
      this.socket?.removeEventListener('message', listener);
      this.socket?.addEventListener('message', listener);
    });
  }

  private connect(): void {
    if (this.isConnected()) return;

    const authUrl = this.getAuthUrl();
    this.socket = new WebSocket(authUrl);
    this.shouldReconnect = true;

    this.socket.onopen = () => {
      this.attachListeners();
    };

    this.socket.onclose = () => {
      if (this.shouldReconnect) {
        setTimeout(() => this.connect(), 1000); // Attempt to reconnect after 1 second
      }
    };

    this.socket.onerror = (error: Event) => {
      console.error('WebSocket Error:', error);
    };
  }

  isConnected(): boolean {
    return this.socket !== null && this.socket.readyState === WebSocket.OPEN;
  }

  sendMessage(message: Record<string, unknown>): void {
    if (this.isConnected()) {
      this.socket?.send(JSON.stringify(message));
    }
  }

  onMessage(callback: (event: MessageEvent) => void): void {
    this.messageListeners.push(callback);
    if (this.isConnected()) {
      this.socket?.addEventListener('message', callback);
    }
  }

  close(): void {
    this.shouldReconnect = false;
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
    this.messageListeners = []; // Clear registered listeners
  }
}

// Export function to create a new WebSocketService instance
export const createWebSocketService = (url: string, token: string) => new WebSocketService(url, token);
