import { Accordion } from '@/components/accordion/accordion';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import '../tracker-tip.scss';
import { TipModalProps } from '../types';
import { useAnalytics } from '@/services/hooks/use-analytics';

const OfferTipModal = ({ openTip }: TipModalProps) => {
  const tabs = ['Prepare', 'Negotiate', 'Evaluate & Decide'];
  const { trackEvent } = useAnalytics();

  const trackCopiedToClipboard = () => {
    trackEvent('Tracker Tip: Copied to Cliboard');
  };

  return (
    <div className="company-saved-info-modal">
      <div className="company-saved-info-modal">
        <TabsHeader
          tabs={tabs}
          defaultTab={tabs.includes(openTip.tab) ? openTip.tab : tabs[0]}
          withBorder
        />
        <TabsContent>
          <div data-tab="Prepare">
            <div className="tip-modal-accordions">
              <Accordion handleCopied={trackCopiedToClipboard} copiable isOpened={openTip.tipName === 'Research comparable salaries'} title="Research comparable salaries">
                <div>
                  <p>
                    You can use these resources below to get information on typical salaries for your role.
                  </p>
                  <ul>
                    <li>
                      <a href="https://www.glassdoor.com/index.htm" target="__blank">Glassdoor</a>
                      {' '}
                    </li>
                    <li><a href="https://www.salarylist.com/" target="__blank">Salarylist</a></li>
                    <li><a href="https://wellfound.com/" target="__blank">wellfound</a></li>
                    <li><a href="https://h1bdata.info/topjobs.php" target="__blank"> H1B Data</a></li>
                  </ul>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable isOpened={openTip.tipName === 'Prepare your negotiation'} title="Prepare your negotiation">
                <div>
                  <p>
                    The most important thing to know here is that negotiations are normal. You might be reluctant to negotiate your salary. But you are dealing with professionals, and reasonable attempts to negotiate will not be taken personally or used against you.
                  </p>
                  <p>Review your unique accomplishments and focus on the value you could bring to the company (e.g. experience, languages, skills, …) to prepare good arguments for your negotiation.</p>
                </div>
              </Accordion>

            </div>
          </div>
          <div data-tab="Negotiate">
            <div className="tip-modal-accordions">
              <Accordion handleCopied={trackCopiedToClipboard} copiable isOpened={openTip.tipName === 'Always Negotiate!'} title="Always Negotiate!">
                <div>
                  <p>
                    Always negotiate! Always. Remember that you can negotiate several aspects of your offer, not just salary. You can also negotiate on variable compensation (bonuses and stock options), promotion fast tracks, remote working, perks and benefits).
                  </p>
                  <p>
                    We strongly recommend not to negotiate via email, but instead to set up a video call or in-person meeting. For example, tell the company that you have a time-sensitive update regarding your status and that you would like to set up a call to discuss this.
                  </p>
                </div>
              </Accordion>
            </div>
          </div>
          <div data-tab="Evaluate & Decide">
            <div className="tip-modal-accordions">
              <Accordion handleCopied={trackCopiedToClipboard} copiable isOpened={openTip.tipName === 'Evaluate & Decide'} title="Evaluate & Decide">
                <div>
                  <p>
                    Before you shout “Yes”, take some time to reflect on your offer. Does it tick the boxes for your individual, personal preferences? Are you satisfied with the outcome of your negotiations?
                  </p>
                  <p>If so, now is the time to accept your offer and enjoy your success!</p>
                  <p>If not, try negotiating to get to a more satisfactory outcome. If this is a dead end, respectfully thank the recruiting team for their time and decline the offer.</p>
                </div>
              </Accordion>
            </div>
          </div>
        </TabsContent>
      </div>
    </div>
  );
};

export default withTabs(OfferTipModal);
