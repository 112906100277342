/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useAuth0 } from '@auth0/auth0-react';
import {
  useContext,
  useEffect, useState,
} from 'react';
import { Timestamp, where } from 'firebase/firestore';
import { useLoaderData } from 'react-router-dom';
import { signInWithCustomToken } from '@firebase/auth';
import {
  collection, doc, getDoc, onSnapshot, orderBy, query,
} from '@firebase/firestore';
import { Loader } from '@/components/loader/loader';
import './styles/contact.scss';
import {
  MessageHistory,
  ContactSummary,
  ContactInfoTile,
} from '@/domains/core/contact';
import { useHash } from '@/services/hooks/use-hash';
import {
  getEmails,
  getFirebaseUserToken, sendEmailToContact, sentConnection, updateContactConnectionStatus,
} from '@/services/api/contact';
import { auth, db } from '@/services/firebase/client';
import { ModalContext } from '@/components/modal/modal-provider';
import { ToastContext } from '@/components/toast/toast-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import { AddToastType } from '@/domains/generic/toasts/types';
import { useIdentities, useSelfUniversity } from '@/services/queries/user';
import { QuestType } from '@/domains/core/student/types';
import { ConnectionStatus, ContactActivityEvent, ContactPageResponse } from '@/domains/core/contact/types';
import { getNetworkingStatusByMessages } from '@/services/helpers/contact';
import { InfoModal } from '@/domains/generic/modals';
import useConnectEmail from '@/services/hooks/use-connect-email';
import { EmailsSkeleton } from '@/domains/core/contact/components/message-history/loading-skeleton';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';

function ContactPage() {
  const {
    contact, self, companyIndustry,
  } = useLoaderData() as ContactPageResponse;
  const { hash } = window.location;
  const { getAccessTokenSilently } = useAuth0();
  const { trackEvent } = useAnalytics();
  const { data: university } = useSelfUniversity();
  const [loadingEmails, setLoadingEmails] = useState<any>(true);
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { addToast }: AddToastType = useContext(ToastContext);
  const [messages, setMessages] = useState([]);
  const [emailMessages, setEmailMessages] = useState<any>([]);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [networkingStatus, setNetworkingStatus] = useState<any>('notConnected');
  const { user } = useAuth0();
  const { data: identities, refetch: refetchIdentities } = useIdentities();

  const onSuccess = () => {
    refetchIdentities();
  };

  const { handleConnectEmail } = useConnectEmail(onSuccess);
  const {
    getContactConnectionStatus,
    checkLinkedInLogin,
    sendAccessToken,
    updateAllLinkedInConversations,
  } = useExtensionMessaging();
  const [activityHistory, setActivityHistory] = useState<ContactActivityEvent[]>([]);
  const { handleHashChange } = useHash({});
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('notConnected');
  const contactCompletedQuests = contact.completed_quests?.map((q: any) => q.quest_type) || [];

  const getContactActivity = () => {
    let coffeeChatScheduled: any = contact.completed_quests?.find((q) => q.quest_type === QuestType.ScheduleCoffeeChat);
    coffeeChatScheduled = (coffeeChatScheduled && coffeeChatScheduled.updated_at)
      ? { createdAt: coffeeChatScheduled.updated_at, questType: QuestType.ScheduleCoffeeChat }
      : null;
    const contactAdded: ContactActivityEvent | null = contact.saved_at
      ? { createdAt: contact.saved_at, questType: QuestType.AddNewContact }
      : null;
    const connectedOnLinkedIn: ContactActivityEvent | null = (contact.linkedin_connection_updated_at && connectionStatus === 'connected')
      ? { createdAt: contact.linkedin_connection_updated_at, questType: QuestType.SendConnection }
      : null;

    setActivityHistory([coffeeChatScheduled, connectedOnLinkedIn, contactAdded].filter(Boolean));
  };

  const fetchEmails = async () => {
    if (!identities) {
      return;
    }

    if (identities.length > 0) {
      const response = await getEmails(contact.id);
      if (response.error) {
        if (response.error === 'Authorization required') {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          handleConnectEmail();
        }
        addToast(
          {
            type: 'error',
            message: 'Failed to get emails',
            additionalMessage: "We're looking into the issue. Please try again later or contact us.",
          },
        );
        trackEvent('Toast Error Shown', user, {
          message: 'Failed to get emails',
          error: response.error,
        });
        setLoadingEmails(false);
      }
    }
  };

  const handleCloseLinkedInLoginModal = () => {
    closeModal();
    window.open('https://www.linkedin.com/home', '_blank');
  };

  const handleLinkedInLoginPrompt = (status: string | undefined) => {
    if (status === 'User is not logged into LinkedIn.' || status === 'No LinkedIn tab open.') {
      openModal(
        <InfoModal
          orientation="horizontal"
          icon="bi bi-box-arrow-in-right"
          title="Log into LinkedIn and keep the tab open"
          buttonLabel="Log in Now"
          handleButtonClick={handleCloseLinkedInLoginModal}
          description={(
            <>
              It looks like you&apos;re not logged into LinkedIn in any of your open tabs. Please log in and leave that tab open in the background, then come back to CareerOS.
              <br />
              <br />
              Pro tip: right click on an open LinkedIn tab and select “Pin” to keep the tab open and ready at all times.
            </>
          )}
        />,
      );
    }
  };

  const updateConnectionStatus = async () => {
    let status = null;

    if (contact.linkedin_connection_status === 'connected') {
      setConnectionStatus('connected');
      return;
    }

    try {
      status = await getContactConnectionStatus(contact.linkedin_url);
    } catch (error) {
      return;
    }

    if (!status) {
      const isConnectedOnLinkedin = !!contact.chat_id && contact.chat_id !== '00000000-0000-0000-0000-000000000000';
      setConnectionStatus(isConnectedOnLinkedin ? 'connected' : 'notConnected');
      return;
    }
    if (status !== contact.linkedin_connection_status) {
      await updateContactConnectionStatus(contact, status);
    }
    if (status) {
      setConnectionStatus(status);
    }
  };

  const handleDownloadExtensionClick = () => {
    closeModal();
    window.open(import.meta.env.VITE_EXTENSION_LINK, '_blank');
  };

  const checkAbilityToSendLinkedIn = async () => {
    let linkedInLoginStatus = null;
    try {
      linkedInLoginStatus = await checkLinkedInLogin();
    } catch (error) {
      addToast({
        type: 'error',
        message: 'There is a problem with the Chrome extension',
        additionalMessage: 'Please try reinstalling the extension or contact support',
      });
      trackEvent('Toast Error Shown', user, {
        message: 'There is a problem with the Chrome extension',
        error,
      });
      return false;
    }

    if (!linkedInLoginStatus) {
      openModal(
        <InfoModal
          icon="bi bi-puzzle"
          title="Looks like you haven’t downloaded our Chrome Extension"
          description="You’ll have the power to send LinkedIn messages directly from CareerOS. Download it now!"
          handleButtonClick={handleDownloadExtensionClick}
          buttonLabel="Download Extension"
          orientation="horizontal"
        />,
      );

      return false;
    }

    handleLinkedInLoginPrompt(linkedInLoginStatus);

    if (linkedInLoginStatus !== 'User is logged into LinkedIn.') {
      return false;
    }

    await updateConnectionStatus();

    return true;
  };

  const sendConnectionMessage = async () => {
    trackEvent('Linkedin Connection Sent');
    trackEvent('Message sent via Linkedin', user);
    setConnectionStatus('pending');
    await sentConnection(contact.id);
    await updateConnectionStatus();
  };

  const handleContactEmailChanged = () => {
    fetchEmails().then(() => {
      getAllEmailData(true);
    });
  };

  const getAllEmailData = async (force = false) => {
    if ((contact.email || force) && user) {
      setEmailMessages([]);
      setLoadingEmails(true);
      const token: string = await getFirebaseUserToken();
      await signInWithCustomToken(auth, token);
      const docRef = doc(db, 'outlookConversations', `${self.id}:${contact.id}`);
      try {
        const d = await getDoc(docRef);
        if (d.exists()) {
          const messagesRef = collection(
            db,
            'outlookConversations',
            `${self.id}:${contact.id}`,
            'messages',
          );

          const unsubscribe = onSnapshot(
            query(messagesRef, orderBy('sentDateTime')),
            (snapshot) => {
              if (!snapshot.empty) {
                const messagesFromData: any = [];
                snapshot.forEach((docMessages) => {
                  messagesFromData.push(docMessages.data());
                });
                setEmailMessages(messagesFromData);
              }
              setLoadingEmails(false);
            },
          );

          return unsubscribe; // Return the unsubscribe function for cleanup
        }
      } catch (e) {
        addToast(
          {
            type: 'error',
            message: 'Failed to get Email messages',
            additionalMessage: "We're looking into the issue. Please try refreshing the page",
          },
        );
        trackEvent('Toast Error Shown', user, {
          message: 'Failed to get emails from firebase',
          error: e,
        });
        setLoadingEmails(false);
        return null;
      }
    }
    setLoadingEmails(false);
    return null;
  };

  const sendEmail = async (message: string, subject: string, to: string[], originalMessageID?: string) => {
    if (!identities || identities.length === 0) {
      handleConnectEmail();
      return false;
    }

    addToast(
      {
        type: 'success',
        message: 'Success',
        additionalMessage: 'Your email has been sent.',
      },
    );
    const firestoreTimestamp = Timestamp.now();

    const emailPayload: any = {
      text: message,
      to: to[0],
      subject,
      fromUserID: self.id,
      originalMessageID,
      from: identities[0].email,
      ID: originalMessageID,
      sentDateTime: firestoreTimestamp,
    };
    setEmailMessages([emailPayload, ...emailMessages]);
    const response = await sendEmailToContact(message, subject, contact.id, to[0], originalMessageID || null);
    if (response.error) {
      if (response.error === 'Authorization required') {
        handleConnectEmail();
      }
      trackEvent('Email Sending Failed', user, {});
      addToast(
        {
          type: 'error',
          message: 'Failed to send an email',
          additionalMessage: "We're looking into the issue. Please try again later or contact us.",
        },
      );

      trackEvent('Toast Error Shown', user, {
        message: 'Failed to send an email',
        error: response.error,
      });

      trackEvent('Email Sent Failure', user, {
        university: university.name,
        cohort: university.cohort,
      });
      return false;
    }

    trackEvent('Email Sent', user, {
      university: university.name,
      cohort: university.cohort,
    });
    trackEvent('Message Sent');

    try {
      setTimeout(async () => getAllEmailData(), 0);
    } catch (e) {
      console.log(e);
    }

    return true;
  };

  const getAllLinkedinData = async () => {
    setLoadingMessages(true);
    setMessages([]);
    try {
      const token = await getFirebaseUserToken();
      await signInWithCustomToken(auth, token);

      const setupMessagesListener = async (docRef: any) => {
        const messagesRef = collection(docRef, 'messages');
        const unsubscribeMessages = onSnapshot(
          query(messagesRef, orderBy('createdAt')),
          (snapshot) => {
            const messagesFromData: any = [];
            snapshot.forEach((docMessages) => {
              messagesFromData.push(docMessages.data());
            });
            setMessages(messagesFromData);
            setLoadingMessages(false);
          },
        );
        return unsubscribeMessages;
      };

      let unsubscribeConversations: any;
      if (contact.chat_id && contact.chat_id !== '00000000-0000-0000-0000-000000000000') {
        const chatDocRef = doc(db, 'linkedInConversations', contact.chat_id);
        const d = await getDoc(chatDocRef);
        if (d.exists()) {
          return await setupMessagesListener(chatDocRef);
        }
      } else {
        const conversationsRef = collection(db, 'linkedInConversations');
        const q = query(
          conversationsRef,
          where('toContactID', '==', contact.id),
          where('userID', '==', self.id),
        );
        unsubscribeConversations = onSnapshot(q, async (snapshot) => {
          if (!snapshot.empty) {
            const docRef = snapshot.docs[0].ref;
            unsubscribeConversations(); // Stop listening for new conversations once we found the correct one
            return setupMessagesListener(docRef);
          }
        });
      }
    } catch (e) {
      addToast({
        type: 'error',
        message: 'Failed to get Linkedin messages',
        additionalMessage: "We're looking into the issue. Please try refreshing the page",
      });
      trackEvent('Toast Error Shown', user, {
        message: 'Failed to get Linkedin messages',
        error: e,
      });
      setLoadingMessages(false);
      return null;
    }
    setLoadingMessages(false);
    return null;
  };

  const tryGetLinkedInMessages = async () => {
    try {
      updateAllLinkedInConversations();
    } catch (error) {
      addToast(
        {
          type: 'error',
          message: 'Failed to update Linkedin messages',
          additionalMessage: 'Please make sure that you are logged into Linkedin and the Chrome Extension.',
        },
      );
      trackEvent('Toast Error Shown', user, {
        message: 'Failed to update Linkedin messages',
        error,
      });
    }
  };

  useEffect(() => {
    let unsubscribeFromLinkedinMessages: any;

    const fetchData = async () => {
      unsubscribeFromLinkedinMessages = await getAllLinkedinData();
    };

    fetchData();

    return () => {
      // Clean up the subscription when the component unmounts
      if (unsubscribeFromLinkedinMessages) {
        unsubscribeFromLinkedinMessages();
      }
    };
  }, [contact.chat_id, contact.id, self.id]);

  useEffect(() => {
    let unsubscribeFromEmailMessages: any;

    const fetchData = async () => {
      unsubscribeFromEmailMessages = await getAllEmailData();
    };

    fetchData();

    return () => {
      // Clean up the subscription when the component unmounts
      if (unsubscribeFromEmailMessages) {
        unsubscribeFromEmailMessages();
      }
    };
  }, [identities, contact.chat_id, contact.id, self.id]);

  useEffect(() => {
    fetchEmails();
  }, [identities]);

  useEffect(() => {
    getAccessTokenSilently().then(sendAccessToken);
    checkLinkedInLogin().then(handleLinkedInLoginPrompt);
    tryGetLinkedInMessages();
    updateConnectionStatus();
  }, [contact.id]);

  useEffect(() => {
    getContactActivity();
  }, [connectionStatus]);

  useEffect(() => {
    handleHashChange();
  }, [hash]);

  useEffect(() => {
    const status = getNetworkingStatusByMessages(messages, emailMessages);
    setNetworkingStatus(status);
  }, [messages.length, emailMessages.length, connectionStatus, contactCompletedQuests]);

  return (
    <div className="contact-page" id="contact-page">
      <div className="career-os-grid">
        <div className="width-10/24">
          <div className="contact-page__block">
            <ContactInfoTile
              networkingStatus={networkingStatus}
              connectionStatus={connectionStatus}
              contact={contact}
              onEmailChanged={handleContactEmailChanged}
            />
            <ContactSummary careerSummary={contact.career_summary} />
          </div>
        </div>
        <div className="width-14/24">
          <div id="main" className="contact-page__block">
            {loadingMessages || loadingEmails ? (
              <div className="message-history">
                <EmailsSkeleton />
                <EmailsSkeleton />
                <EmailsSkeleton />
                <EmailsSkeleton />
                <EmailsSkeleton />
                <EmailsSkeleton />
                <EmailsSkeleton />
                <EmailsSkeleton />
              </div>
            )
              : (
                <MessageHistory
                  contactFirstName={contact.first_name}
                  linkedInMessages={messages}
                  emailMessages={emailMessages}
                  contactImage={contact.career_summary.basics.image}
                  primaryEmail={contact.email}
                  secondaryEmail={contact.secondary_email}
                  handleSendEmail={sendEmail}
                  contactUrn={contact.linkedin_urn}
                  checkEmailPermissions={handleConnectEmail}
                  contactConnectionStatus={connectionStatus}
                  contactID={contact.id}
                  handleConnectionSent={sendConnectionMessage}
                  checkAbilityToSendLinkedIn={checkAbilityToSendLinkedIn}
                  activeQuests={contact.active_quests || []}
                  companyName={contact.current_position.company}
                  activityHistory={activityHistory}
                  companyID={contact.company_id}
                  lastCompletedQuests={contactCompletedQuests}
                  industry={companyIndustry}
                  jobTitle={contact.current_position.title}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(ContactPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
