import { useEffect } from 'react';
// import { useAuth0 } from '@auth0/auth0-react';
import { router } from '@/main';
import { Loader } from '@/components/loader/loader';

const LoggedInWithUniversity = () => {
  // const { logout } = useAuth0();

  // const handleLogout = (errorDescription: string | null) => {
  //   window.localStorage.removeItem('token');
  //   window.localStorage.removeItem('university');
  //
  //   const errorParam = errorDescription ? `?error=${encodeURI(errorDescription)}` : '';
  //
  //   logout({ logoutParams: { returnTo: `${window.location.origin}/with-university${errorParam}` } });
  // };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get('error');
    const errorDescription = params.get('error_description');

    if (error === 'access_denied') {
      // handleLogout(errorDescription);
      //
      // return;

      router.navigate(`/with-university?error=${errorDescription}`);
      return;
    }

    router.navigate(`/logged-in${window.location.search}`);
  }, []);

  return (
    <div id="loader-zone">
      <Loader />
    </div>
  );
};

export default LoggedInWithUniversity;
