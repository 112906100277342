import React from 'react';
import './rotating-icon.scss';

type Props = {
  expanded: boolean;
};

export const RotatingIcon = ({ expanded }: Props) => (
  <i
    className={`bi bi-chevron-double-down rotate-icon ${expanded ? 'rotated' : ''}`}
  />
);
