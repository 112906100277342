/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';
import { Contact } from '@/domains/core/contact/types';

// Create the context
export type InboxContextType = {
  replyParams: { subject: string; id: string; from: string };
  handleReply: (subject: string | undefined, id: string | undefined, from: string | undefined) => void;
  currentContact: Contact;
};

const InboxContext = createContext<InboxContextType | null>(null);

// Create a custom hook to use the context
export const useInboxContext = () => useContext(InboxContext);

type InboxProviderProps = {
  children: React.ReactNode;
  contact: Contact;
};

// Create a provider component
export const InboxProvider = ({ children, contact }: InboxProviderProps) => {
  const [replyParams, setReplyParams] = useState({ subject: '', id: '', from: '' });

  const handleReply = (subject: string | undefined, id: string | undefined, from: string | undefined) => {
    setReplyParams({ subject: subject || '', id: id || '', from: from || '' });
  };

  return (
    <InboxContext.Provider value={{
      replyParams, handleReply, currentContact: contact,
    }}
    >
      {children}
    </InboxContext.Provider>
  );
};
