import { CompanyCardSkeleton } from './company-card-skeleton';

export const CompanyCardContainerSkeleton = () => (
  <>
    <CompanyCardSkeleton />
    <CompanyCardSkeleton />
    <CompanyCardSkeleton />
    <CompanyCardSkeleton />
  </>
);
