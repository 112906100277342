import {
  Fragment, useMemo,
} from 'react';
import { formatDate } from '@/services/helpers/date';
import { EmailThreadData } from '../../types/messages';
import './email-thread-preview.scss';
import { extractFirstThreadMessage } from '@/services/helpers/messages';
import { Counter } from '@/components/counter/counter';

type EmailThreadProps = {
  messagesCount: number;
  threadData: EmailThreadData;
  handleSelectThread: () => void;
};

export const EmailThreadPreview = ({
  messagesCount,
  threadData,
  handleSelectThread,
}: EmailThreadProps) => {
  const messagePreview = useMemo(() => extractFirstThreadMessage(threadData.text), [threadData.text]);

  return (
    <div className={`email-thread-preview ${threadData.type ? 'email-thread-preview--pending' : ''}`} onClick={handleSelectThread}>
      <div className="email-thread-preview__row">
        <div className="email-thread-preview__first-column">
          <div className="email-thread-preview__recipients">
            {[...threadData.recipients].map((recipient, index, recipients) => (
              <Fragment key={recipient}>
                {(recipient === threadData.senderName)
                  ? (
                    <b>{`${recipient}${index !== recipients.length - 1 ? ', ' : ''}`}</b>
                  )
                  : `${recipient}${index !== recipients.length - 1 ? ', ' : ''}`}
              </Fragment>
            ))}
          </div>
          {messagesCount > 1 && (
          <Counter counter={messagesCount} size="small" mode="default" />
          )}
        </div>
        <div className="email-thread-preview__content">
          <span className="email-thread-preview__subject">{threadData.subject}</span>
          {messagePreview.length > 0 && (
          <span className="email-thread-preview__preview">
            {` - ${messagePreview}`}
          </span>
          )}
        </div>
        <div className="email-thread-preview__timestamp">
          {formatDate(threadData.sentDateTime.toDate())}
        </div>
      </div>

    </div>
  );
};
