import { useEffect, useRef, useState } from 'react';
import { OutreachScenarioType, TemplateRequestPayload } from '@/domains/core/netwoking/types';
import { getScenarioTemplate } from '../api/ai';
import { convertNewLinesToHtml } from '../helpers/messages';
import { useAnalytics } from './use-analytics';

function processResponse(response: string) {
  const dashIndex = response.indexOf('---');
  if (dashIndex !== -1) {
    // Extract the text after the dashes and remove leading/trailing whitespace
    return response.substring(dashIndex + 3).trim();
  }
  // If no dashes are found, return the original response
  return response;
}

export const useOutreachScenario = () => {
  const { trackEvent } = useAnalytics();
  const [selectedScenario, setSelectedScenario] = useState<OutreachScenarioType>();
  const [showScenarios, setShowScenarios] = useState(false);
  const [showRegenerate, setShowRegenerate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<any>('');
  const controller = useRef<AbortController>();

  const hideScenarios = () => {
    setShowScenarios(false);
  };

  const displayScenarios = () => {
    setShowScenarios(true);
  };

  const regenerateScenario = async (payload?: TemplateRequestPayload, scenario: OutreachScenarioType | undefined = selectedScenario) => {
    if (!scenario) {
      trackEvent('Outreach Scenario User Selected None');
    }
    if (!scenario || !payload) {
      hideScenarios();
      return;
    }

    setSelectedScenario(scenario);
    setIsLoading(true);
    setShowRegenerate(true);

    try {
      controller.current = new AbortController();
      const { signal } = controller.current;
      const scenarioTemplate = await getScenarioTemplate(scenario, payload, signal);

      const formattedMessage = convertNewLinesToHtml(processResponse(scenarioTemplate.response));

      trackEvent('Outreach Scenario Generated', { scenario });
      setMessage(formattedMessage);
      setIsLoading(false);
      hideScenarios();
    } catch (error) {
      setIsLoading(false);
      setMessage('');
      trackEvent('Outreach Scenario Generate Failed', { scenario });
    }
  };

  const changeScenario = () => {
    setSelectedScenario(undefined);
    setMessage('');
    setIsLoading(false);
    setShowScenarios(true);
    trackEvent('Outreach Scenario Changed');
  };

  const cancelRegenerate = () => {
    controller.current?.abort();
    trackEvent('Outreach Scenario Generate Cancelled');
  };

  useEffect(() => {
    if (showScenarios) {
      setShowRegenerate(false);
    }
  }, [showScenarios]);

  return {
    showScenarios,
    hideScenarios,
    displayScenarios,
    regenerateScenario,
    changeScenario,
    isLoadingOutreachScenario: isLoading,
    scenarioTemplate: message,
    toggleScenarioVisibility: setShowScenarios,
    showRegenerateScenario: showRegenerate,
    cancelRegenerateScenario: cancelRegenerate,
  };
};
