import { useState } from 'react';
import { Input } from '@/components/input/input';

interface GoalInputProps {
  label: string;
  handleChange: (value: number) => void;
  value: number;
}

export const GoalInput = ({
  label,
  handleChange,
  value,
}: GoalInputProps) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [warningText, setWarningText] = useState('');

  const handleGoalInputChange = (newGoal: number) => {
    if (newGoal > 99) {
      setIsInvalid(true);
      setWarningText('Max goal must be 99');
    } else if (newGoal < 1) {
      setIsInvalid(true);
      setWarningText('Minimum goal must be 1');
    } else {
      setIsInvalid(false);
    }

    handleChange(+newGoal);
  };

  return (
    <Input
      value={value}
      id=""
      placeholder=""
      label={label}
      type="number"
      warningText={warningText}
      invalid={isInvalid}
      handleValueChange={(newGoal) => handleGoalInputChange(+newGoal)}
      min={1}
      max={99}
    />
  );
};
