import '../styles/support.scss';

export default function ServiceLevelAgreementPage() {
  return (
    <div className="privacy-wrapper">
      <div className="privacy-content">
        <div className="sla">
          <h1>Service Level Agreement (SLA) for CareerOS  Application</h1>

          <h5>1. Purpose and Scope</h5>
          <p>This SLA outlines the service standards and responsibilities for the CareerOS web application provided by Jobworx, Inc. to its customers.</p>

          <h5>2. Service Description</h5>
          <p>The CareerOS web application offers networking functionality, including user account management, suggested contants, and messaging.</p>

          <h5>3. Performance Metrics and Standards</h5>
          <ul>
            <li>
              <strong>Uptime/Availability</strong>
              : The web application will be available 99.9% of the time each month.
            </li>
            <li>
              <strong>Response Time</strong>
              : The average page load time will be under 2 seconds.
            </li>
            <li>
              <strong>Throughput</strong>
              : The application will handle up to 1,000 transactions per second.
            </li>
          </ul>

          <h5>4. Support and Response Times</h5>
          <ul>
            <li>
              <strong>Support Hours</strong>
              : 24/7 support via email and phone.
            </li>
            <li>
              <strong>Response Times</strong>
              : Critical issues will be responded to within 30 minutes; non-critical issues within 4 hours.
            </li>
            <li>
              <strong>Resolution Times</strong>
              : Critical issues will be resolved within 4 hours; non-critical issues within 24 hours.
            </li>
          </ul>

          <h5>5. Responsibilities</h5>
          <ul>
            <li>
              <strong>Service Provider Responsibilities</strong>
              : Maintain servers, perform daily backups, provide 24/7 support, and monitor application performance.
            </li>
            <li>
              <strong>Customer Responsibilities</strong>
              : Ensure timely payment, report issues promptly, and maintain internet connectivity.
            </li>
          </ul>

          <h5>6. Monitoring and Reporting</h5>
          <ul>
            <li>
              <strong>Monitoring</strong>
              : Performance will be monitored using automated tools.
            </li>
            <li>
              <strong>Reporting</strong>
              : Monthly performance reports will be delivered to the customer.
            </li>
          </ul>

          <h5>7. Penalties and Remedies</h5>
          <ul>
            <li>
              <strong>Penalties</strong>
              : For each hour of downtime beyond the agreed 99.9% uptime, the customer will receive a service credit equivalent to one day of service.
            </li>
            <li>
              <strong>Remedies</strong>
              : In the event of a significant SLA breach, the customer may terminate the contract without penalty.
            </li>
          </ul>

          <h5>8. Review and Revision Policies</h5>
          <ul>
            <li>
              <strong>Review</strong>
              : The SLA will be reviewed annually.
            </li>
            <li>
              <strong>Revision Process</strong>
              : Revisions will be communicated in writing and agreed upon by both parties.
            </li>
          </ul>

          <h5>9. Legal and Compliance Clauses</h5>
          <ul>
            <li>
              <strong>Confidentiality</strong>
              : Both parties agree to keep all confidential information secure.
            </li>
            <li>
              <strong>Data Protection</strong>
              : Jobworx, Inc. Company will comply with GDPR and other relevant data protection regulations.
            </li>
            <li>
              <strong>Liability</strong>
              : Jobworx, Inc. Company’s liability is limited to the amount paid by the customer for the service in the past 12 months.
            </li>
          </ul>

        </div>

      </div>
    </div>
  );
}
