/* eslint-disable no-restricted-globals */
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CompanyRating, TrackerContextType, TrackerContext,
} from '@/domains/core/company';
import { changeApplicationStatus } from '@/services/api/application';
import './kanban-tile.scss';
import { statuses } from '@/domains/core/tracker-data';
import { ModalContext } from '@/components/modal/modal-provider';
import {
  AddComment,
  InfoModal,
} from '../../modals';
import { KanbanTileJob } from './tile-job';
import { Tooltip } from '@/components/tooltip/tooltip';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import { ContactsSidebar } from '../../sidebars/contacts-sidebar/contacts-sidebar';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { CommentsSidebar } from '@/domains/generic/';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { useSelf } from '@/services/queries/user';
import { updateCompanyRating } from '@/services/api/company';
import { Tile } from '@/domains/core/company/types';

interface KanbanTileProps {
  tile: Tile;
  readOnly?: boolean;
}

/**
 * Primary UI component for the kanban board
 */
export const KanbanTile = ({
  tile, readOnly = false,
}: KanbanTileProps) => {
  const navigate = useNavigate();
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { openSidebar, closeSidebar } = useContext(SidebarContext) as any;
  const [isNameOverflow, setIsNameOverflow] = useState(false);
  const nameElementRef = useRef<any>(null);
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { trackEvent } = useAnalytics();
  const [isArchived, setIsArchived] = useState(false);
  const { data: self } = useSelf();
  const { studentID } = useParams() as any;
  const queryClient = useQueryClient();

  const handleContactsIconClicked = (ev: any) => {
    ev.stopPropagation();
    trackEvent('Overview Board: Contact Icon clicked', {});
    if (readOnly) {
      openSidebar(<ContactsSidebar company={tile} closeSidebar={closeSidebar} userID={studentID} />);
    } else {
      openTracker(tile.company_id, 'Contacts');
    }
  };

  // * Student Overview Board Comments
  const openCommentsSideBar = (ev: any, hasResolveButton = false) => {
    ev.stopPropagation();
    trackEvent('Overview Board: Comments Icon clicked', {});
    if (tile.comments?.length > 0) {
      openSidebar(<CommentsSidebar company={tile} hasResolveButton={hasResolveButton} closeSidebar={closeSidebar} />);
    } else {
      openSidebar(<CommentsSidebar company={tile} hasResolveButton={hasResolveButton} closeSidebar={closeSidebar} />);
    }
  };

  const goToTracker = (id: string) => {
    history.replaceState({}, '/app/archive');
    openTracker(id);
  };

  const goToArchive = () => {
    closeModal();
    navigate('/app/archive');
  };

  const archiveCompany = async (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberArchiveCompany', 'true');
    }
    closeModal();
    await changeApplicationStatus(tile.id, statuses.Archived, tile.ordering);
    setIsArchived(true);
    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);
    trackEvent('Overview Board: Company Archive', {});
    openModal(
      <InfoModal
        handleButtonClick={goToArchive}
        title="Company Archived!"
        description="The Company is now archived. You can find it in your Archive List."
        buttonLabel="Go to Archive List"
      />,
    );
  };

  const openAddJobModal = (ev: any) => {
    ev.stopPropagation();
    history.replaceState({}, '/app/archive');
    openTracker(tile.company_id, 'Jobs');
  };

  const handleCompanyRate = async (rate: number) => {
    await updateCompanyRating(tile.id, rate);

    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);

    trackEvent('Overview Board: Company Rate emoji clicked', {});
  };

  const openAreYouSure = (ev: any) => {
    ev.stopPropagation();
    const rememberAction = localStorage.getItem('rememberArchiveCompany');
    if (!rememberAction) {
      openModal(
        <InfoModal
          icon="bi bi-exclamation-triangle-fill"
          handleButtonClick={archiveCompany}
          handleSecondaryButtonClick={closeModal}
          title="Are you sure?"
          description="Archiving will stop your progress with this company and remove it from your Overview Board. It will be moved to your Archive List."
          buttonLabel="Archive"
          secondaryButtonLabel="Cancel"
          rememberText="Remember my choice"
          isButtonDanger
        />,
      );
    } else {
      archiveCompany(false);
    }
  };

  const openAddCommentModal = (ev: any) => {
    ev.stopPropagation();
    openModal(
      <AddComment id={tile.id} payload={{}} type="company" />,
    );
  };

  const goToNotesTab = (ev: any) => {
    ev.stopPropagation();
    trackEvent('Overview Board: Notes Icon clicked', {});
    openTracker(tile.company_id, 'Notes');
  };

  useEffect(() => {
    if (nameElementRef.current) {
      setIsNameOverflow(nameElementRef.current.scrollWidth > nameElementRef.current.clientWidth);
    }
  }, [self?.id]);
  return (
    !isArchived ? (
      <div
        className="kanban-tile card"
        onClick={() => !readOnly && goToTracker(tile.company_id)}
      >
        <div onClick={() => !readOnly && goToTracker(tile.company_id)} className="kanban-tile__logo">
          <DefaultLogo
            source={tile.logo_url}
            type="company"
            name={tile.company_name}
            className="kanban-tile__logo__image"
            size={24}
          />
        </div>
        <Tooltip label={tile.company_name} position="bottom-center" disableHoverListener={!isNameOverflow}>
          <h3 ref={nameElementRef} onClick={() => !readOnly && goToTracker(tile.company_id)} className="kanban-tile__name">
            {tile.company_name}
          </h3>
        </Tooltip>
        {!readOnly && (
        <div className="kanban-tile__rating">
          <CompanyRating readOnly={readOnly} handleCompanyRate={handleCompanyRate} currentRate={tile.rating} />
        </div>
        )}
        <p className="kanban-tile__industry">{tile.industry}</p>
        <div className="kanban-tile__jobs">
          {tile.jobs?.length > 0 && tile.jobs.map((job) => (
            <KanbanTileJob key={job.title} title={job.title} url={job.linkedin_url} companyId={tile.company_id} readOnly={readOnly} />
          ))}
        </div>
        {readOnly && (
        <div className="kanban-tile__add-comment" onClick={(ev) => openAddCommentModal(ev)}>
          <Tooltip label="Write a comment" withArrow position="top">
            <i className="bi bi-plus-lg" />
          </Tooltip>
        </div>
        )}
        <div className="kanban-tile__cta">
          {!readOnly && (
          <div className="kanban-tile__cta__icon" onClick={(ev) => goToNotesTab(ev)}>
            <Tooltip label="View Notes" withArrow position="top">
              <div className="kanban-tile__cta__icon__contacts">
                <i className="bi bi-sticky" />
              </div>
            </Tooltip>
          </div>
          )}
          <div className="kanban-tile__cta__icon" onClick={(ev) => handleContactsIconClicked(ev)}>
            <Tooltip label={readOnly ? 'View Contacts' : `You have saved ${tile.count_of_contacts} contact(s) at this company.`} withArrow position="top">
              <div className={!tile.count_of_contacts ? 'kanban-tile__cta__icon__contacts kanban-tile__cta__icon__contacts--zero' : 'kanban-tile__cta__icon__contacts'}>
                <i className="bi bi-people" />
                <span className="kanban-tile__cta__icon__contacts__number">
                  {tile.count_of_contacts || 0}
                </span>
              </div>
            </Tooltip>
          </div>
          {!readOnly ? ( // this needs a separate component
            <>
              {tile.comments?.length > 0 && (
              <div className="kanban-tile__cta__icon" onClick={(ev) => openCommentsSideBar(ev, true)}>
                <Tooltip label="See Comments" withArrow position="top">
                  <div className="kanban-tile__cta__icon__contacts">
                    <i className="bi bi-chat-square-text" />
                  </div>
                </Tooltip>
              </div>
              )}
              <div className="kanban-tile__cta__icon" onClick={(event) => openAddJobModal(event)}>
                <Tooltip label="Save job posting" withArrow position="top">
                  <i className="bi bi-plus-lg" />
                </Tooltip>
              </div>
              <div className="kanban-tile__cta__icon" onClick={(ev) => openAreYouSure(ev)}>
                <Tooltip label="Archive Company" withArrow position="top">
                  <i className="bi bi-archive" />
                </Tooltip>
              </div>
            </>
          ) : ( // this needs a separate component
            <div className="kanban-tile__cta__icon" onClick={(ev) => openCommentsSideBar(ev, false)}>
              <Tooltip label="View Comments" withArrow position="top">
                <div className="kanban-tile__cta__icon__contacts">
                  <i className="bi bi-chat-square-text" />
                  {tile.comments?.length || 0}
                </div>
              </Tooltip>
            </div>
          )}
        </div>

      </div>
    ) : null
  );
};
