import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHash } from '@/services/hooks/use-hash';
import './connection-status-pending.scss';

export const ConnectionStatusPending = () => {
  const { hash } = window.location;
  const { handleHashChange } = useHash({});
  useEffect(() => {
    handleHashChange();
  }, [hash]);

  return (
    <div className="connection-status-pending">
      <p className="connection-status-pending__title">
        Congrats on reaching out to your contact!
      </p>
      <p className="connection-status-pending__description">
        We will notify you once the contact has responded or a follow-up is
        needed. In the meantime, prepare for your coffee chat with our
        <Link to="#coffeeChatTips"> tips and tricks here.</Link>
      </p>
    </div>
  );
};
