import './tracker-header.scss';
import { DefaultLogo } from '@/components/default-logo/default-logo';

interface HeaderProps {
  logo: string;
  name: string;
}

export const TrackerHeader = ({
  logo,
  name,
}: HeaderProps) => (
  <div className="company-tracker-header">
    <div className="company-tracker-header__logo">
      <DefaultLogo
        source={logo}
        type="company"
        name={name}
        className=""
        size={72}
      />
    </div>
    <h3 className="company-tracker-header__name">
      {name}
    </h3>
  </div>
);
