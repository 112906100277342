import { getSingleCompany } from '@/services/api/company';
import { getAllStudents, getAllStudentsByCohortID } from '@/services/api/university';

export async function applicationsByCompanyLoader(request: any) {
  const { companyID, universityID } = request.params;
  const company = await getSingleCompany(companyID);
  let students: any[] = [];

  const studentsResponse: any = await getAllStudents(universityID);
  students = studentsResponse ? studentsResponse.map((student: any) => ({
    ...student,
    name: `${student.first_name} ${student.last_name}`,
    initials: `${student.first_name[0]}`,
    tiles: student.applications,
  })) : [];

  return { students, company };
}

export async function applicationsByCompanyandCohortLoader(request: any) {
  const { companyID, cohortID } = request.params;
  const company = await getSingleCompany(companyID);
  let students: any[] = [];

  const studentsResponse: any = await getAllStudentsByCohortID(cohortID);
  students = studentsResponse ? studentsResponse.map((student: any) => ({
    ...student,
    name: `${student.first_name} ${student.last_name}`,
    initials: `${student.first_name[0]}`,
    tiles: student.applications,
  })) : [];

  return { students, company };
}
