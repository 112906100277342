import { Link } from 'react-router-dom';

import './empty-contacts-list.scss';

export const EmptyContactsList = () => (
  <div className="empty-contacts-list">
    <h3 className="empty-contacts-list__title">Looks like you haven’t saved any contacts yet</h3>
    <p className="empty-contacts-list__text">
      Network on easy mode by
      {' '}
      <Link
        className="empty-contacts-list__link"
        to="https://www.linkedin.com/search/results/all/"
        target="_blank"
      >
        saving contacts from LinkedIn
      </Link>
      {' '}
      or even better, get suggested contacts for any company once you have your Career Co-Pilot,
      {' '}
      <Link
        to={import.meta.env.VITE_EXTENSION_LINK}
        target="_blank"
        className="empty-contacts-list__link"
      >
        the Chrome Extension
      </Link>
      .
    </p>
  </div>
);
