import '../styles/support.scss';

export default function PrivacyPolicyPage() {
  return (
    <div className="privacy-wrapper">
      <div className="privacy-content">
        <h1>CareerOS Privacy Policy</h1>
        <p>This Privacy Policy was last updated on: April 30th, 2024</p>

        <p>
          We would love to answer any questions you may have regarding the processing of your personal data. The purpose of this Privacy Policy is to inform you of the measures we have taken to ensure the highest possible level of privacy and protection of your personal data. It is intended to inform you about the processing and management of any personal data you provide us with, the reasons why we collect it and how we ensure its security.
          This Privacy Policy has been written in accordance with the principles of the General Data Protection Regulation (“GDPR”).
          If, after reading this document, you still have questions, you can email us at
          {' '}
          <a href="mailto:help@thecareeros.com">help@thecareeros.com</a>
          {' '}
          and we will happily respond.
          By using our platform, you consent to the collection, use, and sharing of your personal information as described in this policy.
        </p>

        <h2>1. General Overview</h2>
        <p>
          CareerOS is an integrated career development and execution platform (“the Service”) that connects Students, University Career Advisors, and Employers.
          CareerOS is the data controller of any personal data you have provided us with during your use of the Service.
          University Career Advisors may be recipients of data describing the activity of their students. Employers may be recipients of data regarding student profiles and job opportunities.
          Each University Career Advisor or their respective institution, and employers, collect and process data in accordance with the applicable laws and subject to their own responsibility.
          CareerOS is a product provided by Jobworx, Inc.
        </p>

        <h2>2. Scope</h2>
        <p>
          CareerOS offers several categories of user accounts, each associated with specific services.
          This Privacy Policy applies to the personal data we collect from Students, University Career Advisors, and Employers.
        </p>

        <h2>3. Type of Data Collected</h2>
        <p>
          For optimal results from our Service, we will ask you for some personal data. We may also process data about you provided by our partner Universities or from third data sources, such as publicly available data or Linkedin data.
          Personal data we collect is data that personally identifies you, such as information in relation to:
        </p>
        <ul>
          <li>Your identity: surname(s), first name(s), email address(es), gender, ethnicity, etc.</li>
          <li>
            Your professional profile:
            <ul>
              <li>For Students: your education history, your professional experience, your desired industry and job position, your preferred job type (in-person, remote, or hybrid), languages you speak, work authorizations</li>
              <li>For University Career Advisors: the name of your institution, your title</li>
              <li>For Employers: the name of your company, your title, student profiles you are looking for</li>
            </ul>
          </li>
        </ul>
        <p>
          In addition, we may also collect technical logs of your connection to our website (IP address, date and time of connection), browser type, and operating system.
          Students who are using the CareerOS Chrome Extension also acknowledge that CareerOS may temporarily save Linkedin profile information, including Linkedin messages between the Student and their CareerOS Contacts, for its integrated networking feature.
        </p>

        <p>
          We do not use any Google Workspace data for any purpose other than to provide the Service to you. We do not share any Google Workspace data with third parties, except as required by law.
          <b>We do not use any Google Workspace data to train any AI models or machine learning models.</b>
        </p>

        <h2>4. Purpose of Data Processing</h2>
        <p>We use your personal information for the following purposes:</p>
        <ul>
          <li>To provide you with our web platform and its features.</li>
          <li>To personalize your experience on our platform and recommend job opportunities and companies that match your interests.</li>
          <li>To communicate with you about our platform and provide customer support.</li>
          <li>To improve our platform and develop new features.</li>
          <li>To comply with legal obligations.</li>
        </ul>

        <h2>5. Recipients of the Data</h2>
        <p>
          Personal data provided may be viewed by our employees or any subcontractors of CareerOS, strictly in accordance with the purposes described in Article 4 above and on a strict “need-to-know” basis.
          To guarantee you the best possible Service, we may share your personal information with third parties for the following purposes:
        </p>
        <ul>
          <li>With employers you have bookmarked or saved job postings for. We will share your contact information and other information you have provided to us with these companies to facilitate your job application process.</li>
          <li>With University Career Advisors. We will share an overview of your activity on CareerOS, namely which companies and industries you are engaging with on the platform, in order for your Advisor to give you the best possible, tailored advice.</li>
          <li>With service providers who help us operate our platform and provide our services.</li>
          <li>With our affiliates, business partners, or investors in connection with a corporate transaction, such as a merger or acquisition.</li>
          <li>With law enforcement or other government agencies when required by law or in response to a subpoena or other legal process.</li>
        </ul>

        <h2>6. Transfers Outside of the European Union</h2>
        <p>
          The data platform is hosted within the European Union. Where data transfers take place outside of the European Union, safeguards are taken: either providers are located in a country whose level of protection has been declared adequate by the European Commission, or standard contractual clauses approved by the European Commission have been signed with these recipients. We may also put in place any other guarantee that complies with data protection regulation that constitutes an “appropriate guarantee” within the meaning of the GDPR.
        </p>

        <h2>7. Storage Period</h2>
        <p>
          As part of the service we offer, we may be required to store the content that Users publish on CareerOS. Your personal information will not be stored beyond the period strictly necessary. You can close your account at any time by clicking the “Delete My Account” button on your profile settings page.
          Users are informed that their personal data will be stored for a maximum of two years following their last connection to the Service.
        </p>

        <h2>8. Security and Privacy</h2>
        <p>
          We have taken all appropriate organizational and technical measures and all necessary precautions to preserve the security of the information described above. We will ensure the integrity and availability of the data we are required to process at all times.
          These measures include, but are not limited to: restricted access to data through strict access controls and effective encryption of the data.
          A number of strict policies incorporate the protection of personal data throughout the internal organization of CareerOS, and all employees are subject to strict non-disclosure agreements about customer data.
        </p>

        <h2>9. Terms and Exercise of Rights</h2>
        <p>
          In accordance with data protection regulation, you have a right to access, correct, and delete your personal information. You have the right to withdraw your consent at any time.
          You can exercise your rights by contacting our Data Protection Officer via
          {' '}
          <a href="mailto:help@thecareeros.com">help@thecareeros.com</a>
          .
          We will do our best to answer your queries regarding the processing of your personal data. If you are not satisfied with the responses provided, you may make a complaint to:
        </p>
        <p>
          Irish Data Protection Commissioner
          <br />
          Office of the Data Protection Commissioner
          <br />
          Canal House, Station Road, Portarlington, Co. Laois, R32 AP23, Ireland
          <br />
          Phone: +353 57 868 4757
          <br />
          Fax: +353 57 868 4757
          <br />
          Email:
          {' '}
          <a href="mailto:info@dataprotection.ie">info@dataprotection.ie</a>
        </p>

        <h2>10. Data Protection Officer</h2>
        <p>
          Our Data Protection Officer and appointed EU Representative is:
        </p>
        <p>
          Oleksii Reshetnik
          <br />
          <a href="mailto:help@thecareeros.com">help@thecareeros.com</a>
          <br />
          Carrer de Valencia 293, 08009 Barcelona, Spain
        </p>

        <h2>11. Amendments of this Privacy Policy</h2>
        <p>
          We reserve the right to amend or update this Privacy Policy. If we make substantial changes to the content of this Privacy Policy, those changes will be announced via a notice visibly displayed on the Platform or by other means, such as the email address you provided at the time of your registration. If you object to any changes, you may close your account.
        </p>

        <h2>12. Use of Cookies</h2>
        <p>
          For more information about cookies, please consult our
          <a href="https://drive.google.com/file/d/1csKQut6DJK7i6rxh_S9smPFsH3yJQTz2/view" target="_blank" rel="noreferrer">“Cookie Policy”.</a>
        </p>
      </div>
    </div>
  );
}
