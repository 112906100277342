import {
  useEffect, useMemo, useRef, useState,
} from 'react';

import './email-recipient.scss';

type Props = {
  selectedEmails: string[];
  setSelectedEmails: (emails: string[]) => void;
  emailAddresses: string[];
};

export const EmailRecipient = ({ selectedEmails, emailAddresses, setSelectedEmails }: Props) => {
  const [filteredSelectedEmails, setFilteredSelectedEmails] = useState(selectedEmails.filter(email => !!email));
  const [showUnselectedEmails, setShowUnselectedMessages] = useState(false);
  const emailListRef = useRef<HTMLUListElement>(null);
  const unselectedEmails = useMemo(() => emailAddresses.filter(email => email && !selectedEmails.includes(email)), [selectedEmails, emailAddresses.length]);

  const selectEmailFromList = (email: string, ev: any) => {
    setSelectedEmails([email]);
    setShowUnselectedMessages(false);
    ev.stopPropagation();
  };

  const onEmailInputFocusHandler = () => {
    if (unselectedEmails.length) {
      setShowUnselectedMessages(true);
    }
  };

  const unselectEmail = (email: string) => setSelectedEmails(selectedEmails.filter(eml => eml !== email));

  useEffect(() => {
    setFilteredSelectedEmails(selectedEmails.filter(email => !!email));
  }, [selectedEmails]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (emailListRef.current && !emailListRef.current.contains(event.target)) {
        setShowUnselectedMessages(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="email-recipient" onClick={onEmailInputFocusHandler}>
      {filteredSelectedEmails.map(email => (
        <div key={email} className="email-recipient__email">
          <span>{email}</span>
          <button
            type="button"
            className="email-recipient__remove-button"
            onClick={() => unselectEmail(email)}
          >
            <i className="bi bi-x" />
          </button>
        </div>
      ))}
      {filteredSelectedEmails.length === 0 && (
        <span className="email-recipient__placeholder">
          Select email
        </span>
      )}
      {showUnselectedEmails && (
        <ul ref={emailListRef} className="email-recipient__list">
          {unselectedEmails.map(email => (
            <li
              key={email}
              className="email-recipient__item"
              onClick={(ev) => selectEmailFromList(email, ev)}
            >
              {email}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
