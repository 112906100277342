import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Loader } from '@/components/loader/loader';

const LogoutLoader = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    window.localStorage.removeItem('token');
    logout({ logoutParams: { returnTo: window.location.origin } });
    window.localStorage.removeItem('university');
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <div id="loader-zone">
      <Loader />
    </div>
  );
};

export default LogoutLoader;
