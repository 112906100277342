import { Loader } from '@/components/loader/loader';
import CustomWithAuthenticationRequired from './custom-protected-route';

function ReloginPage() {
  return (
    <div className="student-dashboard" />
  );
}

export default CustomWithAuthenticationRequired(ReloginPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
