import { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { Loader } from '@/components/loader/loader';
import { Tile } from '@/domains/core/company/types';
import './styles/overview.scss';
import { CompanyKanbanBoard } from '@/domains/core/advisor';
import { Button } from '@/components/button/Button';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';

const companies: string[] = [
  'Company Saved',
  'Networking',
  'Applying',
  'Applied',
  'Interviewing',
  'Offer',
];

function CompanyOverviewPage() {
  const { students, company } = useLoaderData() as any;
  const [kanbanLayout, setKanbanLayout] = useState({});

  const computeColumnData = (data: any, column: string) => {
    const filteredData = data.filter((student: any) => student.tiles.length > 0 && student.tiles.some((tile: any) => tile.status === column && tile.company_id === company.id));
    const mappedData = filteredData.map((student: any) => ({
      ...student,
      company_name: student.name,
      industry: student.cohort, // TODO this needs to be dynamic
      studentID: student.id,
      jobs: student.tiles[0].jobs || [],
    }));

    return mappedData;
  };
  const computeKanbanLayout = (allStudents: Tile[]) => {
    const kanban = {
      'Company Saved': computeColumnData(allStudents, 'saved'),
      Networking: computeColumnData(allStudents, 'networking'),
      Applying: computeColumnData(allStudents, 'applying'),
      Applied: computeColumnData(allStudents, 'applied'),
      Interviewing: computeColumnData(allStudents, 'interviewing'),
      Offer: computeColumnData(allStudents, 'offer'),
    };
    setKanbanLayout(kanban);
  };

  const backButtonClicked = () => {
    // eslint-disable-next-line no-restricted-globals
    history.back();
  };

  useEffect(() => {
    const studentsWhoHaveThisCompany = students.filter((student: any) => student.tiles.some((tile: any) => tile.company_id === company.id));
    computeKanbanLayout(studentsWhoHaveThisCompany);
  }, [students.length]);

  return (
    <div className="advisor-overview">
      <div className="advisor-overview__top">
        <div
          className="advisor-overview__top__back-button"
        >
          <Button
            mode="invisible"
            size="medium"
            label="Back"
            icon="bi bi-arrow-left"
            handleClick={backButtonClicked}
          />
        </div>
        <div className="advisor-overview__top__company-name">
          <img className="advisor-overview__top__company-name__logo" src={company.logo_url} alt="" />
          <span className="advisor-overview__top__company-name__name">
            {company.name}
          </span>
        </div>
      </div>
      <CompanyKanbanBoard
        columns={companies}
        layout={kanbanLayout}
        students={students.map((student: any) => student.id)}
      />
    </div>
  );
}

export default CustomWithAuthenticationRequired(CompanyOverviewPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
