import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { Loader } from '@/components/loader/loader';
import { useSelf, useSelfUniversity } from '@/services/queries/user';

import './styles/dashboard.scss';
import { router } from '@/main';
import { saveOnboardingData } from '@/services/api/user';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useAnalytics } from '@/services/hooks/use-analytics';

function processAndValidateFields(fields: Field[]): Field[] {
  return fields.map(field => ({
    ...field,
    answer: {
      value: String(field.answer.value), // Convert value to string
    },
  }));
}

interface Field {
  id: string;
  title: string;
  type: 'INPUT_TEXT' | 'INPUT_NUMBER' | 'INPUT_EMAIL' | 'INPUT_PHONE_NUMBER' | 'INPUT_LINK' | 'INPUT_DATE' | 'INPUT_TIME' | 'TEXTAREA' | 'MULTIPLE_CHOICE' | 'DROPDOWN' | 'CHECKBOXES' | 'LINEAR_SCALE' | 'FILE_UPLOAD' | 'HIDDEN_FIELDS' | 'CALCULATED_FIELDS' | 'RATING' | 'MULTI_SELECT' | 'MATRIX' | 'RANKING' | 'SIGNATURE' | 'PAYMENT';
  answer: { value: string };
}

interface SubmissionPayload {
  id?: string; // submission ID
  respondentId: string;
  formId: string;
  formName: string;
  createdAt: Date; // submission date
  updated_at: Date;
  fields: Array<Field>;
}
const FORM_SPACING = 168;
function TallyPage() {
  const { data: self, isLoading: isSelfLoading } = useSelf();
  const { trackEvent } = useAnalytics();
  const { user } = useAuth0();
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight - FORM_SPACING);
  const { data: university, isLoading: isUniversityLoading } = useSelfUniversity();
  const [searchParams, setSearchParams] = useSearchParams();
  const setHiddenFields = () => {
    // we need to move this in the redirect
    if (self && !searchParams.has('id')) {
      const { id, email, first_name: firstName } = self;
      const { name: school, cohort } = university || {};

      setSearchParams({
        user: id,
        email,
        first_name: firstName,
        school,
        cohort,
      });
    }
  };
  const redirectToFilterPage = (selectedIndustries: string | undefined) => {
    if (!selectedIndustries) {
      router.navigate('/app/dashboard');
      return;
    }

    // Normalize the string to Unicode Normalization Form C (NFC)
    const normalizedIndustries = selectedIndustries.normalize('NFC');

    // Updated regex to match invisible characters and more emoji patterns
    // eslint-disable-next-line no-misleading-character-class
    const emojiRegex = /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FA6F}|\u{1FA70}-\u{1FAFF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{2300}-\u{23FF}|\u{2B50}-\u{2BFF}|\u{2900}-\u{297F}|\u{3200}-\u{32FF}|\u{3300}-\u{33FF}|\u{FE0F}|\u{200D}|\u{20E3}]+/gu;

    const cleanIndustries = normalizedIndustries.replace(emojiRegex, '').trim();
    let firstIndustry = cleanIndustries === 'FMCG, Food & Retail' ? cleanIndustries : cleanIndustries.split(',')[0].trim();
    if (firstIndustry === 'FMCG') {
      firstIndustry = 'FMCG, Food & Retail';
    }
    router.navigate(`/app/companies/explore/${encodeURIComponent(firstIndustry)}`);
  };
  useEffect(() => {
    const widgetScriptSrc = 'https://tally.so/widgets/embed.js';
    const load = () => {
      // Load Tally embeds
      if (typeof Tally !== 'undefined') {
        Tally.loadEmbeds();
        return;
      }

      // Fallback if window.Tally is not available
      document
        .querySelectorAll('iframe[data-tally-src]:not([src])')
        .forEach((element) => {
          const iframeEl = element as HTMLIFrameElement;
          iframeEl.src = iframeEl.dataset.tallySrc || '';
        });
    };

    // If Tally is already loaded, load the embeds
    if (typeof Tally !== 'undefined') {
      load();
      return;
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement('script');
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
    }
    window.addEventListener('message', async (e) => {
      if (typeof e.data === 'string' && e.data.includes('Tally.FormSubmitted')) {
        const data = JSON.parse(e.data);
        const { payload }: { payload: SubmissionPayload } = data;
        try {
          payload.updated_at = new Date(payload.createdAt);
          payload.fields = processAndValidateFields(payload.fields);
          delete payload.id;
          await saveOnboardingData(payload);
          trackEvent('Tally Form Submitted', user, payload);
        } catch (error) {
          console.error('Error saving onboarding data', error);
        }
        const selectedIndustries = payload.fields.find((field: Field) => field.title === 'industries')?.answer.value;

        redirectToFilterPage(selectedIndustries);
      }
    });
  }, []);
  useEffect(() => {
    if (!isSelfLoading && !isUniversityLoading) {
      setHiddenFields();
    }
  }, [isSelfLoading, isUniversityLoading]);

  useEffect(() => {
    const updateHeight = () => {
      const height = window.innerHeight - FORM_SPACING;
      setIframeHeight(height);
    };

    // Initial height setting
    updateHeight();

    // Adding resize event listener
    window.addEventListener('resize', updateHeight);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);
  return (
    <div className="student-dashboard student-dashboard--form">
      <div className="career-os-grid">
        <div className="width-24/24">
          <div className="form-wrapper">
            <iframe
              data-tally-src="https://tally.so/embed/3Xe2Ld?transparentBackground=1"
              loading="lazy"
              width="100%"
              height={iframeHeight}
              frameBorder={0}
              marginHeight={0}
              marginWidth={0}
              title="Hey @first_name, let's accelerate your career!"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(TallyPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
