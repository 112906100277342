import Lottie from 'react-lottie';
import LoaderGif from '@/assets/images/ai-loader.json';

import './ai-tool-loader.scss';

export const AIToolLoader = () => (
  <div className="ai-tool-loader">
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: LoaderGif,
      }}
      height={150}
      width={150}
      style={{
        background: 'transparent',
      }}
    />
    <span className="ai-tool-loader__text">
      Generating your unique insights.
      Use them to prepare for coffee chats or interviews and to prepare your resume and cover letter.
    </span>
  </div>
);
