import { PDFDownloadLink } from '@react-pdf/renderer';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/button/Button';
import { PreviewResume } from '../resume-preview/resume-preview';
import { ResumeSchema, TemplateNames } from '../../types';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { RESUME_TEMPLATES } from '../../helpers/resume-rules';

GlobalWorkerOptions.workerSrc = '/pdf-worker.mjs';

type SaveResumeProps = {
  baseResume: ResumeSchema;
  title: string;
  templateName: TemplateNames;
  saveResume: () => void;
};

const SaveResume = ({
  baseResume, title, templateName, saveResume,
}: SaveResumeProps) => {
  const SelectedComponent = RESUME_TEMPLATES[templateName];
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();

  const track = () => {
    trackEvent('Resume Downloaded', user, {
      source: 'Resume Save Step',
    });
  };

  const saveResumeAndRedirect = async () => {
    trackEvent('Resume Saved', user, {
      source: 'Resume Save Step',
    });
    await saveResume();
    navigate('/app/resume-os');
  };

  return (
    <>
      <h2 className="resume-builder__title">
        Save & Export
      </h2>
      <div>
        <p className="resume-builder__subtitle">Done! You can now download your resume as a PDF or simply save it here in CareerOS.</p>
        <p className="resume-builder__subtitle">You can then use it as a base resume to tailor it to a specific job posting from your saved jobs! 🚀</p>
      </div>
      <div className="resume-builder__bottom">
        <div className="resume-builder__resume-name">{`PDF Preview: ${title}.pdf`}</div>
        <div className="resume-builder__export-buttons">
          {baseResume && (
          <PDFDownloadLink fileName={`${title}.pdf`} document={<SelectedComponent resume={baseResume} title={`${title}.pdf`} />}>
            <Button icon="bi bi-download" handleClick={track} label="Download PDF" mode="primary" size="medium" outlined />
          </PDFDownloadLink>
          )}
          <Button icon="bi bi-floppy" label="Save in CareerOS" handleClick={saveResumeAndRedirect} mode="primary" outlined size="medium" />
        </div>
      </div>
      {baseResume && (
      <PreviewResume preview hideHighlights selectedTemplate={templateName} baseResume={baseResume} />
      )}
    </>
  );
};

export default SaveResume;
