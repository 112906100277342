/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '@/components/loader/loader';
import { enroll, registerIfNotExists } from '@/services/api/login';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { sendMessageToExtension } from '@/services/hooks/use-extension-messaging';
import { routeByRole, trackUser } from '@/services/helpers/user';

async function registerUserIfNotExists(token: string): Promise<void> {
  const response = await registerIfNotExists(token);

  if (!response) {
    throw new Error('failed to create user account');
  }
}

async function sendAuthTokenToExtension(token: string, trackEvent: any) {
  try {
    const response = await sendMessageToExtension('Set access token', token);
    if (response === 'Authenticated') {
      trackEvent('Successful Login in Chrome Extension');
    }
  } catch (error) {
    console.log('access token not sent: %s', error);
    trackEvent('Failed Login in Chrome Extension');
  }
}

function setUniversityInLocalStorage(university: string): void {
  window.localStorage.setItem('university_name', university);
}

function setCohortInLocalStorage(cohort: string): void {
  window.localStorage.setItem('cohort', cohort);
}

function setTokenInLocalStorage(token: string): void {
  window.localStorage.setItem('token', token);
}

export async function enrollStudent(university: string | null, trackEvent: any): Promise<string | null> {
  if (university) setUniversityInLocalStorage(university);

  if (!university) {
    throw new Error('University is not defined');
  }

  const enrollResponse = await enroll(university);

  trackEvent(`Enrolled to ${university}`, {});

  if (typeof enrollResponse.cohort === 'string' && enrollResponse.cohort.length > 0) {
    setCohortInLocalStorage(enrollResponse);
    return enrollResponse;
  }

  return null;
}

const LoginLoaderIncognito = () => {
  const { trackEvent } = useAnalytics();
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const doLogin = async (token: string | null, university: string | null) => {
    if (!user) return;

    try {
      if (token) await sendAuthTokenToExtension(token, trackEvent);
    } catch (error) {
      console.log(error);
    }

    try {
      const enrollResponse: string | null = await enrollStudent(university, trackEvent);

      if (!enrollResponse) {
        navigate('/app/cohort-assignment');
        return;
      }

      trackUser(user, trackEvent, university, enrollResponse, true);
    } catch (error) {
      // not failing the login process if enrollment fails. We will fix it by hand if needed
      console.log(error);
    }
    navigate(routeByRole(user));
  };

  const fetchTokenAndSetToStorage = async (): Promise<string | undefined> => {
    try {
      const token = await getAccessTokenSilently();
      setTokenInLocalStorage(token);

      return token;
    } catch (er) {
      console.log(er);
    }

    return undefined;
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const university = params.get('university');

    fetchTokenAndSetToStorage()
      .then((token) => {
        if (token) {
          if (user && user.email) {
            registerUserIfNotExists(token).then(() => doLogin(token, university));
          }
        }
      });
  }, [user?.email, isAuthenticated]);

  return (
    <div id="loader-zone">
      <Loader />
    </div>
  );
};

export default withAuthenticationRequired(LoginLoaderIncognito, {
  returnTo: '/incognito-login',
});
