import './linkedin-message-skeleton.scss';

export const LinkedinMessageSkeleton = () => (
  <div className="linkedin-message">
    <div className="linkedin-message__avatar">
      <div className="linkedin-message__image">
        <div className="linkedin-message-skeleton__icon" />
      </div>
    </div>
    <div className="linkedin-message__content">
      <div className="linkedin-message__text">
        <div className="linkedin-message-skeleton__text linkedin-message-skeleton__text--name" />
      </div>
      <div className="linkedin-message__timestamp">
        <div className="linkedin-message-skeleton__text linkedin-message-skeleton__text--status" />
      </div>
    </div>
  </div>
);
