import React, {
  createContext, useContext, useState, useMemo, useCallback,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { HighlightWords, Suggestions } from '@/domains/core/resume/types';
import { useAnalytics } from '@/services/hooks/use-analytics';

export type ResumeBuilderContextType = {
  highlightWords: HighlightWords | null | Partial<HighlightWords>;
  suggestions: Suggestions | null;
  addHighlightWords: (highlights: HighlightWords) => void;
  addSuggestions: (suggestions: Suggestions) => void;
  toggle: () => void;
  toggleHighlightsValue: boolean;
  addActiveResumeField: (type: 'work' | 'additional_information', index: number, bulletIndex: number) => void;
  removeActiveResumeField: () => void;
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null;
  addComments: (comments: any) => void;
  comments: any;
  zoomIn: () => void;
  zoomOut: () => void;
  zoomLevel: ZoomLevel;
  latestScore: number;
  setScore: (score: number) => void;
  isSidebarOpened: boolean;
  toggleSidebar: () => void;
  setIsSidebarOpened: (isSidebarOpened: boolean) => void;
};

const ResumeBuilderContext = createContext<ResumeBuilderContextType>({
  highlightWords: null,
  suggestions: null,
  addHighlightWords: () => {}, // Provide a no-op function as default
  addSuggestions: () => {},
  toggle: () => {},
  toggleHighlightsValue: true,
  addActiveResumeField: () => {},
  removeActiveResumeField: () => {},
  activeField: null,
  addComments: () => {},
  comments: [],
  zoomIn: () => {},
  zoomOut: () => {},
  zoomLevel: 'level-0',
  latestScore: 0,
  setScore: () => {},
  isSidebarOpened: false,
  toggleSidebar: () => {},
  setIsSidebarOpened: () => {},
});

export const useResumeBuilderContext = () => {
  const context = useContext(ResumeBuilderContext);
  if (context === undefined) {
    throw new Error('useResumeBuilderContext must be used within a ResumeBuilderProvider');
  }
  return context;
};

type ResumeBuilderProviderProps = {
  children: React.ReactNode;
};

type ZoomLevel = 'level-0' | 'level-plus-1' | 'level-plus-2' | 'level-plus-3' | 'level-minus-1' | 'level-minus-2' | 'level-minus-3';

export const ResumeBuilderProvider: React.FC<ResumeBuilderProviderProps> = ({ children }) => {
  const [highlightWords, setHighlightWords] = useState<HighlightWords | null>(null);
  const [toggleHighlightsValue, setToggleHighlightsValue] = useState(true);

  const [suggestions, setSuggestions] = useState<Suggestions | null>(null);
  const [comments, setComments] = useState<any>(null);

  const [zoomLevel, setZoomLevel] = useState<ZoomLevel>('level-0');
  const [latestScore, setLatestScore] = useState<number>(0);

  const [isSidebarOpened, setIsSidebarOpened] = useState<boolean>(false);
  const toggleSidebar = () => {
    setIsSidebarOpened((prev) => !prev);
  };

  const setScore = useCallback((score: number) => {
    setLatestScore(score);
  }, []);

  const zoomLevels: ZoomLevel[] = ['level-minus-3', 'level-minus-2', 'level-minus-1', 'level-0', 'level-plus-1', 'level-plus-2', 'level-plus-3'];

  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const [activeField, setActiveField] = useState<{ type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null>(null);

  const getCurrentZoomIndex = () => zoomLevels.indexOf(zoomLevel);

  const addHighlightWords = useCallback((highlights: HighlightWords) => {
    setHighlightWords(highlights);
  }, []);

  const toggle = () => {
    setToggleHighlightsValue((prev) => !prev);
  };

  const zoomIn = () => {
    const currentIndex = getCurrentZoomIndex();
    if (currentIndex < zoomLevels.length - 1) {
      const newZoomLevel = zoomLevels[currentIndex + 1];
      setZoomLevel(newZoomLevel);
      trackEvent('Resume Zoomed In', user, {
        source: window.location,
        newZoomLevel,
      });
    }
  };

  const zoomOut = () => {
    const currentIndex = getCurrentZoomIndex();
    if (currentIndex > 0) {
      const newZoomLevel = zoomLevels[currentIndex - 1];
      setZoomLevel(newZoomLevel);
      trackEvent('Resume Zoomed Out', user, {
        source: window.location,
        newZoomLevel,
      });
    }
  };

  const addSuggestions = useCallback((sgst: Suggestions) => {
    setSuggestions(sgst);
  }, []);

  const addComments = useCallback((cms: any) => {
    setComments(cms);
  }, []);

  const addActiveResumeField = useCallback((type: 'work' | 'additional_information', index: number, bulletIndex: number) => {
    setActiveField({ type, index, bulletIndex });
  }, []);

  const removeActiveResumeField = () => {
    setActiveField(null);
  };

  const computeHighlightWords = (hwds: HighlightWords | null, aF: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null): Partial<HighlightWords> | null => {
    if (!hwds || !aF) return null;

    const { type, index, bulletIndex } = aF;

    if (type === 'work') {
      return {
        skills: null,
        additional_information: [],
        work: [hwds.work[index].filter(item => item.listIndex === bulletIndex)],
      };
    }
    if (type === 'additional_information') {
      return {
        skills: null,
        work: [],
        additional_information: hwds.additional_information.filter(item => item.listIndex === bulletIndex),
      };
    }
    return null;
  };

  const value = useMemo(() => ({
    highlightWords: toggleHighlightsValue ? highlightWords : computeHighlightWords(highlightWords, activeField),
    suggestions,
    addHighlightWords,
    toggle,
    addSuggestions,
    setScore,
    toggleHighlightsValue,
    addActiveResumeField,
    removeActiveResumeField,
    activeField,
    addComments,
    comments,
    zoomIn,
    zoomOut,
    zoomLevel,
    latestScore,
    isSidebarOpened,
    toggleSidebar,
    setIsSidebarOpened,
  }), [highlightWords, addHighlightWords, toggleHighlightsValue, toggle, addSuggestions, suggestions, latestScore]);

  return (
    <ResumeBuilderContext.Provider value={value}>
      {children}
    </ResumeBuilderContext.Provider>
  );
};
