import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useCookies } from 'react-cookie';
import { LinkType } from './types/types';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { SidebarView } from './sidebar-view/sidebar-view';
import {
  AdminLinks,
  BetaUsersLinks,
  CareerAdvisorLinks,
  StudentLinks,
} from './sidebar-links';

type SidebarProps = {
  collapsed: boolean;
  handleToggle: () => void;
};

export default function Sidebar({ collapsed, handleToggle }: SidebarProps) {
  const { user } = useAuth0();
  // const location = useLocation();
  const { trackEvent } = useAnalytics();
  // const [onboarding, setOnboarding] = useState(false);
  const [links, setLinks] = useState<LinkType[]>([]);
  const [cookies] = useCookies(['cookiesAccepted']);
  const [logoLink, setLogoLink] = useState('/app/dashboard');
  const handleLinkClick = (linkName: string) => {
    trackEvent('Page Visit', user, {
      pageURL: linkName,
    });
    const newLinks = links?.map((linksLink: LinkType) => {
      linksLink.isActive = false;
      return linksLink;
    });
    setLinks(newLinks);
  };

  const setUpLinks = () => {
    if (user) {
      const roles = user['https://careeros.com/roles'];
      let visibleLinks = [];
      if (roles.includes('CareerAdvisor')) {
        visibleLinks = [...CareerAdvisorLinks, ...StudentLinks];
        setLogoLink('/app/dashboard-advisor');
      } else if (roles.includes('Admin')) {
        visibleLinks = [
          ...AdminLinks,
          ...CareerAdvisorLinks,
          ...StudentLinks,
        ];
        setLogoLink('/app/admin-view');
      } else {
        visibleLinks = [...StudentLinks];
      }
      if (roles.includes('BetaUser')) {
        visibleLinks = [
          ...visibleLinks.filter(
            (link) => link.label !== 'Saved Companies' && link.label !== 'Archive',
          ),
          ...BetaUsersLinks,
        ];
      }
      setLinks(visibleLinks.sort((a, b) => a.order - b.order));
    }
  };

  useEffect(() => {
    setUpLinks();
  }, [user]);

  return (
    <SidebarView
      collapsed={collapsed}
      isCookieBannerVisible={!cookies.cookiesAccepted}
      showContent
      logoLink={logoLink}
      links={links}
      handleToggle={handleToggle}
      handleLinkClick={handleLinkClick}
    />
  );
}
