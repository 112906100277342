import GoogleLogo from '@/assets/images/google-logo.png';
import MicrosoftLogo from '@/assets/images/microsoft-logo.svg';
import './provider-login-button.scss';
import { LoginProviders } from '../types';
import { useProviderAuth } from '@/services/hooks/use-provider-auth';

type Props = {
  provider: LoginProviders;
  universityName: string;
  accountType: 'consumers' | 'organizations';
  universityId?: string;
};

const providerNameMap: { [index in LoginProviders]: string } = {
  windowslive: 'Microsoft Account',
  'google-oauth2': 'Google',
  waad: 'Azure',
  samlp: 'SAML',
  basic: 'Basic',
};

export const ProviderLoginButton = ({
  provider,
  accountType,
  universityName,
  universityId,
}: Props) => {
  const buttonLabel = `Continue with ${providerNameMap[provider]}`;
  const icon = provider === 'google-oauth2' ? GoogleLogo : MicrosoftLogo;
  const { loginWithProvider } = useProviderAuth();

  const handleClick = () => {
    localStorage.setItem('providerUniversity', JSON.stringify({
      name: universityName,
      id: universityId,
    }));
    localStorage.setItem('provider', JSON.stringify(provider));
    loginWithProvider(provider, accountType, universityName, universityId);
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className="provider-login-button"
    >
      <img className="provider-login-button__icon" src={icon} alt="" />
      <span>
        {buttonLabel}
      </span>
    </button>
  );
};
