const BASE_URL = import.meta.env.VITE_PUBLIC_URL;

async function request(
  url: string,
  method = 'GET',
  data: any = null,
): Promise<any> {
  const options: RequestInit = { method };

  if (data) {
    if (data instanceof FormData) {
      options.body = data;
    } else {
      options.headers = { 'Content-Type': 'application/json; charset=UTF-8' };
      options.body = JSON.stringify(data);
    }
  }

  return fetch(BASE_URL + url, options)
    .then(response => {
      const contentType = response.headers.get('content-type');

      if (!response.ok) {
        return response.text().then(text => { throw new Error(text); });
      }

      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json();
      }

      return {};
    }).catch(err => {
      let error;
      try {
        error = JSON.parse(err.message);

        throw new Error(error.message);
      } catch (e) {
        // trackEvent(`JSON Parse Error for ${url} `, e);
        // throw new Error(error.message);
      }
    });
}

export const http = {
  get: (url: string) => request(url),
  delete: (url: string) => request(url, 'DELETE'),
  post: (url: string, data: any) => request(url, 'POST', data),
  patch: (url: string, data: any) => request(url, 'PATCH', data),
  put: (url: string, data: any) => request(url, 'PUT', data),
};
