import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { ModalContext } from '@/components/modal/modal-provider';
import { MessageTemplates, TrackerInfo, TrackerTip } from '@/domains/generic/modals';
import { trackerTipsLocation } from '@/domains/core/tracker-data/tracker-data';
import { useAnalytics } from './use-analytics';

export function useHash(pageData: any) {
  const { hash } = window.location;
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const { openModal } = useContext(ModalContext) as any;
  const {
    templates, activeState, status, company, university,
  } = pageData;

  const openTemplatesModal = () => {
    trackEvent('Messaging Templates Modal Shown', user);
    openModal(
      <MessageTemplates
        templates={templates}
      />,
    );
  };

  const openTipsModal = () => {
    trackEvent('Tracker Page: Tips Modal clicked', user);
    openModal(<TrackerTip title={activeState.tipTitle} university={university} activeState={status} tipLocation={hash || '#openTipModal'} />);
  };

  const openInfoModal = () => {
    trackEvent('Tracker Page: Info Modal clicked', user);
    openModal(<TrackerInfo title="Tips for the win" activeState={status} />);
  };

  const handleHashChange = () => {
    if (hash === '#messagingTemplates') {
      trackEvent('Tracker Page: Messaging Templates clicked', user);
      openTemplatesModal();
    }
    if (hash === '#openAlreadyConnected') {
      // eslint-disable-next-line no-restricted-globals
      history.back();
    }
    if (hash === '#coffeeChatTips') {
      setTimeout(() => {
        openModal(<TrackerTip title="Coffee Chat Tips" activeState="networking" tipLocation="#openTipModal" />);
      }, 100);
    }
    if (Object.keys(trackerTipsLocation).includes(hash)) {
      openTipsModal();
    }
    if (hash === '#coffeeChat') {
      trackEvent('Tracker Page: Coffee Chat clicked', user);
      openInfoModal();
    }

    if (!company?.name) {
      return;
    }

    if (hash === '#networkingJobLinkedin') {
      trackEvent('Tracker Page: Job Link clicked', user);
      window.open(`https://www.linkedin.com/jobs/search/?keywords=${encodeURIComponent(company.name)}&spellCorrectionEnabled=false`, '_blank');
      // eslint-disable-next-line no-restricted-globals
      history.back();
    }
    if (hash === '#networkingLinkedin') {
      trackEvent('Tracker Page: Networking Link clicked', user);
      window.open(`https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(company.name)}&origin=GLOBAL_SEARCH_HEADER&sid=n)F&spellCorrectionEnabled=false`, '_blank');
      // eslint-disable-next-line no-restricted-globals
      history.back();
    }
    if (hash === '#connectContact') {
      trackEvent('Tracker Page: Contact Link clicked', user);
      window.open(`https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(company.name)}&origin=SWITCH_SEARCH_VERTICAL&sid=n)F&spellCorrectionEnabled=false`, '_blank');
      // eslint-disable-next-line no-restricted-globals
      history.back();
    }
    if (hash === '#googleNews') {
      trackEvent('Tracker Page: Google News clicked', user);
      window.open(`https://news.google.com/search?q=${encodeURIComponent(company.name)}&hl=en-US&gl=US&ceid=US%3Aen`, '_blank');
      // eslint-disable-next-line no-restricted-globals
      history.back();
    }
  };

  return { handleHashChange };
}
