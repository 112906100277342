import { useEffect, useState } from 'react';
import { Button } from '@/components/button/Button';
import './job-picker.scss';
import { DropdownSearch } from '@/components/dropdown-search/dropdown-search';
import { Input } from '@/components/input/input';
import { AddJobButton } from '../add-job-button/add-job-button';

type Props = {
  jobs: string[];
  companyLinkedIn: string;
  changeTab: (tab: string, state?: string) => void;
  handleJobPick: (job: string) => void;
};

export const JobPicker = ({
  jobs, companyLinkedIn, changeTab, handleJobPick,
}: Props) => {
  const [selectedJob, setSelectedJob] = useState('');
  const [inputWarning, setInputWarning] = useState(false);

  const handleJobSelect = () => {
    if (selectedJob.length >= 100) {
      setInputWarning(true);
      return;
    }

    setInputWarning(false);
    handleJobPick(selectedJob);
  };

  useEffect(() => {
    if (inputWarning && selectedJob.length < 100) {
      setInputWarning(false);
    }
  }, [selectedJob]);

  return (
    <div className="job-picker">
      <div className="job-picker__content">
        {jobs.length === 0 ? (
          <div className="job-picker__section">
            <h3 className="job-picker__text">You haven&apos;t saved any jobs, yet.</h3>
            <div className="job-picker__buttons">
              <Button
                mode="primary"
                size="medium"
                icon="bi bi-plus-circle"
                outlined
                label="Save Job Manually"
                handleClick={() => changeTab('Jobs', 'addJobManually')}
              />
              <span className="job-picker__separator">or</span>
              {/* <Button
                mode="primary"
                size="medium"
                icon="bi bi-box-arrow-up-right"
                iconPosition="right"
                label="Save Jobs from LinkedIn"
                handleClick={() => window.open(`${companyLinkedIn}/jobs`, '_blank')}
              /> */}
              <AddJobButton
                mode="primary"
                size="medium"
                companyLinkedIn={companyLinkedIn}
                icon="bi bi-box-arrow-up-right"
                buttonLabel="Save Jobs from Job Boards"
              />
            </div>
          </div>
        ) : (
          <div className="job-picker__section">
            <h3 className="job-picker__text">
              Select from your
              {' '}
              <span onClick={() => changeTab('Jobs')} className="job-picker__text job-picker__text--highlighted">list of saved jobs:</span>
            </h3>
            <div className="job-picker__dropdown">
              <DropdownSearch
                name="job-picker"
                selectedOption=""
                id="job-picker"
                placeholder="Select job"
                options={jobs.map((job) => ({ name: job }))}
                handleItemSelect={handleJobPick}
                canAddItems={false}
                showIcon={false}
                size="medium"
                withArrow
              />
            </div>
          </div>
        )}
        <span className="job-picker__separator">or</span>
        <div className="job-picker__section">
          <h3 className="job-picker__text">
            Enter your desired role:
          </h3>
          <div className="job-picker__buttons">
            <Input
              value={selectedJob}
              id="desired-job"
              placeholder="Enter target job title"
              label=""
              handleValueChange={setSelectedJob}
              invalid={inputWarning}
              warningText="Maximum job title length is 100 characters."
            />
          </div>
        </div>
        <div className="job-picker__button">
          <Button
            mode="primary"
            size="medium"
            icon="bi bi-arrow-right"
            iconPosition="right"
            label="Continue"
            disabled={selectedJob.length === 0}
            handleClick={handleJobSelect}
          />
        </div>
      </div>
    </div>
  );
};
