import { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@/components/tooltip/tooltip';
import './multi-select-option.scss';

type Props = {
  isSelected: boolean;
  name: string | JSX.Element;
  width: 'full' | 'fit-box';
};

export const MultiSelectOption = ({
  name, isSelected, width,
}: Props) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const elementRef = useRef<any>(null);

  useEffect(() => {
    if (elementRef.current) {
      setIsOverflow(elementRef.current.scrollWidth > elementRef.current.clientWidth);
    }
  }, []);

  return (
    <div className="filter-option">
      <div className={`filter-option__checkbox ${isSelected ? 'filter-option__checkbox--checked' : ''}`} />
      <Tooltip label={name.toString()} position="top" disableHoverListener={typeof name !== 'string' || !isOverflow}>
        <span ref={elementRef} className={`filter-option__name filter-option__name--${width}`}>{name}</span>
      </Tooltip>
    </div>
  );
};
