/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  ComponentType, useEffect, FC,
} from 'react';
import { useAuth0, Auth0Context } from '@auth0/auth0-react';
import { useProviderAuth } from '@/services/hooks/use-provider-auth'; // Ensure the path is correct
import { LoginProviders } from '@/domains/generic/auth/types';
import { setTokenInLocalStorage } from '../login-loader';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { getLatestUrlFromStorage, setLetestUrlToStorage } from '@/services/helpers/browser-storage';

const defaultOnRedirecting = (): JSX.Element => <></>;

interface WithAuthenticationRequiredOptions {
  returnTo?: string | (() => string);
  onRedirecting?: () => JSX.Element;
  context?: React.Context<any>;
}

/**
 * This HOC wraps a component, ensuring that the user is authenticated using custom login logic before rendering.
 * It returns a functional component that performs these checks.
 */
const CustomWithAuthenticationRequired = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {},
): FC<P> => {
  // Extract options with defaults
  const {
    onRedirecting = defaultOnRedirecting,
    context = Auth0Context, // Use the default Auth0 context unless a specific one is provided
  } = options;

  // This is the functional component that the HOC returns. It includes all necessary logic.
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const {
      isAuthenticated, isLoading, getAccessTokenSilently, logout,
    } = useAuth0(context); // Use the Auth0 context for auth status
    const { loginWithProvider } = useProviderAuth(); // Custom hook for your specific login logic
    const { trackEvent } = useAnalytics();

    const relogin = async () => {
      const token = await getAccessTokenSilently();
      setTokenInLocalStorage(token);
      trackEvent('Successful login', {});

      const preservedUrl = getLatestUrlFromStorage();

      if (preservedUrl) {
        window.location.href = preservedUrl;
        return;
      }

      window.location.href = '/app/dashboard';
    };

    useEffect(() => {
      if (isLoading) {
        return;
      }

      if (isAuthenticated) {
        if (window.location.pathname === '/relogin') {
          relogin();
        }
        return;
      }

      // Execute when the user is neither loading nor authenticated
      const university = localStorage.getItem('providerUniversity');
      if (!university) {
        setLetestUrlToStorage(window.location.href);
        logout({ logoutParams: { returnTo: `${window.location.origin}/with-university` } });
        return;
      }

      const universityObj = JSON.parse(university);
      const { id: universityId, name: universityName } = universityObj;

      const provider = localStorage.getItem('provider');
      if (!provider) {
        // If no provider is found, redirect to a specific page
        setLetestUrlToStorage(window.location.href);
        logout({ logoutParams: { returnTo: `${window.location.origin}/with-university` } });
        return;
      }

      const providerParsed = JSON.parse(provider) as LoginProviders;
      // Use the custom login function
      setLetestUrlToStorage(window.location.href);
      loginWithProvider(providerParsed, 'organizations', universityName, universityId);
    }, [isLoading, isAuthenticated, loginWithProvider]);

    // Render the component only if authenticated, otherwise show the redirecting component
    return isAuthenticated ? <Component {...props} /> : onRedirecting();
  };
};

export default CustomWithAuthenticationRequired;
