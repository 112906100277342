import { useState } from 'react';
import { Button } from '@/components/button/Button';

import './contact-info-modal.scss';

type Props = {
  orientation?: 'horizontal' | 'vertical';
  icon?: string;
  title: string;
  description: string | JSX.Element;
  buttonLabel: string;
  buttonIcon?: string;
  secondaryButtonLabel?: string;
  secondaryButtonIcon?: string;
  handleSecondaryButtonClick?: () => void;
  handleButtonClick: (remember: boolean) => void;
  isButtonDanger?: boolean;
  isSecondButtonDanger?: boolean;
  rememberText?: string;
};

export const InfoModal = ({
  orientation = 'horizontal',
  icon,
  title,
  description,
  buttonLabel,
  buttonIcon,
  handleButtonClick,
  secondaryButtonLabel,
  secondaryButtonIcon,
  handleSecondaryButtonClick,
  rememberText,
  isButtonDanger,
  isSecondButtonDanger,
}: Props) => {
  const [rememberAnswer, setRememberAnswer] = useState(false);

  return (
    <div className={`info-modal info-modal--${orientation}`}>
      {icon && (
      <div className="info-modal__image">
        <i className={icon} />
      </div>
      )}
      <div className="info-modal__text">
        <h3 className="info-modal__title">{title}</h3>
        <div className="info-modal__description">{description}</div>
      </div>
      <div className="info-modal__actions">
        {rememberText && (
        <div className="info-modal__remember">
          <input
            type="checkbox"
            id="remember"
            className="info-modal__remember-checkbox"
            checked={rememberAnswer}
            onChange={() => setRememberAnswer(!rememberAnswer)}
          />
          <label htmlFor="remember">{rememberText}</label>
        </div>
        )}
        <div className="info-modal__button">
          {(secondaryButtonLabel && handleSecondaryButtonClick) && (
            <div className="info-modal__button-secondary info-modal__button-secondary--mobile">
              <Button
                icon={secondaryButtonIcon}
                label={secondaryButtonLabel}
                mode={isSecondButtonDanger ? 'dangerous' : 'primary'}
                size="medium"
                handleClick={handleSecondaryButtonClick}
                outlined
              />
            </div>
          )}
          <Button
            icon={buttonIcon}
            label={buttonLabel}
            mode={isButtonDanger ? 'dangerous' : 'primary'}
            size="medium"
            handleClick={() => handleButtonClick(rememberAnswer)}
          />
        </div>
      </div>
    </div>
  );
};
