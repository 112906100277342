import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTabs } from './TabsContext';
import './tabs.scss';
import { Tab } from './tab';

interface TabsHeaderProps {
  tabs: string[];
  unreadTabs?: string[];
  defaultTab: string;
  icons?: string[];
  size?: 'medium' | 'large';
  tabCounters?: { [key: string]: number };
  disabledTabs?: string[];
  withBorder?: boolean;
  containerName?: string;
  onTabClick?: (tab: string) => void;
}
/**
 * Primary UI component for Tabs Header
 */
export const TabsHeader = ({
  tabs,
  defaultTab,
  size = 'large',
  icons,
  unreadTabs = [],
  disabledTabs = [],
  tabCounters,
  withBorder = false,
  containerName,
  onTabClick,
}: TabsHeaderProps) => {
  const { currentTab, setCurrentTab } = useTabs();
  const [searchParams] = useSearchParams();
  const tabParamName = containerName ? `${containerName}-tab` : 'tab';

  const updateTabParam = (tab: string) => {
    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.set(tabParamName, encodeURIComponent(tab));
    window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
  };

  const handleTabClicked = (tab: string) => {
    if (onTabClick) {
      onTabClick(tab);
    }
    setCurrentTab(tab);
    updateTabParam(tab);
  };

  useEffect(() => {
    setCurrentTab(defaultTab);
  }, [setCurrentTab, defaultTab]);

  useEffect(() => {
    if (searchParams.has(tabParamName)) {
      const tab = decodeURIComponent(searchParams.get(tabParamName) || '');
      setCurrentTab(tabs.includes(tab) ? tab : defaultTab);
    }
  }, [searchParams]);

  useEffect(() => {
    if (currentTab) {
      updateTabParam(currentTab);
    }
  }, [currentTab]);

  useEffect(() => () => {
    const newSearchParams = new URLSearchParams(window.location.search);
    setCurrentTab(undefined);

    if (newSearchParams.get(tabParamName) && tabs.includes(decodeURIComponent(newSearchParams.get(tabParamName) || ''))) {
      newSearchParams.delete(tabParamName);
      window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
    }
  }, []);

  return (
    <div className={`tabs-header ${withBorder ? 'tabs-header--with-border' : ''}`}>
      {tabs.map((tab: string, index: number) => (
        <Tab
          key={tab}
          tabName={tab}
          size={size}
          icon={icons && icons[index]}
          isActive={currentTab === tab}
          isUnread={unreadTabs.includes(tab)}
          counter={tabCounters && tabCounters[tab]}
          handleTabClicked={() => handleTabClicked(tab)}
          isDisabled={disabledTabs.includes(tab)}
        />
      ))}
    </div>
  );
};
