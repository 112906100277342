import { QuestType } from '@/domains/core/student/types';
import './history-event.scss';

const formatDate = (date: Date) => {
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(date);

  const formattedDateWithAt = formattedDate.replace(/, (\d{1,2}:\d{2} [APM]{2})$/, ' at $1');
  return formattedDateWithAt;
};

type EventContent = {
  [QuestType.AddNewContact]: {
    text: string;
    image: string;
  };
  [QuestType.SendConnection]: {
    text: string;
    image: string;
  };
  [QuestType.ScheduleCoffeeChat]: {
    text: string;
    image: string;
  };
};

type Props = {
  contactName: string;
  type: keyof EventContent;
  date: string;
};

export const HistoryEvent = ({ contactName, type, date }: Props) => {
  const eventContent: EventContent = {
    [QuestType.AddNewContact]: {
      text: `You saved ${contactName} from LinkedIn.`,
      image: 'person',
    },
    [QuestType.SendConnection]: {
      text: `You and ${contactName} are now connected on LinkedIn.`,
      image: 'check-circle',
    },
    [QuestType.ScheduleCoffeeChat]: {
      text: `You have scheduled a coffee chat with ${contactName}.`,
      image: 'cup-hot',
    },
  };
  return (
    <div className="history-event">
      <div className="history-event__image">
        <i className={`bi bi-${eventContent[type]?.image}`} />
      </div>

      <span className="history-event__text">{eventContent[type]?.text}</span>
      <span className="history-event__date">{formatDate(new Date(date))}</span>
    </div>
  );
};
