export const postedDateStaticOptions = [
  {
    name: 'Last Week',
    value: '1',
  },
  {
    name: 'Last Month',
    value: '2',
  },
];

export const workTypeStaticOptions = [
  {
    name: 'Remote',
    value: 'Remote',
  },
  {
    name: 'Hybrid',
    value: 'Hybrid',
  },
  {
    name: 'On-site',
    value: 'On-Site',
  },
];

export const contractStaticOptions = [
  {
    name: 'Full-time',
    value: 'Full-time',
  },
  {
    name: 'Part-time',
    value: 'Part-time',
  },
  {
    name: 'Internship',
    value: 'Internship',
  },
];
