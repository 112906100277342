import { router } from '@/main';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { Accordion } from '@/components/accordion/accordion';
import HowToImage from '@/assets/images/how-tos.svg';
import './styles/support-page.scss';
import { Button } from '@/components/button/Button';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';

const HowToPage = () => {
  const backButtonClickHandler = () => {
    router.navigate('/app/support/');
  };
  return (
    <div className="support-page">
      <div className="white-wrapper">
        <div
          className="white-wrapper__back-button"
        >
          <Button
            mode="invisible"
            size="medium"
            icon="bi bi-arrow-left"
            iconPosition="left"
            label="Back"
            handleClick={backButtonClickHandler}
          />
        </div>
        <div className="support-page__content">
          <img className="support-page__image" src={HowToImage} alt="" />
          <h2 className="support-page__title">
            How To’s
          </h2>
          <p className="support-page__subtitle">
            Short and sweet walkthrough of the Chrome Extension features.
          </p>
          <TabsHeader
            tabs={['Save a company', 'Save contact', 'Save a job']}
            defaultTab="Save a company"
          />
          <TabsContent>
            <div className="support-page__tab" data-tab="Save a company">
              <h3 className="support-page__tab__title">Finding companies of interest</h3>
              <Accordion title="In CareerOS">
                <img src="https://storage.googleapis.com/student-app-assets/add-company-COS.gif" alt="" />
              </Accordion>
              <Accordion title="From LinkedIn">
                <img src="https://storage.googleapis.com/student-app-assets/add-company-linkedin.gif" alt="" />
              </Accordion>
            </div>
            <div className="support-page__tab" data-tab="Save contact">
              <h3 className="support-page__tab__title">Building your network</h3>
              <p className="support-page__tab__description">
                From LinkedIn
              </p>
              <img src="https://storage.googleapis.com/student-app-assets/adding-contact.gif" alt="" />
            </div>
            <div className="support-page__tab" data-tab="Save a job">
              <h3 className="support-page__tab__title">Searching for your dream role</h3>
              <Accordion title="From LinkedIn">
                <img src="https://storage.googleapis.com/student-app-assets/add-job-linkedin.gif" alt="" />
              </Accordion>
              <Accordion title="From other websites">
                <img src="https://storage.googleapis.com/student-app-assets/manually-adding-job.gif" alt="" />
              </Accordion>
            </div>
          </TabsContent>
        </div>
      </div>
    </div>
  );
};

export default CustomWithAuthenticationRequired(withTabs(HowToPage));
