import './company-card-skeleton.scss';

export const CompanyCardSkeleton = () => (
  <div className="company-card">
    <div className="company-card__top">
      <div className="company-card__image">
        <div className="company-card-skeleton__image" />
      </div>
      <div
        className="company-card__industry"
      >
        <div className="company-card-skeleton__text company-card-skeleton__text--status" />
      </div>
      <div
        className="company-card__name"
      >
        <div className="company-card-skeleton__text company-card-skeleton__text--title" />
      </div>

      <div data-testid="company-card-location" className="company-card__info">
        <div className="company-card-skeleton__text company-card-skeleton__text--status" />
        <div className="company-card__column">
          <div className="company-card-skeleton__text company-card-skeleton__text--status" />
        </div>
      </div>
    </div>
    <div className="company-card__description-container">
      <div className="company-card-skeleton__text company-card-skeleton__text--body" />
    </div>
  </div>
);
