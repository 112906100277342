import { ContactInfoTile, ContactSummary } from '@/domains/core/contact';
import { ConnectionStatus } from '@/domains/core/contact/types';
import { InboxContextType, useInboxContext } from '@/pages/context/inbox';

import './contact-bio-sidebar.scss';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { ContactNotes } from '../contact-notes/contact-notes';

type Props = {
  connectionStatus: ConnectionStatus;
  networkingStatus: ConnectionStatus;
  onEmailChanged: () => void
};

const ContactBioSidebar = ({
  connectionStatus,
  networkingStatus,
  onEmailChanged,
}: Props) => {
  const { currentContact: contact } = useInboxContext() as InboxContextType;

  return (
    <div className="contact-sidebar">
      <ContactInfoTile
        networkingStatus={networkingStatus}
        connectionStatus={connectionStatus}
        onEmailChanged={onEmailChanged}
        contact={contact}
      />
      <TabsHeader containerName="inbox-contact" tabs={['Profile', 'Notes']} defaultTab="Profile" size="medium" />

      <div className="contact-sidebar__content">
        <TabsContent>
          <div className="contact-sidebar__tab" data-tab="Profile">
            <ContactSummary careerSummary={contact.career_summary} />
          </div>
          <div className="contact-sidebar__tab" data-tab="Notes">
            <ContactNotes />
          </div>
        </TabsContent>
      </div>
    </div>
  );
};

export default withTabs(ContactBioSidebar);
