import { useRevalidator } from 'react-router-dom';
import { useContext } from 'react';
import { Note } from '../../types';
import './tracker-notes.scss';
import { addCompanyNote, deleteCompanyNote, editCompanyNote } from '@/services/api/company';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import NoteCard from '@/components/note-card/note-card';
import { NoteInput } from '@/components/note-input/note-input';

interface NotesProps {
  notes: Note[];
  setNotes: (notes: Note[]) => void;
  companyId: string;
}

export default function TrackerNotes({ notes, companyId, setNotes }: NotesProps) {
  const { addToast }: AddToastType = useContext(ToastContext);
  const revalidator = useRevalidator();
  const { trackEvent } = useAnalytics();

  const handleAddNote = async (title: string, content: string, successCallback: () => void) => {
    if (!title.trim().length || !content.trim().length) {
      return;
    }

    try {
      const noteResponse = await addCompanyNote(companyId, title, content);

      if (noteResponse.error) {
        throw new Error('Failed to add note');
      }

      revalidator.revalidate();
      setNotes([noteResponse, ...notes]);
      trackEvent('New note added', {});
      successCallback();
      addToast(
        {
          type: 'success',
          message: 'Note added successfully',
          additionalMessage: "This note is now saved to your 'Notes' section.",
        },
      );
    } catch (error) {
      addToast(
        {
          type: 'error',
          message: 'Failed to add note',
          additionalMessage: 'Please try again later',
        },
      );
      trackEvent('Toast Error Shown', {}, {
        message: 'Failed to add note',
        error,
      });
    }
  };

  const handleEditNote = async (id: string, title: string, content: string, successCallback: () => void) => {
    try {
      const updatedNote = await editCompanyNote(companyId, id, title, content);

      const updatedNotes = notes.map((note) => (note.id === id ? updatedNote : note));
      setNotes(updatedNotes);
      trackEvent('Note Edited', {});
      successCallback();
      addToast({
        type: 'success',
        message: 'Note edited successfully',
        additionalMessage: 'This note is now saved to your "Notes" section.',
      });
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Failed to save note',
        additionalMessage: 'Please try again later',
      });
      trackEvent('Toast Error Shown', {}, {
        message: 'Failed to edit note',
        error,
      });
    }
  };

  const handleDeleteNote = async (id: string) => {
    try {
      await deleteCompanyNote(companyId, id);
      revalidator.revalidate();
      trackEvent('Notes Deleted', {});

      const updatedNotes = notes.filter((note) => note.id !== id);
      setNotes(updatedNotes);
      addToast({
        type: 'success',
        message: 'Note deleted successfully',
        additionalMessage: "This note is now deleted from your 'Notes' section.",
      });
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Failed to delete note',
        additionalMessage: 'Please try again later',
      });
      trackEvent('Toast Error Shown', {}, {
        message: 'Failed to delete note',
        error,
      });
    }
  };

  const handleSaveSilently = async (title: string, content: string) => {
    if (!title.trim().length || !content.trim().length) {
      return;
    }

    await addCompanyNote(companyId, title, content);
    revalidator.revalidate();
  };

  return (
    <div className="notes">
      <NoteInput
        handleSave={handleAddNote}
        saveSilently={handleSaveSilently}
        parentID={companyId}
      />
      {notes.map((note: any) => (
        <NoteCard
          key={note.id}
          parentID={companyId}
          note={note}
          handleEdit={handleEditNote}
          handleDelete={handleDeleteNote}
        />
      ))}
    </div>
  );
}
