import './contact-box-skeleton.scss';

const ContactBoxSkeleton = () => (
  <div className="contact-box">
    <div className="contact-box__image">
      <div className="contact-box-skeleton__icon" />
    </div>
    <div className="contact-box__info">
      <div className="contact-box__top-info">
        <div className="contact-box__name">
          <div className="contact-box-skeleton__text contact-box-skeleton__text--name" />
        </div>
        <div className="contact-box__date" />
      </div>
      <div className="contact-box__company">
        <div className="contact-box-skeleton__text contact-box-skeleton__text--status" />
      </div>
      <div className="contact-box__text">
        <div className="contact-box-skeleton__text contact-box-skeleton__text--message" />
      </div>
    </div>
  </div>
);

export default ContactBoxSkeleton;
