import { useContext } from 'react';
import { useExtensionMessaging } from './use-extension-messaging';
import { ModalContext } from '@/components/modal/modal-provider';
import { InfoModal } from '@/domains/generic/modals';

export const useCheckExtension = () => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { checkLinkedInLogin } = useExtensionMessaging();

  const handleDownloadExtensionClick = () => {
    closeModal();
    window.open(import.meta.env.VITE_EXTENSION_LINK, '_blank');
  };

  const checkUserExtension = async () => {
    const linkedInLoginStatus = await checkLinkedInLogin();

    if (!linkedInLoginStatus) {
      openModal(
        <InfoModal
          icon="bi bi-puzzle"
          title="Looks like you haven’t downloaded our Chrome Extension"
          description="You’ll have the power to save jobs, contacts and new companies from LinkedIn and other job boards to your Overview Board."
          handleButtonClick={handleDownloadExtensionClick}
          buttonLabel="Download Extension"
          orientation="horizontal"
        />,
      );
      return false;
    }

    return true;
  };

  return {
    checkUserExtension,
  };
};
