import './message-history.scss';

export const EmailsSkeleton = () => (
  <div data-testid="message-history-skeletone" className="grey-container">
    <div className="message-history__message message">
      <div className="message__info">
        <div className="message__info__title skeleton" />

        <div className="skeleton__text skeleton__text--title" />
        <span className="message__info__date">
          <div className="skeleton__text skeleton__text--date" />
        </span>
        <div className="message__info__status">
          <div className="skeleton__text skeleton__text--status" />
        </div>
        <div className="message__info__text">
          <div className="skeleton__text skeleton__text--body" />
        </div>
      </div>
    </div>
  </div>
);
