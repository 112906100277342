import { useState, useEffect } from 'react';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { Comment } from '@/domains/core/company/types';
import '../sidebar.scss';
import './comments-sidebar.scss';
import Comments from '@/assets/images/comments.svg';
import CheckIcon from '@/assets/images/icons/checkmark.svg';

interface AllCommentSidebarProps {
  comments: Comment[]
}

const AllCommentsSidebar = ({
  comments,
}: AllCommentSidebarProps) => {
  const [isEmpty, setIsEmpty] = useState(false);
  const unresolved = `Unresolved (${comments?.filter((comment: Comment) => !comment.resolved).length})`;
  const tabs = comments?.filter((comment: Comment) => !comment.resolved).length > 0 ? [unresolved, 'View all'] : ['View all', unresolved];
  const defaultTab = comments?.filter((comment: Comment) => !comment.resolved).length > 0 ? unresolved : 'View all';
  useEffect(() => {
    setIsEmpty(!comments.length);
  }, [comments]);

  return (
    <div className="industry-sidebar">
      <h2 className="industry-sidebar__title">
        Full Comments History
      </h2>
      <p className="industry-sidebar__subtitle">
        {isEmpty ? 'All comments left by Career Advisors.' : 'All comments for student’s entire Overview Board.'}
      </p>
      {isEmpty ? (
        <div className="industry-sidebar__comments">
          <img src={Comments} alt="" />
          <p>You currently don’t have any comments.</p>
        </div>
      ) : (
        <>
          <div className="industry-sidebar__tabs-header">
            <TabsHeader tabs={tabs} defaultTab={defaultTab} />
          </div>
          <div className="industry-sidebar__tabs-content">
            <TabsContent>
              <div data-tab="View all">
                <div className="comments">
                  {comments.map((comment: Comment) => (
                    <div key={comment.id} className="comments__item">
                      <div className="comments__item-header">
                        <div className="comments__item-header-sender">
                          {comment.company_name}
                        </div>
                        <div className="comments__item-header-date">
                          {formatDateDeltaFromNow(comment.created_at)}
                        </div>
                        {comment.resolved && (
                        <div className="comments__item-header-esolved">
                          <img className="comments__item-header-resolved-img" src={CheckIcon} alt="" />
                          Resolved
                        </div>
                        )}
                      </div>
                      <div className="comments__item-content">
                        {comment.comment}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div data-tab={unresolved}>
                <div className="comments">
                  {comments.filter((comment: Comment) => !comment.resolved).map((comment: Comment) => (
                    <div key={comment.id} className="comments__item">
                      <div className="comments__item-header">
                        <div className="comments__item-header-sender">
                          {comment.company_name}
                        </div>
                        <div className="comments__item-header-date">
                          {formatDateDeltaFromNow(comment.created_at)}
                        </div>
                        {comment.resolved && (<div className="comments__item-header-resolved">Resolved</div>)}
                      </div>
                      <div className="comments__item-content">
                        {comment.comment}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </TabsContent>
          </div>
        </>
      )}
    </div>
  );
};

export default withTabs(AllCommentsSidebar);
