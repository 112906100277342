import {
  useEffect, useState,
} from 'react';
import './topbar.scss';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { BreadCrumbs, Crumb } from '@/components/breadcrumbs/breadcrumbs';
import { SkipToMainContent } from '@/domains/support/skip/skip';
import { getIndustryByCategoryName, getSingleCompany } from '@/services/api/company';
import { getContact } from '@/services/api/contact';
import { Contact } from '@/domains/core/contact/types';
import { TopbarDumb } from './topbar-dumb';
import { useSelf } from '@/services/queries/user';
import { Company } from '@/domains/core/company/types';

type TopBarProps = {
  collapsed: boolean;
};

export default function TopBar({ collapsed }: TopBarProps) {
  const { user } = useAuth0();
  const { data: self, isLoading: isSelfLoading } = useSelf();
  const [onboarding, setOnboarding] = useState(false);
  const [isResume, setIsResume] = useState(false);
  const location = useLocation();
  const [crumbs, setCrumbs] = useState<Crumb[]>([]);
  const companyPagePathRegex = /app\/companies\/company\/([a-f0-9-]+)/;
  const contactCompanyPathRegex = /app\/contacts\/([a-f0-9-]+)\/company\/([a-f0-9-]+)/;
  const industryPathRegex = /app\/companies\/explore\/([^/]+)/;
  const tagPathRegex = /app\/companies\/filter\/tag\/by\/([^/]+)/;
  const categoryPathRegex = /app\/companies\/filter\/category\/by\/([^/]+)/;

  // TODO: move to a separate file all the functions that are not related to the breabcrumbs
  const setCompanyPageCrumbs = async (companyId?: string) => {
    try {
      const company: Company = await getSingleCompany(companyId);
      const industry = company.careeros_industry;
      setCrumbs(
        [
          { name: 'Explore Companies', link: '/app/companies/explore' },
          { name: industry, link: `/app/companies/explore/${encodeURIComponent(industry)}` },
          { name: company.name, link: `/app/companies/company/${companyId}` },
        ],
      );
    } catch (error) {
      setCrumbs(
        [
          { name: 'Explore Companies', link: '/app/companies/explore' },
          { name: 'Industry', link: '/app/companies/explore' },
          { name: 'Company', link: `/app/companies/company/${companyId}` },
        ],
      );
    }
  };

  const setContactPageCrumbs = async (companyId?: string, contactId?: string) => {
    try {
      if (!companyId || !contactId) {
        throw new Error('Invalid company or contact id');
      }

      const contact: Contact = await getContact(contactId);

      setCrumbs(
        [
          { name: 'Contacts', link: '/app/contacts' },
          { name: contact.current_position.company, link: companyId, isCompany: true },
          { name: `${contact.first_name} ${contact.last_name}`, link: location.pathname },
        ],
      );
    } catch (error) {
      setCrumbs(
        [
          { name: 'Contacts', link: '/app/contacts' },
          { name: 'Company', link: '' },
          { name: 'Contact', link: location.pathname },
        ],
      );
    }
  };

  const setTagPageCrumbs = (tag?: string) => {
    setCrumbs(
      [
        { name: 'Explore Companies', link: '/app/companies/explore' },
        { name: decodeURIComponent(tag || 'Tag'), link: location.pathname },
      ],
    );
  };

  const setCategoryPageCrumbs = async (category?: string) => {
    try {
      const industryCategory = category ? await getIndustryByCategoryName(category) : '';
      if (industryCategory) {
        setCrumbs(
          [
            { name: 'Explore Companies', link: '/app/companies/explore' },
            { name: industryCategory.name, link: `/app/companies/explore/${encodeURIComponent(industryCategory.name)}` },
            { name: decodeURIComponent(category || 'Category'), link: location.pathname },
          ],
        );
        return;
      }
      setCrumbs(
        [
          { name: 'Explore Companies', link: '/app/companies/explore' },
          { name: decodeURIComponent(category || 'Category'), link: location.pathname },
        ],
      );
    } catch (error) {
      setCrumbs(
        [
          { name: 'Explore Companies', link: '/app/companies/explore' },
          { name: decodeURIComponent(category || 'Category'), link: location.pathname },
        ],
      );
    }
  };

  const updateCrumbs = () => {
    const path = location.pathname;

    if (companyPagePathRegex.test(path)) {
      const companyId = path.match(companyPagePathRegex)?.[1];
      setCompanyPageCrumbs(companyId);
    }

    if (contactCompanyPathRegex.test(path)) {
      const companyId = path.match(contactCompanyPathRegex)?.[2];
      const contactId = path.match(contactCompanyPathRegex)?.[1];
      setContactPageCrumbs(companyId, contactId);

      return;
    }

    if (industryPathRegex.test(path)) {
      const industry = path.match(industryPathRegex)?.[1];
      setCrumbs(
        [
          { name: 'Explore Companies', link: '/app/companies/explore' },
          { name: decodeURIComponent(industry || 'Industry'), link: path },
        ],
      );
      return;
    }

    if (tagPathRegex.test(path)) {
      const tag = path.match(tagPathRegex)?.[1];
      setTagPageCrumbs(tag);

      return;
    }

    if (categoryPathRegex.test(path)) {
      const category = path.match(categoryPathRegex)?.[1];
      setCategoryPageCrumbs(category);
      return;
    }

    if (path.includes('/app/dashboard')) {
      setCrumbs(
        [
          { name: 'Home', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/dashboard-advisor')) {
      setCrumbs(
        [
          { name: 'Dashboard', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/companies/explore') || path.includes('/app/search')) {
      setCrumbs(
        [
          { name: 'Explore Companies', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/jobs')) {
      setCrumbs(
        [
          { name: 'Jobs', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/overview')) {
      setCrumbs(
        [
          { name: 'Overview Board', link: path },
        ],
      );
      return;
    }
    if (path.includes('app/resume-os')) {
      setCrumbs(
        [
          { name: 'ResumeOS', link: '/app/resume-os' },
        ],
      );
      return;
    }
    if (path.includes('app/tour')) {
      setCrumbs(
        [
          { name: 'Tour', link: path },
        ],
      );
      return;
    }
    if (path.includes('app/inbox')) {
      setCrumbs(
        [
          { name: 'Inbox', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/favorites')) {
      setCrumbs(
        [
          { name: 'Saved Companies', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/student-overview')) {
      setCrumbs(
        [
          { name: 'Student Overview Board', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/company-overview')) {
      setCrumbs(
        [
          { name: 'Student Activity', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/profile') || path.includes('/app/documents')) {
      setCrumbs(
        [
          { name: 'My Account', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/students')) {
      setCrumbs(
        [
          { name: 'Student Table', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/support')) {
      setCrumbs(
        [
          { name: 'Support', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/archive')) {
      setCrumbs(
        [
          { name: 'Archive', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/contacts')) {
      setCrumbs(
        [
          { name: 'Contacts', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/companies')) {
      setCrumbs(
        [
          { name: 'Explore Companies', link: '/app/companies/explore' },
          { name: 'Advanced Search', link: '/app/companies' },
        ],
      );
      return;
    }

    if (path.includes('/app/cohort-assignment')) {
      setCrumbs(
        [
          { name: 'Welcome to CareerOS!', link: path },
        ],
      );
      return;
    }

    // If the path is not recognized, set the default CarrerOS breadcrumb (for the storybook)
    if (!location.hash.includes('thecareeros')) {
      setCrumbs(
        [
          { name: 'CareerOS', link: path },
        ],
      );
    }
  };

  useEffect(() => {
    updateCrumbs();
  }, [location]);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('/app/onboarding') || path.includes('/app/cohort-assignment')) {
      setOnboarding(true);
    } else {
      setOnboarding(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes('/app/resume-os')) {
      setIsResume(true);
    } else {
      setIsResume(false);
    }
  }, [location.pathname]);

  if (isSelfLoading) return null;

  return (
    <div className={collapsed ? 'topbar-wrapper topbar-wrapper--collapsed' : 'topbar-wrapper'}>
      <TopbarDumb
        title={(
          <>
            <BreadCrumbs crumbs={crumbs} />
            <SkipToMainContent />
          </>
        )}
        withSearch={!onboarding && !isResume}
        withProfile={!onboarding}
        profileImage={self?.profile_picture_url || user?.picture}
        userName={user?.name}
      />
    </div>
  );
}
