import { Button } from '@/components/button/Button';
import './shortcuts.scss';
import { AddJobButton } from '../add-job-button/add-job-button';
import { useTabs } from '@/components/tabs/TabsContext';
import { useAnalytics } from '@/services/hooks/use-analytics';

type Props = {
  handleArchiveClicked: () => void;
  companyLinkedIn: string;
  changeTab: (tab: string, state?: string, setTabAsCurrent?: boolean) => void;
};

export const Shortcuts = ({ handleArchiveClicked, companyLinkedIn, changeTab }: Props) => {
  const { setCurrentTab } = useTabs();
  const { trackEvent } = useAnalytics();

  const handleSaveContactsClick = () => {
    setCurrentTab('Contacts');
    trackEvent('Shortcut: Save Contact clicked');
  };

  const handleAddJobClick = (tab: string, state?: string, setTabAsCurrent?: boolean) => {
    changeTab(tab, state, setTabAsCurrent);
    trackEvent('Shortcut: Save Job clicked');
  };

  const handleAddNoteClick = () => {
    setCurrentTab('Notes');
    trackEvent('Shortcut: Add Note clicked');
  };

  const handleInterviewPrepClick = () => {
    changeTab('AI Tools', 'Interview Preparation', true);
    trackEvent('Shortcut: Interview Prep clicked');
  };

  const handleArchiveClick = () => {
    handleArchiveClicked();
    trackEvent('Shortcut: Archive Company clicked');
  };

  return (
    <div className="tracker-shortcuts">
      <h3 className="tracker-shortcuts__title">
        Shortcuts
      </h3>
      <Button
        handleClick={handleSaveContactsClick}
        icon="bi bi-person"
        label="Save Contact"
        mode="invisible"
        size="large"
      />
      <AddJobButton
        mode="invisible"
        size="large"
        companyLinkedIn={companyLinkedIn}
        changeTab={handleAddJobClick}
        withSaveManually
      />
      <Button
        handleClick={handleAddNoteClick}
        icon="bi bi-file-earmark-text"
        label="Add Note"
        mode="invisible"
        size="large"
      />
      <Button
        handleClick={handleInterviewPrepClick}
        icon="bi bi-list-check"
        label="Interview Prep"
        mode="invisible"
        size="large"
      />
      <Button
        handleClick={handleArchiveClick}
        icon="bi bi-archive"
        label="Archive Company"
        mode="invisible"
        size="large"
      />
    </div>
  );
};
