/* eslint-disable no-restricted-syntax */
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import * as Sentry from '@sentry/react';
import { useLoaderData } from 'react-router';
import { Loader } from '@/components/loader/loader';
import VMockLogo from '@/assets/images/vmock-logo.png';
import HandShakeLogo from '@/assets/images/handshake.svg';
import GeorgetownLogo from '@/assets/images/hs-school-logo-data.png';
import './styles/dashboard.scss';
import ESADELogo from '@/assets/images/university/esade-logo.jpeg';

import StandfordLogo from '@/assets/images/university/Standford_GSB.png';
import { ModalContext } from '@/components/modal/modal-provider';
import { MessageTemplates } from '@/domains/generic/modals';

import { updateContactConnectionStatus } from '@/services/api/contact';
import { Conversations } from '@/domains/core/contact';
import {
  ResourceTile,
  WeeklyGoals,
  ChallengesWidget,
  LeaderBoard,
  Quests,
  MobileDashboard,
} from '@/domains/core/student';

import { useSelf, useSelfUniversity } from '@/services/queries/user';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import { EmptyConversation } from '@/domains/core/contact/components/recent-messages/recent-messages';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { Contact, UserConnectionsResponse } from '@/domains/core/contact/types';
import { updateSelfProfileImage } from '@/services/api/user';
import { batchTasks } from '@/services/helpers/batch-tasks';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { DashboardResponse } from '@/domains/core/student/types';

const ESADE_MASTER_PROGRAMS = ['MSM', 'MiM', 'MMkt', 'CEMS', 'MiBA', 'MFin', 'MIE'];
const RATE_LIMIT = 24;

function DashboardPage() {
  const {
    conversationIDs, statistic, pendingContacts, leaderboard, contacts,
  } = useLoaderData() as DashboardResponse;
  const { data: university, isLoading: isUniversityLoading } = useSelfUniversity();
  const { data: self, isLoading: isSelfLoading } = useSelf();
  const { hash } = window.location;
  const { trackEvent } = useAnalytics();
  const [isESADEStudent, setIsESADEStudent] = useState(false);
  const [isGeorgeTownStudent, setIsGeorgeTownStudent] = useState(false);
  const [isQuestsHighlighted, setIsQuestsHighlighted] = useState(false);
  const [isStanfordStudent, setIsStanfordStudent] = useState(false);
  const [showChallenges, setShowChallenges] = useState(false);
  const [userConnections, setUserConnections] = useState<UserConnectionsResponse>();
  const {
    getUserLinkedInConnections, updateAllLinkedInConversations, checkLinkedInLogin,
    getUserProfilePicture,
  } = useExtensionMessaging();
  const { openModal } = useContext(ModalContext) as any;
  const questsBoxRef = useRef<HTMLDivElement>(null);

  const openTemplatesModal = () => {
    openModal(
      <MessageTemplates
        templates={[]}
      />,
    );
  };

  const checkIfGeorgetown = () => {
    if (university?.name === 'Georgetown University') {
      setIsGeorgeTownStudent(true);
    }
  };

  const checkIfStanford = () => {
    if (university?.name === 'Stanford Graduate School of Business') {
      setIsStanfordStudent(true);
    }
  };

  const checkIfESADEStudent = async () => {
    if (university) {
      setIsESADEStudent(university.name === 'ESADE');
    }
  };

  const statusUpdateTask = async (contact: Contact) => {
    await updateContactConnectionStatus(contact, 'connected');
  };

  const checkStatusUpdates = async (contactsData: Contact[]) => {
    const userConnectionsResponse: UserConnectionsResponse | undefined = await getUserLinkedInConnections();
    setUserConnections(userConnectionsResponse);

    if (!userConnectionsResponse) {
      return;
    }

    const connectedContacts = userConnectionsResponse.connected || [];
    const onlyConnected = contactsData.filter((contact: Contact) => connectedContacts.find((connection) => connection.url === contact.linkedin_url));
    if (onlyConnected.length) {
      const batches = batchTasks(onlyConnected, RATE_LIMIT, statusUpdateTask);

      for await (const batch of batches) {
        await Promise.all(batch.map(task => task()));
      }
    }
  };

  const cheeckIfWeShowChallenges = () => {
    if (statistic?.challenges?.saved_companies > 6 || statistic?.challenges?.saved_contacts > 10 || statistic?.challenges?.sent_linkedin_invitations > 10) {
      setShowChallenges(true);
    }
  };

  const highlightQuestsBox = () => {
    if (!questsBoxRef.current) {
      return;
    }

    const questsScrollTop = (questsBoxRef.current?.getBoundingClientRect().top || 0) + window.scrollY - 20;

    window.scrollTo({ top: questsScrollTop, behavior: 'smooth' });
    setIsQuestsHighlighted(true);

    setTimeout(() => setIsQuestsHighlighted(false), 3000);
  };

  const checkForProfilePicture = async () => {
    if (self && !self.profile_picture_url) {
      try {
        const profileImage = await getUserProfilePicture();

        if (!profileImage) {
          // TODO: proper error handling from requests to extension messaging - reason why we don't have profile image
          // would be useful to report to Sentry

          return;
        }

        await updateSelfProfileImage(profileImage);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  useEffect(() => {
    if (hash === '#messagingTemplates') {
      openTemplatesModal();
    }
  }, [hash]);

  useEffect(() => {
    if (isSelfLoading) return;
    checkForProfilePicture();
  }, [isSelfLoading]);

  useEffect(() => {
    if (conversationIDs?.length) {
      checkLinkedInLogin()
        .then((isLogged) => {
          if (isLogged) {
            updateAllLinkedInConversations();
          }
        })
        .catch(() => console.error('Error updating LinkedIn conversations'));
    }
  }, [conversationIDs?.length]);

  useEffect(() => {
    if (isUniversityLoading) return;
    checkIfESADEStudent();
    checkIfGeorgetown();
    checkIfStanford();
    cheeckIfWeShowChallenges();
  }, [isUniversityLoading]);

  useEffect(() => {
    if (pendingContacts.length) {
      checkStatusUpdates(pendingContacts);
    }
  }, [pendingContacts]);

  useEffect(() => {
    trackEvent('Page Visit', {}, {
      pageURL: 'Dashboard',
    });
  }, []);

  return (
    <>
      <div className="student-dashboard--mobile">
        <MobileDashboard />
      </div>
      <div className="student-dashboard">
        <div className="career-os-grid">
          <div ref={questsBoxRef} className="width-12/24">
            <div className="student-dashboard__block">
              <div id="main" className={`student-dashboard__box student-dashboard__box--no-padding ${isQuestsHighlighted ? 'student-dashboard__box--highlighted' : ''}`}>
                <Quests
                  userConnections={userConnections}
                />
              </div>
              {conversationIDs?.length ? (
                <div className="student-dashboard__box student-dashboard__box--no-padding">
                  <Conversations contacts={contacts} />
                </div>
              ) : (
                <div className="student-dashboard__box student-dashboard__box--no-padding student-dashboard__box--remaining-space">
                  <EmptyConversation />
                </div>
              )}
            </div>
          </div>
          <div className="width-12/24">
            {isGeorgeTownStudent && (
              <div className="width-24/24">
                <div className="student-dashboard__box">
                  <ResourceTile
                    name="SFSCC Course on Canvas"
                    description="Career resources covering a variety of topics, including resumes, interviewing, and networking"
                    image={GeorgetownLogo}
                    linkLabel="Visit now"
                    link="https://georgetown.instructure.com/courses/60142"
                  />
                  <ResourceTile
                    name="Handshake"
                    description="Launch the next step in your career."
                    image={HandShakeLogo}
                    linkLabel="Visit now"
                    link="https://georgetown.joinhandshake.com"
                  />
                </div>
              </div>
            )}
            {isStanfordStudent && (
              <div className="width-24/24">
                <div className="student-dashboard__box">
                  <ResourceTile
                    name="Stanford | goCanvas"
                    description="Over 2,400 instructors and 19,000 students use Canvas in 2,000 courses every quarter across Stanford’s seven schools,"
                    image={StandfordLogo}
                    linkLabel="Visit now"
                    link="https://gocanvas.stanford.edu"
                  />
                </div>
              </div>
            )}
            {(isESADEStudent) && (
              <div className="width-24/24">
                <div className="student-dashboard__box">
                  {university.cohort && ESADE_MASTER_PROGRAMS.includes(university.cohort) ? (
                    <>
                      <ResourceTile
                        name="Build a LinkedIn Profile that is a Recruiter Magnet"
                        description="Master the most advanced Job Search techniques through this LinkedIn Masterclass"
                        image={ESADELogo}
                        linkLabel="Visit now"
                        link="https://bit.ly/liesade"
                      />
                      <ResourceTile
                        name="Optimize the content on your LinkedIn Profile"
                        description="Ensuring alignment with your target career roles & your CV"
                        image={VMockLogo}
                        linkLabel="Visit now"
                        link="https://www.vmock.com/esade"
                      />
                    </>
                  )
                    : (
                      <ResourceTile
                        name="MBA Career Hub"
                        description="Visit this channel for Internal Content & Communication"
                        image={ESADELogo}
                        linkLabel="Visit now"
                        link="https://urledu.sharepoint.com/sites/EsadeMBACareers"
                      />
                    )}
                </div>
              </div>
            )}
            <div className="student-dashboard__box">
              <WeeklyGoals goals={statistic.weekly_goals} />
            </div>
            {showChallenges && (
              <div className="student-dashboard__box">
                <ChallengesWidget progress={statistic.challenges} />
              </div>
            )}
            {(leaderboard.students?.length > 0) && (
              <div className="student-dashboard__box">
                <LeaderBoard highlightQuests={highlightQuestsBox} students={leaderboard.students} cohort={leaderboard.cohort_name} universityName={leaderboard.university_name} />
              </div>
            )}
          </div>

        </div>
      </div>
    </>
  );
}

export default CustomWithAuthenticationRequired(DashboardPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
