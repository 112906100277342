import { useContext, useRef } from 'react';
import { Button } from '@/components/button/Button';
import './message-input-buttons.scss';
import { RegenerateAIButton, UseAIButton } from '@/domains/core/student';
import { ModalContext } from '@/components/modal/modal-provider';
import { InfoModal } from '@/domains/generic/modals';
import { GenerateMessageOptions } from './options';
import { TemplateRequestPayload } from '@/domains/core/netwoking/types';

type Props = {
  primaryButtonLabel: string;
  primaryButtonIcon: string;
  contactID: string;
  characterLimit?: number;
  handleRegenerateClick: any;
  handleSendMessageClick: () => void;
  messageType: string;
  isPrimaryButtonDisabled: boolean;
  regenerateWithOptions?: boolean;
  withHint?: boolean;
  withAIButton?: boolean;
  regenerateData: TemplateRequestPayload;
};

export const MessageInputButtons = ({
  primaryButtonIcon,
  primaryButtonLabel,
  isPrimaryButtonDisabled,
  contactID,
  characterLimit,
  handleRegenerateClick,
  handleSendMessageClick,
  messageType,
  regenerateWithOptions = false,
  withHint = true,
  withAIButton = true,
  regenerateData,
}: Props) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const regenerateRef = useRef<any>(null);
  const handleWhySendMessageClick = () => {
    openModal(
      <InfoModal
        orientation="vertical"
        icon="bi bi-envelope"
        title="Networking on Easy Mode!"
        buttonLabel="Got it!"
        handleButtonClick={closeModal}
        description="80% of all jobs are filled through professional networking and candidates with referrals have much higher chances of advancing to the interview stage. Start networking with this contact to gain unique insights and maximize your chances of getting in."
      />,
    );
  };

  return (
    <div className="message-input-buttons">
      {withHint && (
        <Button
          label="Why should I send this message?"
          icon="bi bi-question-circle"
          onClick={handleWhySendMessageClick}
          mode="text"
          iconPosition="right"
          size="large"
          isHint
        />
      )}

      <div className="message-input-buttons__actions">
        {regenerateWithOptions && (
          <RegenerateAIButton
            handleResult={handleRegenerateClick}
            payload={regenerateData}
            buttonRef={regenerateRef}
          />
        )}
        {withAIButton && (
          <UseAIButton
            options={GenerateMessageOptions}
            type={messageType}
            handleResult={handleRegenerateClick}
            metaData={{
              contactID,
              characterLimit,
            }}
          />
        )}
        <Button
          label={primaryButtonLabel}
          icon={`bi bi-${primaryButtonIcon}`}
          onClick={handleSendMessageClick}
          mode="primary"
          size="medium"
          disabled={isPrimaryButtonDisabled}
        />
      </div>
    </div>
  );
};
