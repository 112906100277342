import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useScroll } from '@/services/hooks/use-scroll';
import { Button } from '@/components/button/Button';
import { CompanyCard } from '../../components/company-card/company-card';
import { Company } from '../../types';
import './browse-company.scss';
import { IconButton } from '@/components/icon-button/icon-button';

interface BrowseCompaniesProps {
  relatedCompanies: Company[];
  tag: string;
}

const COMPANY_TILE_SIZE = 444;
const COMPANIES_COLUMN_GAP = 24;
const COMPANIES_IN_VIEW = 4;

export const BrowseCompanies = ({ relatedCompanies, tag }: BrowseCompaniesProps) => {
  const [companiesContainer, handleCompaniesScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(true);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);
  const navigate = useNavigate();

  const calculateScrollLimit = (childCount: number) => {
    const scrollLimit = (childCount - COMPANIES_IN_VIEW) * (COMPANY_TILE_SIZE + COMPANIES_COLUMN_GAP);
    return scrollLimit;
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;

    const scrollLimit = calculateScrollLimit(relatedCompanies.length);

    setShowRightScrollArrow(!(el.scrollLeft > scrollLimit));
    setShowLeftScrollArrow(el.scrollLeft !== 0);
  };

  const handleButtonClick = () => {
    navigate(`/app/companies/filter/tag/by/${tag}`);
  };

  return (
    <div className="company-browse">
      <div className="company-browse__top">
        <h3 className="company-browse__title">Browse Related Companies</h3>
        <div className="company-browse__controls">
          <Button handleClick={handleButtonClick} mode="rounded" outlined size="small" label="View all" />
          <IconButton
            icon="bi bi-chevron-left"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => handleCompaniesScroll('left')}
            disabled={!showLeftScrollArrow}
          />
          <IconButton
            icon="bi bi-chevron-right"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => handleCompaniesScroll('right')}
            disabled={!showRightScrollArrow}
          />
        </div>
      </div>
      <div className="company-browse__companies" ref={companiesContainer} onScroll={handleScroll}>
        {relatedCompanies.map(relatedCompany => (
          <CompanyCard
            key={relatedCompany.id}
            id={relatedCompany.id}
            companyName={relatedCompany.name}
            companyIndustry={relatedCompany.careeros_industry}
            officeLocations={relatedCompany.locations || []}
            companySize={relatedCompany.company_size}
            companyDescription={relatedCompany.short_description ? relatedCompany.short_description : relatedCompany.overview}
            imageURL={relatedCompany.logo_url}
            tags={relatedCompany.tags}
            companyApplicationId={relatedCompany.application_id || ''}
          />
        ))}
      </div>
    </div>
  );
};
