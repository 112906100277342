import { useEffect, useState } from 'react';
import { Outlet, useNavigation, useLocation } from 'react-router-dom';
import Sidebar from './layout/sidebar/sidebar';
import TopBar from './layout/topbar/topbar';
import { Loader } from '@/components/loader/loader';
import { ModalProvider } from '@/components/modal/modal-provider';
import { SidebarProvider } from '@/components/sidebar/sidebar-provider';
import { ToastProvider } from '@/components/toast/toast-provider';
import WebSocketHandler from '@/pages/websocket';
import { TrackerProvider } from '@/domains/core/company';
// import { ChatBot } from '@/domains/core/student';

export default function Root() {
  const location = useLocation();
  const { state } = useNavigation();
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);

  const handleSidebarChange = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.pathname.includes('/app/onboarding') || location.pathname.includes('/app/cohort-assignment')) {
      setIsOnboarding(true);
    } else {
      setIsOnboarding(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (state === 'loading') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [state]);

  return (
    <div className={`layout ${sidebarCollapsed ? 'layout--collapsed' : ''} ${isOnboarding ? 'layout--no-sidebar' : ''}`}>
      <SidebarProvider>
        <ToastProvider>
          <ModalProvider>
            <TrackerProvider>
              {!isOnboarding && <Sidebar collapsed={sidebarCollapsed} handleToggle={handleSidebarChange} />}
              <TopBar collapsed={sidebarCollapsed} />
              <div
                id="detail"
                className={sidebarCollapsed ? 'detail--collapsed' : ''}
              >
                {state === 'loading' && (
                  <div id="loader-zone">
                    <Loader />
                  </div>
                )}
                <Outlet />
              </div>
              <WebSocketHandler />
            </TrackerProvider>
          </ModalProvider>
        </ToastProvider>
      </SidebarProvider>
    </div>
  );
}
