import { Timestamp } from 'firebase/firestore';
import { useAuth0 } from '@auth0/auth0-react';
import { MessageStatus } from '../message-status/message-status';
import './message-header.scss';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { MessageType } from '@/domains/core/netwoking/types';
import { IconButton } from '@/components/icon-button/icon-button';
import { Button } from '@/components/button/Button';
import { Tooltip } from '@/components/tooltip/tooltip';
import { useSelf } from '@/services/queries/user';

type Props = {
  messageType: MessageType;
  isReceived?: boolean;
  isRepliable?: boolean;
  contactFirstName: string;
  contactImage: string;
  subtitle: string;
  title: string;
  messageCreatedAt: Timestamp | null;
  handleReplyClick: () => void;
  isReplyButtonExpanded?: boolean;
  isPreview?: boolean;
};

const formatDate = (date: Date) => {
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(date);

  const formattedDateWithAt = formattedDate.replace(/, (\d{1,2}:\d{2} [APM]{2})$/, ' at $1');
  return formattedDateWithAt;
};

export const MessageHeader = ({
  messageType,
  isReceived = false,
  isRepliable = false,
  contactFirstName,
  contactImage,
  subtitle,
  title,
  handleReplyClick,
  messageCreatedAt,
  isReplyButtonExpanded,
  isPreview = false,
}: Props) => {
  const { user } = useAuth0();
  const { data: self } = useSelf();
  const isEmailMessage = messageType === 'email';

  const handleIconClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    handleReplyClick();
  };

  return (
    <div className="message-header">
      <div className="message-header__left">
        <div className={`message-header__image message-header__image--${messageType}`}>
          {isEmailMessage ? (
            <DefaultLogo
              type="contact"
              source={isReceived ? contactImage : self?.profile_picture_url || user?.picture || ''}
              className=""
              name={contactFirstName}
              data-testid="message-icon"
              size={40}
            />
          ) : (
            <i data-testid="message-icon" className="message-header__icon bi bi-linkedin" aria-hidden="true" />
          )}
        </div>
        <div className="message-header__status">
          <MessageStatus
            isEmailMessage={isEmailMessage}
            isReceived={isReceived}
            contactFirstName={contactFirstName}
            subtitle={subtitle}
            sender={title}
          />
        </div>
      </div>
      {!isPreview && (
        <div className="message-header__right">
          <span data-testid="message-date" className="message-header__date">
            {messageCreatedAt ? formatDate(messageCreatedAt.toDate()) : 'Just now'}
          </span>
          {((isEmailMessage && isRepliable) || (!isEmailMessage && isReceived)) && (
            isReplyButtonExpanded ? (
              <Button
                data-testid="email-reply-button"
                icon="bi bi-reply"
                onClick={handleIconClick}
                label="Reply"
                mode="primary"
                outlined
                size="small"
              />
            ) : (
              <div className="message-header__button">
                <Tooltip label="Reply" position="top">
                  <IconButton
                    data-testid="email-reply-button"
                    icon="bi bi-reply"
                    onClick={handleIconClick}
                    mode="rounded"
                    size="small"
                    outlined
                  />
                </Tooltip>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};
