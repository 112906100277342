import CollapseLeftIcon from '@/assets/images/icons/arrow-export-left.svg';
import CollapseRightIcon from '@/assets/images/icons/arrow-export-right.svg';

import './collapse-button.scss';
import { Tooltip } from '@/components/tooltip/tooltip';

type Props = {
  handleCollapse: () => void;
  isCollapsed: boolean;
};

export const SidebarCollapse = ({ handleCollapse, isCollapsed }: Props) => {
  const icon = isCollapsed ? CollapseRightIcon : CollapseLeftIcon;
  return (
    <div
      role="button"
      onClick={handleCollapse}
      className="sidebar-collapse"
      aria-label="Collapse Sidebar"
      tabIndex={0}
    >
      {isCollapsed ? (
        <Tooltip label="Expand" position="right" disableHoverListener={!isCollapsed}>
          <div
            className="sidebar-collapse__container sidebar-collapse__container--collapsed"
          >
            <img
              src={icon}
              alt=""
              className="sidebar-collapse__icon"
            />
          </div>
        </Tooltip>
      ) : (
        <div
          className="sidebar-collapse__container"
        >
          <img
            src={icon}
            alt=""
            className="sidebar-collapse__icon"
          />
          <span className="sidebar-collapse__label">Collapse</span>
        </div>
      )}

    </div>
  );
};
