import { QuestType } from '@/domains/core/student/types';
import { OneLinerBanner } from '@/components/one-liner-banner/one-liner-banner';

type Props = {
  lastCompletedQuests: QuestType[];
  activeQuests: QuestType[];
  handleClose: () => void;
  contactName: string;
  companyName: string;
  noMessageHistory: boolean;
};

export const OneLinerExplanation = ({
  lastCompletedQuests,
  activeQuests,
  handleClose,
  companyName,
  contactName,
  noMessageHistory,
}: Props) => {
  const getText = () => {
    // for first Linkedin message (if already connected on Linkedin)
    if (noMessageHistory) {
      return 'It\'s time to reach out to your contact! Shoot them an Email, LinkedIn message or LinkedIn connection to start your conversation with them.';
    }
    if (activeQuests.includes(QuestType.SendFollowUpEmail)) {
      return 'It\'s time to follow up with your contact. Let’s send them another email to indicate your sincere interest in connecting with them.';
    }

    // after first response is received (either LinkedIn or Email)
    if (activeQuests.includes(QuestType.ScheduleCoffeeChat)) {
      return `Nice! You have received a response from ${contactName}. It's time to schedule a coffee chat with them to learn more about ${companyName}.`;
    }

    // Sequence ends
    if (lastCompletedQuests.includes(QuestType.ScheduleCoffeeChat)) {
      return `Sweet! You got a conversation started with ${contactName}. Feel free to keep networking with them to build a relationship and for useful insights.`;
    }

    return null;
  };

  if (!getText()) return null;

  return (
    <OneLinerBanner mode="info" text={getText() || ''} handleClose={handleClose} />
  );
};
