import { useLoaderData } from 'react-router-dom';

import { CompanyResponse } from '@/domains/core/company/types';
import { withTabs } from '@/components/tabs';
import { Loader } from '@/components/loader/loader';
import './styles/jobs.scss';
import { NormalCompany } from '@/domains/core/company/containers/company/company';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';

function CompanyPage() {
  const { company, contacts } = useLoaderData() as CompanyResponse;
  const goToLinkedinCompanyJobs = () => {
    window.open(`${company.linkedin_url}/jobs`, '_blank');
  };
  const renderedCompany = () => (<NormalCompany company={company} contacts={contacts} goToLinkedinCompanyJobs={goToLinkedinCompanyJobs} />);

  return (
    <div>
      {company.id ? (
        renderedCompany()
      ) : (
        <p>
          <i>No Companies</i>
        </p>
      )}
    </div>
  );
}

export default CustomWithAuthenticationRequired(withTabs(CompanyPage), {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
