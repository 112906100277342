import chromeIcon from '@/assets/images/chrome-icon.png';
import './job-no-extension.scss';
import { AddJobButton } from '@/domains/core/company/components/add-job-button/add-job-button';

type Props = {
  setupInterval: () => void;
};

export const JobNoExtension = ({ setupInterval }: Props) => (
  <div className="job-no-extension">
    <div className="job-no-extension__container">
      <img
        src={chromeIcon}
        alt="Chrome Icon"
        className="job-no-extension__image"
      />
      <div className="job-no-extension__title">
        Explore endless job opportunities
      </div>
      <div className="job-no-extension__subtitle">
        If you can’t find your dream job here on CareerOS, you can also save jobs from other job boards using our
        {' '}
        <a
          href={import.meta.env.VITE_EXTENSION_LINK}
          target="_blank"
          rel="noreferrer"
          onClick={setupInterval}
        >
          Chrome Extension
        </a>
        {' '}
        on your laptop or desktop.
      </div>

      <AddJobButton
        buttonLabel="Save Job from other Job Boards"
        icon="bi bi-box-arrow-up-right"
      />
    </div>
  </div>
);
