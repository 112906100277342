import { useCallback, useState } from 'react';
import { Toast } from '@/domains/generic/toasts/types';

interface UseToastReturn {
  toasts: Toast[];
  addToast: (toast: Toast) => void;
  removeToast: (id: number) => void;
}

const AUTOCLOSE_TIME_DURATION = 10000;
const MAX_TOASTS = 5;

export function useToast(): UseToastReturn {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const addToast = useCallback(({ autoClose = true, ...toast }: Omit<Toast, 'id'>) => {
    const newToast: Toast = {
      id: Date.now(),
      autoClose,
      ...toast,
    };

    setToasts((prevToasts) => {
      if (prevToasts.some(t => t.id === newToast.id)) {
        return prevToasts; // Don't add if a toast with the same ID is already present
      }
      const updatedToasts = [...prevToasts, newToast].slice(-MAX_TOASTS);
      return updatedToasts;
    });

    if (autoClose) {
      setTimeout(() => {
        setToasts((currentToasts) => currentToasts.filter((toastToDelete) => toastToDelete.id !== newToast.id));
      }, AUTOCLOSE_TIME_DURATION);
    }
  }, []);

  const removeToast = useCallback((id: number): void => {
    setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id));
  }, []);

  return {
    toasts, removeToast, addToast,
  };
}
