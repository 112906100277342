import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './search-bar.scss';
import { useAnalytics } from '@/services/hooks/use-analytics';

export const SearchBar = () => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();

  function removeSpecialCharacters(searchValue : string) {
    return searchValue.trim().replace(/\\/g, '').replace(/\//g, '');
  }

  const onSearchSubmitHandler: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (searchInputRef.current !== null && searchInputRef.current?.value) {
      const removeSpecialChar = removeSpecialCharacters(searchInputRef.current.value);
      const queryParams = new URLSearchParams([['query', removeSpecialChar]]);
      trackEvent('Topbar search', undefined, removeSpecialChar);
      navigate(`/app/search?${queryParams.toString()}`);
    }
  };
  return (
    <form className="search-form" method="" onSubmit={onSearchSubmitHandler}>
      <button className="search-form__button" aria-label="Search for companies on CareerOS" type="submit">
        <i className="bi bi-search" />
      </button>
      <label className="search-form__label" htmlFor="search" aria-label="Search for companies on CareerOS">
        <input
          className="search-form__input"
          ref={searchInputRef}
          type="text"
          id="search"
          name="search"
          placeholder="Search for companies on CareerOS"
          autoComplete="off"
        />
      </label>
    </form>
  );
};
