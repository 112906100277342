import { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@/components/tooltip/tooltip';

interface JobProps {
  title: string;
  url: string;
}

export const CompanyTileJob = ({ title, url }: JobProps) => {
  const [isJobOverflow, setIsJobOverflow] = useState(false);
  const jobElementRef = useRef<any>(null);

  useEffect(() => {
    if (jobElementRef.current) {
      setIsJobOverflow(jobElementRef.current.scrollWidth > jobElementRef.current.clientWidth);
    }
  }, []);
  return (
    <div key={url} className="kanban-tile__jobs__job">
      <i className="kanban-tle__jobs__job__icon bi bi-briefcase" />
      <Tooltip label={title} position="bottom" disableHoverListener={!isJobOverflow}>
        <a target="_blank" href={url} ref={jobElementRef} className="kanban-tile__jobs__job__title" rel="noreferrer">
          {title}
        </a>
      </Tooltip>
    </div>
  );
};
