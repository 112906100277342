import { useContext } from 'react';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { IconButton } from '@/components/icon-button/icon-button';
import { BaseResumeListingResponse, TailoredResumeListingResponse } from '../../types';
import './resume-listing-box.scss';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { InfoModal } from '@/domains/generic/modals';
import { ModalContext } from '@/components/modal/modal-provider';
import { deleteBaseResume, deleteTailoredResume } from '@/services/api/resume';
import { Tooltip } from '@/components/tooltip/tooltip';
import { Tag } from '@/components/tag/tag';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';

type ResumeListingBoxProps = {
  resume: BaseResumeListingResponse | TailoredResumeListingResponse
  type: 'base' | 'tailored'
};

type ResumeAdvisorListingBoxProps = {
  studentID?: string
  resume: BaseResumeListingResponse
  type: 'base' | 'tailored'
};

export const ResumeListingBox = ({ resume, type }: ResumeListingBoxProps) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const { addToast }: AddToastType = useContext(ToastContext);

  const editResume = () => {
    if (type === 'tailored') {
      navigate(`/app/resume-os/tailored/${resume.id}`);
      return;
    }
    navigate(`/app/resume-os/base/${resume.id}`);
  };

  const downloadResume = (url: string) => {
    trackEvent('Resume Downloaded', user, {
      source: 'Resume Listing',
    });
    window.open(url, '_blank');
  };

  const rememberDeleteResume = (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberDeleteResume', 'true');
    }
  };

  const deleteResumeConfirmed = async (rememberAction: boolean) => {
    rememberDeleteResume(rememberAction);
    if (type === 'base') {
      await deleteBaseResume(resume.id);
    } else {
      await deleteTailoredResume(resume.id);
    }
    addToast({
      type: 'success',
      message: 'Resume Successfully Deleted!',
      additionalMessage: 'You threw out the trash. Feel free to now make a new resume if you like.',
    });
    trackEvent('Resume Deleted', user, {
      source: 'Resume Listing',
    });
    closeModal();
    revalidator.revalidate();
  };

  const deleteResume = () => {
    const rememberAction = localStorage.getItem('rememberDeleteResume');
    if (rememberAction) {
      deleteResumeConfirmed(true);
    } else {
      openModal(
        <InfoModal
          handleSecondaryButtonClick={closeModal}
          handleButtonClick={deleteResumeConfirmed}
          title="Delete Resume"
          description={`The Resume ${resume.title} will be deleted forever. Are you sure?`}
          secondaryButtonLabel="Cancel"
          buttonLabel="Delete"
          isButtonDanger
          rememberText="Don't ask me again"
        />,
      );
    }
  };

  return (
    <div className="resume-listing-box">
      <div className="resume-listing-box__image-container">
        {!resume.preview_url || resume.preview_url.includes('default_preview_logo') ? (
          <div className="resume-listing-box__image resume-listing-box__image-skeleton" />
        ) : (
          <img className="resume-listing-box__image" src={resume.preview_url} alt="Template" />
        )}
      </div>
      <div className="resume-listing-box__title">{resume.title}</div>
      <div className="resume-listing-box__subtitle">
        Updated
        {' '}
        {formatDateDeltaFromNow(resume.updated_at)}
      </div>
      <div className="resume-listing-box__comments">
        <i className="bi bi-chat-left-dots" />
        {resume.comments_count}
        {' '}
        Open Comment(s)
      </div>
      <div className="resume-listing-box__actions">
        <Tooltip withArrow label="Edit Resume" position="top">
          <IconButton icon="bi bi-pencil" outlined mode="rounded" handleClick={editResume} size="small" />
        </Tooltip>
        <Tooltip withArrow label="Download Resume" position="top">
          <IconButton icon="bi bi-download" onClick={() => downloadResume(resume.source_url)} outlined mode="rounded" size="small" />
        </Tooltip>
        <Tooltip withArrow label="Delete Resume" position="top">
          <IconButton icon="bi bi-trash3" outlined mode="rounded" isDangerous handleClick={deleteResume} size="small" />
        </Tooltip>
      </div>
    </div>
  );
};

export const ResumeAdvisorListingBox = ({ resume, type, studentID }: ResumeAdvisorListingBoxProps) => {
  const navigate = useNavigate();

  const seeResume = () => {
    if (type === 'tailored') {
      navigate(`/app/resume-os/student/${studentID}/tailored/${resume.id}`);
      return;
    }
    navigate(`/app/resume-os/student/${studentID}/base/${resume.id}`);
  };

  const downloadResume = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <div className="resume-listing-box resume-listing-box--advisor">
      <div className="resume-listing-box__image">
        {!resume.preview_url || resume.preview_url.includes('default_preview_logo') ? (
          <div className="resume-listing-box__image resume-listing-box__image-skeleton" />
        ) : (
          <img className="resume-listing-box__image" src={resume.preview_url} alt="Template" />
        )}
      </div>
      <div className="resume-listing-box__title">{resume.title}</div>
      <div className="resume-listing-box__subtitle">
        Updated
        {' '}
        {formatDateDeltaFromNow(resume.updated_at)}
      </div>
      <div className="resume-listing-box__comments">
        <i className="bi bi-chat-left-dots" />
        {resume.comments_count}
        {' '}
        Open Comment(s)
      </div>
      <div className="resume-listing-box__type">
        {type === 'base' ? <Tag label="Base" color="blue-green" /> : <Tag label="Tailored" color="light-blue" />}
      </div>
      {type === 'tailored' && resume.job && (
      <div className="resume-listing-box__job">
        {resume.job.title}
      </div>
      )}
      <div className="resume-listing-box__actions">
        <Tooltip withArrow label="Add Comment" position="top">
          <IconButton icon="bi bi-chat-right-dots" outlined mode="rounded" handleClick={seeResume} size="small" />
        </Tooltip>
        <Tooltip withArrow label="Download Resume" position="top">
          <IconButton icon="bi bi-download" onClick={() => downloadResume(resume.source_url)} outlined mode="rounded" size="small" />
        </Tooltip>
      </div>
    </div>
  );
};
