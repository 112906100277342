import { useState } from 'react';
import { Input } from '@/components/input/input';
import { Button } from '@/components/button/Button';
import { updateJobDeadline } from '@/services/api/job';
import './job-deadline-edit.scss';

type JobDeadlineProps = {
  isDateRolling?: boolean;
  deadlineDate?: string;
  handleClose: () => void;
  jobID: string;
  isEditing?: boolean;
};

export const JobDeadlineEdit = ({
  isDateRolling = false,
  deadlineDate = '',
  isEditing = false,
  jobID,
  handleClose,
}: JobDeadlineProps) => {
  const [deadline, setDeadline] = useState(deadlineDate);
  const [isRolling, setIsRolling] = useState(isDateRolling);

  const handleDeadlineChange = (date: string) => {
    setDeadline(date);
    setIsRolling(false);
  };
  const handleRollingDateChange = (value: any, name: any, checked: boolean | undefined) => {
    setIsRolling(!!checked);
    setDeadline(deadline);
  };

  const handleSaveClick: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const deadlineDateTime = deadline ? new Date(deadline).toISOString() : null;
    await updateJobDeadline(jobID, deadlineDateTime, isRolling);
    handleClose();
  };

  return (
    <form className="job-deadline" onSubmit={handleSaveClick}>
      <Input
        label={`${isEditing ? 'Edit' : 'Add'} application deadline`}
        value={deadline}
        id="deadline"
        placeholder=""
        type="date"
        handleValueChange={handleDeadlineChange}
        min={new Date().toISOString().split('T')[0]}
        max="3000-12-31"
        readOnly={isRolling}
      />
      <Input
        label="Rolling application (no deadline)"
        value=""
        id="rollingDate"
        placeholder=""
        type="checkbox"
        handleValueChange={handleRollingDateChange}
        checked={isRolling}
      />
      <div className="job-deadline__buttons">
        <Button
          label="Save"
          type="submit"
          size="XS"
          mode="primary"
        />
        <Button
          label="Cancel"
          size="XS"
          mode="primary"
          outlined
          handleClick={handleClose}
        />
      </div>
    </form>
  );
};
