import { ReactElement, useEffect, useRef } from 'react';

import './popup-window.scss';

interface PopupWindowProps {
  handleClose: () => void;
  children: ReactElement;
}

export const PopupWindowWrapper = ({
  handleClose,
  children,
}: PopupWindowProps) => {
  const boxRef = useRef<HTMLDivElement>(null);

  const handleBlur = (e: any) => {
    const { target } = e;

    if (!boxRef.current?.contains(target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);

    return () => document.removeEventListener('mousedown', handleBlur);
  }, []);

  return (
    <div tabIndex={-1} className="popup-window" ref={boxRef}>
      {children}
    </div>
  );
};
