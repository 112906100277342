import {
  useRef, useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { ManageTags } from '@/components/manage-tags/manage-tags';
import { TableRowItem } from '../table-types';
import { Tag, TagType } from '@/components/tag/tag';
import { createTag } from '@/services/api/advisor';

import './tags-cell.scss';
import { IconButton } from '@/components/icon-button/icon-button';

type Props = {
  cellData: TableRowItem;
};

export const TagsCell = ({ cellData }: Props) => {
  const [isTagManagerOpen, setIsTagManagerOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<TagType[]>(cellData.tags || []);
  const queryClient = useQueryClient();
  const cellTextRef = useRef<any>(null);
  const tagsContainerRef = useRef<any>(null);

  const metadata = {
    universityID: cellData.student.universityID,
    cohortID: cellData.student.cohortID,
  };

  const revalidateData = () => {
    queryClient.invalidateQueries(['university-tags', metadata.universityID]);
    queryClient.invalidateQueries(['cohort', metadata.cohortID]);
  };

  const handleTagAdd = async (tag: TagType, shouldCreateTag?: boolean) => {
    let addedTag = tag;
    if (cellData.addTag) {
      if (shouldCreateTag) {
        const tagWithSameName = cellData.allTags?.find((t) => t.label.trim().toLocaleLowerCase() === tag.label.trim().toLocaleLowerCase());
        if (!tagWithSameName) {
          addedTag = await createTag(tag.label, tag.color, metadata.universityID);
        } else {
          addedTag = tagWithSameName;
        }
      }
      if (!addedTag) {
        return;
      }
      if (selectedTags.find((t) => t.id === addedTag.id)) {
        return;
      }
      cellData.addTag(addedTag);
      setSelectedTags([...selectedTags, addedTag]);
      revalidateData();
    }
  };

  const handleRemoveTag = (tag: TagType) => {
    if (cellData.removeTag) {
      setSelectedTags(selectedTags.filter((t) => t.id !== tag.id));
      cellData.removeTag(tag);
      revalidateData();
    }
  };

  const handleOpenTag = () => {
    setIsTagManagerOpen(true);
  };

  return (
    <td className="table-cell">
      {isTagManagerOpen && (
        <div ref={tagsContainerRef} className="table-cell__tags-manage">
          <ManageTags
            id={cellData.id || 'default'}
            selectedTags={selectedTags}
            existingTags={cellData.allTags || []}
            handleTagAdd={handleTagAdd}
            handleTagRemove={handleRemoveTag}
            handleClose={() => setIsTagManagerOpen(false)}
          />
        </div>
      )}
      <span ref={cellTextRef} className="table-cell__content">
        <div className="tags-cell" onClick={handleOpenTag} ref={cellTextRef}>
          {selectedTags.length > 0 ? (
            <>
              <IconButton mode="rounded" outlined size="small" icon="bi bi-plus" />
              {selectedTags.map((tag) => (
                <Tag
                  key={tag.id}
                  label={tag.label}
                  color={tag.color}
                />
              ))}

            </>
          ) : (
            <span className="tags-cell__placeholder">
              <IconButton mode="rounded" outlined size="small" icon="bi bi-plus" />
              {' '}
              Add tag
            </span>
          )}
        </div>
      </span>
    </td>
  );
};
