import StarIcon from '@/assets/images/icons/star-purple.svg';

type Props = {
  university: string;
  studentName?: string;
  rating: number;
  completedScans: number;
  universityLogo: string;
};

export const QRLeaderboardItem = ({
  university,
  studentName,
  rating,
  completedScans,
  universityLogo,
}: Props) => (
  <li
    className={`leaderboard__student ${studentName ? 'leaderboard__student--highlighted' : ''}`}
  >
    <div className="leaderboard__student-rating">{rating}</div>
    <img src={universityLogo} alt="" className="leaderboard__university-logo" />
    <span className="leaderboard__student-name">
      {studentName || `Fellow ${university} Rockstar`}
    </span>
    <div className="leaderboard__student-quests">
      <p>
        {completedScans}
        {' '}
        scans
      </p>
      <img src={StarIcon} alt="" />
    </div>
  </li>
);
