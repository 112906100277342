// ./frontend/stc/app/[lang]/utils/fetch-api.tsx
import qs from 'qs';
import * as Sentry from '@sentry/react';
import { getStrapiURL } from './api-helpers';

export async function fetchStrapiAPI(
  path: string,
  urlParamsObject = {},
  options = {},
) {
  try {
    // Merge default and user options
    const token = import.meta.env.VITE_PUBLIC_STRAPI_API_TOKEN;

    const mergedOptions = {
      next: { revalidate: 60 },
      headers: {
        'Content-Type': 'application/json',
      },
      Authorization: `Bearer ${token}`,
      ...options,
    };

    // Build request URL
    const queryString = qs.stringify(urlParamsObject);
    const requestUrl = `${getStrapiURL(
      `/api${path}${queryString ? `?${queryString}` : ''}`,
    )}`;

    // Trigger API call
    const response = await fetch(requestUrl, mergedOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    return {};
  }
}
