import { ReactElement, useRef, useState } from 'react';
import './accordion.scss';
import { Button } from '../button/Button';

interface AccordionProps {
  title: string;
  children: ReactElement;
  isOpened?: boolean;
  handleClick?: () => void;
  handleCopied?: () => void;
  copiable?: boolean;
}

/**
 * Primary UI component for User Information Box
 */
export const Accordion = ({
  title,
  children,
  isOpened = false,
  copiable = false,
  handleClick = () => {},
  handleCopied,
}: AccordionProps) => {
  const [opened, toggleOpen] = useState(isOpened);
  const [isCopied, setIsCopied] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleCopyToClipboard = () => {
    if (!contentRef.current) {
      return;
    }

    const content = contentRef.current.innerText;
    navigator.clipboard.writeText(content)
      .then(() => setIsCopied(true))
      .catch(() => setIsCopied(false));

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    if (handleCopied) {
      handleCopied();
    }
  };

  return (
    <div
      className={
        opened
          ? 'careerOS-accordion careerOS-accordion--opened'
          : 'careerOS-accordion'
      }
    >
      <div onClick={() => { toggleOpen(!opened); handleClick(); }} className="careerOS-accordion__title">
        <div
          className="careerOS-accordion__toggle"
        >
          <i className={`bi bi-${opened ? 'caret-down-fill' : 'caret-right-fill'}`} />
        </div>
        {title}
      </div>
      <div ref={contentRef} className="careerOS-accordion__content">{children}</div>
      {opened && copiable && (
        <div className="careerOS-accordion__buttons">
          <div className={`careerOS-accordion__copy-button ${isCopied ? 'careerOS-accordion__copy-button--copied' : ''}`}>
            <Button
              handleClick={handleCopyToClipboard}
              icon="bi bi-copy"
              data-testid="copy-button"
              label="Copy to Clipboard"
              mode="primary"
              size="medium"
              outlined
            />
          </div>
        </div>
      )}
    </div>
  );
};
