import { Timestamp } from 'firebase/firestore';
import DOMPurify from 'dompurify';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import './linkedin-message.scss';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { prettifyHTML } from '@/services/helpers/processHtml';

const sanitizeConfig = {
  ADD_ATTR: ['target'], // Allow the "target" attribute
};

type LinkedinMessageProps = {
  messageText: string;
  messageType?: 'pending' | 'sent' | 'received';
  messageCreatedAt?: Timestamp;
  avatar: string;
  isFromUser: boolean;
  messageAuthor: string
};

export const LinkedinMessage = ({
  avatar,
  isFromUser,
  messageAuthor,
  messageText,
  messageCreatedAt,
  messageType,
}: LinkedinMessageProps) => (
  <div className={`linkedin-message ${messageType && 'linkedin-message--pending'} ${isFromUser ? 'linkedin-message--fromUser' : 'linkedin-message--fromContact'}`}>
    <div className="linkedin-message__avatar">
      <DefaultLogo
        type="contact"
        source={avatar}
        handleClick={() => {}}
        className="linkedin-message__image"
        name={messageAuthor}
        size={48}
      />
    </div>
    <div className="linkedin-message__content">
      <div className="linkedin-message__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prettifyHTML(messageText), sanitizeConfig) }} />
      {messageCreatedAt && (
        <div className="linkedin-message__timestamp">
          {formatDateDeltaFromNow(messageCreatedAt.toDate())}
        </div>
      )}
    </div>
  </div>

);
