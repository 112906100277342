import { useEffect, useRef, useState } from 'react';
import { useScroll } from '@/services/hooks/use-scroll';
import { IconButton } from '../icon-button/icon-button';
import { Industry } from '@/domains/core/company/types';
import { FilterButton } from '../filter/filter';

import './industry-filters.scss';
import { Button } from '../button/Button';

type Props = {
  industries: Industry[];
  selectedIndustry?: string;
  isExpanded: boolean;
  filtersSearchParams: string;
};

export const IndustryFilters = ({
  industries,
  selectedIndustry,
  isExpanded,
  filtersSearchParams,
}: Props) => {
  const [industryContainer, handleVerticalScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(true);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(!isExpanded);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleBlur = (e: any) => {
    if (selectedIndustry === 'Featured') {
      return;
    }

    const { target } = e;

    if (!containerRef.current?.contains(target)) {
      setIsCollapsed(true);
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;
    const allColumnsWidth = industryContainer.current.scrollWidth;
    const columnsInViewWidth = industryContainer.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }
    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  const handleUncollapse = () => {
    setIsCollapsed(false);
    handleScroll({ currentTarget: industryContainer.current } as React.UIEvent<HTMLDivElement, UIEvent>);
  };

  useEffect(() => {
    setIsCollapsed(!isExpanded);
  }, [isExpanded, selectedIndustry]);

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);
    handleScroll({ currentTarget: industryContainer.current } as React.UIEvent<HTMLDivElement, UIEvent>);

    return () => document.removeEventListener('mousedown', handleBlur);
  }, [selectedIndustry]);

  return (
    <div ref={containerRef} className="industry-filters" data-testid="scroll-container">
      {!isCollapsed && (
        <div className="industry-filters__header">
          <h2 className="industry-filters__title">
            Explore by Industry
          </h2>

          <div className="industry-filters__controls">
            <IconButton
              icon="bi bi-chevron-left"
              mode="rounded"
              size="medium"
              data-testid="button-left"
              outlined
              handleClick={() => handleVerticalScroll('left')}
              disabled={!showLeftScrollArrow}
            />
            <IconButton
              icon="bi bi-chevron-right"
              mode="rounded"
              size="medium"
              data-testid="button-right"
              outlined
              handleClick={() => handleVerticalScroll('right')}
              disabled={!showRightScrollArrow}
            />
          </div>
        </div>
      )}
      <div className="industry-filters__main">
        <div
          ref={industryContainer}
          onScroll={handleScroll}
          className={`industry-filters__scroll-container ${!showRightScrollArrow ? 'industry-filters__scroll-container--no-mask' : ''} ${isCollapsed ? 'industry-filters__scroll-container--collapsed' : ''}`}
        >
          <div className={`industry-filters__filters ${isCollapsed ? 'industry-filters__filters--collapsed' : ''}`}>
            {industries.map((industry) => (
              <FilterButton
                key={industry.name}
                label={industry.name}
                icon={industry.icon}
                link={`/app/companies/explore/${encodeURIComponent(industry.name)}?${filtersSearchParams}`}
                active={selectedIndustry === industry.name}
                expanded={!isCollapsed}
              />
            ))}
          </div>
        </div>
        {isCollapsed && (
          <>
            <div className="industry-filters__expand industry-filters__expand--small" onClick={() => setIsCollapsed(!isCollapsed)}>
              <Button
                label=""
                icon="bi bi-arrow-bar-down"
                mode="invisible"
                size="medium"
                iconPosition="right"
              />
            </div>

            <div className="industry-filters__expand" onClick={handleUncollapse}>
              <Button
                label="Expand"
                icon="bi bi-arrow-bar-down"
                mode="invisible"
                size="medium"
                iconPosition="right"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
