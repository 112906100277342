import { LinkButton } from '@/components/link/link';
import GetStartedImage from '@/assets/images/getting-started.svg';
import HowTosImage from '@/assets/images/how-tos.svg';
import ContactTeamImage from '@/assets/images/contact-team.svg';

import './styles/help.scss';
import { router } from '@/main';

const HelpPage = () => {
  const goToGettingStarted = () => {
    router.navigate('/app/support/get-started');
  };
  const goToHowToPage = () => {
    router.navigate('/app/support/how-to');
  };
  const contactTeam = () => {
    window.open('https://share.hsforms.com/1XspFVBkwT2S7dMKLWzrKqg55xla', '_blank');
  };

  return (
    <div className="help">
      <div className="white-wrapper">
        <h2 className="help__title">
          CareerOS Help & Support Hub
        </h2>
        <div className="help__cards">
          <div className="help__card" onClick={goToGettingStarted}>
            <img src={GetStartedImage} alt="" className="help__card__image" />
            <h3 className="help__card__title">Getting Started</h3>
            <p className="help__card__subtitle">
              Learn about the basics on how to quickly get you using CareerOS.
            </p>
            <LinkButton label="Let’s go" icon="bi bi-arrow-right-circle" link="get-started" target="_self" />
          </div>
          <div className="help__card" onClick={goToHowToPage}>
            <img src={HowTosImage} alt="" className="help__card__image" />
            <h3 className="help__card__title">How To’s</h3>
            <p className="help__card__subtitle">
              Short and sweet walkthrough of the Chrome Extension features.
            </p>
            <LinkButton label="Learn more" icon="bi bi-arrow-right-circle" link="how-to" target="_self" />
          </div>
          <div className="help__card" onClick={contactTeam}>
            <img src={ContactTeamImage} alt="" className="help__card__image" />
            <h3 className="help__card__title">Contact CareerOS team</h3>
            <p className="help__card__subtitle">
              Send requests to help@thecareeros.com.
            </p>
            <LinkButton label="Message now" icon="bi bi-arrow-right-circle" link="get-started" target="_self" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpPage;
