import '../quest-tile/quest-tile.scss';
import QuestCompleteIcon from '@/assets/images/quest-complete.png';

export const QuestsEmpty = () => (
  <div className="quest-tile">
    <div className="quest-tile__icon quest-tile__icon--complete">
      <img src={QuestCompleteIcon} alt="Quest Complete" />
    </div>
    <div className="quest-tile__info">
      <div className="quest-tile__title quest-tile__title--complete">All Quests completed!</div>
      <div className="quest-tile__description">You did it! Now, relax and wait for more...</div>
    </div>
    <div className="quest-tile__cta" />
  </div>
);
