import { SidebarLink } from '../sidebar-link/sidebar-link';
import { LinkType } from '../types/types';
import './sidebar-links.scss';

interface SidebarLinksProps {
  links: LinkType[] | undefined;
  collapsed: boolean;
  handleLinkClick: (link: string) => void;
}
export const SidebarLinks = ({ links, collapsed, handleLinkClick }:SidebarLinksProps) => (
  <ul className="sidebar__links">
    {!!links && links.map((link: any) => (
      <li key={link.url}>
        <SidebarLink
          link={link}
          collapsed={collapsed}
          handleLinkClick={() => handleLinkClick(link.label)}
        />
      </li>
    ))}
  </ul>
);
