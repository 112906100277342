import { Link, NavLink } from 'react-router-dom';
import { Tooltip } from '@/components/tooltip/tooltip';
import { LinkType } from '../types/types';
import './sidebar-link.scss';

interface SidebarLinkProps {
  link: LinkType;
  collapsed: boolean;
  handleLinkClick: () => void;
}
export const SidebarLink = ({ link, collapsed, handleLinkClick }:SidebarLinkProps) => (
  <Tooltip label={link.label} position="right" disableHoverListener={!collapsed}>
    {link.isExternal ? (
      <Link
        to={link.url}
        target="_blank"
        aria-label={link.label}
        onClick={handleLinkClick}
        className={collapsed ? 'sidebar-link sidebar-link--collapsed' : 'sidebar-link'}
      >
        <img
          className="sidebar-link__icon"
          src={link.icon}
          role="presentation"
          alt={link.label}
        />
        <span className="sidebar-link__label">
          {link.label}
        </span>
      </Link>
    ) : (
      <NavLink
        to={link.url}
        onClick={handleLinkClick}
        aria-label={link.label}
        id={`sidebar-${link.label}`}
        className={({ isActive }) => ((isActive || (link.url === 'companies/explore' && window.location.pathname.includes('/app/companies')))
          ? 'sidebar-link sidebar-link--active'
          : 'sidebar-link') + (collapsed ? ' sidebar-link--collapsed' : '')}
        style={{ '--selected-icon': `url(${link.iconSelected})` } as any}
      >
        <img
          className="sidebar-link__icon"
          src={link.icon}
          role="presentation"
          alt={link.label}
        />
        <span className="sidebar-link__label">
          {link.label}
        </span>
      </NavLink>

    )}
  </Tooltip>
);
