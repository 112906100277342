import { useContext } from 'react';
import { Button } from '@/components/button/Button';
import './saved-job.scss';
import { TrackerContextType, TrackerContext } from '../tracker-provider/tracker-context';

interface SavedJobsProps {
  title: string;
  location: string;
  companyID: string;
  applicationDeadline?: string;
  companyName?: string;
  handleButtonClick?: () => void;
  buttonLabel?: string;
}

export const SavedJob = ({
  title,
  location,
  companyID,
  applicationDeadline,
  handleButtonClick,
  companyName,
  buttonLabel,
}: SavedJobsProps) => {
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;

  const goToTrackerPage = () => {
    openTracker(companyID, 'Jobs');
  };
  return (
    <div className="saved-job">
      <h3 className="saved-job__title">{title}</h3>
      <div className="saved-job__info">
        <div className="saved-job__detail">
          <i className="bi bi-briefcase" />
          <span className="saved-job__detail__label">{companyName}</span>
        </div>
        <div className="saved-job__detail">
          <i className="bi bi-geo-alt-fill" />
          <span className="saved-job__detail__label">{location}</span>
        </div>
        {applicationDeadline && (
          <div className="saved-job__detail">
            <i className="bi bi-calendar" />
            <span className="saved-job__detail__label">
              Deadline:
              {' '}
              {applicationDeadline}
            </span>
          </div>
        )}
      </div>
      <Button mode="primary" handleClick={handleButtonClick || goToTrackerPage} outlined size="medium" label={buttonLabel || 'Go to Progress Tracker'} />
    </div>
  );
};
