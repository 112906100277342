import {
  useContext, useEffect, useRef, useState,
} from 'react';
import ReactQuill from 'react-quill';
import './message-box.scss';
import { useRevalidator } from 'react-router-dom';
import { convertNewLinesToHtml, findTextInBrackets, sendingMessageToasts } from '@/services/helpers/messages';
import { useAnalytics } from '@/services/hooks/use-analytics';
import useDebounceEffect from '@/services/hooks/use-debounce';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import { checkForQuestCompletion } from '@/domains/core/student';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { MessageInputButtons } from '../message-input-buttons/message-input-buttons';
import '@/services/helpers/quill-config';
import { OutreachScenarios } from '@/domains/core/netwoking/components';
import { useOutreachScenario } from '@/services/hooks/use-outreach-scenario';
import { IconButton } from '@/components/icon-button/icon-button';
import { Tooltip } from '@/components/tooltip/tooltip';
import { TemplateRequestPayload } from '@/domains/core/netwoking/types';

interface MessageBoxProps {
  toUserID: string;
  toUserLinkedInUrn: string;
  isConnection?: boolean;
  handleSentMesage: () => void;
  handleConnectionSent: () => void;
  withHint?: boolean;
  shouldGenerateMessage?: boolean;
  prefilledMessage?: string;
  premiumPrefilledMessage?: string;
  regenerateData: TemplateRequestPayload;
}

const MAX_MESSAGE_LENGTH = 200;
const MAX_MESSAGE_LENGTH_PREMIUM = 300;
const MIN_MESSAGE_LENGTH = 25;

/**
 * Primary UI component for User Information Box
 */
export const MessageBox = ({
  toUserID,
  toUserLinkedInUrn,
  isConnection,
  handleConnectionSent,
  handleSentMesage,
  shouldGenerateMessage = false,
  withHint = true,
  regenerateData,
  prefilledMessage = '',
  premiumPrefilledMessage = '',

}: MessageBoxProps) => {
  const quillEditor = useRef<any>(null);
  const reactQuillRef = useRef<ReactQuill | null>(null);
  const { addToast }: AddToastType = useContext(ToastContext);
  const {
    sendLinkedInMessage,
    getPremiumStatus,
    sendLinkedInConnection,
  } = useExtensionMessaging();
  const [message, setMessage] = useState<any>('');
  const {
    showScenarios,
    regenerateScenario,
    isLoadingOutreachScenario,
    hideScenarios,
    scenarioTemplate,
    showRegenerateScenario,
    displayScenarios,
    cancelRegenerateScenario,
  } = useOutreachScenario();
  const [isSendingDisabled, setIsSendingDisabled] = useState(true);
  const [warningMessage, setWarningMessage] = useState<string>();
  const [messageLength, setMessageLength] = useState(0);
  const { trackEvent } = useAnalytics();
  const [premiumStatus, setPremiumStatus] = useState(false);

  const revalidator = useRevalidator();
  const limit = premiumStatus ? MAX_MESSAGE_LENGTH_PREMIUM : MAX_MESSAGE_LENGTH;

  const checkPremiumProfile = async () => {
    try {
      const status = await getPremiumStatus();
      if (status) {
        setPremiumStatus(status);
        setMessage(premiumPrefilledMessage || prefilledMessage);
      } else {
        setMessage(prefilledMessage);
      }
    } catch {
      setMessage(prefilledMessage);
    }
  };

  const installExtensionToast = () => {
    addToast(
      {
        type: 'warning',
        message: 'It looks like you don\'t have our LinkedIn extension installed.',
        additionalMessage: 'Please install it to be able to send LinkedIn messages.',
      },
    );
  };

  const handleWriteMessageSelection = () => {
    hideScenarios();
    setMessage('');
  };

  const handleSendMessage = async (messageText: string) => {
    const messageResult = await sendLinkedInMessage(toUserLinkedInUrn, messageText);

    if (!messageResult) {
      installExtensionToast();
      throw new Error('Failed to send message');
    }

    return messageResult;
  };

  const handleSendConnectionRequest = async (messageText: string) => {
    const connectionResult = await sendLinkedInConnection(toUserLinkedInUrn, messageText);

    if (!connectionResult) {
      installExtensionToast();
      throw new Error('Failed to send connection request');
    }

    return connectionResult;
  };

  const handleMessageChange = (value: string) => {
    const textLength = quillEditor.current.getText().trim().length;
    setMessage(value);
    setMessageLength(textLength);
  };

  const checkMessagePlaceholders = () => {
    const hasPlaceholders = message.match(/\[[^[\]]*\]/g);

    return hasPlaceholders;
  };

  const checkMessage = () => {
    if (checkMessagePlaceholders()) {
      setIsSendingDisabled(true);
      setWarningMessage('Please make sure to replace the placeholders in brackets with the according information.');

      return false;
    }

    if (!isConnection) {
      return true;
    }

    if (messageLength > limit) {
      setIsSendingDisabled(true);
      setWarningMessage(`Please make sure your message does not exceed ${limit} characters.`);

      return false;
    }

    if (messageLength < MIN_MESSAGE_LENGTH) {
      setIsSendingDisabled(true);
      setWarningMessage('Please send a personalized outreach message with at least 25 characters.');

      return false;
    }

    setIsSendingDisabled(false);
    setWarningMessage(undefined);

    return true;
  };

  const successMessageSendToast = () => {
    addToast(
      {
        type: 'success',
        message: sendingMessageToasts.success[isConnection ? 'connection' : 'linkedin'].message,
        additionalMessage: sendingMessageToasts.success[isConnection ? 'connection' : 'linkedin'].additionalMessage,
      },
    );
  };

  const errorMessageSendToast = (errorMessage?: string) => {
    if (errorMessage === 'You have reached the maximum number of pending invitations.') {
      addToast({
        type: 'error',
        message: 'You have reached your weekly LinkedIn invitation limit.',
        additionalMessage: 'This limit is set by Linkedin and will reset once per week. Upgrade to Linkedin premium or use our email networking features to keep networking in the meantime.',
      });
      trackEvent('Toast Error Shown', {}, {
        message: 'You have reached your weekly LinkedIn invitation limit',
        error: errorMessage,
      });
      return;
    }

    addToast({
      type: 'error',
      message: errorMessage || sendingMessageToasts.error[isConnection ? 'connection' : 'linkedin'].message,
      additionalMessage: sendingMessageToasts.error[isConnection ? 'connection' : 'linkedin'].additionalMessage,
    });
    trackEvent('Toast Error Shown', {}, {
      message: errorMessage || sendingMessageToasts.error[isConnection ? 'connection' : 'linkedin'].message,
      error: sendingMessageToasts.error[isConnection ? 'connection' : 'linkedin'].additionalMessage,
    });
  };

  const handleSendMessageClick = async () => {
    const messageText = quillEditor.current.getText();

    if (!checkMessage()) {
      return;
    }

    try {
      if (isConnection) {
        await handleSendConnectionRequest(messageText);
        handleConnectionSent();
      } else {
        await handleSendMessage(messageText);
      }
      trackEvent('Message Sent');
      successMessageSendToast();
      setMessage('');

      await checkForQuestCompletion(toUserID);

      handleSentMesage();

      revalidator.revalidate();
    } catch (error) {
      const errorMessage = typeof error === 'string' ? error : undefined;
      errorMessageSendToast(errorMessage);
      trackEvent(`LinkedIn ${isConnection ? 'connection' : 'message'} send failed`, {}, { errorMessage });
    }
  };

  const attachQuillRefs = () => {
    if (!reactQuillRef.current) {
      return;
    }
    if (typeof reactQuillRef.current.getEditor !== 'function') return;
    quillEditor.current = reactQuillRef.current.getEditor();
  };

  const highlightPlaceholders = () => {
    if (!reactQuillRef.current) {
      return;
    }
    const bracketedParts = findTextInBrackets(quillEditor.current.getText());
    quillEditor.current.formatText(0, quillEditor.current.getText().length, {
      color: false,
      bold: false,
    });
    bracketedParts.forEach(part => {
      quillEditor.current.formatText(part.index, part.length, {
        color: 'var(--purpose-information)',
        bold: true,
      });
    });
  };

  useDebounceEffect(() => {
    highlightPlaceholders();
    setIsSendingDisabled(messageLength === 0);

    if (warningMessage) {
      checkMessage();
    }
  }, [message]);

  useEffect(() => {
    if (scenarioTemplate && !isLoadingOutreachScenario) {
      setMessage(scenarioTemplate);
    }
  }, [scenarioTemplate, isLoadingOutreachScenario]);

  useEffect(() => {
    attachQuillRefs();
    checkPremiumProfile();
    displayScenarios();
  }, []);

  return (
    <div className="message-box">
      <div className={`message-box__container ${warningMessage ? 'message-box__container--dangerous' : ''}`}>
        {(showScenarios || isLoadingOutreachScenario) && (
          <div className="message-box__scenarios">
            <OutreachScenarios
              handleApplyTemplate={(scenario) => regenerateScenario({ ...regenerateData, maxCharacters: limit }, scenario)}
              handleWriteMessageSelection={handleWriteMessageSelection}
              isLoading={isLoadingOutreachScenario}
            />
          </div>
        )}
        {shouldGenerateMessage && !showScenarios && !isLoadingOutreachScenario && (
        <div className="message-box__scenario-icon">
          <Tooltip label="Change Template" position="top">
            <IconButton
              label="Change Template"
              icon="bi bi-card-text"
              onClick={displayScenarios}
              mode="rounded"
              size="x-small"
              outlined
              isLoading={isLoadingOutreachScenario}
            />
          </Tooltip>
        </div>
        )}

        <ReactQuill
          ref={reactQuillRef}
          placeholder="We are generating a message for you. Please wait...."
          modules={{
            toolbar: null,
          }}
          theme="snow"
          value={message}
          onChange={handleMessageChange}
          style={{
            border: 'none',
            padding: '0',
            borderRadius: '8px',
            height: 76,
            display: (showScenarios || isLoadingOutreachScenario) ? 'none' : 'block',
          }}
        />
      </div>
      <div className="message-box__bottom">
        {warningMessage && <span className="email-box__warning">{warningMessage}</span>}
        {isConnection && (
          <span className={`message-box__length ${warningMessage ? 'message-box__length--dangerous' : ''}`}>
            {messageLength}
            /
            {limit}
          </span>
        )}
      </div>
      <div className="message-box__footer">
        {showRegenerateScenario && (
        <IconButton
          label="Regenerate message"
          icon={isLoadingOutreachScenario ? 'bi bi-x-lg' : 'bi bi-arrow-clockwise'}
          onClick={() => (isLoadingOutreachScenario ? cancelRegenerateScenario() : regenerateScenario({ ...regenerateData, maxCharacters: limit }))}
          mode="special"
          size="large"
          outlined
        />
        )}
        <MessageInputButtons
          primaryButtonLabel={isConnection ? 'Send LinkedIn Connection Request' : 'Send LinkedIn Message'}
          primaryButtonIcon="send"
          isPrimaryButtonDisabled={isSendingDisabled}
          handleRegenerateClick={(msg: string) => setMessage(convertNewLinesToHtml(msg))}
          handleSendMessageClick={handleSendMessageClick}
          regenerateWithOptions={!shouldGenerateMessage}
          withHint={withHint}
          withAIButton={!shouldGenerateMessage}
          messageType="linkedin"
          contactID={toUserID}
          characterLimit={isConnection ? limit : undefined}
          regenerateData={{ ...regenerateData, maxCharacters: limit }}
        />
      </div>
    </div>
  );
};
