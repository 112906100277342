import { EmailMessageSkeleton } from './email-chat-skeleton';

export const EmailMessageSkeletonContainer = () => (
  <div>
    <EmailMessageSkeleton />
    <EmailMessageSkeleton />
    <EmailMessageSkeleton />
    <EmailMessageSkeleton />
    <EmailMessageSkeleton />
    <EmailMessageSkeleton />
    <EmailMessageSkeleton />
    <EmailMessageSkeleton />
    <EmailMessageSkeleton />
    <EmailMessageSkeleton />
    <EmailMessageSkeleton />
    <EmailMessageSkeleton />
  </div>
);
