/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useRef, useState } from 'react';
import { ModalContext } from '@/components/modal/modal-provider';

import './add-document.scss';
import { Button } from '@/components/button/Button';
import { Input } from '@/components/input/input';
import { Select } from '@/components/select/select';
import { DocumentType } from '@/domains/core/student/types';
import { InfoModal } from '../contact-info-modal/contact-info-modal';

interface AddDocumentProps {
  mode?: 'add' | 'edit';
  fileLink?: string;
  defaultTitle?: string;
  defaultType?: DocumentType;
  handleSubmit: any;
}

export const AddDocument = ({
  mode = 'add',
  fileLink,
  defaultTitle = '',
  defaultType,
  handleSubmit,
}: AddDocumentProps) => {
  const { closeModal, openModal } = useContext(ModalContext) as any;
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const [fileError, setFileError] = useState(false);
  const [link, setLink] = useState(fileLink);
  const [title, setTitle] = useState(defaultTitle);
  const [titleError, setTitleError] = useState(false);
  const [type, setType] = useState(defaultType);
  const [typeError, setTypeError] = useState(false);

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) {
      return;
    }
    const { files } = event.target;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const fileUploaded = files[i];

      // 20 MB in bytes
      const maxFileSize = 20 * 1024 * 1024;

      if (fileUploaded.size > maxFileSize) {
        alert('File size should not exceed 20 MB');
        return;
      }
    }
    const fileUploaded = event.target.files[0];
    setFile(fileUploaded);
    setLink(fileUploaded.name);
    setFileError(false);
  };

  const handleTypeChange = (value: 'Resume' | 'Cover Letter' | 'Other') => {
    setType(value);
    setTypeError(false);
  };

  const handleSubmitButton = () => {
    if (!file) {
      setFileError(true);
    }
    if (!title.trim().length) {
      setTitleError(true);
    }
    if (!type) {
      setTypeError(true);
    }

    if (!file || !title.length || !type) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', title);
    formData.append('type', type);

    handleSubmit(formData);
    closeModal();
    setFileError(false);
    setTitleError(false);
    setTypeError(false);
  };

  const reopenCurrentModal = () => {
    openModal(
      <AddDocument
        mode={mode}
        fileLink={link}
        defaultType={type}
        defaultTitle={title}
        handleSubmit={handleSubmit}
      />,
    );
  };

  const handleDiscardButton = () => {
    openModal(
      <InfoModal
        handleButtonClick={closeModal}
        handleSecondaryButtonClick={reopenCurrentModal}
        buttonLabel="Discard"
        secondaryButtonLabel="Cancel"
        description="Are you sure you want to discard all the changes you made to this document?"
        title="Discard Changes"
        isButtonDanger
      />,
      true,
    );
  };

  return (
    <div className="add-document">
      <div className="add-document__title">
        {mode === 'add' ? 'Add' : 'Edit'}
        {' '}
        Document
      </div>
      <div className="add-document__content">
        <form action="" className="add-document__form">
          <div className="add-document__file-input add-document__form-field add-document__form-field--required">
            <label htmlFor="file">Select file (max size 20 MB)</label>
            <input
              ref={hiddenFileInput}
              onChange={handleFileChange}
              type="file"
              id="file"
              accept=".pdf, .docx, .xls, .xlsx, .ppt, .pptx"
              style={{ display: 'none' }}
            />
            {link ? (
              <div className="add-document__uploaded-file">
                <div className="add-document__uploaded-file-container">
                  {fileError && (
                    <i className="add-document__file-error-icon bi bi-exclamation-triangle-fill" />
                  )}
                  <span className="add-document__uploaded-file-name">
                    {link}
                  </span>
                </div>
                {fileError && (
                  <span className="add-document__file-error-message">File is too big (max. 20 MB)</span>
                )}
                <Button
                  label="Reupload File"
                  size="small"
                  mode="primary"
                  handleClick={() => hiddenFileInput.current?.click()}
                />
              </div>
            ) : (
              <>
                <Button
                  label="Upload File"
                  size="medium"
                  mode="primary"
                  handleClick={() => hiddenFileInput.current?.click()}
                />
                {fileError && (
                  <span className="add-document__file-error-message">Please upload your document</span>
                )}
              </>
            )}
          </div>
          <div className="add-document__file-input add-document__form-field add-document__form-field--required">
            <Input
              type="text"
              id="title"
              label="Title"
              placeholder="e.g. Amazon Resume"
              value={title}
              handleValueChange={(value) => {
                setTitle(value);
                setTitleError(false);
              }}
              invalid={titleError}
              warningText="Please complete this field."
            />
          </div>
          <div className="add-document__file-input add-document__form-field add-document__form-field--required">
            <Select
              id="type"
              label="Document type"
              options={['Resume', 'Cover Letter', 'Other']}
              value={type || ''}
              isInvalid={typeError}
              handleValueChange={handleTypeChange}
              warningMessage="Please complete this field."
            />
          </div>
        </form>
      </div>
      <div className="add-document__actions">
        <Button
          handleClick={handleDiscardButton}
          label="Cancel"
          mode="primary"
          size="medium"
          outlined
        />
        <Button
          handleClick={handleSubmitButton}
          label={mode === 'add' ? 'Add' : 'Submit'}
          mode="primary"
          size="medium"
        />
      </div>
    </div>
  );
};
