import './info-box.scss';

interface InfoBoxProps {
  text: string;
  list?: string[];
  title?: string;
  type: 'normal' | 'bad example' | 'good example' | 'list';
}

/**
 * Primary UI component for User Information Box
 */
export const InfoBox = ({
  type = 'normal',
  text,
  title,
  list = [],
}: InfoBoxProps) => {
  const isBoxBadExample = type === 'bad example'
    ? 'careerOS-info-box--bad-example'
    : 'careerOS-info-box--normal';

  const isBoxGoodExample = type === 'good example'
    ? 'careerOS-info-box--good-example'
    : 'careerOS-info-box--normal';

  return (
    <div
      data-testid="info-box"
      className={['careerOS-info-box', isBoxBadExample, isBoxGoodExample].join(
        ' ',
      )}
    >
      {!!title && <div className="careerOS-info-box__title">{title}</div>}
      {!!text && <div className="careerOS-info-box__text">{text}</div>}
      {!!list.length && (
        <ul className="careerOS-info-box__list">
          {list.map((listItem: string) => (
            <li key={listItem}>{listItem}</li>
          ))}
        </ul>
      )}
    </div>
  );
};
