export const GenerateMessageOptions = [
  {
    name: 'Set up Coffee Chat',
    type: 'Follow-Ups',
  },
  {
    name: 'Coffee Chat Thank You',
    type: 'Thank You Notes',
  },
  {
    name: 'Coffee Chat Follow-up',
    type: 'Follow-Ups',
  },
  {
    name: 'Ask for Interview Tips',
    type: 'Follow-Ups',
  },
  {
    name: 'Application Follow-Up',
    type: 'Follow-Ups',
  },
  {
    name: 'Interview Thank You',
    type: 'Thank You Notes',
  },
  {
    name: 'Negotiating an offer',
    type: 'Offer Stage',
  },
  {
    name: 'Accepting an offer',
    type: 'Offer Stage',
  },
];
