import './style-guide.scss';

export default function StyleGuide() {
  return (
    <div id="style-guide">
      <h1>Style Guide</h1>
      <div className="career-os-grid">
        <div className="width-16/24">
          <div className="style-guide-blocks">
            <h2 className="style-guide-blocks__title">Display</h2>
            <div className="style-guide-blocks__block style-guide-blocks__block--display-large">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/display/large
                <code> @include display-large</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
            <div className="style-guide-blocks__block style-guide-blocks__block--display-medium">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/display/medium
                <code> @include display-medium</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
            <div className="style-guide-blocks__block style-guide-blocks__block--display-small">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/display/small
                <code> @include display-small</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
          </div>
          <div className="style-guide-blocks">
            <h2 className="style-guide-blocks__title">Headline</h2>
            <div className="style-guide-blocks__block style-guide-blocks__block--headline-large">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/headline/large
                <code> @include headline-large</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
            <div className="style-guide-blocks__block style-guide-blocks__block--headline-medium">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/headline/medium
                <code> @include headline-medium</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
            <div className="style-guide-blocks__block style-guide-blocks__block--headline-small">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/headline/small
                <code> @include headline-small</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
          </div>
          <div className="style-guide-blocks">
            <h2 className="style-guide-blocks__title">Title</h2>
            <div className="style-guide-blocks__block style-guide-blocks__block--title-large">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/title/large
                <code> @include title-large</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
            <div className="style-guide-blocks__block style-guide-blocks__block--title-medium">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/title/medium
                <code> @include title-medium</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
            <div className="style-guide-blocks__block style-guide-blocks__block--title-small">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/title/small
                <code> @include title-small</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
          </div>
          <div className="style-guide-blocks">
            <h2 className="style-guide-blocks__title">Body</h2>
            <div className="style-guide-blocks__block style-guide-blocks__block--body-large">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/body/large
                <code> @include body-large</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
            <div className="style-guide-blocks__block style-guide-blocks__block--body-medium">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/body/medium
                <code> @include body-medium</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
            <div className="style-guide-blocks__block style-guide-blocks__block--body-small">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/body/small
                <code> @include body-small</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
          </div>
          <div className="style-guide-blocks">
            <h2 className="style-guide-blocks__title">Label</h2>
            <div className="style-guide-blocks__block style-guide-blocks__block--label-large">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/label/large
                <code> @include label-large</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
            <div className="style-guide-blocks__block style-guide-blocks__block--label-medium">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/label/medium
                <code> @include label-medium</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
            <div className="style-guide-blocks__block style-guide-blocks__block--label-small">
              <div className="style-guide-blocks__block__block-title">
                CareerOS text styles/label/small
                <code> @include label-small</code>
              </div>
              <div className="style-guide-blocks__block__block-value">
                The quick brown fox jumps over the lazy dog.
              </div>
            </div>
          </div>
        </div>
        <div className="width-8/24">
          <div className="style-guide-blocks">
            <h2 className="style-guide-blocks__title">Colors</h2>
            <div className="style-guide-blocks__colors">
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--primary-container">
                --primary-container
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--error">
                --error
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--outline-variant">
                --outline-variant
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--surface-variant">
                --surface-variant
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--surface">
                --surface
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--background">
                --background
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--on-surface">
                --on-surface
              </div>

              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--tertiary90">
                --tertiary90
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--tertiary95">
                --tertiary95
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--tertiary40">
                --tertiary40
              </div>

              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral30">
                --neutral30
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral50">
                --neutral50
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral60">
                --neutral60
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral70">
                --neutral70
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral90">
                --neutral90
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral95">
                --neutral95
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral98">
                --neutral98
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral99">
                --neutral99
              </div>

              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral-variant10">
                --neutral-variant10
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral-variant20">
                --neutral-variant20
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral-variant30">
                --neutral-variant30
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral-variant40">
                --neutral-variant40
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral-variant50">
                --neutral-variant50
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral-variant70">
                --neutral-variant70
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral-variant90">
                --neutral-variant90
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral-variant95">
                --neutral-variant95
              </div>

              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--tone70">
                --tone70
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--tone90">
                --tone90
              </div>

              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--surface1">
                --surface1
              </div>

              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--primary">
                --primary
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--neutral">
                --neutral
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--secondary">
                --secondary
              </div>

              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--stepper-comepleted">
                --stepper-comepleted
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--stepper-active">
                --stepper-active
              </div>

              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--primary20">
                --primary20
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--primary40">
                --primary40
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--primary60">
                --primary60
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--primary80">
                --primary80
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--primary98">
                --primary98
              </div>
              <div className="style-guide-blocks__colors__color style-guide-blocks__colors__color--primary99">
                --primary99
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
