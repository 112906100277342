import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Loader } from '@/components/loader/loader';
import { useSelf, useSelfUniversity } from '@/services/queries/user';

import './styles/dashboard.scss';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';

const FORM_SPACING = 118;
function TallyPage() {
  const { data: self, isLoading: isSelfLoading } = useSelf();
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight - FORM_SPACING);
  const { data: university, isLoading: isUniversityLoading } = useSelfUniversity();
  const [searchParams, setSearchParams] = useSearchParams();
  const setHiddenFields = () => {
    // we need to move this in the redirect
    if (self && !searchParams.has('id')) {
      const { id, email, first_name: firstName } = self;
      const { name: school, cohort } = university || {};

      setSearchParams({
        user: id,
        email,
        first_name: firstName,
        school,
        cohort,
      });
    }
  };

  useEffect(() => {
    const widgetScriptSrc = 'https://tally.so/widgets/embed.js';
    const load = () => {
      // Load Tally embeds
      if (typeof Tally !== 'undefined') {
        Tally.loadEmbeds();
        return;
      }

      // Fallback if window.Tally is not available
      document
        .querySelectorAll('iframe[data-tally-src]:not([src])')
        .forEach((element) => {
          const iframeEl = element as HTMLIFrameElement;
          iframeEl.src = iframeEl.dataset.tallySrc || '';
        });
    };

    // If Tally is already loaded, load the embeds
    if (typeof Tally !== 'undefined') {
      load();
      return;
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement('script');
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
    }
  }, []);
  useEffect(() => {
    if (!isSelfLoading && !isUniversityLoading) {
      setHiddenFields();
    }
  }, [isSelfLoading, isUniversityLoading]);

  useEffect(() => {
    const updateHeight = () => {
      const height = window.innerHeight - FORM_SPACING;
      setIframeHeight(height);
    };

    // Initial height setting
    updateHeight();

    // Adding resize event listener
    window.addEventListener('resize', updateHeight);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);
  return (
    <div className="student-dashboard student-dashboard--form">
      <div className="career-os-grid">
        <div className="width-24/24">
          <div className="form-wrapper form-wrapper--learning">
            <iframe
              data-tally-src="https://tally.so/embed/wgLVjd?transparentBackground=1"
              loading="lazy"
              width="100%"
              height={iframeHeight}
              frameBorder={0}
              marginHeight={0}
              marginWidth={0}
              title="Hey @first_name, let's accelerate your career!"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(TallyPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
