import { Link } from 'react-router-dom';
import './link.scss';

export interface LinkProps {
  label: string;
  icon?: string;
  link: string;
  target?: React.HTMLAttributeAnchorTarget;
  onClick?: () => void;
}

/**
 * Primary UI component for the link button
 */
export const LinkButton = ({
  label,
  icon,
  link,
  target = '_blank',
  onClick,
}: LinkProps) => (
  <Link className="link-button" onClick={onClick} target={target} to={link}>
    {icon && (
      <i className={icon} data-testid="test-icon" />
    )}
    <span className="link-button__label">{label}</span>
  </Link>
);
