import { useContext, useEffect, useState } from 'react';
import { ModalContext } from '@/components/modal/modal-provider';
import { CustomTemplateEditor } from '../custom-template-editor/custom-template-editor';
import { ContactPlaceholders, TemplatePreviewType, TemplateType } from '../../types';
import { CustomTemplatesContainer } from '../custom-templates-container/custom-templates-container';

import './custom-templates-modal.scss';
import {
  deleteCustomTemplate, getCustomTemplates, saveCustomTemplate, updateCustomTemplate,
} from '@/services/api/template';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';

type Props = {
  contactPlaceholders: ContactPlaceholders;
  hasLinkedInPremium?: boolean;
  contactEmail?: string;
  previewType: TemplatePreviewType;
  selectTemplate: (message: string, subject?: string) => void;
};

export const CustomTemplatesModal = ({
  contactPlaceholders,
  previewType,
  selectTemplate,
  contactEmail,
  hasLinkedInPremium = false,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const { closeModal } = useContext(ModalContext) as any;
  const { addToast }: AddToastType = useContext(ToastContext);
  const [templates, setTemplates] = useState<TemplateType[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [isChangingTemplate, setIsChangingTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateType>();

  const getMaxLength = () => {
    if (previewType !== 'connection') {
      return undefined;
    }

    return hasLinkedInPremium ? 300 : 200;
  };

  const handleEditTemplate = (template?: TemplateType) => {
    setSelectedTemplate(template);
    setIsEditing(true);

    if (template) {
      setIsChangingTemplate(true);
    }
  };

  const getTemplatesFromApi = async () => {
    const response = await getCustomTemplates();

    if (response) {
      setTemplates(response);
    } else {
      setTemplates([]);
      handleEditTemplate();
    }

    setIsInitializing(false);
  };

  const useTemplate = (message: string, subject?: string) => {
    selectTemplate(message, subject);
  };

  const handleCloseEditor = () => {
    setIsEditing(false);
    setIsChangingTemplate(false);
  };

  const handleCancelEditing = () => {
    if (templates.length === 0) {
      closeModal();
    }

    handleCloseEditor();
  };

  const removeTemplate = async (templateId: string) => {
    try {
      await deleteCustomTemplate(templateId);
      await getTemplatesFromApi();
      trackEvent('Custom template deleted');
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Error while deleting template',
      });
    }
  };

  const handleSaveTemplate = async (title: string, message: string, subject?: string) => {
    try {
      if (isChangingTemplate) {
        const updatedTemplate = {
          subject: subject || '',
          message,
          title,
          id: selectedTemplate?.id || '',
        };

        await updateCustomTemplate(updatedTemplate);
        trackEvent('Custom template updated');
        setSelectedTemplate(updatedTemplate);
      } else {
        await saveCustomTemplate({ title, message, subject });
        trackEvent('Custom template created');
      }
      getTemplatesFromApi();
      handleCloseEditor();
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Error while saving template',
      });
    }
  };

  useEffect(() => {
    getTemplatesFromApi();
    trackEvent('Custom templates modal opened');
  }, []);

  return (
    <div className="templates-modal">
      <div className="templates-modal__header">
        <h2 className="templates-modal__title">
          Custom Templates
        </h2>
        <div
          className="templates-modal__close-button"
          onClick={closeModal}
          aria-label="Close"
          role="button"
          tabIndex={0}
        >
          <i className="templates-modal__close-icon bi bi-x-circle" />
          <i className="templates-modal__close-icon templates-modal__close-icon--hover bi bi-x-circle-fill" />
        </div>
      </div>

      {isInitializing && (
        <div className="templates-modal__loading">
          <div className="templates-modal__loading-spinner" />
        </div>
      )}

      {isEditing && !isInitializing && (
        <CustomTemplateEditor
          defaultContent={selectedTemplate?.message}
          defaultSubject={selectedTemplate?.subject}
          defaultTitle={selectedTemplate?.title}
          templateType={previewType}
          handleCancel={handleCancelEditing}
          handleSaveTemplate={handleSaveTemplate}
          contactPlaceholders={contactPlaceholders}
          maxLength={getMaxLength()}
          contactEmail={contactEmail}
        />
      )}

      {!isEditing && !isInitializing && (
        <CustomTemplatesContainer
          previewType={previewType}
          templates={templates}
          handleEditTemplate={handleEditTemplate}
          handleRemoveTemplate={removeTemplate}
          handleCancel={closeModal}
          handleUseTemplate={useTemplate}
          contactPlaceholders={contactPlaceholders}
          contactEmail={contactEmail}
          selectTemplate={setSelectedTemplate}
          selectedTemplate={selectedTemplate}
          maxLength={getMaxLength()}
        />
      )}
    </div>
  );
};
