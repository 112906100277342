import { useEffect, useState } from 'react';
import './select.scss';

interface SelectProps {
  value: string;
  id: string;
  label: string;
  options: string[];
  handleValueChange: (value: any) => void;
  isInvalid?: boolean;
  warningMessage?: string;
  placeholder?: string;
}
/**
 * Primary UI component for the Select
 */
export const Select = ({
  label,
  id,
  value = '',
  options = [],
  handleValueChange,
  placeholder,
  isInvalid = false,
  warningMessage,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOptionClick = (option: string) => {
    handleValueChange(option);
    setIsOpen(false);
  };
  const handleBlur = (e: any) => {
    if (!e.target.classList.contains('select-wrapper__options') && !e.target.classList.contains('select-wrapper__select')
    && !e.target.classList.contains('select-wrapper__option') && !e.target.classList.contains('select-wrapper__label')) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);

    return () => document.removeEventListener('mousedown', handleBlur);
  }, []);

  return (
    <div className="select-wrapper">
      <label className="select-wrapper__label" htmlFor={id} onClick={() => setIsOpen(!isOpen)}>
        {label}
      </label>
      <div
        className={`select-wrapper__select ${isOpen ? 'select-wrapper__select--open' : ''} ${!options.includes(value) ? 'select-wrapper__select--default' : ''} ${isInvalid ? 'select-wrapper__select--invalid' : ''}`}
        id={id}
        onClick={() => setIsOpen(!isOpen)}
      >
        {value || placeholder || 'Select an option'}
      </div>
      {isOpen && (
        <ul className="select-wrapper__options">
          {options.map(option => (
            <li
              key={option}
              className="select-wrapper__option"
              data-name={option}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
      {isInvalid && <span className="select-wrapper__warning">{warningMessage}</span>}
    </div>
  );
};
