import LinkedinIconActive from '@/assets/images/inbox/linkedin-banner-active.svg';
import MessageIcon from '@/assets/images/inbox/message-banner-inactive.svg';
import MessageIconActive from '@/assets/images/inbox/message-banner-active.svg';
import CoffeeIcon from '@/assets/images/inbox/coffee-banner-inactive.svg';
import CoffeeIconActive from '@/assets/images/inbox/coffee-banner-active.svg';
import DoneIcon from '@/assets/images/inbox/done-banner.svg';
import './chat-box-empty-state-banner.scss';

export enum Step {
  Step1 = 'Step 1',
  Step2 = 'Step 2',
  Step3 = 'Step 3',
}

const steps = [
  {
    icon: LinkedinIconActive,
    iconActive: LinkedinIconActive,
    iconDone: DoneIcon,
    name: Step.Step1,
    index: 0,
  },
  {
    icon: MessageIcon,
    iconActive: MessageIconActive,
    iconDone: DoneIcon,
    name: Step.Step2,
    index: 1,
  },
  {
    icon: CoffeeIcon,
    iconActive: CoffeeIconActive,
    iconDone: DoneIcon,
    name: Step.Step3,
    index: 2,
  },
];

const getMessageForStep = (stepIndex: number) => {
  switch (stepIndex) {
    case 0:
      return 'It’s time to reach out to your contact! Send them a personalized LinkedIn message below to start a conversation.';
    case 1:
      return (
        <div>
          <b>Congrats on reaching out to your contact! </b>
          We will notify you once the contact has responded. Prepare for your
          coffee chat with our tips and tricks here.
        </div>
      );
    case 2:
      return 'Outreach successful! Your contact has gotten back to you. It’s time to schedule a coffee chat!';
    default:
      return 'Sweet! You’ve scheduled a coffee chat. Feel free to keep networking with them to build a relationship and for useful insights.';
  }
};

const getStepIcon = (step: any, activeStep: number) => {
  switch (true) {
    case step.index < activeStep:
      return step.iconDone;
    case step.index === activeStep:
      return step.iconActive;
    default:
      return step.icon;
  }
};

type Props = {
  activeStep: number;
};

export const ChatBoxEmptyStateBanner = ({ activeStep }: Props) => (
  <div className="chat-box-empty-state-banner">
    <div className="chat-box-empty-state-banner__container">
      {steps.map((step) => {
        const stepIcon = getStepIcon(step, activeStep);
        return (
          <div
            key={step.index}
            className={`chat-box-empty-state-banner__step ${
              step.index === activeStep ? 'active' : ''
            }`}
          >
            <img
              className="chat-box-empty-state-banner__icon"
              src={stepIcon}
              alt={step.name}
            />
            <span className="chat-box-empty-state-banner__name">
              {step.name}
            </span>
          </div>
        );
      })}
    </div>
    <div className="chat-box-empty-state-banner__message">
      {getMessageForStep(activeStep)}
    </div>
  </div>
);
