import {
  useState, useRef, useEffect,
} from 'react';
import { getFilteredCompanies } from '../api/company';
import {
  Company, CompanyLocation, FilterCompaniesRequestBody, CompaniesFilterType,
} from '@/domains/core/company/types';

type Props = {
  defaultFilterAttribute?: string;
  defaultFilterValue?: string | boolean;
};

export const useCompaniesFiltering = ({ defaultFilterValue, defaultFilterAttribute }: Props) => {
  const [isPageEnd, setIsPageEnd] = useState(false);
  const [isError, setIsError] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [nextPage, setNextPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [filtersRequestBody, setFiltersRequestBody] = useState<FilterCompaniesRequestBody>({});
  const observerTarget = useRef<HTMLDivElement>(null);

  const fetchNextPageData = async () => {
    if (isPageEnd) {
      return;
    }

    setIsNewPageLoading(true);
    setIsError(false);

    try {
      const requestBody = (defaultFilterAttribute && defaultFilterValue)
        ? { [defaultFilterAttribute]: defaultFilterValue }
        : {};

      const requestBodyWithFilters = { ...filtersRequestBody, ...requestBody };

      const response = await getFilteredCompanies(requestBodyWithFilters, nextPage);

      if (!response || !response?.length) {
        setIsPageEnd(true);
        return;
      }

      const companiesSet = new Set([...companies, ...response]);

      setCompanies([...companiesSet]);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsNewPageLoading(false);
      setCurrentPage(nextPage);
    }
  };

  const fetchDataWithFilters = async (filters: FilterCompaniesRequestBody = {}) => {
    setNextPage(1);
    setCompanies([]);
    setIsPageEnd(false);
    setIsNewPageLoading(true);
    setIsError(false);
    setIsLoading(true);

    try {
      const requestBody = (defaultFilterAttribute && defaultFilterValue)
        ? { [defaultFilterAttribute]: defaultFilterValue }
        : {};

      const requestBodyWithFilters = { ...filters, ...requestBody };
      const response = await getFilteredCompanies(requestBodyWithFilters, 1);
      const companiesSet = new Set([...response]);

      setCompanies([...companiesSet]);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsNewPageLoading(false);
      setIsLoading(false);
      setCurrentPage(1);
    }
  };

  const handleFiltersChange = (
    filters?: CompaniesFilterType,
    savedByUser?: boolean | undefined,
    locations?: CompanyLocation[],
  ) => {
    const parsedFilters: FilterCompaniesRequestBody = Object.entries(filters || {})
      .reduce((acc: { [key: string]: any }, [key, value]) => {
        if (value.length > 0) {
          acc[key] = value.flatMap(v => v.value);
        }
        return acc;
      }, {});

    parsedFilters.saved_by_user = savedByUser;
    parsedFilters.locations = locations;

    setFiltersRequestBody(parsedFilters);
    fetchDataWithFilters(parsedFilters);
  };

  useEffect(() => {
    // to avoid double fetching data on initial render
    if (nextPage > currentPage && !isNewPageLoading) {
      const fetchData = async () => {
        await fetchNextPageData();
      };
      fetchData();
    }
  }, [nextPage, currentPage, isNewPageLoading]);

  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    if (!observer && observerTarget.current) {
      observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            setNextPage(prevPage => prevPage + 1);
          }
        },
        { threshold: 1 },
      );
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observer && observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget.current, companies.length]);

  return {
    companies,
    observerTarget,
    isNewPageLoading,
    isLoading,
    isError,
    handleFiltersChange,
  };
};
