import { useCallback, useMemo } from 'react';
import { Tag, TagColors } from '@/components/tag/tag';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { ActionButtonSave } from '@/components/action-buttons/action-button-save';
import { getTagIcons } from '../../helpers/getTagIcons';
import { JobFeedJob } from '../../types/job';
import { sortTags } from '@/services/helpers/job-feed';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { Tooltip } from '@/components/tooltip/tooltip';
import './job-tile.scss';

type Props = {
  job: JobFeedJob;
  isActive: boolean;
  isLoading: boolean;
  isSaved: boolean;
  onJobSaveToggle: (jobId: string) => void;
  onSelectActive: (jobId: string) => void;
};

export const JobTile = ({
  job,
  isActive,
  isSaved,
  isLoading,
  onJobSaveToggle,
  onSelectActive,
}: Props) => {
  const handleSave = useCallback(() => onJobSaveToggle(job.id), [onJobSaveToggle, job.id]);
  const sortedTags = useMemo(() => (job && job.tags ? sortTags(job.tags) : []), [job?.tags]);
  const postedDate = useMemo(() => formatDateDeltaFromNow(job.posted_on), [job.posted_on]);

  return (
    <div
      className={['job-tile', isActive ? 'job-tile--active' : ''].join(' ')}
      onClick={() => onSelectActive(job.id)}
    >
      <div className="job-tile__action" data-testid="job-tile-loader">
        <ActionButtonSave
          onClick={handleSave}
          isLoading={isLoading}
          iconName="bi-bookmark-heart"
          iconNameSaved="bi-bookmark-heart-fill"
          label="Save"
          labelSaved="Unsave"
          ariaLabel="Save Job"
          ariaLabelSaved="Unsave Job"
          isSaved={isSaved}
        />
      </div>
      <div className="job-tile__main">
        <Tooltip label={job.title} position="top">
          <p className="job-tile__title">{job.title}</p>
        </Tooltip>
        <div className="job-tile__company" data-testid="job-logo">
          <DefaultLogo
            source={job.linkedin_logo_url}
            type="job"
            name={job.company_name}
            className="job-tile__company-logo"
            size={24}
          />
          <p className="job-tile__company-name">{job.company_name}</p>
        </div>
        <p className="job-tile__posted-on">{postedDate}</p>
      </div>
      <div className="job-tile__bottom">
        {job?.tags && sortedTags.map((tag) => (
          <Tag
            key={tag.tag_type}
            color={TagColors.Grey}
            label={tag.tag_content}
            image={getTagIcons(tag.tag_type)}
          />
        ))}
      </div>
    </div>
  );
};
