import { getApplications, getArchivedApplications } from '@/services/api/application';
import { getCompaniesByName } from '@/services/api/company';
import { getFavoriteCompanies } from '@/services/api/favorite';

export async function searchLoader({ request }: any) {
  const url = new URL(request.url);
  const searchQuery = url.searchParams.get('query') || '';

  const [foundCompanies, getMyCompaniesResponse, tilesResponse, archivedCompanies] = await Promise.all([
    getCompaniesByName(searchQuery),
    getFavoriteCompanies(),
    getApplications(),
    getArchivedApplications(),
  ]);
  const myCompanies = Array.isArray(getMyCompaniesResponse) ? getMyCompaniesResponse.map((company: any) => company.id) : [];

  return {
    foundCompanies: Array.isArray(foundCompanies) ? foundCompanies : [], favoriteCompanies: Array.isArray(myCompanies) ? myCompanies : [], applications: Array.isArray(tilesResponse) ? tilesResponse : [], archivedCompanies: Array.isArray(archivedCompanies) ? archivedCompanies : [],
  };
}
