/* eslint-disable no-underscore-dangle */
import ReactGA from 'react-ga4';
import { Mixpanel } from './mixpanel';

const envCheck = import.meta.env.VITE_ENV === 'prod';

export function useAnalytics() {
  const trackEvent = (eventName: string, user?: any, properties?: any) => {
    if (!envCheck) return;
    try {
      Mixpanel.track(eventName, { ...properties, ...user });
      ReactGA.event({
        category: 'CareerOS App',
        action: eventName,
        value: 99, // optional, must be a number
        nonInteraction: false, // optional, true/false
      });
      if ((window as any).Appcues) {
        (window as any).Appcues.track(eventName, {
          ...properties,
        });
      }
      if ((window as any)._cio) {
        (window as any)._cio.track(eventName, properties);
      }
      if ((window as any).hj) {
        (window as any).hj('event', eventName);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { trackEvent };
}
