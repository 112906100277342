export const trackerTabs = ['Tips & Tricks', 'AI Tools', 'Contacts', 'Jobs', 'Notes', 'Company'];

const tools = [
  'Company Insights',
  'Learn About Roles',
  'Questions to ask in a Coffee Chat',
  'Interview Preparation',
];

export type AIToolsNames = typeof tools[number];

export type JobTabStates = 'addJobManually' | undefined;

export type ContactTabStates = 'addContact' | undefined;

export type TrackerTabsWithStates = {
  'Tips & Tricks': 'tips' | undefined,
  'AI Tools': AIToolsNames | undefined,
  Contacts?: ContactTabStates,
  Jobs: JobTabStates,
  Notes?: undefined,
  Company?: undefined,
};

export const defaultTrackerTabsState: TrackerTabsWithStates = {
  'Tips & Tricks': undefined,
  'AI Tools': undefined,
  Jobs: undefined,
};
