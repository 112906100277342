import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import DOMPurify from 'dompurify';
import { AIToolsNames } from '@/domains/core/tracker-data';
import './ai-tool-response.scss';
import { Button } from '@/components/button/Button';
import { addCompanyNote } from '@/services/api/company';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import {
  getCompanyInsights, getInterviewQuestions, getJobQuestions, getRoleInformation,
} from '@/services/api/ai';
import { prettifyHTML } from '@/services/helpers/processHtml';
import { Note } from '../../types';
import { AIToolLoader } from '../ai-tool-loader/ai-tool-loader';
import { AIToolError } from '../ai-tool-error/ai-tool-error';

type Props = {
  toolName: AIToolsNames;
  jobTitle?: string;
  companyID: string;
  companyName: string;
  handleNoteAdded: (note: Note) => void;
};

export const AIToolResponse = ({
  jobTitle,
  companyID,
  toolName,
  companyName,
  handleNoteAdded,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const { addToast }: AddToastType = useContext(ToastContext);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);
  const [regenerateCount, setRegenerateCount] = useState(0);
  const [isCopied, setIsCopied] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleCopyToClipboard = () => {
    if (!contentRef.current) {
      return;
    }

    const content = contentRef.current.innerText;
    navigator.clipboard.writeText(content)
      .then(() => setIsCopied(true))
      .catch(() => setIsCopied(false));

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleSaveNote = async () => {
    if (!response) {
      return;
    }
    const title = jobTitle ? `${toolName}: ${jobTitle}` : toolName;
    const addedNoteREsponse = await addCompanyNote(companyID, title, prettifyHTML(response));

    handleNoteAdded(addedNoteREsponse);
    trackEvent('Notes Added', {});

    addToast(
      {
        type: 'success',
        message: 'Note added successfully',
        additionalMessage: "This note is now saved to your 'Notes' section.",
      },
    );
  };

  const handleErrorResponse = () => {
    setIsError(true);
    setIsLoading(false);
  };

  const getToolData = async (tool: string) => {
    if (!tool) return;
    setResponse('');
    const defaultResponse = 'Brilliant move! Talking to someone who\'s already in the field is like getting a backstage pass to the concert of your career. Here are some insightful questions you might want to ask: After your chat with your contact, don\'t hesitate to buzz me up for some more career assistance, whether it\'s exploring roles, resume preparation, or interview prep. Go get \'em, future Strategy Manager at Adidas! 💪😄';
    setIsLoading(true);
    if (tool === 'Company Insights') {
      const insights = await getCompanyInsights(companyName);
      if (!insights || insights.error) {
        handleErrorResponse();
        return;
      }
      setResponse(insights);
      trackEvent('Company AI insights generated');
      setIsLoading(false);
      return;
    }
    if (tool === 'Learn About Roles' && jobTitle) {
      const roleInformation = await getRoleInformation(jobTitle, companyName);
      if (!roleInformation || roleInformation.error) {
        handleErrorResponse();
        return;
      }
      setResponse(roleInformation);
      trackEvent('Learn about roles AI insights generated');
      setIsLoading(false);
      return;
    }
    if (tool === 'Questions to ask in a Coffee Chat' && jobTitle) {
      const questions = await getJobQuestions(jobTitle, companyName);
      if (!questions || questions.error) {
        handleErrorResponse();
        return;
      }
      setResponse(questions);
      trackEvent('Questions to ask AI insights generated');
      setIsLoading(false);
      return;
    }
    if (tool === 'Interview Preparation' && jobTitle) {
      const interviewQuestions = await getInterviewQuestions(jobTitle, companyName);
      if (!interviewQuestions || interviewQuestions.error) {
        handleErrorResponse();
        return;
      }
      setResponse(interviewQuestions);
      trackEvent('Interview Preparation AI insights generated');
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    setResponse(defaultResponse);
  };

  const handleRegenerate = async () => {
    await getToolData(toolName);
    trackEvent('Tracker Page: AI answer regenerated');
    setRegenerateCount((prev) => prev + 1);
  };

  useEffect(() => {
    getToolData(toolName);
    setIsError(false);
  }, [toolName, jobTitle]);

  return (
    <div className={`ai-tool-response ${isError ? 'ai-tool-response--error' : ''}`}>
      {isLoading && (
        <AIToolLoader />
      )}
      {isError && (
        <AIToolError />
      )}
      {response && (
        <div className="ai-tool-response__response">
          <div className="ai-tool-response__actions">
            <div className={`ai-tool-response__copy-button ${isCopied ? 'ai-tool-response__copy-button--copied' : ''}`}>
              <Button
                mode="primary"
                size="medium"
                icon="bi bi-copy"
                outlined
                label="Copy to Clipboard"
                handleClick={handleCopyToClipboard}
              />
            </div>
            <Button
              mode="primary"
              size="medium"
              icon="bi bi-pencil"
              outlined
              label="Save as Note"
              handleClick={handleSaveNote}
            />
            {regenerateCount < 3 && (
              <Button
                mode="special"
                size="medium"
                icon="bi bi-stars"
                label="Regenerate"
                handleClick={handleRegenerate}
              />
            )}
          </div>
          <p ref={contentRef} className="ai-tool-response__response-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prettifyHTML(response)) }} />
        </div>
      )}
    </div>
  );
};
