import { Outlet } from 'react-router-dom';
import '../styles/university-login.scss';
import { ToastProvider } from '@/components/toast/toast-provider';

const LoginWithUniversityPage = () => (
  <ToastProvider>
    <div className="university-login">
      <div className="university-login__box">
        <div className="university-login__input-box">
          <Outlet />
        </div>
      </div>
    </div>

  </ToastProvider>
);

export default LoginWithUniversityPage;
