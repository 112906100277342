import './email-chat-skeleton.scss';

export const EmailMessageSkeleton = () => (
  <div className="email-thread-preview">
    <div className="email-thread-preview__row">
      <div className="email-thread-preview__recipients">
        <div className="email-thread-skeleton__text email-thread-skeleton__text--status" />
      </div>
      <div className="email-thread-preview__content">
        <div className="email-thread__subject">
          <div className="email-thread-skeleton__text email-thread-skeleton__text--status" />
        </div>
        -
        <div className="email-thread-preview__preview">
          <div className="email-thread-skeleton__text email-thread-skeleton__text--message" />
        </div>
      </div>
      <div className="email-thread-preview__timestamp">
        <div className="email-thread-skeleton__text email-thread-skeleton__text--status" />
      </div>
    </div>
  </div>
);
