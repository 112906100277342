import './table.scss';
import TableBody from './table-body';
import TableHead from './table-head';
import { useSortableTable } from './use-sort-table';
import { Column, TableRow } from './table-types';

type TableProps = {
  data: TableRow[];
  columns: Column[];
  withRowNumber?: boolean;
  isRowClickable?: boolean;
};

const Table = ({
  data,
  columns,
  withRowNumber = false,
  isRowClickable = false,
}: TableProps) => {
  const [tableData, handleSorting] = useSortableTable(data, columns);

  return (
    <table className="table">
      <TableHead columns={columns} handleSorting={handleSorting} withRowNumber={withRowNumber} />
      <TableBody
        isRowClickable={isRowClickable}
        columns={columns}
        tableData={tableData}
        withRowNumber={withRowNumber}
      />
    </table>
  );
};

export default Table;
