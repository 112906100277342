import './one-liner-banner.scss';

type Props = {
  text: string;
  handleClose?: () => void;
  mode?: 'warning' | 'info' | 'success';
};

export const OneLinerBanner = ({ text, handleClose, mode = 'info' }: Props) => (
  <div className={`one-liner-banner one-liner-banner--mode-${mode}`}>
    <p className="one-liner-banner__text">{text}</p>
    <div data-testid="one-liner-banner__close-icon" className="one-liner-banner__close-icon" onClick={handleClose}>
      <i className="bi bi-x-lg" />
    </div>
  </div>
);
