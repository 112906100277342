import './click-on-conversation-cta.scss';
import ChatCTA from '@/assets/images/illustrations/chat-cta.svg';

export const ClickOnConversationCTA = () => (
  <div className="click-on-conversation-cta">
    <h2 className="click-on-conversation-cta__title">Click on a conversation to start</h2>
    <div className="click-on-conversation-cta__image">
      <img src={ChatCTA} alt="Illustration to click on a chat box" />
    </div>
  </div>

);
