import {
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { searchJobs } from '../api/job';
import { JobFeedJob } from '@/domains/core/job/types/job';

type Prop = {
  currentTab: string | null;
  isLoading: boolean;
};

export const useSavedJobs = ({ currentTab, isLoading }: Prop) => {
  const [isPageSavedEnd, setIsPageSavedEnd] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [savedJobs, setSavedJobs] = useState<JobFeedJob[]>([]);
  const [totalJobsCountSaved, setTotalJobsCountSaved] = useState<number>(0);
  const [nextPageSaved, setNextPageSaved] = useState(1);
  const [currentPageSaved, setCurrentPageSaved] = useState(1);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [currentActiveId, setCurrentActiveId] = useState('');
  const observerTargetSaved = useRef<HTMLDivElement>(null);
  const isSmallScreen = () => window.innerWidth <= 720;

  const isSavedJobsTabSelected = useMemo(() => currentTab === 'Saved Jobs', [currentTab]);

  const updateCurrentActiveIdBasedOnScreen = (defaultId: string) => {
    if (!isSmallScreen()) {
      setCurrentActiveId(defaultId);
    } else {
      setCurrentActiveId('');
    }
  };

  const fetchNextPageDataSaved = async () => {
    if (isSavedJobsTabSelected && isPageSavedEnd) {
      return;
    }

    setIsNewPageLoading(true);
    setError(null);

    try {
      const { jobs: jobsResponse, total } = await searchJobs('', nextPageSaved, true);

      setTotalJobsCountSaved(total);

      if (!jobsResponse || !jobsResponse?.length) {
        setIsPageSavedEnd(true);
      }

      const newJobs = [...savedJobs, ...jobsResponse];

      if (!currentActiveId && jobsResponse.length > 0) {
        updateCurrentActiveIdBasedOnScreen(jobsResponse[0].id);
      }

      setSavedJobs(newJobs);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsNewPageLoading(false);
      setCurrentPageSaved(nextPageSaved);
    }
  };

  const fetchSavedJobs = async () => {
    setNextPageSaved(1);
    setSavedJobs([]);
    setIsPageSavedEnd(false);
    setIsNewPageLoading(true);
    setError(null);

    try {
      const { jobs: jobsResponse, total } = await searchJobs('', 1, true);

      setTotalJobsCountSaved(total);

      if (!jobsResponse || !jobsResponse?.length) {
        setIsPageSavedEnd(true);
        return;
      }

      if (!currentActiveId && jobsResponse.length > 0) {
        updateCurrentActiveIdBasedOnScreen(jobsResponse[0].id);
      }

      setSavedJobs(jobsResponse);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsNewPageLoading(false);
      setCurrentPageSaved(1);
    }
  };

  useEffect(() => {
    if (isSavedJobsTabSelected && nextPageSaved > currentPageSaved && !isNewPageLoading && !isPageSavedEnd) {
      fetchNextPageDataSaved();
    }
  }, [nextPageSaved, currentPageSaved, isNewPageLoading, isPageSavedEnd]);

  useEffect(() => {
    fetchSavedJobs();
  }, []);

  useEffect(() => {
    let observerSaved: IntersectionObserver | null = null;

    if (!observerSaved && observerTargetSaved.current) {
      observerSaved = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setNextPageSaved((prevPage) => prevPage + 1);
          }
        },
        { threshold: 1 },
      );
      observerSaved.observe(observerTargetSaved.current);
    }

    return () => {
      if (observerSaved && observerTargetSaved.current) {
        observerSaved.unobserve(observerTargetSaved.current);
      }
    };
  }, [observerTargetSaved.current, savedJobs.length, currentTab, isSavedJobsTabSelected, isLoading]);

  return {
    savedJobs,
    observerTargetSaved,
    isNewPageLoading,
    error,
    currentActiveId,
    totalJobsCountSaved,
    isPageSavedEnd,
    setCurrentActiveId,
    setTotalJobsCountSaved,
    setSavedJobs,
  };
};
