import { useState, useRef } from 'react';
import { Toast as ToastType } from '../types';

import Confetti from './confetti/confetti';
import Success from '@/assets/images/icons/success.svg';
import Warning from '@/assets/images/icons/warning.svg';
import Error from '@/assets/images/icons/error.svg';
import Info from '@/assets/images/icons/info.svg';
import RocketIconPurple from '@/assets/images/icons/rocket-icon-purple.svg';
import RocketIconBlack from '@/assets/images/icons/rocket-icon-black.svg';
import './toast.scss';

export interface ToastItemProps {
  toast: ToastType;
  onClose: () => void;
}

const toastIcons: { [key: string]: string } = {
  success: Success,
  warning: Warning,
  error: Error,
  info: Info,
  quest: RocketIconPurple,
  completed: RocketIconBlack,
};
const TOAST_CLOSING_ANIMATION_DURATION = 630;

function Toast({
  toast, onClose,
}: ToastItemProps) {
  const [isActive, setIsActive] = useState(true);
  const parentRef = useRef(null);
  const handleOnClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsActive(false);
    setTimeout(() => {
      onClose();
    }, TOAST_CLOSING_ANIMATION_DURATION);
  };

  return (
    <div className={`toast ${isActive ? 'toast--active' : ''} ${toast.handleClick ? 'toast--clickable' : ''}`} ref={parentRef} onClick={toast.handleClick}>
      {toast.type === 'completed' && <Confetti parentRef={parentRef} />}
      <div className="toast__button-close" onClick={handleOnClose}>&times;</div>
      <div className="toast__message">
        {toast.type in toastIcons && (<img className="toast__icon" src={toastIcons[toast.type]} alt={toast.type} />)}
        <p className="toast__title">{toast.message}</p>
        {toast.additionalMessage && (
        <div className="toast__additional-message">
          <p>{toast.additionalMessage}</p>
        </div>
        )}
      </div>
      <div className={`toast__progress-bar ${toast.autoClose ? `toast__progress-bar--active toast__progress-bar--mode-${toast.type}` : ''}`} />
    </div>
  );
}

export default Toast;
