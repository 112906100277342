import { captureException } from '@sentry/react';
import { LoginProviders } from '@/domains/generic/auth/types';

const samlConnectionMap: Record<string, string> = {
  SSOCircle: 'ssocircle',
  'University of Maryland': 'umd',
  'SDA Bocconi School of Management': 'bocconi',
};

export function useProviderAuth() {
  const getRedirectUrl = (provider: 'windowslive' | 'google-oauth2' | 'tcos' | 'samlp' | 'basic', universityName: string) => {
    const redirectUrl = new URL(`${import.meta.env.VITE_LOGIN_REDIRECT}-with-university`);

    const params = new URLSearchParams();

    params.append('provider', provider);
    params.append('university', encodeURIComponent(universityName));

    redirectUrl.search = params.toString();

    return `redirect_uri=${encodeURIComponent(redirectUrl.toString())}`;
  };

  const buildOAuthLoginURL = (provider: LoginProviders, accountType: 'organizations' | 'consumers', universityName: string, universityId?: string) => {
    const baseUrl = `https://${import.meta.env.VITE_DOMAIN}/authorize`;
    const responseType = 'response_type=code';
    const clientId = `client_id=${import.meta.env.VITE_CLIENT_ID}`;

    const connection = provider === 'waad' ? import.meta.env.VITE_AUTH_AZURE_ENTERPRISE_CONNECTION : provider;
    const redirectUri = getRedirectUrl(connection, universityName); // maybe not necessary to pass connection
    const connectionParam = `connection=${connection}`;

    const scope = 'scope=openid%20profile%20email';
    const domainHint = `domain_hint=${accountType}`;
    const userType = `user_type=${accountType}`;
    const universityIdParam = universityId ? `&university_id=${universityId}` : '';

    return `${baseUrl}?prompt=login&${responseType}&${clientId}&${redirectUri}&${connectionParam}&${scope}&${domainHint}&${userType}${universityIdParam}`;
  };

  const buildSAMLLoginURL = (universityName: string, universityId?: string) => {
    const baseUrl = `https://${import.meta.env.VITE_DOMAIN}/authorize`;
    const responseType = 'response_type=code';
    const clientId = `client_id=${import.meta.env.VITE_CLIENT_ID}`;

    // this should be only SAML. if we are receiving full uni name here we should map it to uni slug / connection name
    const connection = samlConnectionMap[universityName];
    const redirectUri = getRedirectUrl('samlp', universityName);
    const connectionParam = `connection=${connection}`;

    const scope = 'scope=openid%20profile%20email';
    const universityIdParam = universityId ? `&university_id=${universityId}` : '';

    return `${baseUrl}?&${clientId}&${responseType}&${connectionParam}&prompt=login&${scope}&${redirectUri}${universityIdParam}`;
  };

  const buildBasicAuthLoginURL = (universityName: string, universityId?: string) => {
    const baseUrl = `https://${import.meta.env.VITE_DOMAIN}/authorize`;
    const responseType = 'response_type=code';
    const clientId = `client_id=${import.meta.env.VITE_CLIENT_ID}`;

    const redirectUri = getRedirectUrl('basic', universityName);
    const connectionParam = 'connection=basic'; // shared connection for all basic auth

    const scope = 'scope=openid%20profile%20email';
    const universityIdParam = universityId ? `&university_id=${universityId}` : '';

    return `${baseUrl}?&${clientId}&${responseType}&${connectionParam}&prompt=login&${scope}&${redirectUri}${universityIdParam}`;
  };

  const loginWithProvider = (provider: LoginProviders, accountType: 'organizations' | 'consumers', universityName: string, universityId?: string) => {
    let loginUrl;

    switch (provider) {
      case 'google-oauth2':
      case 'windowslive':
      case 'waad':
        loginUrl = buildOAuthLoginURL(provider, accountType, universityName, universityId);
        break;
      case 'samlp':
        if (accountType === 'consumers') {
          captureException(new Error('SAML provider is not supported for consumers'));
        }
        loginUrl = buildSAMLLoginURL(universityName, universityId);
        break;
      case 'basic':
        loginUrl = buildBasicAuthLoginURL(universityName, universityId);
        break;
      default:
        throw new Error(`Unsupported provider ${provider}`);
    }

    window.location.href = loginUrl;
  };

  return { loginWithProvider };
}
