import { Button } from '@/components/button/Button';
import './outreach-scenarios.scss';
import { outreachScenarios } from './scenarios';
import { OutreachScenarioType } from '../../types';

type Props = {
  handleApplyTemplate: (scenario: OutreachScenarioType) => void;
  handleWriteMessageSelection: () => void;
  isLoading?: boolean;
};

export const OutreachScenarios = ({
  handleApplyTemplate,
  handleWriteMessageSelection,
  isLoading = false,
}: Props) => {
  if (isLoading) {
    return (
      <div data-testid="outreach-scenarios-loading" className="outreach-scenarios">
        <div className="outreach-scenarios__loading-animation" />
        <p className="outreach-scenarios__loading-text">Generating message...</p>
      </div>
    );
  }

  return (
    <div data-testid="outreach-scenarios" className="outreach-scenarios">
      <div className="outreach-scenarios__header">
        <h3 className="outreach-scenarios__title">
          What is your relationship with this contact?
        </h3>
        <p className="outreach-scenarios__subtitle">
          Let’s use AI to generate an amazing outreach message
        </p>
      </div>
      <ul data-testid="outreach-scenarios-options-list" className="outreach-scenarios__options-list">
        {outreachScenarios.map((scenario) => (
          <li className="outreach-scenarios__option">
            <Button
              mode="primary"
              size="small"
              label={scenario.name}
              icon={scenario.icon}
              outlined
              handleClick={() => handleApplyTemplate(scenario.value)}
            />
          </li>
        ))}
        <li className="outreach-scenarios__option">
          <Button
            mode="text"
            size="small"
            label="I want to write the message myself."
            handleClick={handleWriteMessageSelection}
          />
        </li>
      </ul>
    </div>
  );
};
