import './tracker-resources.scss';
import { ResourceTile } from '@/domains/core/student';

interface TrackerResourcesProps {
  resources: any[];
  university?: string;
}

export const TrackerResources = ({ resources, university }: TrackerResourcesProps) => (
  <div className="resources">
    <h3 className="resources__title">
      {university}
      {' '}
      Additional Resources
    </h3>
    {resources.map((resource: any) => (
      resource.forUniversities.includes(university) && (
        <div className="resources__resource">
          <ResourceTile
            name={resource.title}
            description={resource.description}
            image={resource.image}
            linkLabel={resource.buttonLabel}
            link={resource.link}
            linkTarget={resource.linkTarget}
          />
        </div>
      )
    ))}
  </div>
);
