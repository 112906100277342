import { getAllKPIs } from '@/services/api/admin';
import { getAllUniversities } from '@/services/api/university';
import { KPI } from '../types/kpi';

export async function adminLoader() {
  const universities = await getAllUniversities();
  const KPIs: KPI = await getAllKPIs();
  const {
    features, acquisition, activation,
  } = KPIs;
  return {
    universities: universities ?? [], features: features ?? [], acquisition, activation,
  };
}
