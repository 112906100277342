import { useEffect, useState } from 'react';
import { Input } from '@/components/input/input';
import { ContactPlaceholders, TemplatePreviewType, TemplateType } from '../../types';
import { CustomTemplateCard } from '../custom-template-card/custom-template-card';

import './custom-templates-container.scss';
import { CustomTemplatePreview } from '../custom-template-preview/custom-template-preview';
import { Button } from '@/components/button/Button';
import { resolveTemplatePlaceholdersWithContactData } from '@/services/helpers/processHtml';
import { useSelf } from '@/services/queries/user';
import { TemplatesEmptySearch } from '../custom-templates-empty-search/custom-templates-empty-search';

type Props = {
  previewType: TemplatePreviewType;
  selectedTemplate: TemplateType | undefined;
  selectTemplate: (template?: TemplateType) => void;
  templates: TemplateType[];
  handleRemoveTemplate: (id: string) => void;
  handleEditTemplate: (template?: TemplateType) => void;
  handleUseTemplate: (message: string, subject?: string) => void;
  handleCancel: () => void;
  contactPlaceholders: ContactPlaceholders;
  contactEmail?: string;
  maxLength?: number;
};

export const CustomTemplatesContainer = ({
  selectedTemplate,
  previewType,
  templates,
  contactPlaceholders,
  contactEmail,
  handleEditTemplate,
  handleRemoveTemplate,
  handleUseTemplate,
  handleCancel,
  selectTemplate,
  maxLength,
}: Props) => {
  const { data: self } = useSelf();
  const [filteredTemplates, setFilteredTemplates] = useState<TemplateType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [previewSubject, setPreviewSubject] = useState('');
  const [previewMessage, setPreviewMessage] = useState('');

  const filterTemplates = () => {
    const filteredTemplatesResult = templates
      .filter((template) => template.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
    const selectedTemplateIndex = filteredTemplatesResult.findIndex((template) => template.id === selectedTemplate?.id);

    if (selectedTemplateIndex === -1) {
      selectTemplate(filteredTemplatesResult[0]);
    }
    setFilteredTemplates(filteredTemplatesResult);
  };

  const handleUseTemplateClick = () => {
    if (!selectedTemplate) {
      return;
    }

    handleUseTemplate(previewMessage, previewSubject);
  };

  useEffect(() => {
    filterTemplates();
  }, [searchValue]);

  useEffect(() => {
    if (!selectedTemplate) {
      setPreviewMessage('');
      setPreviewSubject('');
      return;
    }

    setPreviewMessage(resolveTemplatePlaceholdersWithContactData(selectedTemplate.message, contactPlaceholders));
    setPreviewSubject(selectedTemplate.subject ? resolveTemplatePlaceholdersWithContactData(selectedTemplate.subject, contactPlaceholders) : '');
  }, [selectedTemplate?.message, selectedTemplate?.subject]);

  useEffect(() => {
    filterTemplates();
  }, [templates]);

  return (
    <div className="templates-container">
      <div className="templates-container__content">
        <div className="templates-container__search">
          <Input
            value={searchValue}
            id="templates-search"
            label=""
            placeholder="Search templates"
            handleValueChange={setSearchValue}
            icon="bi bi-search"
          />
        </div>
        <div className="templates-container__list">
          {filteredTemplates.length === 0 && (
            <TemplatesEmptySearch />
          )}
          {filteredTemplates.length > 0 && filteredTemplates.map((template) => (
            <CustomTemplateCard
              key={template.id}
              title={template.title}
              content={template.message}
              isSelected={selectedTemplate?.id === template.id}
              handleSelectTemplate={() => selectTemplate(template)}
              handleRemoveTemplate={() => handleRemoveTemplate(template.id)}
              handleEditTemplate={() => handleEditTemplate(template)}
            />
          ))}
        </div>
      </div>

      <div className="templates-container__preview">
        <CustomTemplatePreview
          template={previewMessage}
          subject={previewSubject}
          messageType={previewType}
          user={{
            name: `${self?.first_name} ${self?.last_name}`,
            picture: self?.profile_picture_url || '',
          }}
          contactEmail={contactEmail}
          withCTA={!!selectedTemplate}
          handleRemoveTemplate={() => selectedTemplate && handleRemoveTemplate(selectedTemplate.id)}
          handleEditTemplate={() => handleEditTemplate(selectedTemplate)}
          maxLength={maxLength}
          isEmpty={!selectedTemplate}
        />
      </div>

      <div className="templates-container__footer">
        <Button
          label="Create Custom Template"
          mode="blue"
          outlined
          icon="bi bi-pencil-square"
          size="medium"
          handleClick={() => handleEditTemplate(undefined)}
        />
        <div className="template-editor__cta">
          <Button
            label="Cancel"
            mode="primary"
            outlined
            size="medium"
            handleClick={handleCancel}
          />
          <Button
            label="Use Template"
            mode="primary"
            size="medium"
            disabled={!selectedTemplate}
            handleClick={handleUseTemplateClick}
          />
        </div>
      </div>
    </div>
  );
};
