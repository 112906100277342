import React from 'react';

import './loading-modal.scss';

type Props = {
  orientation: 'horizontal' | 'vertical';
  image?: string;
  title: string;
};

export const LoadingModal = ({
  orientation,
  image,
  title,
}: Props) => (
  <div className={`loading-modal info-modal--${orientation}`}>
    {image && (
      <div className="loading-modal__image">
        <i className={image} />
      </div>
    )}
    <div className="loading-modal__text">
      <h3 className="loading-modal__title">{title}</h3>
    </div>
    <div className="loading-modal__loader" />
  </div>
);
