import { OutreachScenarioType } from '../../types';

type OutreachType = {
  name: string;
  icon: string;
  value: OutreachScenarioType;
};

export const outreachScenarios: OutreachType[] = [
  {
    name: 'Cold Outreach',
    icon: 'bi bi-1-circle',
    value: 'cold_outreach',
  },
  {
    name: 'Met at in-person Career Fair',
    icon: 'bi bi-2-circle',
    value: 'meet_at_in_person_career_fair',
  },
  {
    name: 'Met at virtual Career Fair',
    icon: 'bi bi-3-circle',
    value: 'meet_at_virtual_fair',
  },
  {
    name: 'Contact is an alum',
    icon: 'bi bi-4-circle',
    value: 'contact_is_an_alum',
  },
  {
    name: 'Referral from Career Advisor',
    icon: 'bi bi-5-circle',
    value: 'referral_from_career_advisor',
  },
];

export const CatolicaTemplate = (firstName: string) => `Hi [First Name],

I really enjoyed our chat at Career Networking Monday in Consulting! I’m ${firstName}, a [year] student from [program]. I really found our talk insightful, especially learning about [points about the conversation].

I'd love to connect and learn more about the industry and your company.

Thanks so much!
`;
