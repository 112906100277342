import Quill from 'quill';

const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

class PlainClipboard extends Clipboard {
  onPaste(e: any) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const text = e.clipboardData?.getData('text/plain');
    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert(text);
    const index = text.length + range.index;
    const length = 0;
    this.quill.updateContents(delta, 'silent');
    this.quill.setSelection(index, length, 'silent');
    this.quill.scrollIntoView();
  }
}

Quill.register('modules/clipboard', PlainClipboard, true);
const block = Quill.import('blots/block');
block.tagName = 'div';
Quill.register(block);

const Link = Quill.import('formats/link');

Link.sanitize = (url: string) => {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`;
  }
  return url;
};

Quill.register(Link, true);
