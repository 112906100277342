import React from 'react';
import { useModal } from '@/services/hooks/use-modal';
// eslint-disable-next-line import/no-cycle
import Modal from './modal';

// eslint-disable-next-line import/no-mutable-exports
let ModalContext: any;
// eslint-disable-next-line no-multi-assign
const { Provider } = (ModalContext = React.createContext<any>(null));

const ModalProvider = ({ children }: any) => {
  const {
    showModal, openModal, modalContent, closeModal, modalNotDismissible,
  } = useModal();
  return (
    <Provider value={{
      showModal, openModal, modalContent, closeModal, modalNotDismissible,
    }}
    >
      <Modal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
