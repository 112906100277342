import DOMPurify from 'dompurify';
import './message-status.scss';

type Props = {
  isEmailMessage: boolean;
  isReceived: boolean;
  contactFirstName: string;
  subtitle: string;
  sender: string;
};

export const MessageStatus = ({
  isEmailMessage,
  isReceived,
  contactFirstName,
  subtitle,
  sender,
}: Props) => {
  const senderEmail = sender.match(/<(.*)>/)?.[1] || sender;
  const getStatus = () => {
    if (isEmailMessage) {
      return {
        title: senderEmail,
        subtitle,
      };
    }
    return {
      title: isReceived ? 'Received' : 'Sent',
      subtitle: isReceived ? `from ${contactFirstName}` : `to ${contactFirstName}`,
    };
  };
  return (
    <div data-testid="message-status" className="message-status">
      <span className="message-status__title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getStatus().title) }} />
      <span className="message-status__subtitle" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getStatus().subtitle) }} />
    </div>
  );
};
