import { useEffect, useState } from 'react';
import './hubspot-form.scss';

declare global {
  interface Window {
    hbspt: any;
  }
}

type Props = {
  id: string,
  submitText?: string,
  inlineMessage?: string,
  handleSubmit: (data: any) => void,
  hiddenFieldName?: string,
  handleFormReady?: () => void,
};

export default function HubSpotForm({
  id,
  submitText,
  inlineMessage,
  handleSubmit,
  handleFormReady,
  hiddenFieldName,
}: Props) {
  const [formData, setFormData] = useState<any>({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onFormReady = (form: any) => {
    if (hiddenFieldName) {
      const field = form.querySelector('input[name="school"]');

      if (field) {
        field.closest('.hs-form-field').style.display = 'none';
      }
    }
    handleFormReady?.();
  };

  const onFormSubmit = (form: any) => {
    setFormData(Object.fromEntries(new FormData(form)));
  };

  useEffect(() => {
    if (isSubmitted) {
      handleSubmit(formData);
    }
  }, [isSubmitted]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '8674894',
          formId: id,
          target: '#hubspotForm',
          submitText,
          cssRequired: '',
          inlineMessage,
          onFormSubmit,
          onFormReady,
          onFormSubmitted: setIsSubmitted,
        });
      }
    });
  }, []);

  return (
    <div id="hubspotForm" className="hubspot-form">
      {/* eslint-disable-next-line react/no-unknown-property */}
      <script charSet="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js" />
    </div>
  );
}
