import { Button } from '@/components/button/Button';
import './ai-tool-card.scss';
import { AIToolsNames } from '@/domains/core/tracker-data';

type Props = {
  toolName: AIToolsNames;
  handleButtonClick: () => void;
  companyName: string;
};

export const AIToolCard = ({ toolName, companyName, handleButtonClick }: Props) => {
  const toolDescriptions: { [key in AIToolsNames]: string } = {
    'Company Insights':
      'Learn more about the company’s core business model, recent developments and culture.',
    'Learn About Roles':
      `Generate insights into day-to-day activities that you would perform in your dream role at ${companyName}.`,
    'Questions to ask in a Coffee Chat':
      'Wondering what to ask in a coffee chat? Generate some meaningful questions for your contact.',
    'Interview Preparation':
      'Prepare for typical interview questions that you may face for this company and your target position.',
  };
  return (
    <div className="ai-tool-card">
      <div className="ai-tool-card__content">
        <h3 className="ai-tool-card__title">
          {toolName}
        </h3>
        <p className="ai-tool-card__description">
          {toolDescriptions[toolName]}
        </p>
      </div>
      <Button
        mode="special"
        label="Generate"
        icon="bi bi-stars"
        size="medium"
        handleClick={handleButtonClick}
      />
    </div>
  );
};
