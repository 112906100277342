/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useMemo, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { pdf } from '@react-pdf/renderer';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface MemoizedPDFDocumentProps {
  pdfUrl: string;
  numberOfPages: number | null;
  opt: any;
  onDocumentLoadSuccess: ({ numPages }: { numPages: number }) => void;
  updateOldResume: (url: string) => void;
}

const MemoizedPDFDocument: React.FC<MemoizedPDFDocumentProps> = React.memo(
  ({
    pdfUrl, numberOfPages, opt, onDocumentLoadSuccess, updateOldResume,
  }: any) => {
    const pages = useMemo(() => Array.from(
      new Array(numberOfPages),
      (el, index) => (
        <Page
          renderMode="canvas"
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          onRenderSuccess={() => updateOldResume(pdfUrl)}
        />
      ),
    ), [numberOfPages, pdfUrl, updateOldResume]);

    return (
      <Document
        options={opt}
        loading={null}
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        className="rendering-document"
      >
        {pages}
      </Document>
    );
  },
  (prevProps, nextProps) => prevProps.pdfUrl === nextProps.pdfUrl,
);

const PDFViewer = ({ children, onError, setNumberOfPages }: any) => {
  const [pdfUrl, setPdfUrl] = useState<any>(null);
  const [previousRenderValue, setPreviousRenderValue] = useState<any>(null);
  const [numberOfPages, setNumPages] = useState<any>(null);
  useEffect(() => {
    const child = React.Children.only(children);
    pdf(child).toBlob().then(blob => {
      setPdfUrl(URL.createObjectURL(blob));
    });
  }, [children.props.resume, children.props.title, children.props.highlightedWords, children.props.activeField, numberOfPages]);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
    setNumberOfPages(numPages);
    if (numPages > 3) {
      onError('The resume should be less than 3 pages');
    }
  }

  const opt = useMemo(() => ({
    cMapUrl: '/bcmaps/',
    cMapPacked: true,
  }), []);

  const updateOldResume = (url: string) => {
    setPreviousRenderValue(url);
  };

  return (
    <div className="renders">
      {previousRenderValue ? (
        <Document
          key={previousRenderValue}
          className="previous-document"
          file={previousRenderValue}
          loading={null}
        >
          <Page renderMode="canvas" key={1} pageNumber={1} />
        </Document>
      ) : null}
      {pdfUrl && (
        <MemoizedPDFDocument
          pdfUrl={pdfUrl}
          numberOfPages={numberOfPages}
          opt={opt}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          updateOldResume={updateOldResume}
        />
      )}
    </div>

  );
};

export default PDFViewer;
