import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useScroll } from '@/services/hooks/use-scroll';
import './contacts-kanban.scss';
import { Counter } from '@/components/counter/counter';
import { IconButton } from '@/components/icon-button/icon-button';
import { Contact } from '../../types';
import { ContactKanbanTile } from '@/domains/generic/kanban/contact-tile/contact-tile';

interface ContactsKanbanProps {
  columns: string[];
  layout: any;
  onChange: any;
}

export const ContactsKanbanBoard = ({
  columns,
  layout,
  onChange,
}: ContactsKanbanProps) => {
  const [kanbanContainer, handleVerticalScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(true);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);
  const navigate = useNavigate();

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;
    const allColumnsWidth = kanbanContainer.current.scrollWidth;
    const columnsInViewWidth = kanbanContainer.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }
    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  const onDragEnd = (result: any) => {
    const { source, destination, draggableId } = result;

    const payload = {
      id: draggableId,
      status: source.droppableId,
      dropPosition: destination.index,
    };
    onChange(payload, destination.droppableId);
  };

  return (
    <div className="kanban-board-contacts">
      <div className="controls-wrapper">
        <div className="kanban-board-contacts__controls">
          <IconButton
            icon="bi bi-chevron-left"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => handleVerticalScroll('left')}
            disabled={!showLeftScrollArrow}
          />
          <IconButton
            icon="bi bi-chevron-right"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => handleVerticalScroll('right')}
            disabled={!showRightScrollArrow}
          />
        </div>
      </div>
      <div className="kanban-board-contacts__content" ref={kanbanContainer} onScroll={handleScroll}>
        <DragDropContext onDragEnd={onDragEnd}>
          {columns.map((column, index) => (
            <div
              key={column}
              className={`kanban-board-contacts__column kanban-board-contacts__column--nr-${index}`}
            >
              <div className="kanban-board-contacts__title">
                <span>{column}</span>
                <span className="kanban-board-contacts__counter">
                  <Counter counter={layout[column] && layout[column].length} size="medium" mode="default" />
                </span>
              </div>

              <Droppable key={column} droppableId={column}>
                {(providedDrop) => (
                  <div
                    className="kanban-board-contacts__drop-zone"
                    ref={providedDrop.innerRef}
                    {...providedDrop.droppableProps}
                  >
                    {layout[column] && layout[column].map((contact: Contact, idx: number) => (
                      <Draggable key={contact.id} draggableId={contact.id} index={idx}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="kanban-board-contacts__tile">
                              <ContactKanbanTile
                                key={contact.id}
                                image={contact.career_summary.basics.image}
                                name={contact.career_summary.basics.name}
                                companyName={contact.current_position.company}
                                linkedInConnectionStatus={contact.linkedin_connection_status}
                                communicationChannel="email"
                                handleTileClick={() => navigate(`/app/inbox/${contact.id}`)}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {providedDrop.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
};
