import { University } from '@/domains/generic/auth/types';
import TAMULogo from '@/assets/images/university/tamu-logo.png';
import ESMTLogo from '@/assets/images/university/esmt-logo.png';
import ESADELogo from '@/assets/images/university/esade-logo.jpeg';
import MarylandLogo from '@/assets/images/university/maryland-logo.png';
import WHULogo from '@/assets/images/university/whu-logo.png';
import HECLogo from '@/assets/images/university/hec-logo.png';
import SchillerLogo from '@/assets/images/university/Schiller_International_University.png';
import TomorrowLogo from '@/assets/images/university/tomorrow-university-logo.png';
import MBSLogo from '@/assets/images/university/mbs-logo.png';
import PlaceholderLogo from '@/assets/images/university/university-placeholder-logo.png';
import CatolicaLogo from '@/assets/images/university/catolica.png';
import SouthCarolinaLogo from '@/assets/images/university/darla-moore-school-business-south-carolina-united-states.jpg';
import GeorgeTownLogo from '@/assets/images/university/gu.png';
import StandfordLogo from '@/assets/images/university/Standford_GSB.png';
import RiceLogo from '@/assets/images/university/rice-small.png';
import BocconiLogo from '@/assets/images/university/SDA-Bocconi-School-of-Managment.png';
import LondonBusinessSchoolLogo from '@/assets/images/university/London_Business_School.jpeg';
import Oxford from '@/assets/images/university/University of Oxford.png';
import UCD from '@/assets/images/university/UCD_Dublin.png';
import CBS from '@/assets/images/university/cbs.jpeg';
import Edinbourough from '@/assets/images/university/Uni of Edinburgh Logo.png';
import Georgia from '@/assets/images/university/georgia.png';
import Retterdam from '@/assets/images/university/rotterdam.jpeg';
import Leeds from '@/assets/images/university/Leeds Logo.jpg';
import ESSECLogo from '@/assets/images/university/essec.png';
import EBSLogos from '@/assets/images/university/ebs3.png';
import AMSLogo from '@/assets/images/university/AMS3.png';
import HHLLogo from '@/assets/images/university/HHL.png';
import BayesLogo from '@/assets/images/university/Bayes.png';
import ZepelinLogo from '@/assets/images/university/zepelin.png';
import ISEGLogo from '@/assets/images/university/ISEG.png';

export const universitiesLogos: { [key: string]: string } = { // TODO we need to make this dynamic, or have this in the API
  ESADE: ESADELogo,
  ESMT: ESMTLogo,
  'Schiller International University': SchillerLogo,
  'Texas A&M': TAMULogo,
  'Munich Business School': MBSLogo,
  HEC: HECLogo,
  'WHU – Otto Beisheim School of Management': WHULogo,
  'University of Maryland': MarylandLogo,
  Tomorrow: TomorrowLogo,
  'Católica Lisbon School of Business': CatolicaLogo,
  'Darla Moore School of Business, University of South Carolina': SouthCarolinaLogo,
  'Georgetown University': GeorgeTownLogo,
  'Stanford Graduate School of Business': StandfordLogo,
  'Rice University': RiceLogo,
  'SDA Bocconi School of Management': BocconiLogo,
  'London Business School': LondonBusinessSchoolLogo,
  'University College Dublin': UCD,
  'University of Oxford Saïd Business School': Oxford,
  'Copenhagen Business School': CBS,
  'The University of Edinburgh': Edinbourough,
  'University of Georgia': Georgia,
  'Rotterdam School of Management': Retterdam,
  'The University of Leeds': Leeds,
  'EBS Business School': EBSLogos,
  'Antwerp Management School': AMSLogo,
  'HHL - Leipzig Graduate School of Management': HHLLogo,
  'Bayes Business School': BayesLogo,
  'Zeppelin University': ZepelinLogo,
  ISEG: ISEGLogo,
  ESSEC: ESSECLogo,
};

export function mapTrialUniversitiesFromStrapiToGeneric(universities: any[]): University[] {
  return universities.map((university) => ({
    name: university?.attributes.name,
    logo: university?.attributes.logo?.data?.attributes.url || PlaceholderLogo,
    isTrial: true,
    id: university.id,
  }));
}

export function mapUniversitiesToGeneric(universities: any[]): University[] {
  return universities.map((university) => ({
    name: university.name,
    id: university.id,
    logo: universitiesLogos[university.name] || PlaceholderLogo,
    isTrial: false,
  }));
}
