import { useRef, useState } from 'react';
import { Button } from '@/components/button/Button';
import { DropdownList } from '@/components/dropdown-list/dropdown-list';

import './custom-templates-insert-button.scss';

type Props = {
  handleVariableInsert: (variable: string) => void;
};

export const CustomTemplatesInsertButton = ({ handleVariableInsert }: Props) => {
  const [isInsertOptionsVisible, setIsInsertOptionsVisible] = useState(false);
  const insertButtonRef = useRef<HTMLDivElement | null>(null);

  const handleOptionClick = (variable: string) => {
    handleVariableInsert(variable);
    setIsInsertOptionsVisible(false);
  };

  const handleOpenButtonClick = () => {
    setIsInsertOptionsVisible(!isInsertOptionsVisible);
  };

  const templateVaraibles = [
    {
      label: (
        <>
          <span className="insert-button__placeholder">[FirstName]</span>
          {' '}
          - First Name
        </>
      ),
      onClick: () => handleOptionClick('[FirstName]'),
    },
    {
      label: (
        <>
          <span className="insert-button__placeholder">[LastName]</span>
          {' '}
          - Last Name
        </>
      ),
      onClick: () => handleOptionClick('[LastName]'),
    },
    {
      label: (
        <>
          <span className="insert-button__placeholder">[Company]</span>
          {' '}
          - Company
        </>
      ),
      onClick: () => handleOptionClick('[Company]'),
    },
  ];

  return (
    <div ref={insertButtonRef} className="insert-button">
      {isInsertOptionsVisible && (
        <div className="insert-button__options">
          <DropdownList
            options={templateVaraibles}
            parentRef={insertButtonRef}
            handleClose={() => setIsInsertOptionsVisible(false)}
          />
        </div>
      )}
      <Button
        mode="primary"
        outlined
        size="medium"
        label="Insert Variable"
        icon={isInsertOptionsVisible ? 'bi bi-chevron-down' : 'bi bi-chevron-up'}
        iconPosition="right"
        onClick={handleOpenButtonClick}
        data-testid="insert-button"
      />
    </div>
  );
};
