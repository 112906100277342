import ManGesturingNo from '@/assets/images/man-gesturing-no.png';
import './ai-tool-error.scss';

export const AIToolError = () => (
  <div className="ai-tool-error">
    <img src={ManGesturingNo} alt="" className="ai-tool-error__image" />
    <div className="ai-tool-error__text">
      <h2 className="ai-tool-error__title">Our AI tool is experiencing some issues</h2>
      <p className="ai-tool-error__description">
        We were unable to generate insights for you at this time. Please try again later.
      </p>
    </div>
  </div>
);
