import { LinkType } from '@/pages/layout/sidebar/types/types';

import homeIcon from '@/assets/images/icons/nav/home-stroke.svg';
import homeFilledIcon from '@/assets/images/icons/nav/home-filled.svg';
import exploreIcon from '@/assets/images/icons/nav/building-stroke.svg';
import exploreFilledIcon from '@/assets/images/icons/nav/building-filled.svg';
import overviewIcon from '@/assets/images/icons/nav/board-stroke.svg';
import overviewFilledIcon from '@/assets/images/icons/nav/board-filled.svg';
import archiveIcon from '@/assets/images/icons/nav/archive-stroke.svg';
import archiveFilledIcon from '@/assets/images/icons/nav/archive-filled.svg';
import dashboardIcon from '@/assets/images/icons/nav/analytics-stroke.svg';
import dashboardFilledIcon from '@/assets/images/icons/nav/analytics-filled.svg';
import peopleIcon from '@/assets/images/icons/nav/people-two-stroke.svg';
import peopleFilledIcon from '@/assets/images/icons/nav/people-two-filled.svg';
import jobFeedIcon from '@/assets/images/icons/nav/briefcase-modern-stroke.svg';
import jobFeedFilledIcon from '@/assets/images/icons/nav/briefcase-modern-filled.svg';
import ResumeBuilderFilledIcon from '@/assets/images/icons/nav/file-earmark-fill.svg';
import ResumeBuilderIcon from '@/assets/images/icons/nav/file-earmark-stroke.svg';
import contactsIcon from '@/assets/images/icons/nav/person-one-stroke.svg';
import contactsFilledIcon from '@/assets/images/icons/nav/person-one-filled.svg';
import extensionIcon from '@/assets/images/icons/nav/puzzle-piece-stroke.svg';
import inboxIcon from '@/assets/images/icons/nav/chat-stroke.svg';
import inboxSelectedIcon from '@/assets/images/icons/nav/chat-filled.svg';

export const CareerAdvisorLinks: LinkType[] = [
  {
    label: 'Dashboard',
    url: 'dashboard-advisor',
    icon: dashboardIcon,
    iconSelected: dashboardFilledIcon,
    order: 0,
  },
  {
    label: 'Students',
    url: 'students',
    icon: peopleIcon,
    iconSelected: peopleFilledIcon,
    order: 1,
  },
];

export const AdminLinks: LinkType[] = [
  {
    label: 'Admin',
    url: 'admin-view',
    icon: homeIcon,
    iconSelected: homeFilledIcon,
    order: 0,
  },
];

export const StudentLinks: LinkType[] = [
  {
    label: 'Home',
    url: 'dashboard',
    icon: homeIcon,
    iconSelected: homeFilledIcon,
    order: 2,
  },
  {
    label: 'Overview',
    url: 'overview',
    icon: overviewIcon,
    iconSelected: overviewFilledIcon,
    order: 3,
  },
  {
    label: 'Companies',
    url: 'companies/explore',
    icon: exploreIcon,
    iconSelected: exploreFilledIcon,
    order: 7,
  },
  {
    label: 'Contacts',
    url: 'contacts',
    icon: contactsIcon,
    iconSelected: contactsFilledIcon,
    order: 5,
  },
  {
    label: 'Archive',
    url: 'archive',
    icon: archiveIcon,
    iconSelected: archiveFilledIcon,
    order: 9,
  },
  {
    label: 'Inbox',
    url: 'inbox',
    icon: inboxIcon,
    iconSelected: inboxSelectedIcon,
    order: 4,
  },
  {
    label: 'Chrome Extension',
    url: 'https://chromewebstore.google.com/detail/careeros-save-jobs-contac/ahkmpgpdcneppdhhdgmmmcgicgfcfmka?hl=en-GB',
    icon: extensionIcon,
    iconSelected: extensionIcon,
    isExternal: true,
    order: 100,
  },
  {
    label: 'Jobs',
    url: 'jobs',
    icon: jobFeedIcon,
    iconSelected: jobFeedFilledIcon,
    order: 6,
  },
  {
    label: 'ResumeOS',
    url: 'resume-os',
    icon: ResumeBuilderIcon,
    iconSelected: ResumeBuilderFilledIcon,
    order: 8,
  },
];

export const BetaUsersLinks: LinkType[] = [

];
