import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import HighFiveAnimation from '@/assets/images/highfive-animation.json';
import AddContactAnimation from '@/assets/images/add-contact-animation.json';
import { QuestType } from '@/domains/core/student/types';
import { Banner } from '@/components/banner/banner';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';

type Props = {
  lastCompletedQuests: QuestType[];
  activeQuests: QuestType[];
  companyID: string;
  hasContactResponded: boolean;
  hasUserSentMessage: boolean;
};

export const PendingState = ({
  lastCompletedQuests, activeQuests, hasContactResponded, hasUserSentMessage, companyID,
}: Props) => {
  const [type, setType] = useState<string>();
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;

  const getType = () => {
    if (activeQuests.includes(QuestType.AddNewContact) && !hasContactResponded) {
      setType('add-contact');
      return;
    }

    if (activeQuests.includes(QuestType.ScheduleCoffeeChat)) {
      setType('outreach-success');
      return;
    }

    if (!hasContactResponded && (lastCompletedQuests.includes(QuestType.SendFollowUpEmail) || lastCompletedQuests.includes(QuestType.SendMessage))) {
      setType('follow-up');
      return;
    }

    if (hasUserSentMessage && !hasContactResponded) {
      setType('reach-out');
      return;
    }

    setType(undefined);
  };

  const content: { [key: string]: any } = {
    'reach-out': {
      animation: HighFiveAnimation,
      title: 'Congrats on reaching out to your contact!',
      description: (
        <>
          We will notify you once the contact has responded or a follow-up is needed.
          In the meantime, prepare for your coffee chat with our
          {' '}
          <Link to="#coffeeChatTips">tips and tricks here</Link>
          .
        </>
      ),
    },
    'follow-up': {
      animation: HighFiveAnimation,
      title: 'Congrats on following-up with your contact!',
      description: (
        <>
          We will notify you once the contact has responded or a follow-up is needed.
          <br />
          In the meantime, prepare for your coffee chat with our
          {' '}
          <Link to="#coffeeChatTips">tips and tricks here</Link>
          .
        </>
      ),
    },
    'outreach-success': {
      animation: HighFiveAnimation,
      title: 'Outreach successful! Your contact has gotten back to you. It’s time to schedule a coffee chat!',
      description: (
        <>
          Get unique insights from your contact by asking them the right questions in a coffee chat.
          <br />
          Check out our coffee chat
          {' '}
          <Link to="#coffeeChatTips">tips and tricks here</Link>
          {' '}
          to prepare.
        </>
      ),
    },
    'add-contact': {
      animation: AddContactAnimation,
      title: 'Looks like you never heard back. No sweat!',
      description: (
        <>
          You have not heard back from this contact in a while. Let’s
          {' '}
          <span
            className="banner__linked-text"
            onClick={() => openTracker(companyID, 'Contacts')}
          >
            add a new contact
          </span>
          .
        </>
      ),
    },
  };

  useEffect(() => {
    getType();
  }, [lastCompletedQuests, activeQuests]);

  if (!type) {
    return null;
  }

  return (
    <Banner
      title={content[type].title}
      text={content[type].description}
      animation={content[type].animation}
      isButtonOutlined
    />
  );
};
