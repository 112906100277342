// It should come from the backend
export const USUniversities = [
  'Darla Moore School of Business, University of South Carolina',
  'Georgetown University',
  'Rice University',
  'Stanford Graduate School of Business',
  'Texas A&M',
  'University of Maryland',
  'University of Georgia',
];
