import { useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';
// eslint-disable-next-line import/no-cycle
import { SidebarContext } from './sidebar-provider';
import './sidebar.scss';

// TODO: find a better way to solve problem with root
const sidebarRoot = document.querySelector('#sidebar-root') as HTMLElement || document.querySelector('#storybook-root') as HTMLElement;
const Sidebar = () => {
  const {
    sidebarContent, showSidebar, closeSidebar,
  } = useContext(SidebarContext) as any;

  const closeOnEscapeKeyDown = (e: any) => {
    if ((e.charCode || e.keyCode) === 27) {
      closeSidebar();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);

  useEffect(() => {
    if (showSidebar) {
      document.body.classList.add('body-no-scroll');
    } else {
      document.body.classList.remove('body-no-scroll');
    }
  }, [showSidebar]);

  return createPortal(
    <div className={showSidebar ? 'sidebar-right show' : 'sidebar-right'} onClick={closeSidebar}>
      <div className="sidebar-right-content" onClick={(e) => e.stopPropagation()}>
        <div onClick={closeSidebar} className="sidebar-right-content__close">
          &times;
        </div>
        <div className="sidebar-right-body">{sidebarContent}</div>
      </div>
    </div>,
    sidebarRoot,
  );
};

export default Sidebar;
