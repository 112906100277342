import chromeIcon from '@/assets/images/chrome-icon.png';
import './job-banner.scss';

type Props = {
  header: string;
  text: string;
  isActive: boolean;
  onClick: () => void;
  type?: string;
};

export const JobBanner = ({
  header,
  text,
  isActive,
  onClick,
  type,
}: Props) => (
  <div
    className={['job-banner', isActive ? 'job-banner--active' : '', type ? 'job-banner--extension' : ''].join(' ')}
    onClick={onClick}
    data-testid="job-banner"
  >
    <div className="job-banner__image">
      <img src={chromeIcon} alt="Chrome Icon" />
    </div>
    <p className="job-banner__content">
      <span className="job-banner__content-bold">{header}</span>
      {' '}
      {text}
      {' '}
      <span className="job-banner__content-accent">
        Click here to learn more.
      </span>
    </p>
  </div>
);
