import ContactBoxSkeleton from './contact-box-skeleton';
import './contact-box-skeleton-container.scss';

export const ContactBoxSkeletonContainer = () => (
  <div className="contact-box-skeleton-container">
    <ContactBoxSkeleton />
    <ContactBoxSkeleton />
    <ContactBoxSkeleton />
    <ContactBoxSkeleton />
    <ContactBoxSkeleton />
    <ContactBoxSkeleton />
  </div>
);
