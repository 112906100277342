import { Button } from '@/components/button/Button';

type Props = {
  exensionIsInstalled: boolean;
  isLoggedIntoLinkedIn: boolean;
};

export const SuggestedContactsError = ({ exensionIsInstalled, isLoggedIntoLinkedIn }: Props) => {
  const renderText = () => {
    if (!exensionIsInstalled) {
      return 'Looks like you haven’t installed your Career Co-Pilot, yet. Only then can you receive suggested contacts for each company you’ve saved within CareerOS! ';
    }

    if (!isLoggedIntoLinkedIn) {
      return 'Log into LinkedIn to be able to save contacts and see your suggested contacts for this company! ';
    }

    return 'Failed to load suggested contacts. Make sure you have installed the CareerOS Extension and are signed in to your LinkedIn account.';
  };

  const renderButton = !exensionIsInstalled ? 'Install CareerOS Chrome Extension' : 'Log in now';

  const handleButtonClick = () => {
    if (!exensionIsInstalled) {
      window.open(import.meta.env.VITE_EXTENSION_LINK, '_blank');
    } else {
      window.open('https://www.linkedin.com/', '_blank');
    }
  };

  return (
    <div className="suggested-contacts__error">
      <span className="suggested-contacts__error-message">
        {renderText()}
      </span>
      {(!exensionIsInstalled || !isLoggedIntoLinkedIn) && (
        <Button
          mode="primary"
          size="medium"
          icon="bi bi-box-arrow-up-right"
          iconPosition="right"
          label={renderButton}
          handleClick={handleButtonClick}
        />
      )}
    </div>
  );
};
