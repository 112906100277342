import './benefit.scss';

interface BenefitProps {
  icon: string;
  topic: string;
  text: string;
}

export default function Benefit({ icon, topic, text }: BenefitProps) {
  return (
    <div className="benefit">
      <img className="benefit__icon" src={icon} alt="" />
      <div>
        <p className="benefit__topic">{topic}</p>
        <p className="benefit__text">
          {text}
        </p>
      </div>
    </div>
  );
}
