import { useEffect, useState } from 'react';

export function useModal() {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalNotDismissible, setModalNotDismissible] = useState(false);
  const [onCloseCallback, setOnCloseCallback] = useState<(() => void) | undefined>(undefined);

  const openModal = (content: any, shouldNotDissmiss = false, onClose?: () => void) => {
    setShowModal(true);
    if (content) {
      setModalContent(content);
      setModalNotDismissible(shouldNotDissmiss);
      setOnCloseCallback(() => () => onClose?.());
    }
  };

  const closeModal = () => {
    // eslint-disable-next-line no-restricted-globals
    history.replaceState({}, document.title, window.location.href.split('#')[0]);
    setShowModal(false);
    if (onCloseCallback) {
      onCloseCallback();
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      if (showModal) {
        setShowModal(false);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return {
    showModal, openModal, modalContent, closeModal, modalNotDismissible,
  };
}
