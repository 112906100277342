/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useContext, useState } from 'react';

type TabsContextType = {
  currentTab: string | null;
  setCurrentTab: any;
};
export const TabsContext = React.createContext<TabsContextType | null>(null);

export const withTabs: any = (Component: any) => ({ children, ...props }: any) => {
  const [currentTab, setCurrentTab] = useState(null);

  return (
    <TabsContext.Provider value={{ currentTab, setCurrentTab }}>
      <Component {...props}>{children}</Component>
    </TabsContext.Provider>
  );
};

export const useTabs = () => {
  const { currentTab, setCurrentTab } = useContext(
    TabsContext,
  ) as TabsContextType;

  if (!TabsContext) {
    throw new Error('useTabs should be used inside TabsProvider');
  }

  return {
    currentTab,
    setCurrentTab,
  };
};
