import './chip.scss';

const chipIconsByType = {
  size: 'bi bi-people',
  tag: 'bi bi-tag',
  location: 'bi bi-geo-alt',
  industry: 'bi bi-buildings',
};

export type ChipTypes = keyof typeof chipIconsByType;

type Props = {
  label: string;
  type: ChipTypes;
  handleClose: () => void;
};

export const Chip = ({ label, type, handleClose }: Props) => (
  <div className="careerOS-chip">
    <div className="careerOS-chip__icon">
      <i className={chipIconsByType[type]} />
    </div>
    <span className="careerOS-chip__label">{label}</span>
    <div className="careerOS-chip__close" onClick={handleClose}>
      <i className="bi bi-x" />
    </div>
  </div>
);
