import MonocleFaceImage from '@/assets/images/face-with-monocle.png';
import './custom-templates-empty-search.scss';

export const TemplatesEmptySearch = () => (
  <div className="templates-empty-search">
    <img src={MonocleFaceImage} alt="Face with monocle" className="templates-empty-search__image" />
    <h5 className="templates-empty-search__title">
      We could not find any results for your search.
    </h5>
    <p className="templates-empty-search__description">
      Please try a different template name or create a new custom template.
    </p>
  </div>
);
