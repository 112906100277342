import gmailIcon from '@/assets/images/icons/gmail-icon.svg';
import outlookIcon from '@/assets/images/icons/outlook-icon.svg';

import './choose-provider-modal.scss';

type Props = {
  orientation: 'horizontal' | 'vertical';
  image?: string;
  title: string;
  handleButtonClick: (provider: string, providerID: string) => void;
};

const microsoftProvider = 'Microsoft Outlook';
const googleProvider = 'Google/Gmail';

export const ChooseProviderModal = ({
  orientation,
  image,
  title,
  handleButtonClick,
}: Props) => (
  <div className={`choose-provider-modal info-modal--${orientation}`}>
    {image && (
      <div className="choose-provider-modal__image">
        <i className={image} />
      </div>
    )}
    <div className="choose-provider-modal__text">
      <h3 className="choose-provider-modal__title">{title}</h3>
    </div>
    <div className="choose-provider-modal__button-box">
      <button
        type="button"
        className="choose-provider-modal__button careerOS-button--outlined"
        onClick={() => handleButtonClick(googleProvider, 'google')}
      >
        <img className="choose-provider-modal__button-icon" src={gmailIcon} alt="" />
        <span>Google/Gmail</span>
      </button>

      <button
        type="button"
        className="choose-provider-modal__button careerOS-button--outlined"
        onClick={() => handleButtonClick(microsoftProvider, 'microsoft')}
      >
        <img className="choose-provider-modal__button-icon" src={outlookIcon} alt="" />
        <span>Microsoft Outlook</span>
      </button>
    </div>
  </div>
);
