import { SidebarCollapse } from '../collapse-button/collapse-button';
import { SidebarLinks } from '../sidebar-links/sidebar-links';
import { SidebarLogo } from '../sidebar-logo/sidebar-logo';
import { LinkType } from '../types/types';

import './sidebar-view.scss';

interface SidebarViewProps {
  collapsed: boolean;
  isCookieBannerVisible: boolean;
  showContent: boolean;
  logoLink: string;
  links: LinkType[];
  handleLinkClick: (link: string) => void;
  handleToggle: () => void;
}

export const SidebarView = ({
  collapsed,
  isCookieBannerVisible,
  showContent,
  logoLink,
  links,
  handleToggle,
  handleLinkClick,
}: SidebarViewProps) => (
  <aside
    className={[
      'sidebar',
      collapsed ? 'sidebar--collapsed' : '',
      isCookieBannerVisible ? 'sidebar--with-cookie-banner' : '',
    ].join(' ')}
  >
    {showContent && <SidebarLogo logoLink={logoLink} collapsed={collapsed} />}
    {showContent && (
      <nav className="sidebar__navigation">
        <SidebarLinks
          links={links}
          collapsed={collapsed}
          handleLinkClick={handleLinkClick}
        />
        <SidebarCollapse
          handleCollapse={handleToggle}
          isCollapsed={collapsed}
        />
      </nav>
    )}
  </aside>
);
