import { User } from '@auth0/auth0-react';
import { getLatestUrlFromStorage } from './browser-storage';
import { routeByRole } from './user';

export function getUserRouteByRoleAndLatestUrl(user: User): string {
  const latestAppUrl = getLatestUrlFromStorage();

  if (latestAppUrl) {
    return latestAppUrl;
  }

  return routeByRole(user);
}
