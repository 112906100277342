import { useState, useEffect } from 'react';
import './counter.scss';

interface CounterProps {
  counter: number;
  size: 'small' | 'medium' | 'large';
  mode: 'default' | 'blue' | 'warning' | 'danger' | 'white';
}

export const Counter = ({
  counter, size = 'medium', mode = 'default',
} : CounterProps) => {
  const [counterValue, setCounterValue] = useState(0);

  useEffect(() => {
    setCounterValue(counter);
  }, [counter]);

  return (
    <div className={`counter counter--size-${size} counter--mode-${mode}`}>
      <span>{counterValue}</span>
    </div>
  );
};
