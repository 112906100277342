import { useMemo } from 'react';
import { DropdownSearch } from '@/components/dropdown-search/dropdown-search';
import { CompanyLocation } from '../../types/company';
import { stringifyCompanyLocation } from './data-parser';
import { CompanyLocationWithName } from '../../types/companies-filtering';

type Props = {
  allLocations: CompanyLocation[];
  selectedLocations: CompanyLocationWithName[];
  handleLocationSelect: (filterValue: CompanyLocationWithName) => void;
};

export const CompaniesLocationFilter = ({
  selectedLocations,
  handleLocationSelect,
  allLocations,
}: Props) => {
  const nonSelectedLocations = useMemo<CompanyLocationWithName[]>(() => {
    const mappedLocations = allLocations.map(stringifyCompanyLocation);
    const mappedSelectedLocationsNames = selectedLocations.map((location) => location.name);

    return mappedLocations.filter((location) => !mappedSelectedLocationsNames.includes(location.name));
  }, [selectedLocations.length]);

  return (
    <DropdownSearch
      selectedOption=""
      options={nonSelectedLocations.map((location) => ({ name: location.name, value: location.value }))}
      handleItemSelect={(item, value) => handleLocationSelect({ name: item, value })}
      placeholder="Search office locations"
      name="loaction-search"
      id="location-search"
      inputIcon="bi bi-search"
      size="medium"
      canAddItems={false}
      showIcon={false}
      withMultipleSelection
      openOnClick
      listWidth="full"
    />
  );
};
