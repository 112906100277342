import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@/components/button/Button';

export const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    window.localStorage.removeItem('token');
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <Button handleClick={handleLogout} mode="primary" size="medium" label="Log out" />
  );
};
