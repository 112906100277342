import { Logo } from '@/components/logo/logo';
import { BenefitsList } from '../benefits-list/benefits-list';
import './benefits.scss';

export const Benefits = () => (
  <div id="main" className="benefits">
    <div className="benefits__wrapper">
      <div className="benefits__content">
        <div className="benefits__title">
          <Logo />
          <div className="benefits__subtitle-container">
            <div className="benefits__subtitle-container-inner">
              <div className="benefits__subtitle">
                where you land your dream job
              </div>
              <div className="benefits__subtitle">
                your executable career playbook
              </div>
              <div className="benefits__subtitle">
                that excel sheet but better
              </div>
              <div className="benefits__subtitle">
                your networking one stop shop
              </div>
              <div className="benefits__subtitle">
                more than just a job board
              </div>
            </div>
          </div>
        </div>
        <BenefitsList />
      </div>
    </div>
  </div>
);
