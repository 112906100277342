import { Company, Tile } from '@/domains/core/company/types';
import { http } from '@/services/api/http';

export const getApplications = async (): Promise<Tile[] | null> => http.get('/applications');

export const getStudentApplications = async (studentID: string) => http.get(`/applications/${studentID}`);

export const getArchivedApplications = async (): Promise<Company[] | null> => http.get('/applications/archive');

export const changeApplicationStatus = async (id: string, status: string, position: number) => {
  const response = await http.patch('/applications', {
    id,
    status,
    ordering: position,
  });

  return response;
};
