import { useEffect, useState } from 'react';
import './leaderboard.scss';
import PlaceholderLogo from '@/assets/images/university/university-placeholder-logo.png';
import { QRLeaderboardItemType } from '../../types/dashboard-response';
import { QRLeaderboardItem } from './qr-leaderboard-item';
import { universitiesLogos } from '@/services/helpers/university';

type Props = {
  universityName: string;
  students: QRLeaderboardItemType[];
};

type Student = {
  first_name: string;
  last_name: string;
  scanned_companies: number;
  rating: number;
};

const NUMBER_OF_STUDENTS_ABOVE = 3;
const NUMBER_OF_STUDENTS_BELOW = 3;

export const QRLeaderBoard = ({
  universityName, students,
}: Props) => {
  const [topThreeStudents, setTopThreeStudents] = useState<Student[]>([]);
  const [studentClosestCompetitors, setStudentClosestCompetitors] = useState<Student[]>([]);
  const [studentTopCompetitors, setStudentTopCompetitors] = useState<number>(0);
  const [studentBottomCompetitors, setStudentBottomCompetitors] = useState<number>(0);
  const universityLogo = universityName in universitiesLogos ? universitiesLogos[universityName] : PlaceholderLogo;

  const computeLeaderboard = () => {
    const mappedStudents = students
      .map((student, index) => ({ ...student, rating: index + 1 }));
    const currentStudentIndex = mappedStudents
      .findIndex((student) => student.first_name.length > 0 && student.last_name.length > 0);

    if (currentStudentIndex <= 3) {
      // if the current student is in the top 4 show simply 10 top students
      setStudentClosestCompetitors(mappedStudents.slice(3, mappedStudents.length > 10 ? 10 : mappedStudents.length));
      setTopThreeStudents(mappedStudents.slice(0, 3));
      setStudentTopCompetitors(0);
      setStudentBottomCompetitors(mappedStudents.length - 10);

      return;
    }

    const higherRatedUserIndex = currentStudentIndex - 3 > 2 ? currentStudentIndex - 3 : 3;
    const studentCompetitors = mappedStudents
      .slice(higherRatedUserIndex, higherRatedUserIndex + NUMBER_OF_STUDENTS_ABOVE + NUMBER_OF_STUDENTS_BELOW + 1);
    const studentTopCompetitorsNumber = higherRatedUserIndex - NUMBER_OF_STUDENTS_ABOVE;
    const studentBottomCompetitorsNumber = mappedStudents.length - (currentStudentIndex + NUMBER_OF_STUDENTS_BELOW) - 1;

    setStudentClosestCompetitors(studentCompetitors);
    setTopThreeStudents(mappedStudents.slice(0, 3));
    setStudentTopCompetitors(studentTopCompetitorsNumber);
    setStudentBottomCompetitors(studentBottomCompetitorsNumber);
  };

  useEffect(() => {
    computeLeaderboard();
  }, []);

  return (
    <div className="leaderboard">
      <div className="leaderboard__header">
        <div className="leaderboard__title">
          <span className="leaderboard__university-name">QR Scan</span>
          <span>Leaderboard</span>
        </div>
        <p className="leaderboard__description">
          <span
            className="leaderboard__quests-button"
          >
            Scan QR Codes
          </span>
          {' '}
          and climb the ladder ✨
        </p>
      </div>
      <ul className="leaderboard__students-list">
        {topThreeStudents.map((student) => (
          <QRLeaderboardItem
            key={student.rating}
            university={universityName}
            studentName={student.first_name.length ? `${student.first_name} ${student.last_name}` : undefined}
            rating={student.rating}
            completedScans={student.scanned_companies}
            universityLogo={universityLogo}
          />
        ))}
        {studentTopCompetitors > 0 && (
          <div className="leaderboard__split">
            <span className="leaderboard__split-number">
              {studentTopCompetitors}
            </span>
          </div>
        )}
        {studentClosestCompetitors.map((student) => (
          <QRLeaderboardItem
            key={student.rating}
            university={universityName}
            studentName={student.first_name.length ? `${student.first_name} ${student.last_name}` : undefined}
            rating={student.rating}
            completedScans={student.scanned_companies}
            universityLogo={universityLogo}
          />
        ))}
        {studentBottomCompetitors > 0 && (
          <div className="leaderboard__split">
            <span className="leaderboard__split-number">
              {studentBottomCompetitors}
            </span>
          </div>
        )}
      </ul>
    </div>
  );
};
