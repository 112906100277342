import { AddJobButton } from '@/domains/core/company/components/add-job-button/add-job-button';
import './jobs-not-found.scss';

export const JobsNotFound = () => (
  <div className="jobs-not-found">
    <div className="jobs-not-found__container">
      <div className="jobs-not-found__image">&#129335;</div>
      <div className="jobs-not-found__title">
        We could not find any results for your search.
      </div>
      <div className="jobs-not-found__subtitle jobs-not-found__subtitle--desktop">
        We couldn’t quite find jobs that match your filters. But don’t worry! You can also save jobs from other job boards using our
        {' '}
        <a
          href={import.meta.env.VITE_EXTENSION_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Chrome Extension.
        </a>
      </div>
      <div className="jobs-not-found__subtitle jobs-not-found__subtitle--mobile">
        We couldn’t quite find jobs that match your filters. But don’t worry. You can save more jobs using our Chrome Extension in our app on your desktop browser. Happy job hunting!
      </div>

      <AddJobButton
        buttonLabel="Save Job from other Job Boards"
        icon="bi bi-box-arrow-up-right"
      />
    </div>
  </div>
);
