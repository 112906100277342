/* eslint-disable @typescript-eslint/no-use-before-define */
import { Link } from 'react-router-dom';
import {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import { TableRowItem } from '../table-types';
import { Tooltip } from '../../tooltip/tooltip';
import './table-cell.scss';

type Props = {
  cellData: TableRowItem;
  type: string;
};

export const TableCell = ({ cellData, type }: Props) => {
  const [isTextOverflow, setIsTextOverflow] = useState(false);
  const cellTextRef = useRef<any>(null);

  const handleLinkClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (cellData.handleClick) {
      e.preventDefault();
      cellData.handleClick();
    }
  };

  useEffect(() => {
    if (cellTextRef.current) {
      setIsTextOverflow(cellTextRef.current.scrollWidth > cellTextRef.current.clientWidth);
    }
  }, [cellTextRef]);

  let content: ReactNode | string = '——';
  switch (type) {
    case 'text':
      content = (cellData !== null && cellData !== undefined) ? cellData.label : '——';
      break;
    case 'image':
      content = <img className="table__image" src={cellData.src} alt={cellData.alt || ''} />;
      break;
    case 'icon':
      content = <i className={`table-cell__icon ${cellData.src}`} />;
      break;
    case 'link-icon':
      content = (
        <Link onClick={(e) => e.stopPropagation()} to={cellData.to || ''} state={cellData.state}>
          <Tooltip label={cellData.tooltip || cellData.value?.toString()} withArrow position="top">
            <i className={`table-cell__icon ${cellData.icon}`} />
          </Tooltip>
        </Link>
      );
      break;
    case 'link':
      content = (
        <Link className="table-cell__link" onClick={handleLinkClick} to={cellData.to || ''} target={cellData.target || '_self'}>
          {cellData.image && cellData.image}
          <div className="table-cell__link-text" ref={cellTextRef}>{cellData.label}</div>
        </Link>
      );
      break;
    default:
      content = cellData.label || '——';
  }

  return (
    <td className="table-cell">
      <Tooltip label={cellData.value?.toString()} position="top" disableHoverListener={!isTextOverflow}>
        <span ref={cellTextRef} className="table-cell__content">{content}</span>
      </Tooltip>
    </td>
  );
};
