import { useEffect, useState } from 'react';
import './recent-messages.scss';
import { signInWithCustomToken } from 'firebase/auth';
import {
  collection, getDocs, limit, orderBy, query,
  where,
} from 'firebase/firestore';
import { getFirebaseUserToken } from '@/services/api/contact';
import { Tooltip } from '@/components/tooltip/tooltip';
import { RecentMessage } from './message';
import { auth, db } from '@/services/firebase/client';
import { Contact } from '../../types';
import { useSelf } from '@/services/queries/user';

export const LoadingMessages = () => (
  <div className="recent-messages">
    <div className="recent-messages__title">Recent Messages</div>
    <div className="recent-messages__loading">Your conversations are loading. This will take a minute, hang tight!</div>
  </div>
);

export const RecentMessages = ({ conversations, contacts }: any) => {
  const [messages, setMessages] = useState<any[]>([]);

  const getAllMessages = async (conversationsObject: any) => {
    // Sort conversations by lastMessageTimestamp in descending order
    const sortedConversations = conversationsObject.sort((a: any, b: any) => b.meta.lastMessageTimestamp.seconds - a.meta.lastMessageTimestamp.seconds
      || b.meta.lastMessageTimestamp.nanoseconds - a.meta.lastMessageTimestamp.nanoseconds);

    // TODO move to seperate function
    const uniqueConversationsIDs = new Set();
    const uniqueConversations = sortedConversations.filter((conversation: any) => {
      const { toContactID } = conversation.meta;
      if (uniqueConversationsIDs.has(toContactID)) {
        return false;
      }
      uniqueConversationsIDs.add(toContactID);
      return true;
    });

    const chatMessages = uniqueConversations.map((conversation: any) => {
      const user = contacts.find((userObject: any) => userObject && userObject?.id === conversation.meta.toContactID);
      return {
        ...conversation,
        user,
      };
    }).filter((conversation: any) => conversation.user);

    // Set the filtered and enriched conversations
    setMessages(chatMessages);
  };

  useEffect(() => {
    getAllMessages(conversations);
  }, [conversations]);

  return (
    <div className="recent-messages">
      <div className="recent-messages__title">
        <span>Recent Messages</span>
        <div className="recent-messages__icon-container">
          <Tooltip
            withArrow
            position="top"
            label="Answer recent messages from contacts you’re networking with"
          >
            <div className="recent-messages__icon">
              <i className="bi bi-info-circle" />
              <i className="bi bi-info-circle-fill" />
            </div>
          </Tooltip>
        </div>
      </div>
      {!messages && <div className="recent-messages__loading">Your conversations are loading. This will take a minute, hang tight!</div>}
      {messages && (
        <div className="recent-messages__scroll-container">
          <div className="recent-messages__messages">
            {messages.map((message: any) => (
              <RecentMessage
                key={message.id}
                contactId={message.user.id}
                company={message.user.current_position.company}
                contactImage={message.user.career_summary.basics.image}
                contactName={message.user.career_summary.basics.name}
                messageTimestamp={message.meta.lastMessageTimestamp}
                type={message.type}
                text={message.meta.lastMessage || 'You have a new message or email'}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export const EmptyConversation = () => (
  <div className="recent-messages">
    <div className="recent-messages__title">
      <span>Recent Messages</span>
      <div className="recent-messages__icon-container">
        <Tooltip
          withArrow
          position="top"
          label="Answer recent messages from contacts you’re networking with"
        >
          <div className="recent-messages__icon">
            <i className="bi bi-info-circle" />
            <i className="bi bi-info-circle-fill" />
          </div>
        </Tooltip>
      </div>
    </div>
    <div className="recent-messages__white-box">
      <div className="recent-messages__no-messages">No messages yet...</div>
      <div className="recent-messages__text">
        Save companies and contacts with our
        {' '}
        <a target="_blank" href="https://chromewebstore.google.com/detail/careeros-save-jobs-contac/ahkmpgpdcneppdhhdgmmmcgicgfcfmka?hl=en-GB" rel="noreferrer">Chrome Extension</a>
        {' '}
        to start networking
      </div>
    </div>
  </div>
);

export const Conversations = ({ contacts }: { contacts: Contact[] }) => {
  const [allConversations, setAllConversations] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { data: self, isLoading: isSelfLoading } = useSelf();

  const fetchAllEmailsAndLinkedin = async (contactIDs: string[]) => {
    setIsLoading(true);
    const token: string = await getFirebaseUserToken();
    await signInWithCustomToken(auth, token);
    const conversations: any = [];

    if (contactIDs.length && self) {
      const conversationPromises = contactIDs.map(async (contactId) => {
        const conversationId = `${self.id}:${contactId}`;
        const messagesRef = collection(db, 'outlookConversations', conversationId, 'messages');
        const queryForLatestMessage = query(messagesRef, orderBy('sentDateTime', 'desc'), limit(1));
        const messageSnapshot = await getDocs(queryForLatestMessage);

        if (!messageSnapshot.empty) {
          const latestMessageData = messageSnapshot.docs[0].data();
          conversations.push({
            id: latestMessageData.ID,
            meta: {
              toContactID: contactId,
              lastMessage: latestMessageData.text,
              lastMessageTimestamp: latestMessageData.sentDateTime,
              userID: self.id,
            },
            type: 'email',
          });
        }

        const conversationsRef = collection(db, 'linkedInConversations');
        const q = query(conversationsRef, where('toContactID', '==', contactId), where('userID', '==', self.id));
        const linkedInSnapshot = await getDocs(q);

        if (!linkedInSnapshot.empty) {
          const docSnap = linkedInSnapshot.docs[0];
          if (docSnap.exists()) {
            conversations.push({
              id: docSnap.id,
              meta: docSnap.data(),
              type: 'linkedin',
            });
          }
        }
      });

      await Promise.all(conversationPromises).then(() => setIsLoading(false));
      setAllConversations(conversations);
    }
  };

  useEffect(() => {
    if (contacts?.length && !isSelfLoading) {
      fetchAllEmailsAndLinkedin(contacts.map((contact) => contact.id));
    }
  }, [contacts?.length, isSelfLoading]);

  return (!isLoading ? <RecentMessages conversations={allConversations} contacts={contacts} /> : <LoadingMessages />);
};
