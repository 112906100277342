import { useMemo, useState } from 'react';
import DOMPurify from 'dompurify';

import './message.scss';
import { MessageHeader } from '../message-header/message-header';
import { extractEmailAddress } from '@/services/helpers/contact';
import { addBlankTargetToLinks, prettifyHTML } from '@/services/helpers/processHtml';
import { Message } from '@/domains/core/netwoking/types';
import { extractFirstThreadMessage } from '@/services/helpers/messages';

const sanitizeConfig = {
  ADD_ATTR: ['target'], // Allow the "target" attribute
};

interface Props {
  message: Message;
  contactFirstName: string;
  contactImage: string;
  handleReplyMessage: (subject?: string, messageID?: string, from?: string) => void;
  isRepliable: boolean
  isMessageFromUser?: boolean;
  contactEmail?: string;
  isInitialMessage?: boolean;
}

export const SingleMessage = ({
  message,
  contactFirstName,
  handleReplyMessage,
  contactImage,
  isRepliable,
  contactEmail,
  isMessageFromUser = false,
  isInitialMessage = false,
}: Props) => {
  const [messageOpen, setMessageOpen] = useState(isInitialMessage);
  const [isThreadOpen, setIsThreadOpen] = useState(false);
  const messagePreview = useMemo(() => extractFirstThreadMessage(message.text), [message.text]);
  const headerSubtitle = useMemo(() => (messageOpen ? `to ${message.to}` : messagePreview) || '', [message.to, messagePreview.length, messageOpen]);
  const isEmailMessage = message.type === 'email';

  const toggleOpen = () => setMessageOpen(!messageOpen);

  const toggleThread = () => setIsThreadOpen(!isThreadOpen);

  const handleReplyEmailClick = () => {
    const from = isMessageFromUser ? message.to : message.from;
    const emailAddresses = extractEmailAddress(from) || contactEmail || undefined;
    handleReplyMessage(message.subject, message.ID, emailAddresses);
  };

  const handleHeaderClick = () => {
    if (isInitialMessage) {
      return;
    }

    toggleOpen();
  };

  const handleReplyClick = () => {
    if (isEmailMessage) {
      handleReplyEmailClick();
    } else {
      handleReplyMessage();
    }
  };

  return (
    <div className={`message message--${message.fromContactID ? 'from ' : 'to '}`}>
      <div className={`message__header ${isInitialMessage ? '' : 'message__header--clickable'}`} onClick={handleHeaderClick}>
        <MessageHeader
          messageType={message.type}
          isReceived={!isMessageFromUser}
          isRepliable={messageOpen && (isEmailMessage || isRepliable)}
          contactFirstName={contactFirstName}
          contactImage={contactImage}
          subtitle={headerSubtitle}
          title={message.from || ''}
          messageCreatedAt={message.createdAt}
          handleReplyClick={handleReplyClick}
          isReplyButtonExpanded={isInitialMessage}
        />
      </div>
      <div className="message__content">
        {messageOpen && (
          <div
            className={`message__text ${messageOpen ? 'message__text--open' : ''}`}
          >
            <div
              data-testid="message-text"
              dangerouslySetInnerHTML={{
                __html: isEmailMessage
                  ? DOMPurify.sanitize(addBlankTargetToLinks(message.text), sanitizeConfig)
                  : DOMPurify.sanitize(addBlankTargetToLinks(prettifyHTML(message.text)), sanitizeConfig),
              }}
            />
            <div data-testid="message-toggle" className="message__toggle" onClick={toggleThread}>
              <i className="bi bi-three-dots" />
            </div>
            <div
              className={`message__thread ${isThreadOpen ? 'message__thread--open' : ''}`}
              dangerouslySetInnerHTML={{
                __html: isEmailMessage
                  ? DOMPurify.sanitize(addBlankTargetToLinks(message.text), sanitizeConfig)
                  : DOMPurify.sanitize(addBlankTargetToLinks(prettifyHTML(message.text)), sanitizeConfig),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
