import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ModalTip from './tooltip-modal';
import { OnboardingProgress } from '@/domains/generic/modals';
import { onboardingStepDescriptions } from '@/domains/generic/modals/onboarding-tip/onboarding-description';

// eslint-disable-next-line import/no-mutable-exports
let OnboardingContext: any;
// eslint-disable-next-line no-multi-assign
const { Provider } = (OnboardingContext = React.createContext<any>(null));

const OnboardingProvider = ({ children }: any) => {
  const { pathname } = window.location;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInProgress, setIsInProgress] = useState(false);
  const [progress, setProgress] = useState<OnboardingProgress>({ step: 1, stage: 'app' });

  const startOnboarding = (updatedProgress: OnboardingProgress) => {
    setProgress(updatedProgress);
  };

  const completeOnboarding = () => {
    setIsInProgress(false);
    localStorage.setItem('completedOnboarding', 'true');
    setSearchParams();
  };

  const moveToNextStep = () => {
    const isLastStepOfStage = +Object.keys(onboardingStepDescriptions[progress.stage])[Object.keys(onboardingStepDescriptions[progress.stage]).length - 1] === progress.step;

    switch (progress.stage) {
      case 'app':
        if (isLastStepOfStage) {
          completeOnboarding();
        } else {
          setProgress(() => ({ ...progress, step: progress.step + 1 }));
        }
        break;
      case 'extension':
        if (isLastStepOfStage) {
          setProgress({ step: 1, stage: 'app' });
        } else {
          setProgress({ ...progress, step: progress.step + 1 });
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (searchParams.get('SHOW_ONBOARDING') && !localStorage.getItem('completedOnboarding')) {
      setIsInProgress(true);
    } else {
      setIsInProgress(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (pathname.includes('/app/overview/') && pathname.length > '/app/overview/'.length) {
      setProgress({ step: 1, stage: 'app' });
    } else if (pathname.includes('/app/overview')) {
      setProgress({ stage: 'extension', step: 5 });
    } else {
      setIsInProgress(false);
    }
  }, [pathname]);

  return (
    <Provider value={{
      onboardingProgress: progress,
      isOnboardingInProgress: isInProgress,
      moveToNextOnboardingStep: moveToNextStep,
      startOnboarding,
    }}
    >
      <ModalTip />
      {children}
    </Provider>
  );
};

export { OnboardingContext, OnboardingProvider };
