import { useContext, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { LoginLoaderResponse, LoginProviders, University } from '@/domains/generic/auth/types';
import { DropdownSearch } from '@/domains/generic/auth';
import { Button } from '@/components/button/Button';
import LogoImage from '@/assets/images/new-logo.svg';
import { useProviderAuth } from '@/services/hooks/use-provider-auth';
import { router } from '@/main';

import '../styles/login-box-form.scss';
import { waitlistHubspotFormId } from './waitlist-form';
import { getUniversityProviders } from '@/services/public-api/universities';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';

export default function SearchUniversity() {
  const { addToast }: AddToastType = useContext(ToastContext);
  const { trackEvent } = useAnalytics();
  const { universities, selectedUniversity } = useLoaderData() as LoginLoaderResponse;
  const { loginWithProvider } = useProviderAuth();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUniversityName, setSelectedUniversityName] = useState('');

  const handleUniversitySelect = (universityName: string) => {
    setSelectedUniversityName(universityName);
    setIsSubmitted(false);
  };

  const checkLoginError = () => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get('error');

    if (error) {
      addToast({
        type: 'error',
        message: 'An error occurred while logging in.',
        additionalMessage: error,
        autoClose: false,
      });

      trackEvent('Toast Error Shown', {}, {
        message: 'An error occurred while logging in',
        error,
      });
    }
  };

  const redirectToProviderSelect = (university: string) => router.navigate(`/with-university/provider-select?school=${encodeURIComponent(university)}`);

  const getUniversityLoginProviders = async (universityId: string): Promise <LoginProviders[] | null> => {
    const providers = await getUniversityProviders(universityId);

    if (!providers) {
      addToast({
        type: 'error',
        message: `No sign in provider associated with institution ${selectedUniversityName}.`,
        additionalMessage: 'Please try again later or choose a different institution.',
        autoClose: false,
      });

      trackEvent('Toast Error Shown', {}, {
        message: `No sign in provider associated with institution ${selectedUniversityName}`,
        error: 'No providers found',
      });

      return null;
    }

    // fix for Maryland - remove gmail provider from the response to not render the button.
    // It is used only for legacy logins
    if (universityId === '81cd0f3d-5ce0-497c-9da0-ad25cb6823fa') {
      return ['samlp'];
    }

    return providers;
  };

  const handleSelectedUniversitySubmit = async (university?: University, cohortName?: string | null) => {
    if (!university) {
      router.navigate(`/with-university/hubspot?school=${encodeURIComponent(selectedUniversityName)}&hs_form_id=${waitlistHubspotFormId}`);
      return;
    }

    localStorage.setItem('providerUniversity', JSON.stringify(university));
    if (university.isTrial) {
      router.navigate(`/with-university/code-check?school=${encodeURIComponent(selectedUniversityName)}${cohortName ? `&cohort=${encodeURIComponent(cohortName)}` : ''}`);
      return;
    }

    const loginProviders = await getUniversityLoginProviders(university.id);

    if (!loginProviders) {
      return;
    }

    if (loginProviders.length > 1) {
      redirectToProviderSelect(university.name);
      return;
    }

    localStorage.setItem('provider', JSON.stringify(loginProviders[0]));
    loginWithProvider(loginProviders[0], 'organizations', university.name, university.id);
  };

  const handleSubmitButtonClick = () => {
    if (selectedUniversityName.length === 0) {
      setIsSubmitted(true);
      return;
    }

    const selected = universities.find((sch) => sch.name === selectedUniversityName);
    handleSelectedUniversitySubmit(selected);
    trackEvent('Login: Continue Button on welcome page clicked', {}, { chosenInstitution: selectedUniversityName, institutionExists: !!selected });
  };

  function getCohortFromLocalStorage(): string | null {
    return window.localStorage.getItem('cohort');
  }

  function getUniversityFromLocalStorage(): string | null {
    return window.localStorage.getItem('university_name');
  }

  function extractFromLegacyStorage() {
    const uniCohort = window.localStorage.getItem('university');

    if (!uniCohort) {
      return null;
    }

    const uniCohortObj = JSON.parse(uniCohort);

    if (!uniCohortObj) {
      return null;
    }

    // Start with the university key always included
    const data: { university: string | null; cohort: string | null } = {
      university: uniCohortObj.university,
      cohort: null,
    };

    // If uniCohortObj.cohort is not null, add it to the data object
    if (uniCohortObj.cohort !== null && uniCohortObj.cohort !== 'null') {
      data.cohort = uniCohortObj.cohort;
    }

    return data;
  }

  const redirectToProviderIfUniversityInCookie = async () => {
    let universityName = getUniversityFromLocalStorage();
    let cohortName = getCohortFromLocalStorage();

    if (!universityName || !cohortName) {
      const uniCohort = extractFromLegacyStorage();

      if (uniCohort) {
        if (!universityName) universityName = uniCohort.university;
        if (!cohortName) cohortName = uniCohort.cohort;
      }
    }

    if (universityName === 'Trial') {
      return;
    }

    if (!universityName) {
      return;
    }

    // retrieving full university object from the list by its name from local storage
    const university = universities.find((sch) => sch.name === universityName);
    if (!university) {
      return;
    }

    setSelectedUniversityName(university.name);

    const loginProviders = await getUniversityLoginProviders(university.id);

    if (!loginProviders || loginProviders.length === 0) {
      addToast(
        {
          type: 'error',
          message: 'An error occurred while logging in.',
          additionalMessage: 'Please try again later.',
          autoClose: false,
        },
      );

      trackEvent('Toast Error Shown', {}, {
        message: 'An error occurred while logging in',
        error: 'No providers found',
      });

      return;
    }

    if (loginProviders.length > 1) {
      redirectToProviderSelect(university.name);
      return;
    }

    loginWithProvider(
      loginProviders[0],
      'organizations',
      university.name,
      university.id,
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !!selectedUniversityName) {
      handleSubmitButtonClick();
    }
  };

  useEffect(() => {
    checkLoginError();
    setIsLoading(true);
    redirectToProviderIfUniversityInCookie();

    if (selectedUniversity) {
      handleSelectedUniversitySubmit(selectedUniversity);
    }

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <div onKeyDown={handleKeyDown} className="login-form">
      <div className="login-form__header">
        <img
          src={LogoImage}
          alt=""
          className="login-form__image"
        />
        <h1 className="login-form__title">
          Welcome to CareerOS
        </h1>
      </div>
      <DropdownSearch
        name="university"
        selectedOption={selectedUniversityName}
        id="university-search"
        options={universities}
        label={selectedUniversityName.length > 0 ? 'Your institution' : 'Search for your institution'}
        placeholder="Type your institution..."
        warningText="Please choose or add your institution first."
        invalid={isSubmitted && selectedUniversityName.length === 0}
        handleItemSelect={handleUniversitySelect}
      />

      <div className={`login-form__button ${isSubmitted ? 'login-form__button--disabled' : ''}`}>
        <Button
          mode="primary"
          size="fill"
          label="Continue"
          disabled={isSubmitted || selectedUniversityName.length === 0}
          handleClick={handleSubmitButtonClick}
        />
      </div>
    </div>
  );
}
