import { http } from '@/services/api/http';

export const registerIfNotExists = (accessToken: string) => http.post('/login', { token: accessToken });

// This API call signature should not accept null university name best-practice wise.
// BUT considering that/;
//  - it is a part of login
//  - we don't have client observability,
//  we are sending null values to the backend, so it will fail validation check, and then we will see the problem
//  in logs/Slack channel
export const enroll = (universityName: string | null) => {
  const reqBody = {
    university: universityName,
  };

  return http.post('/users/enroll', reqBody);
};
