/* eslint-disable no-restricted-syntax */
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';

import {
  useContext, useEffect, useState,
} from 'react';
import { Loader } from '@/components/loader/loader';
import './styles/dashboard.scss';
import {
  CompaniesTableColumns,
  companyOptions,
  GraphWidget,
  industriesObject,
  industryOptions,
  networkingOptions,
  StudentTableColumns,
} from '@/domains/core/advisor';

import { Input } from '@/components/input/input';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import { IndustrySidebar } from '@/domains/generic/';
import { statuses } from '@/domains/core/tracker-data';
import AnalyticsImage from '@/assets/images/analitic-man.svg';
import { router } from '@/main';
import { useAnalytics } from '@/services/hooks/use-analytics';
import Table from '@/components/table/table';
import { TableRow } from '@/components/table/table-types';
import { getUserRoles } from '@/services/helpers/user';
import {
  mapCompaniesSavedByStudentsToTableData,
  mapStudentsToTableData,
} from '@/services/helpers/advisor';
import { useSelfUniversity } from '@/services/queries/user';
import { useGetCohortsByUniversityID, useGetStudentsByCohort } from '@/services/queries/student';
import { excludedCAs } from '@/domains/core/advisor/utils/excluded';
import { Button } from '@/components/button/Button';
import { FilterOption, Tile } from '@/domains/core/company/types';
import { useGetUniversityTags } from '@/services/queries/tag';
import { TagType } from '@/components/tag/tag';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { MultiSelect } from '@/components/multi-select/multi-select';
import { Cohort, Student } from '@/domains/core/advisor/types';

function DashboardAdvisorPage() {
  const [students, setStudents] = useState<Student[]>([]);
  const [tiles, setTiles] = useState<Tile[]>([]);
  const [parsedStudents, setParsedStudents] = useState<TableRow[]>([]);
  const { openSidebar, closeSidebar } = useContext(SidebarContext) as any;
  const [searchCompanyValue, setSearchCompanyValue] = useState('');
  const [networkingData, setNetworkingData] = useState<any>(null);
  const [industries, setIndustries] = useState<any>([]);
  const [industriesCompanies, setIndustriesCompanies] = useState<any>([]);
  const [industryData, setIndustryData] = useState<any>(null);
  const [companiesData, setCompaniesData] = useState<any>(null);
  const [allCompanies, setAllCompanies] = useState<any[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<TableRow[]>([]);
  const [searchStudentsValue, setSearchStudentsValue] = useState('');
  const { trackEvent } = useAnalytics();
  const { universityID, cohortID } = useParams();
  const { data: university } = useSelfUniversity();
  const [tagFilters, setTagFilters] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { data: cohorts, isLoading: isCohortsLoading } = useGetCohortsByUniversityID(universityID || university?.id, {
    enabled: !!universityID || !!university?.id, // Only fetch when a valid universityID is available
  });
  const firstCohortId = cohorts?.[0]?.id ?? '';
  const { data: studentsResponse, isLoading: isStudentsLoading } = useGetStudentsByCohort(cohortID || firstCohortId, {
    enabled: !!firstCohortId || !!cohortID, // Only fetch when a valid cohortID is available
  });
  const { data: tags } = useGetUniversityTags(universityID || university?.id, {
    enabled: !!universityID || !!university?.id, // Only fetch when a valid universityID is available
  });
  const { user } = useAuth0();
  const searchStudentByName = (name: string) => {
    const pStudents: TableRow[] = mapStudentsToTableData(students, tiles, tags);
    if (name === '') {
      setParsedStudents(pStudents);
    } else {
      const filteredStudents = students
        .filter((student: any) => {
          const fullName = `${student.first_name} ${student.last_name}`;
          return (fullName.toLowerCase().includes(name.toLowerCase().trim()) || student.email.toLowerCase().includes(name.toLowerCase().trim()));
        }).filter(Boolean);
      const filteredParsedStudents = mapStudentsToTableData(filteredStudents, tiles, tags);
      setParsedStudents(filteredParsedStudents);
    }
    setSearchStudentsValue(name);
  };

  const searchCompaniesByName = (name: string) => {
    if (name === '') {
      setFilteredCompanies(mapCompaniesSavedByStudentsToTableData(allCompanies, cohortID || firstCohortId));
    }
    setSearchCompanyValue(name);
    const filteredAllCompanies = allCompanies
      .filter((company: any) => company.company_name.toLowerCase().includes(name.toLowerCase().trim()));
    setFilteredCompanies(mapCompaniesSavedByStudentsToTableData(filteredAllCompanies, cohortID || firstCohortId));
  };

  const computeIndustryData = (tilesData: Tile[]) => {
    const tilesArray = tilesData.flatMap((array: any) => array).filter(Boolean);
    const industryCount = tilesArray.reduce((acc: any, obj: any) => {
      const key = obj.industry;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    const sortedIndustries = Object.entries(industryCount)
      .sort((a: any, b: any) => b[1].length - a[1].length)
      .map(entry => entry[0]);
    setIndustries(sortedIndustries);
    setIndustriesCompanies(industryCount);
    const industriesValues = sortedIndustries.map((industry: any) => industryCount[industry].length || 0);
    const allIndustries = industriesObject.map((industry: any) => industry.name);
    const industryDataMock = {
      labels: [...sortedIndustries, ...allIndustries.filter((industry: any) => !sortedIndustries.includes(industry))],
      datasets: [
        {
          label: 'Saved Companies',
          data: industriesValues,
          backgroundColor: '#00A120',
          color: '#ffffff',
        },
      ],
    };
    setIndustryData(industryDataMock);
  };

  const computeNetworkingData = (studentsData: any) => {
    const networkingStudents = studentsData.filter((student: any) => student.messagesSent > 0 || student.messagesReceived > 0).length;
    const networkingStudentsPercentage = Math.floor((networkingStudents / studentsData.length) * 100);
    const networkingDataMock = {
      labels: ['Networking', 'Not Networking'],
      datasets: [
        {
          label: '% of Students Networking',
          data: [networkingStudentsPercentage, 100 - networkingStudentsPercentage],
          backgroundColor: [
            'rgba(88, 80, 141, 1)',
            'rgba(161, 85, 185, 1)',

          ],
        },
      ],
    };
    setNetworkingData(networkingDataMock);
  };

  const handleCompanyClick = (companyIndex: number) => {
    const companyName = companiesData.labels[companyIndex];
    const company = allCompanies.find((cmp) => cmp.company_name === companyName);
    trackEvent('Company Overview Clicked');
    const cID = cohortID || firstCohortId;
    router.navigate(`/app/company-overview/${company?.company_id}/cohort/${cID}`);
  };

  const computeFilteredCompaniesData = (statusToReturn: string, topTenCompanies: string[], tilesData: Tile[]) => {
    const tilesArray = tilesData.flatMap((array: any) => array).filter(Boolean);
    const status = statuses[statusToReturn];

    const filteredArrayCompanies = tilesArray.filter((tile: any) => tile.status === status);
    const computedData: number[] = [];
    topTenCompanies.forEach((company: any) => {
      const count = filteredArrayCompanies.filter((tile: any) => tile.company_name === company).length;
      computedData.push(count);
    });
    return computedData;
  };

  const computeCompaniesData = (tileData: Tile[]) => {
    const tilesArray = tileData.flatMap((array: any) => array).filter(Boolean);
    const uniqueCompanies: any = [];
    const result = tilesArray.map((obj: any) => {
      // Check if company already exists in the uniqueCompanies array
      const company = uniqueCompanies
        .find((c: any) => c.company_name === obj.company_name && c.industry === obj.industry);

      if (company) {
        // If company already exists, increment count
        // eslint-disable-next-line no-plusplus
        company.count++;
        return null;
      }
      // If company doesn't exist, add it to the uniqueCompanies array and set count to 1
      const newCompany = { ...obj, count: 1 };
      uniqueCompanies.push(newCompany);
      return newCompany;
    }).filter(Boolean).sort((a: any, b: any) => b.count - a.count).slice(0, 10);
    setAllCompanies(uniqueCompanies.sort((a: any, b: any) => b.count - a.count));
    const fCompanies = mapCompaniesSavedByStudentsToTableData(uniqueCompanies.sort((a: any, b: any) => b.count - a.count), cohortID || firstCohortId);
    setFilteredCompanies(fCompanies);
    const topTenCompanies = result.map((company: any) => company.company_name);
    const companyDataMock = {
      labels: topTenCompanies,
      datasets: [
        {
          label: 'Company Saved',
          data: computeFilteredCompaniesData('Company Saved', topTenCompanies, tileData), // this needs to be memoized
          backgroundColor: '#00A120',
          color: '#ffffff',
        },
        {
          label: 'Networking',
          data: computeFilteredCompaniesData('Networking', topTenCompanies, tileData), // this needs to be memoized
          backgroundColor: '#5B32EE',
          color: '#ffffff',
        },
        {
          label: 'Applying',
          data: computeFilteredCompaniesData('Applying', topTenCompanies, tileData), // this needs to be memoized
          backgroundColor: '#3288EE',
          color: '#ffffff',
        },
        {
          label: 'Applied',
          data: computeFilteredCompaniesData('Applied', topTenCompanies, tileData), // this needs to be memoized
          backgroundColor: '#0BC79A',
          color: '#ffffff',
        },
        {
          label: 'Interviewing',
          data: computeFilteredCompaniesData('Interviewing', topTenCompanies, tileData), // this needs to be memoized
          backgroundColor: '#EE9832',
          color: '#ffffff',
        },
        {
          label: 'Offer Received',
          data: computeFilteredCompaniesData('Offer', topTenCompanies, tileData), // this needs to be memoized
          backgroundColor: '#CC78F3',
          color: '#ffffff',
        },
        {
          label: 'Archived',
          data: computeFilteredCompaniesData('Archived', topTenCompanies, tileData), // this needs to be memoized
          backgroundColor: '#dae5e4',
          color: '#ffffff',
        },
      ],
    };
    setCompaniesData(companyDataMock);
  };

  const handleIndustryClick = (industryIndex: number) => {
    const industry = industries[industryIndex];
    const industryCompanies = industriesCompanies[industry];

    const uniqueCompanies: any = [];
    const result = industryCompanies.map((obj: any) => {
      // Check if company already exists in the uniqueCompanies array
      const company = uniqueCompanies
        .find((c: any) => c.company_name === obj.company_name && c.industry === obj.industry);

      if (company) {
        // If company already exists, increment count
        // eslint-disable-next-line no-plusplus
        company.count++;
        return null;
      }
      // If company doesn't exist, add it to the uniqueCompanies array and set count to 1
      const newCompany = { ...obj, count: 1 };
      uniqueCompanies.push(newCompany);
      return newCompany;
    }).filter(Boolean);
    trackEvent('Clicked on Industry breakdown');

    openSidebar(<IndustrySidebar industry={industry} companies={result} closeSidebar={closeSidebar} />);
  };

  const parseStudents = (unParsedStudents: any, applications: Tile[]) => {
    const pStudents = mapStudentsToTableData(unParsedStudents, applications, tags);
    setParsedStudents(pStudents);
  };

  const fetchData = async () => {
    let studentsData: any[] = [];

    if (!studentsResponse) {
      return;
    }
    setIsLoading(true);
    studentsData = studentsResponse.map((student: any) => ({
      ...student,
      name: `${student.first_name} ${student.last_name}`,
      type: 'MBA Student',
      universityID: universityID || university.id,
      cohortID: cohortID || firstCohortId,
      logged_in: true,
      messagesSent: student.messages_count?.messages_sent ? student.messages_count?.messages_sent : 0,
      messagesReceived: student.messages_count?.messages_received ? student.messages_count?.messages_received : 0,
    }));

    const filtersFromLocalStorage = localStorage.getItem('advisorFilters');

    if (filtersFromLocalStorage && typeof filtersFromLocalStorage === 'string') {
      const filters = JSON.parse(filtersFromLocalStorage);
      const filterBy = filters?.map((filter: any) => filter.name) || [];

      if (filterBy.length > 0) {
        setTagFilters(filters);
        studentsData = studentsData.filter((student: any) => student?.tags?.some((tag: TagType) => filterBy.includes(tag.label)));
      }
    }

    const tilesData: any[] = [];
    const studentsFiltered = studentsData.filter((student: any) => !excludedCAs.includes(student.email));
    studentsFiltered.forEach((student: any) => {
      const tilesResponse = student.applications;
      const filteredTilesResponse = tilesResponse ? tilesResponse.filter((tile: any) => tile.company_name !== 'CareerOS') : [];
      const mappedTiles = filteredTilesResponse.map((tile: any) => ({
        ...tile,
        userID: student.id, // this might not be needed
      }));
      tilesData.push(mappedTiles);
    });
    setStudents(studentsFiltered);
    setTiles(tilesData);
    computeNetworkingData(studentsFiltered);
    computeIndustryData(tilesData);
    computeCompaniesData(tilesData);
    parseStudents(studentsFiltered, tilesData);
    setIsLoading(false);
  };

  const handleTagFiltering = (options: FilterOption[]) => {
    const filterBy = options.map((option: FilterOption) => option.name);
    setTagFilters(options);
    const savedFilters = JSON.stringify(options);
    localStorage.setItem('advisorFilters', savedFilters);
    let studentsData: any[] = [];

    if (!studentsResponse) {
      return;
    }
    studentsData = studentsResponse.map((student: any) => ({
      ...student,
      name: `${student.first_name} ${student.last_name}`,
      type: 'MBA Student',
      universityID: universityID || university.id,
      cohortID: cohortID || firstCohortId,
      logged_in: true,
      messagesSent: student.messages_count?.messages_sent ? student.messages_count?.messages_sent : 0,
      messagesReceived: student.messages_count?.messages_received ? student.messages_count?.messages_received : 0,
    }));

    if (filterBy.length > 0) {
      studentsData = studentsData.filter((student: any) => student?.tags?.some((tag: TagType) => filterBy.includes(tag.label)));
    }

    const tilesData: any[] = [];
    const studentsFiltered = studentsData.filter((student: any) => !excludedCAs.includes(student.email));
    studentsFiltered.forEach((student: any) => {
      const tilesResponse = student.applications;
      const filteredTilesResponse = tilesResponse ? tilesResponse.filter((tile: any) => tile.company_name !== 'CareerOS') : [];
      const mappedTiles = filteredTilesResponse.map((tile: any) => ({
        ...tile,
        userID: student.id, // this might not be needed
      }));
      tilesData.push(mappedTiles);
    });
    setStudents(studentsFiltered);
    setTiles(tilesData);
    computeNetworkingData(studentsFiltered);
    computeIndustryData(tilesData);
    computeCompaniesData(tilesData);
    parseStudents(studentsFiltered, tilesData);
  };

  useEffect(() => {
    if (user && user.email) {
      const roles = getUserRoles(user);
      if (!roles.includes('Admin') && !roles.includes('CareerAdvisor')) {
        router.navigate('/app/dashboard');
      }
    }
  }, [user]);

  useEffect(() => {
    if (studentsResponse && !isCohortsLoading && !isStudentsLoading) {
      fetchData();
    }
  }, [studentsResponse, isCohortsLoading, isStudentsLoading, cohortID, universityID]);

  if (isCohortsLoading || isStudentsLoading || isLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="advisor-dashboard">
      <div className="advisor-dashboard__top">
        {cohorts && cohorts.length > 0 && cohorts.map((cohort: Cohort) => (
          <Button
            key={cohort.id}
            onClick={() => router.navigate(`/app/dashboard-advisor/${cohort.university_id}/${cohort.id}`)}
            mode="primary"
            outlined={cohort.id !== cohortID}
            size="medium"
            label={cohort.name}
          />
        ))}
      </div>

      <div className="white-wrapper">
        {parseStudents.length > 0 ? (
          <div className="career-os-grid">
            <div className="width-6/24">
              <MultiSelect
                handleFiltering={handleTagFiltering}
                labelType="number"
                options={tags?.map((tag: any) => ({ name: tag.label, value: tag.id })) ?? []}
                placeholder="Filter by tags"
                selected={tagFilters}
                size="full"
                withSelectAllOption
              />
              {networkingData && (
                <GraphWidget
                  title="Student Networking Overview"
                  subtitle="Networking Criteria: Has initiated conversations from at least one contact in CareerOS."
                  chartType="doughnut"
                  chartClicked={() => { }}
                  options={networkingOptions}
                  data={networkingData}
                />
              )}
            </div>
            <div className="width-18/24">
              <Input
                icon="bi bi-search"
                value={searchStudentsValue}
                id="search-students"
                label=""
                placeholder="Search Students by Name or Email"
                handleValueChange={searchStudentByName}
              />
              <div className="student__table">
                <Table
                  data={parsedStudents}
                  columns={StudentTableColumns}
                  withRowNumber
                />
              </div>
            </div>
            <div className="width-24/24">
              {industryData
                && (
                  <GraphWidget
                    title="Top Industries on CareerOS"
                    subtitle="Click on an industry bar to view the engagement with all the companies in that industry."
                    chartType="bar"
                    chartClicked={handleIndustryClick}
                    options={industryOptions}
                    data={industryData}
                  />
                )}
            </div>
            <div className="width-24/24">
              {companiesData
                && (
                  <GraphWidget
                    title="Top 10 Companies Engagement"
                    subtitle="Click on a company bar to view Student Activity with each company"
                    chartType="bar"
                    chartClicked={handleCompanyClick}
                    options={companyOptions}
                    data={companiesData}
                  />
                )}
            </div>
            <div className="width-24/24">
              <Input
                icon="bi bi-search"
                value={searchCompanyValue}
                id="search-companies"
                label=""
                placeholder="Search Companies"
                handleValueChange={searchCompaniesByName}
              />
              <br />
              <div className="companies__table">
                <Table
                  data={filteredCompanies}
                  columns={CompaniesTableColumns}
                />
              </div>
            </div>
          </div>
        )
          : (
            <div className="no-students">
              <div className="advisor-dashboard__image">
                <div className="coming-soon-box">
                  Coming soon
                </div>
                <img className="advisor-dashboard__image" src={AnalyticsImage} alt="" />
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(DashboardAdvisorPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
