import { createPortal } from 'react-dom';
import { useOnboarding } from '@/services/hooks/use-onboarding';
import './onboarding-overlay.scss';

const modalRoot = document.querySelector('#modal-root') as HTMLElement;
const ModalTip = () => {
  const {
    isOnboardingInProgress,
  } = useOnboarding();

  if (isOnboardingInProgress) {
    return createPortal(
      <div className={isOnboardingInProgress ? 'modal-overlay show' : 'modal-overlay'} />,
      modalRoot,
    );
  } return null;
};

export default ModalTip;
