import { Children } from 'react';
import { useTabs } from './TabsContext';
import './tabs.scss';

interface TabsHeaderProps {
  children?: any;
}
/**
 * Primary UI component for Tabs Content
 */
export const TabsContent = ({ children }: TabsHeaderProps) => {
  const arrayChildren = Children.toArray(children);
  const { currentTab } = useTabs();
  return (
    <div className="tabs-content">
      {Children.map(arrayChildren, (child: any) => (
        <div
          className={
            currentTab === child.props['data-tab']
              ? 'tabs-content__tab tabs-content__tab--active'
              : 'tabs-content__tab'
          }
        >
          {child}
        </div>
      ))}
    </div>
  );
};
