/* eslint-disable import/no-extraneous-dependencies */
import {
  useContext,
  useEffect, useRef, useState,
} from 'react';
import './overview-summary.scss';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { LinkButton } from '@/components/link/link';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { Button } from '@/components/button/Button';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);
const isMobile = window.innerWidth <= 768;
export const options = {
  responsive: true,
  maintainAspectRatio: false, // Allow free resizing
  aspectRatio: isMobile ? 1 : 2, // Adjust the aspect ratio of the chart
  scales: {
    x: {
      beginAtZero: true,
      precision: 0,
      grid: {
        display: false, // Hide x-axis grid lines
      },
      ticks: {
        font: {
          family: 'Lato-Regular', // Specify your desired font family here
        },
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        font: {
          family: 'Lato-Regular', // Specify your desired font family here
        },
        precision: 0,
        callback: (value: any) => (Number.isInteger(value) ? value : ''),
      },
      grid: {
        display: false, // Hide y-axis grid lines
      },
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'Click on a column to see the companies in that stage',
      font: {
        family: 'Lato-Regular', // Specify your desired font family here
      },
    },
    datalabels: {
      color: '#000',
      font: {
        family: 'Lato-Regular', // Specify your desired font family here
      },
    },
  },
};

export const OverviewSummary = ({ kanban }: any) => {
  const [data, setData] = useState<any>(null);
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const chartRef = useRef<any>(null);
  const [activeState, setActiveState] = useState<any>(null);
  const handleClick = (event: any) => {
    const ev = getElementAtEvent(chartRef.current, event);
    if (ev.length > 0) {
      const element = ev[0];
      const { index } = element;
      const labels = Object.keys(kanban);
      const label = labels[index];
      setActiveState(label);
    }
  };

  useEffect(() => {
    if (kanban) {
      const labels = Object.keys(kanban);
      const dataSetsValues = Object.values(kanban);
      const newData = {
        labels,
        datasets: [
          {
            label: 'Companies',
            data: dataSetsValues.map((item: any) => item.length),
            backgroundColor: '#82CFFF',
          },
        ],
      };
      setData(newData);
    }
  }, [kanban]);
  return (
    <div>
      <div className="overview-summary">
        <h2 className="overview-summary__title">
          Overview Board Summary
        </h2>
        <div className="overview-summary__content" style={{ width: '100%', height: isMobile ? '600px' : '400px' }}>
          {kanban && data && <Bar ref={chartRef} onClick={handleClick} options={options} data={data} />}
        </div>
      </div>
      <div className="overview-actions">
        <div className="overview-actions__header">
          <h2 className="overview-actions__header__title">Stage Breakdown</h2>
          {activeState && (
            <p className="overview-actions__header__text">Great work! Click on the companies below to see your next steps. </p>
          )}
        </div>
        {activeState ? (
          <div className="overview-actions__active-state">
            {kanban[activeState].map((company: any) => (
              <div key={company.id} className="overview-actions__active-state__company">
                <div className="overview-actions__active-state__company__info">
                  <div className="overview-actions__active-state__company__logo">
                    <DefaultLogo
                      source={company.logo_url}
                      type="company"
                      name={company.company_name}
                      className=""
                    />
                  </div>
                  <div className="overview-actions__active-state__company__title">
                    {company.company_name}
                  </div>
                  <div className="overview-actions__active-state__company__industry">
                    {company.industry}
                  </div>
                </div>
                <Button handleClick={() => openTracker(company.company_id)} mode="text" size="medium" label="Next Steps" />
              </div>
            ))}
          </div>
        ) : (
          <div className="overview-actions__empty-state">
            <p className="overview-actions__empty-state__text">Select one of the stages to see your progress and next steps or continue progress from your Overview Board.</p>
            <div className="overview-actions__empty-state__links">
              <LinkButton icon="bi bi-box-arrow-up-right" link="/app/overview" target="_self" label="Go To Overview Board" />
              <LinkButton icon="bi bi-box-arrow-up-right" link="/app/companies/explore" target="_self" label="Add another company" />
            </div>
          </div>
        )}
      </div>
    </div>

  );
};
