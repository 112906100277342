import { Link } from 'react-router-dom';
import { Button } from '@/components/button/Button';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { ContactEmails } from '../contact-emails/contact-emails';

import './info-tile.scss';
import { Contact } from '../../types';
import { StatusTag } from '../status-tags/status-tag';
import { ConnectionStatus } from '../../types/contact';

interface InfoTileProps {
  contact: Contact;
  connectionStatus: ConnectionStatus;
  networkingStatus: ConnectionStatus;
  onEmailChanged: () => void
}

export const ContactInfoTile = ({
  contact,
  connectionStatus,
  networkingStatus,
  onEmailChanged,
}: InfoTileProps) => (
  <div className="contact-page-info">
    <div className="contact-page-info__image">
      <DefaultLogo
        type="contact"
        source={contact.career_summary.basics.image}
        className=""
        name={`${contact.first_name} ${contact.last_name}`}
        size={80}
      />
    </div>
    <span className="contact-page-info__name">
      {contact.first_name}
      {' '}
      {contact.last_name}
    </span>
    <span className="contact-page-info__position">
      {contact.current_position.title}
      {' '}
      at
      {' '}
      {contact.current_position.company}
    </span>
    <div className="contact-page-info__status">
      <div className="contact-page-info__tags">
        <StatusTag status={connectionStatus} type="linkedin" withTooltip />
        <StatusTag status={networkingStatus} type="networking" />
      </div>
      <Link className="contact-page-info__linkedin" to={contact.linkedin_url} target="_blank" rel="noreferrer">
        <Button
          label="View Profile"
          mode="primary"
          size="medium"
          icon="bi bi-linkedin"
          hoverIcon="bi bi-box-arrow-up-right"
          outlined
        />
      </Link>
    </div>
    <div className="contact-page-info__email">
      <ContactEmails contactId={contact.id} primaryEmail={contact.email} secondaryEmail={contact.secondary_email} onEmailChanged={onEmailChanged} />
    </div>
  </div>
);
