import { Button } from '@/components/button/Button';
import './email-not-available.scss';

type EmailNotAvailableProps = {
  handleLinkedinClick: () => void;
  handleAddEmailClick: () => void;
};

export const EmailNotAvailable = ({ handleLinkedinClick, handleAddEmailClick }: EmailNotAvailableProps) => (
  <div className="email-not-available">
    <h2 className="email-not-available__title">This contact does not have an email added</h2>
    <p className="email-not-available__tip">
      <b>Remember: </b>
      80% of all jobs are filled through professional networking and candidates with referrals have much higher chances of advancing to the interview stage. Start networking with this contact to gain unique insights and maximize your chances of getting in.
    </p>
    <div className="email-not-available__actions">
      <Button onClick={handleAddEmailClick} mode="primary" size="medium" label="Add Email" iconPosition="left" icon="bi bi-plus-circle" />
      <Button onClick={handleLinkedinClick} mode="primary" outlined size="medium" label="Send LinkedIn Message" iconPosition="left" icon="bi bi-send" />
    </div>
  </div>
);
