import { Accordion } from '@/components/accordion/accordion';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { TipModalProps } from '../types';

import '../tracker-tip.scss';
import { useAnalytics } from '@/services/hooks/use-analytics';

const AppliedTipsModal = ({ openTip }: TipModalProps) => {
  const tabs = ['Prepare your career story', 'Personal Fit', 'Technical Questions'];
  const { trackEvent } = useAnalytics();

  const trackCopiedToClipboard = () => {
    trackEvent('Tracker Tip: Copied to Cliboard');
  };

  return (
    <div className="company-saved-info-modal">
      <div className="company-saved-info-modal">
        <TabsHeader
          tabs={tabs}
          defaultTab={tabs.includes(openTip.tab) ? openTip.tab : tabs[0]}
          withBorder
        />
        <TabsContent>
          <div data-tab="Prepare your career story">
            <div className="tip-modal-accordions">
              <Accordion handleCopied={trackCopiedToClipboard} copiable isOpened={openTip.tipName === 'Advanced Company Research'} title="Advanced Company Research">
                <div>
                  <p>
                    By now, you will probably have a good idea about your target company. But you will want to go one step further and go into your interviews super well-informed. Below are some resources you can tap into for your company research:
                  </p>
                  <ul>
                    <li> Check out the company’s investor relations page, and in particular, read through the CEO summaries of the company’s annual reports. Often, they will discuss the largest challenges and opportunities that the company is facing.</li>
                    <li>Google “[Company] trends”.</li>
                    <li>Check your target company’s social media feeds, especially Twitter and Linkedin.</li>
                    <li>Check recent headlines on the company website.</li>
                  </ul>
                  <p>
                    During your research, remember to selectively pick up pieces of information that truly interest you and reinforce your career story and motivation. For more on this, check out our tips on articulating your motivation in the next section.
                  </p>
                  <p>For more on this, check out our tips on articulating your motivation in the next section.</p>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable isOpened={openTip.tipName === 'Articulate your motivation'} title="Articulate your motivation">
                <div>
                  <p>
                    Remember our frameworks:
                  </p>
                  <ul>
                    <li>
                      GROW model to explain career transitions
                    </li>
                    <li>
                      PASSion model to make the story in your Resume credibleREC: to make the story in your Resume credible
                    </li>
                  </ul>
                  <p><strong>GROW: Grounding Experience - Realizations and Reflections - Opportunity Pursued - Why</strong></p>
                  <p>
                    Example:
                    <br />
                    <br />
                    You want to explain your transition from engineering to consulting:
                  </p>
                  <p>
                    “My educational background in engineering helped me to build a strong foundation in problem-solving and analytical thinking (G - Grounding Experience). However, as I progressed, I realized that I wanted to apply that mindset to a broader range of challenges beyond the field, in particular to complex business challenges (R - Realizations and Reflections). Motivated by these insights, I decided to pursue a career in strategy consulting, starting with a summer internship (O - Opportunity Pursued). Overall, this aligns with my long-term vision to become an expert in the renewables sector, who can assess both the engineering and business angle of such projects (W - Why).”
                  </p>
                  <p><strong>PASSion: Personal Interest - Aptitude and Affinity - Skill Set - Storytelling</strong></p>
                  <p>
                    Example:
                    <br />
                    <br />
                    You want to credibly demonstrate that you are passionate about the Tech industry:
                  </p>
                  <p>
                    “I have always had a deep personal interest (P - Personal Interest) in technology and innovation, even when I was a kid. While other kids were playing sports, I was fixing up computers  or coding mini programs on our school’s computers (A - Aptitude and Affinity). I then became proficient in multiple coding languages during my Computer Science degree (S - Skill Set). My fascination with technology and my constant following of the newest developments give me confidence that I can quickly gain expertise on any software product I might build or improve in the future (S - Storytelling).”
                  </p>
                </div>
              </Accordion>
            </div>
          </div>
          <div data-tab="Personal Fit">
            <div className="tip-modal-accordions">
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="The STAR Method">
                <div>
                  <p>
                    The STAR method is a great way to answer behavioural interview questions.
                  </p>

                  <p>STAR stands for Situation - Task - Action - Result.</p>
                  <p>This framework structures your answers. Practice the STAR methods by writing down answers to the typical behavioral questions and using the example below.</p>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable isOpened={openTip.tipName === 'Typical Questions'} title="Typical Questions">
                <div>
                  <p>
                    Resume
                  </p>
                  <ul>
                    <li>Highlight the 3 most interesting aspects of your Resume</li>
                    <li>Walk me through your Resume</li>
                  </ul>
                  <p>
                    Motivation
                  </p>
                  <ul>
                    <li>
                      What motivates you?
                    </li>
                    <li>Why are you pursuing an MBA? Why X industry? Why X company?</li>
                  </ul>
                  <p>Skills & Achievements</p>
                  <ul>
                    <li>
                      {' '}
                      What are your top 3 achievements?
                    </li>
                    <li> What are your top strengths & weaknesses?</li>
                    <li> What experiences & skills are particularly transferable to our team?</li>
                  </ul>
                  <p>Leadership</p>
                  <ul>
                    <li>  When have you led a team through a big challenge?</li>
                    <li>   When have you taken initiative on a project or otherwise?</li>
                  </ul>
                  <p>Situations</p>
                  <ul>
                    <li> Have you disagreed with your manager in the past? How did you resolve the conflict?</li>
                    <li> Give me an example of team work</li>
                    <li> Tell me about a failure</li>
                  </ul>
                  <p>Future</p>
                  <ul>
                    <li>  What makes you a good candidate for this position?</li>
                    <li>  Where do you see yourself in 5/10/15 years?</li>
                  </ul>
                </div>
              </Accordion>
            </div>
          </div>
          <div data-tab="Technical Questions">
            <div className="tip-modal-accordions">
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Technical Questions">
                <div>
                  <p>
                    Make sure you understand what the interview process looks like and what industry-specific, technical questions could come up.
                  </p>
                  <p>
                    Examples are:
                  </p>
                  <ul>
                    <li>
                      Case cracking for Consulting (e.g. CaseCoach)
                    </li>
                    <li>  Coding interviews for Tech (e.g. LeetCode)</li>
                    <li>
                      Financial modelling simulation for Finance (e.g. WallStreet Prep, Mergers & Inquisitions)
                    </li>
                  </ul>
                </div>
              </Accordion>

            </div>
          </div>
        </TabsContent>
      </div>
    </div>
  );
};

export default withTabs(AppliedTipsModal);
