import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import './contact-box.scss';
import { prettifyHTML } from '@/services/helpers/processHtml';
import { MessageType } from '@/domains/core/netwoking/types';

interface ContactBoxProps {
  contactImage: string;
  contactName: string;
  messageTimestamp: any;
  company: string;
  text: string;
  contactId: string;
  selected?: boolean;
  type: MessageType;
}

export const ContactBox = ({
  contactName,
  contactImage,
  company,
  text,
  messageTimestamp,
  contactId,
  selected = false,
  type,
}: ContactBoxProps) => {
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const boxRef = useRef<HTMLDivElement>(null);

  const scrollToContact = () => {
    if (boxRef.current) {
      boxRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const goToContact = () => {
    trackEvent('Inbox: Contact clicked');
    navigate(`/app/inbox/${contactId}?view=${type}`);
  };

  useEffect(() => {
    if (selected) {
      setTimeout(() => {
        scrollToContact();
      }, 100);
    }
  }, [selected, boxRef.current]);

  return (
    <div
      className={`contact-box ${selected ? 'contact-box--selected' : ''}`}
      ref={boxRef}
      onClick={goToContact}
    >
      <div className="contact-box__container">
        <DefaultLogo
          type="contact"
          source={contactImage}
          handleClick={goToContact}
          className="contact-box__image"
          name={contactName}
          size={48}
        />
        <div className="contact-box__type">
          {type === 'email' ? <i className="bi bi-envelope" /> : <i className="bi bi-linkedin" /> }
        </div>
      </div>
      <div className="contact-box__info">
        <div className="contact-box__top-info">
          <div className="contact-box__name">
            {contactName}
          </div>
          <div className="contact-box__date">
            {messageTimestamp ? formatDateDeltaFromNow(messageTimestamp.toDate()) : <i className="bi bi-chat-heart-fill" />}
          </div>
        </div>
        <div className="contact-box__company">
          {company}
        </div>
        <p className="contact-box__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prettifyHTML(text)) }} />
      </div>
    </div>
  );
};
