import { ApiUser, getSelf } from '@/services/api/user';
import { getAllDocuments } from '@/services/api/documents';

export async function documentsLoader() {
  let documentsResponse = null;
  try {
    const self: ApiUser = await getSelf();
    documentsResponse = await getAllDocuments(self.id);
  } catch (error) {
    console.log(error);
  }

  const documents = documentsResponse || [];

  return { documents };
}
