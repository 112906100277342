import Lottie from 'react-lottie';
import { Button } from '../button/Button';

import './banner.scss';

type Props = {
  title: string;
  text?: any;
  buttonLabel?: string;
  handleButtonClick?: () => void;
  handleClose?: () => void;
  buttonIcon?: string;
  buttonIconPosition?: 'left' | 'right';
  buttonPlacement?: 'horizontal' | 'vertical';
  isButtonOutlined?: boolean;
  isClosable?: boolean;
  image?: string;
  animation?: any;
  backgroundColor?: 'default' | 'white';
};

export const Banner = ({
  title,
  text,
  buttonLabel,
  handleButtonClick,
  handleClose,
  isClosable = false,
  image,
  animation,
  buttonPlacement = 'vertical',
  isButtonOutlined = false,
  backgroundColor = 'default',
  buttonIcon,
  buttonIconPosition,
}: Props) => (
  <div className={`banner banner--color-${backgroundColor} ${isClosable && 'banner--closable'}`}>
    {isClosable && (
      <div data-testid="close-button" className="banner__close" onClick={handleClose}>
        <i className="bi bi-x-lg" />
      </div>
    )}
    {image && (
      <div className="banner__image">
        <img src={image} alt="" />
      </div>
    )}
    {animation && (
      <div className="banner__animation">
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: animation,
          }}
          height={100}
          width={100}
          style={{
            background: 'transparent',
          }}
        />
      </div>
    )}
    <div className={`banner__content banner__content--${buttonPlacement}`}>
      <div className="banner__text">
        <span className="banner__title">{title}</span>
        {text && <span className="banner__description">{text}</span>}
      </div>
      {buttonLabel && handleButtonClick && (
        <Button
          data-testid="button"
          label={buttonLabel}
          mode="primary"
          size="medium"
          outlined={isButtonOutlined}
          handleClick={handleButtonClick}
          icon={buttonIcon}
          iconPosition={buttonIconPosition}
        />
      )}
    </div>
  </div>
);
