import { useContext, useEffect, useState } from 'react';

import './add-comment.scss';
import { useRevalidator } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Button } from '@/components/button/Button';

import { addResumeComment, addComment } from '@/services/api/advisor';
import { ModalContext } from '@/components/modal/modal-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { InfoModal } from '../contact-info-modal/contact-info-modal';

interface AddCommentProps {
  id: string;
  commentValue?: string;
  saved?: boolean;
  type: 'company' | 'resume';
  payload: {
    [key: string]: string;
  }
}

export const AddComment = ({
  id, type, commentValue = '', saved = false, payload = {},
}: AddCommentProps) => {
  const [isSaved, setIsSaved] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [comment, setComment] = useState('');
  const queryClient = useQueryClient();
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const isStorybook = document.querySelector('#storybook-root');
  const revalidator = isStorybook ? false : useRevalidator();
  const { trackEvent } = useAnalytics();

  const onChangeHandler = (value: string) => {
    setComment(value);
  };

  const isFieldValid = () => comment.trim() !== '';

  const reOpenModal = (com: string) => {
    openModal(
      <AddComment payload={payload} type={type} id={id} commentValue={com} />,
    );
  };

  const sendComment = async () => {
    closeModal();
    if (type === 'company') {
      await addComment(comment, id);
    } else {
      await addResumeComment(comment, id, payload);
      queryClient.invalidateQueries(['resumeComments', id]);
    }

    trackEvent('CA Left comment');
    openModal(
      <AddComment payload={payload} type={type} id={id} commentValue={comment} saved />,
    );
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (comment.length > 0) {
      openModal(
        <InfoModal
          title="Ready to send?"
          buttonLabel="Confirm"
          secondaryButtonLabel="Wait, I want to check again"
          handleButtonClick={sendComment}
          handleSecondaryButtonClick={() => reOpenModal(comment)}
          description="The student will receive a notification to view the comment you left. Confirm that the comment is ready to go."
        />,
      );
    }
  };

  useEffect(() => {
    if (commentValue) {
      setComment(commentValue);
    }
  }, [commentValue]);

  // TODO: replace with toast
  useEffect(() => {
    if (saved) {
      setIsSaved(true);
      if (revalidator) {
        revalidator.revalidate();
      }
    }
  }, [saved]);

  if (isSaved) {
    return (
      <div className="comment-saved">
        <h1 className="comment-saved__title">
          Comment successfully sent!
        </h1>
        <span className="comment-saved__subtitle">
          The student will receive a notification about your comment.
        </span>
      </div>
    );
  }

  return (
    <div className="add-comment">
      <h1 className="add-comment__title">
        Add a Comment
      </h1>
      <label htmlFor="description" className="add-comment__label">
        <textarea
          name="description"
          id="description"
          className={isSubmitted && !isFieldValid() ? 'invalid' : ''}
          placeholder={type === 'company' ? 'Give some rad advice...' : 'Give some advice on the student’s resume'}
          onChange={(e) => onChangeHandler(e.target.value)}
          defaultValue={commentValue}
        />
        {(isSubmitted && !isFieldValid()) && (
        <span className="add-comment__warning">This field is mandatory</span>
        )}
      </label>
      <div className="add-comment__button">
        <Button onClick={onSubmitHandler} mode="primary" label="Comment" size="large" />
      </div>
    </div>
  );
};
