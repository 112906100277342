import React from 'react';
import { useSidebar } from '@/services/hooks/use-sidebar';
// eslint-disable-next-line import/no-cycle
import Sidebar from './sidebar';

// eslint-disable-next-line import/no-mutable-exports
let SidebarContext: any;
// eslint-disable-next-line no-multi-assign
const { Provider } = (SidebarContext = React.createContext<any>(null));

const SidebarProvider = ({ children }: any) => {
  const {
    showSidebar, sidebarContent, openSidebar, closeSidebar,
  } = useSidebar();
  return (
    <Provider value={{
      showSidebar, sidebarContent, closeSidebar, openSidebar,
    }}
    >
      <Sidebar />
      {children}
    </Provider>
  );
};

export { SidebarContext, SidebarProvider };
