import { TemplateRequestPayload } from '@/domains/core/netwoking/types';
import { http } from '@/services/api/http';

export const getTemplate = (payload: any): Promise<any> => http.post('/ai/template', payload);

export const regenerateMessage = (payload: any): Promise<any> => http.post('/ai/regenerate', payload);

export const getCompanyInsights = (companyName: string): Promise<any> => http.post('/ai/insights', { company_name: companyName });

export const getRoleInformation = (jobTitle: string, companyName: string): Promise<any> => http.post('/ai/role', { company_name: companyName, job_title: jobTitle });

export const getJobQuestions = (jobTitle: string, companyName: string): Promise<any> => http.post('/ai/questions', { company_name: companyName, job_title: jobTitle });

export const getInterviewQuestions = (jobTitle: string, companyName: string): Promise<any> => http.post('/ai/interview', { company_name: companyName, job_title: jobTitle });

export const getScenarioTemplate = (scenario: string, payload: TemplateRequestPayload, signal: AbortSignal | null): Promise<{ response: string }> => http.post('/ai/scenario', { scenario, ...payload }, { signal });
