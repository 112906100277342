import { useQueryClient } from 'react-query';
import { useState } from 'react';
import { useRevalidator } from 'react-router-dom';
import { updateContactEmails } from '@/domains/core/contact/api/contact';
import { ManageEmailBox } from '@/domains/core/contact';
import { PopupWindowWrapper } from '@/domains/generic';
import { Button } from '@/components/button/Button';

import './contact-emails.scss';

type Props = {
  contactId: string;
  primaryEmail: string;
  secondaryEmail: string;
  onEmailChanged: () => void
};

export const ContactEmails = ({
  contactId, primaryEmail, secondaryEmail, onEmailChanged,
}: Props) => {
  const revalidator = useRevalidator();
  const [isManagingEmail, setIsManagingEmail] = useState(false);
  const queryClient = useQueryClient();

  const handleCloseManageEmailBox = () => {
    setIsManagingEmail(false);
  };
  const handleOpenManageEmailBox = () => setIsManagingEmail(true);
  const revalidateAfterEmailChange = async () => {
    revalidator.revalidate();
    await queryClient.invalidateQueries(['contact', contactId]);
    onEmailChanged();
  };

  const managePrimaryEmail = async (emailAdress: string) => {
    if (!emailAdress.length) {
      await updateContactEmails(contactId, secondaryEmail, null);
      handleCloseManageEmailBox();
    } else {
      await updateContactEmails(contactId, emailAdress, secondaryEmail);
      await revalidateAfterEmailChange();
      handleCloseManageEmailBox();
    }
  };

  const manageSecondaryEmail = async (emailAdress: string) => {
    try {
      await updateContactEmails(contactId, primaryEmail, !emailAdress.length ? null : emailAdress);
      handleCloseManageEmailBox();
      await revalidateAfterEmailChange();
    } catch (error) {
      // Handle error here
    }
  };

  const swapEmails = async () => {
    await updateContactEmails(contactId, secondaryEmail, primaryEmail);
    await revalidateAfterEmailChange();
  };

  return (
    <>
      {primaryEmail ? (
        <div className="contact-emails">
          <div className="contact-emails__content" onClick={handleOpenManageEmailBox}>
            <span className="contact-emails__text">
              {`${primaryEmail}${secondaryEmail && `, ${secondaryEmail}`}`}
            </span>
            <i className="bi bi-pencil-fill contact-emails__icon" />
          </div>
        </div>
      ) : (
        <div className="contact-emails contact-emails--hint">
          <Button
            label="no email found"
            mode="text"
            size="small"
            handleClick={handleOpenManageEmailBox}
            icon="bi bi-plus-circle"
            hoverIcon="bi bi-plus-circle-fill"
            iconPosition="right"
          />
        </div>
      )}
      {isManagingEmail && (
        <PopupWindowWrapper handleClose={handleCloseManageEmailBox}>
          <ManageEmailBox
            handleClose={handleCloseManageEmailBox}
            primaryEmail={primaryEmail}
            secondaryEmail={secondaryEmail}
            swapEmails={swapEmails}
            managePrimaryEmail={managePrimaryEmail}
            manageSecondaryEmail={manageSecondaryEmail}
          />
        </PopupWindowWrapper>
      )}
    </>
  );
};
