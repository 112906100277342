import { CompanyCardContainerSkeleton } from '../../components/company-card-skeleton/company-card-container-skeleton';
import './category-widget-skeleton.scss';

export const CategoryWidgetSkeleton = () => (
  <div className="category-widget">
    <div className="category-widget__top">
      <div className="category-widget__header">
        <h2 className="category-widget__title">
          <div className="category-skeleton__text category-skeleton__text--title" />
        </h2>
        <div className="category-skeleton__split" />
        <h3 className="category-widget__subtitle">
          <div className="category-skeleton__text category-skeleton__text--subtitle" />
        </h3>
      </div>

      <div className="category-widget__buttons" />
    </div>
    <div className="category-widget__wrapper">
      <div className="category-widget__companies">
        <CompanyCardContainerSkeleton />
      </div>
    </div>
  </div>
);
