import { useState, useEffect } from 'react';
import { Column, TableRow, UseSortableTableHook } from './table-types';

// A helper function to get the sortable value by accessor
const getSortableValue = (item: TableRow, accessor: string): any => {
  const value = item.items[accessor];
  if (typeof value === 'object' && value !== null) {
    return value.value || value.src || value.to || '';
  }
  return value;
};

// Move this code into a separate function outside
const checkNullValues = (valA: any, valB: any): number | null => {
  const isValANull = valA === null || !valA;
  const isValBNull = valB === null || !valB;

  if (isValANull) return 1;
  if (isValBNull) return -1;
  if (isValANull && isValBNull) return 0;

  return null;
};

// Function to get default sorting
const getDefaultSorting = (defaultTableData: TableRow[], columns: Column[]): TableRow[] => {
  const defaultSortColumn = columns.find(col => col.sortable && col.sortbyOrder) || { accessor: 'number', sortbyOrder: 'asc' };

  const sorted = [...defaultTableData].sort((a, b) => {
    const valA = getSortableValue(a, defaultSortColumn.accessor) || '';
    const valB = getSortableValue(b, defaultSortColumn.accessor) || '';

    const nullValues = checkNullValues(valA, valB);

    if (nullValues !== null) return nullValues;

    const ascending = valA.toString().localeCompare(valB.toString(), 'en', { numeric: true });

    return defaultSortColumn.sortbyOrder === 'asc' ? ascending : -ascending;
  });

  return sorted;
};

export const useSortableTable: UseSortableTableHook = (data, columns) => {
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [selectedSorting, setSelectedSorting] = useState<{ field: string; order: 'asc' | 'desc' } | null>(null);

  const sortData = (sortField: string, sortOrder: 'asc' | 'desc', dataToSort: TableRow[]) => {
    const sorted = [...dataToSort].sort((a, b) => {
      const valA = getSortableValue(a, sortField) || '';
      const valB = getSortableValue(b, sortField) || '';

      const nullValues = checkNullValues(valA, valB);

      return nullValues || (
        valA.toString().localeCompare(valB.toString(), 'en', { numeric: true }) * (sortOrder === 'asc' ? 1 : -1)
      );
    });

    setSelectedSorting({ field: sortField, order: sortOrder });
    setTableData(sorted);
  };

  const handleSorting = (sortField: string, sortOrder: 'asc' | 'desc') => sortData(sortField, sortOrder, tableData);

  useEffect(() => {
    const sData = getDefaultSorting(data, columns);

    if (selectedSorting) {
      sortData(selectedSorting.field, selectedSorting.order, sData);
    } else {
      setTableData(sData);
    }
  }, [data, columns]);

  return [tableData, handleSorting];
};
