/* eslint-disable consistent-return */
import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useEffect,
  useReducer,
} from 'react';

interface ScoringAnchorsState {
  scoringAnchor: string;
  scoringAnchorHighlight: string;
}

type ScoringAnchorsAction =
  | { type: 'CLEAR_SCORING_ANCHOR' }
  | { type: 'CLEAR_SCORING_ANCHOR_HIGHLIGHT' }
  | { type: 'SET_SCORING_ANCHOR_AND_HIGHLIGHT'; payload: string };

type ScoringAnchorsContextType = ScoringAnchorsState & {
  handleScoringAnchor: (anchor: string) => void;
};

const ScoringAnchorsContext = createContext<ScoringAnchorsContextType | undefined>(undefined);

function reducer(state: ScoringAnchorsState, action: ScoringAnchorsAction): ScoringAnchorsState {
  switch (action.type) {
    case 'CLEAR_SCORING_ANCHOR':
      return { ...state, scoringAnchor: '' };
    case 'CLEAR_SCORING_ANCHOR_HIGHLIGHT':
      return { ...state, scoringAnchorHighlight: '' };
    case 'SET_SCORING_ANCHOR_AND_HIGHLIGHT':
      return { ...state, scoringAnchor: action.payload, scoringAnchorHighlight: action.payload };
    default:
      return state;
  }
}

const initialState: ScoringAnchorsState = {
  scoringAnchor: '',
  scoringAnchorHighlight: '',
};

const scrollToElement = (anchor: string) => {
  const element = document.querySelector(anchor);
  if (element) {
    const rect = element.getBoundingClientRect();
    const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

    if (!isInView) {
      requestAnimationFrame(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    }
  }
};

const useScoringAnchorEffect = (scoringAnchor: string, dispatch: React.Dispatch<ScoringAnchorsAction>) => {
  useEffect(() => {
    if (scoringAnchor) {
      const timer = setTimeout(() => {
        scrollToElement(scoringAnchor);
        const anchorElement = document.querySelector(scoringAnchor);
        if (anchorElement) {
          const clearAnchor = () => {
            dispatch({ type: 'CLEAR_SCORING_ANCHOR' });
            document.removeEventListener('click', clearAnchor);
          };
          const clearHighlight = () => {
            dispatch({ type: 'CLEAR_SCORING_ANCHOR_HIGHLIGHT' });
            anchorElement.removeEventListener('click', clearHighlight);
          };
          document.addEventListener('click', clearAnchor);
          anchorElement.addEventListener('click', clearHighlight);
        }
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [scoringAnchor]);
};

export const ScoringAnchorsProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useScoringAnchorEffect(state.scoringAnchor, dispatch);

  const handleScoringAnchor = useCallback((anchor: string) => {
    dispatch({ type: 'SET_SCORING_ANCHOR_AND_HIGHLIGHT', payload: anchor });
  }, []);

  const value = useMemo(() => ({
    ...state,
    handleScoringAnchor,
  }), [state, handleScoringAnchor]);

  return (
    <ScoringAnchorsContext.Provider value={value}>
      {children}
    </ScoringAnchorsContext.Provider>
  );
};

export const useScoringAnchors = () => {
  const context = useContext(ScoringAnchorsContext);
  if (context === undefined) {
    throw new Error('useScoringAnchors must be used within a ScoringAnchorsProvider');
  }
  return context;
};
