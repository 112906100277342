import { getTrialUniversities } from '@/services/strapi/universities';
import { mapTrialUniversitiesFromStrapiToGeneric, mapUniversitiesToGeneric } from '@/services/helpers/university';
import { LoginLoaderResponse } from '../types';
import { getUniversities } from '@/services/public-api/universities';

export async function loginLoader({ request }: any): Promise<LoginLoaderResponse> {
  const url = new URL(request.url);
  const selectedUniversity = url.searchParams.get('school') || url.searchParams.get('university');
  const decodedUniversityName = selectedUniversity ? decodeURIComponent(selectedUniversity) : selectedUniversity;

  const signedUniversitiesResponse = await getUniversities();
  const signedUniversities = signedUniversitiesResponse || [];
  let trialUniversities: any = {};
  try {
    trialUniversities = await getTrialUniversities() || { data: [] };
  } catch {
    trialUniversities = { data: [] };
  }

  const mappedSignedUniversities = mapUniversitiesToGeneric(signedUniversities);

  const mappedTrialUniversities = mapTrialUniversitiesFromStrapiToGeneric(trialUniversities.data);

  const allUniversities = [...mappedSignedUniversities, ...mappedTrialUniversities];
  const universityInList = allUniversities.find((s) => s.name === decodedUniversityName) || {
    name: decodedUniversityName || '', logo: '', isTrial: false, id: '',
  };

  return {
    universities: allUniversities ?? [],
    selectedUniversity: decodedUniversityName ? universityInList : undefined,
  };
}
