import { IconButton } from '../icon-button/icon-button';
import './add-new-card.scss';

type Props = {
  title: string;
  onClick: () => void;
};

export const AddNewCard = ({
  title,
  onClick,
}: Props) => (
  <div className="add-new-card" onClick={onClick}>
    <IconButton icon="bi bi-plus" size="medium" mode="rounded" outlined />
    <p className="add-new-card__text">{title}</p>
  </div>
);
