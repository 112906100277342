/* eslint-disable jsx-a11y/label-has-associated-control */
import { useAuth0 } from '@auth0/auth0-react';
import ReactQuill from 'react-quill';
import {
  useRef,
  useEffect,
  useState,
} from 'react';
import { EditorToolbar } from '../editor-toolbar/editor-toolbar';
import useDebounceEffect from '@/services/hooks/use-debounce';
import { ContenteditableField } from '@/components/contenteditable-field/contenteditable-field';
import {
  findTextInBrackets,
  transformEmailHTML,
  convertNewLinesToHtml,
  encodeName,
} from '@/services/helpers/messages';
import { EmailRecipient } from '../email-recipient/email-recipient';

import 'react-quill/dist/quill.snow.css';
import '@/services/helpers/quill-config';
import './email-box.scss';
import { MessageInputButtons } from '../message-input-buttons/message-input-buttons';
import { TemplateRequestPayload } from '@/domains/core/netwoking/types';

interface EmailBoxProps {
  primaryEmail: string;
  emailAddresses: string[];
  contactFirstName: string;
  handleSendEmail: (message: string, subject: string, to: string[]) => void;
  prefilledSubject?: string;
  replyEmail?: string;
  contactID: string;
  prefilledMessage?: string;
  regenerateData: TemplateRequestPayload;
}

export const EmailBox = ({
  emailAddresses,
  prefilledSubject,
  primaryEmail,
  handleSendEmail,
  contactFirstName,
  replyEmail,
  contactID,
  prefilledMessage = '',
  regenerateData,
}: EmailBoxProps) => {
  const { user } = useAuth0();
  const [isReply, setIsReply] = useState(!!replyEmail);
  const prefilledEmailSubject = `${encodeName(contactFirstName)} <> ${user?.given_name} | Career Insights `;
  const [warningMessage, setWarningMessage] = useState<string>();
  const [isSendingDisabled, setIsSendingDisabled] = useState(true);
  const [selectedEmails, setSelectedEmails] = useState([primaryEmail]);
  const [messageText, setMessageText] = useState(prefilledMessage);
  const reactQuillRef = useRef<ReactQuill | null>(null);
  const subjectRef = useRef<HTMLDivElement>(null);
  const [subject, setSubject] = useState(prefilledSubject || prefilledEmailSubject);

  const checkMessageFields = () => {
    const isMessageEmpty = reactQuillRef.current && reactQuillRef.current.getEditor().getText().trim().length === 0;
    return !isMessageEmpty && subject.length > 0 && selectedEmails.length > 0;
  };

  const checkTextForPlaceholders = () => {
    const hasPlaceholders = messageText.match(/\[[^[\]]*\]/g) || subject.match(/\[[^[\]]*\]/g);

    if (hasPlaceholders) {
      setIsSendingDisabled(true);
      setWarningMessage('Please make sure to replace the placeholders in brackets with the according information.');
    } else {
      setIsSendingDisabled(false);
      setWarningMessage(undefined);
    }

    return !hasPlaceholders;
  };

  const handleSendMessageClick = () => {
    if (!checkTextForPlaceholders()) {
      return;
    }

    handleSendEmail(transformEmailHTML(messageText), subject, selectedEmails);
  };

  const highlightPlaceholders = () => {
    if (!reactQuillRef.current) {
      return;
    }
    const quillEditor = reactQuillRef.current.getEditor();
    const bracketedParts = findTextInBrackets(quillEditor.getText());
    quillEditor.formatText(0, quillEditor.getText().length, {
      color: false,
    });
    bracketedParts.forEach(part => {
      quillEditor.formatText(part.index, part.length, {
        color: 'var(--purpose-information)',
      });
    });
  };

  useDebounceEffect(() => {
    if (messageText) {
      highlightPlaceholders();
    }
  }, [messageText]);

  useEffect(() => {
    setIsSendingDisabled(!checkMessageFields());

    if (warningMessage) {
      checkTextForPlaceholders();
    }
  }, [messageText, subject, selectedEmails]);

  useEffect(() => {
    if (replyEmail) {
      setSelectedEmails([replyEmail]);
      setIsReply(true);
    }

    if (subjectRef.current) {
      subjectRef.current.innerText = (prefilledSubject || prefilledEmailSubject);
    }
  }, [replyEmail, prefilledSubject]);

  return (
    <div
      className="email-box"
    >
      <div className={`email-box__container ${warningMessage ? 'email-box__container--dangerous' : ''}`}>
        <EmailRecipient
          selectedEmails={selectedEmails}
          setSelectedEmails={setSelectedEmails}
          emailAddresses={emailAddresses}
        />
        <div className="email-box__separator" />
        <div className="email-box__subject email-box__header-line">
          {isReply ? prefilledSubject : (
            <ContenteditableField handleChange={setSubject} ref={subjectRef} placeholder="Enter Subject Line" />
          )}
        </div>
        <div className="email-box__separator" />
        <div className="email-box__text-field">
          <EditorToolbar />
          <ReactQuill
            ref={reactQuillRef}
            placeholder="Draft your message or use our AI assistant to bring your outreach to the next level."
            modules={{
              toolbar: {
                container: '#toolbar',
              },
            }}
            theme="snow"
            value={messageText}
            onChange={setMessageText}
            style={{
              border: 'none',
              padding: '0',
              height: '160px',
            }}
          />
        </div>
      </div>
      {warningMessage && <div className="email-box__warning">{warningMessage}</div>}
      <MessageInputButtons
        primaryButtonLabel="Send Email"
        primaryButtonIcon="envelope"
        isPrimaryButtonDisabled={isSendingDisabled}
        handleRegenerateClick={(message: string) => setMessageText(convertNewLinesToHtml(message))}
        handleSendMessageClick={handleSendMessageClick}
        regenerateWithOptions
        messageType="email"
        contactID={contactID}
        regenerateData={regenerateData}
      />
    </div>
  );
};
