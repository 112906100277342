import { Company } from '../company/types';
import { trackerTabs } from './content';

export interface TaskObject {
  name: TaskNames;
  isAI?: boolean;
  content: {
    label: string;
    isLink?: boolean;
    to?: string;
    action?: TaskAction;
    target?: React.HTMLAttributeAnchorTarget;
  }[];
}

export type TaskAction = {
  type: 'openTab' | 'openTip' | 'useAITool';
  target: string;
};

export enum TaskNames {
  extension = 'Download Chrome Extension',
  saveContact = 'Save your first contact',
  optimizeProfile = 'Optimize LinkedIn Profile',
  scheduleCoffeeChat = 'Schedule coffee chat',
  coffeeChatQuestions = 'Create coffee chat questions',
  followUp = 'Follow up with contacts',
  roleRequirements = 'Understand role requirements',
  careerStory = 'Craft your career story',
  tailorResume = 'Tailor resume',
  tailorCoverLetter = 'Tailor cover letter',
  practiceInterview = 'Practice interview',
  reviewInterviewTips = 'Review interview tips',
  checkNews = 'Check company news',
  prepareInterviewTechniques = 'Prepare for industry-specific interview techniques',
  prepareForInterview = 'Prepare for interview',
  researchInterviewer = 'Research interviewer',
  prepareQuestions = 'Prepare questions for the interviewer',
  reviewInterviewDosAndDonts = 'Review interview dos and don’ts',
  researchSalaries = 'Research comparable salaries',
  prepareNegotiation = 'Prepare for negotiation',
  evaluateOffer = 'Evaluate offer',
}

export type TrackerModalProps = {
  company: Company;
  status: string;
  companyID: string;
  revalidate: () => void;
  openTab?: typeof trackerTabs[number];
};
