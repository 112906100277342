import './tag.scss';

export enum TagColors {
  LightGreen = 'light-green',
  LightPurple = 'light-purple',
  Pink = 'pink',
  Orange = 'orange',
  Blue = 'blue',
  Green = 'green',
  Red = 'red',
  LightBlue = 'light-blue',
  BlueGreen = 'blue-green',
  Grey = 'grey',
}

type TagColorsType = {
  [K in keyof typeof TagColors]: string;
}[keyof typeof TagColors];

export type TagType = {
  id: string;
  label: string;
  color: TagColorsType;
  image?: string;
};

interface TagProps {
  label: string;
  color?: TagColorsType;
  image?: string;
  handleClose?: () => void;
  handleClick?: React.MouseEventHandler<HTMLDivElement>;
}

/**
 * Primary UI component for tags
 */
export const Tag = ({
  label,
  color = 'light-purple',
  image,
  handleClick,
  handleClose,
}: TagProps) => {
  const isInteractive = handleClick || handleClose;

  return (
    <div
      data-testid="tag"
      onClick={handleClick}
      className={[
        'careerOS-tag',
        `careerOS-tag--color-${color}`,
        isInteractive ? 'careerOS-tag--clickable' : '',
      ].join(' ')}
    >
      {image && <img className="careerOS-tag__image" src={image} alt={label} />}
      <span className="careerOS-tag__label">{label}</span>
      {handleClose && (
        <i
          data-testid="close-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
          className="careerOS-tag__icon bi bi-x"
        />
      )}
    </div>
  );
};
