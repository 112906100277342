import { UserConnectionsResponse } from '@/domains/core/contact/types';
import { QuestTileType, QuestType } from '@/domains/core/student/types';
import { changeQuestStatus } from '../api/student';
import { QUESTSTATUS } from '@/domains/core/student/components/quests/quest-service/quest.service';
import { QUEST_ORDER } from '@/domains/core/student/components/quests/quests-data';

// Filter and update quests based on user connections and archived applications
export async function filterAndUpdateQuests(quests: QuestTileType[], userConnections: UserConnectionsResponse | undefined) {
  const completedQuests: QuestTileType[] = [];
  const updatedQuests = quests.filter((quest) => {
    if (quest.type === QuestType.SendConnection) {
      const isUserConnected = userConnections?.connected.find((connection) => connection.url === quest.contact?.linkedin_url);
      const isConnectionPending = userConnections?.pending.find((connection) => connection.url === quest.contact?.linkedin_url);
      if (isUserConnected || isConnectionPending) {
        completedQuests.push(quest);
        return false;
      }

      return true;
    }

    return true;
  });

  // Mark completed quests as done
  await Promise.all(completedQuests.map(async (completedQuest) => {
    await changeQuestStatus(completedQuest.id, QUESTSTATUS.DONE, completedQuest.type);
  }));

  return updatedQuests;
}

export const sortQuests = (questsToSort: QuestTileType[]) => questsToSort.sort((a, b) => {
  // Sort by QUEST_ORDER
  if (QUEST_ORDER[a.type] > QUEST_ORDER[b.type]) return 1;
  if (QUEST_ORDER[a.type] < QUEST_ORDER[b.type]) return -1;

  // If QUEST_ORDER is the same, sort by created_at (newest first)
  // Parse created_at into Date objects to compare timestamps
  const dateA = new Date(a.created_at).getTime();
  const dateB = new Date(b.created_at).getTime();

  // Since we want newest first, we reverse the comparison
  return dateB - dateA;
});
