import { Link, useLoaderData } from 'react-router-dom';
import { useState } from 'react';
import { Input } from '@/components/input/input';
import './styles/people.scss';
import { Student } from '@/domains/core/advisor/types';
import { router } from '@/main';

export default function StudentsPage() {
  const { students } = useLoaderData() as any;
  const [searchValue, setSearchValue] = useState('');
  const [filteredStudents, setFilteredStudents] = useState(students);
  const searchStudentsByName = (value: string) => {
    setSearchValue(value);
    const filteredStudentsResult = students.filter((student: Student) => student.name.toLowerCase().includes(value.toLowerCase().trim()));
    setFilteredStudents(filteredStudentsResult);
  };

  return (
    <div className="people-list">
      <div className="white-wrapper">
        <h2 className="people-list__title">Student Table</h2>
        <p className="people-list__subtitle">
          Here you have full visibility of all the students in the cohort, along with their Overview Boards tracking their progress.
        </p>
        <Input
          icon="bi bi-search"
          value={searchValue}
          id="search-people"
          label=""
          placeholder="Search"
          handleValueChange={searchStudentsByName}
        />
        <div className="people-list__role-count">
          <span>Total Students in Cohort</span>
          <div className="people-list__role-count__number">
            {students.length}
          </div>
        </div>
        <table className="people-list__table table">
          <thead>
            <th>Name</th>
            <th>Email</th>
            <th>Progress</th>
          </thead>
          {filteredStudents.map((student: Student) => (
            <tr onClick={() => router.navigate(`/app/student-overview/${student.id}/university/${student.universityID}`)}>
              <td>{student.name}</td>
              <td>{student.email}</td>
              <td className="people-list__table__td">
                {student.logged_in ? (
                  <>
                    <Link
                      className="people-list__table__link"
                      to={`/app/student-overview/${student.id}/university/${student.universityID}`}
                    >
                      <i className="people-list__table__icon--eye bi bi-eye" />
                      <span>See Overview Board</span>
                    </Link>
                    <Link
                      className="people-list__table__link"
                      to={`/app/student-overview/${student.id}/university/${student.universityID}#Documents`}
                    >
                      <i className="people-list__table__icon--document bi bi-file-earmark" />
                      <span>Documents</span>
                    </Link>
                  </>
                ) : (
                  <>-</>
                )}
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
}
