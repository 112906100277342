import {
  getBaseResume, getBaseResumes, getTailoredResume, getTailoredResumes,
} from '@/services/api/resume';
import {
  Profile, ResumeBuilderResponse, ResumesIDResponse, ResumesResponse,
} from '../types';
import { getSelfJobs } from '@/services/api/job';

export async function resumeBuilderLoader(): Promise<ResumeBuilderResponse> {
  const jobs = await getSelfJobs();
  return {
    jobs,
  };
}

export async function resumeBuilderListLoader(): Promise<ResumesResponse> {
  let [baseResumes, tailoredResumes] = await Promise.all([getBaseResumes(), getTailoredResumes()]);
  baseResumes = Array.isArray(baseResumes) ? baseResumes?.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()) : [];
  tailoredResumes = Array.isArray(tailoredResumes) ? tailoredResumes?.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()) : [];
  return {
    baseResumes,
    tailoredResumes,
  };
}

export async function resumeBuilderBaseLoader(request: any): Promise<ResumesIDResponse> {
  const { resumeID } = request.params;
  const resumeResponse = await getBaseResume(resumeID);
  const nationality = resumeResponse.resume.basics?.nationality?.filter((nat: string) => !!nat) ?? [''];
  return {
    ...resumeResponse,
    resume: {
      basics: {
        ...resumeResponse.resume.basics,
        phone_numbers: [...resumeResponse.resume.basics.phone_numbers ?? ['']],
        emails: [...resumeResponse.resume.basics.emails ?? ['']],
        nationality: nationality.length > 0 ? nationality : [''],
        url: resumeResponse.resume.basics.profiles?.find((profile: Profile) => profile.network === 'LinkedIn')?.url || resumeResponse.resume.basics.url || '',
      },
      education: resumeResponse.resume.education?.length > 0 ? resumeResponse.resume.education : [{
        institution: '', area: '', study_type: '', start_date: '', end_date: '', gpa: '', courses: [''],
      }],
      work: resumeResponse.resume.work?.length > 0 ? resumeResponse.resume.work : [{
        company: '', position: '', website: '', start_date: '', end_date: '', summary: '', highlights: [''],
      }],
      skills: resumeResponse.resume.skills?.length > 0 ? resumeResponse.resume.skills : [{ name: '', level: '' }],
      languages: resumeResponse.resume.languages?.length > 0 ? resumeResponse.resume.languages : [{ language: '', fluency: '' }],
      additional_information: {
        ...resumeResponse.resume.additional_information,
        bullets: resumeResponse.resume.additional_information?.bullets && resumeResponse.resume.additional_information?.bullets.length > 0 ? resumeResponse.resume.additional_information?.bullets : ['', ''],
      },
    },
  };
}

export async function resumeBuilderTailoredLoader(request: any): Promise<ResumesIDResponse> {
  const { resumeID } = request.params;
  const resumeResponse = await getTailoredResume(resumeID);
  return {
    ...resumeResponse,
  };
}
