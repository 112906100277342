import './resume-accordion.scss';

type Props = {
  toggle: React.ReactNode;
  children: React.ReactNode;
  isOpen: boolean;
};

export const ResumeAccordion = ({ toggle, children, isOpen }: Props) => (
  <div className="resume-accordion">
    <div className="resume-accordion__toggle">{toggle}</div>
    <div
      className={`resume-accordion__item ${
        !isOpen ? 'resume-accordion__item--collapsed' : 'resume-accordion__item--opened'
      }`}
    >
      <div className="resume-accordion__content">{children}</div>
    </div>
  </div>
);
