import { Counter } from '../counter/counter';

type Props = {
  size?: 'medium' | 'large';
  tabName: string;
  counter?: number;
  icon?: string;
  isActive: boolean;
  isDisabled?: boolean;
  isUnread?: boolean;
  handleTabClicked: () => void;
};

export const Tab = ({
  tabName,
  icon,
  size,
  isActive,
  counter,
  isDisabled = false,
  isUnread = false,
  handleTabClicked,
}: Props) => (
  <div
    onClick={handleTabClicked}
    className={`careerOS-tab careerOS-tab--${size} ${
      isActive ? 'careerOS-tab--active' : ''
    } ${isDisabled ? 'careerOS-tab--disabled' : ''}`}
  >
    {icon && (
      <div className={`careerOS-tab__icon ${isUnread ? 'careerOS-tab__icon--unread' : ''}`}>
        <i className={icon} />
      </div>
    )}
    <span>{tabName}</span>
    {counter !== undefined && (
      <Counter counter={counter} size="medium" mode="default" />
    )}
  </div>
);
