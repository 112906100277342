import React, { useState, useEffect } from 'react';
import { Button } from '@/components/button/Button';
import './manage-email.scss';
import { ManageEmailField } from './manage-email-field';

interface ManageEmailProps {
  handleClose: () => void;
  managePrimaryEmail: (emailAdress: string) => void;
  manageSecondaryEmail: (emailAdress: string) => void;
  swapEmails: () => void;
  primaryEmail: string;
  secondaryEmail: string;
}

export const ManageEmailBox = ({
  handleClose,
  primaryEmail,
  secondaryEmail,
  managePrimaryEmail,
  manageSecondaryEmail,
  swapEmails,
}: ManageEmailProps) => {
  const [newSecondaryEmail, setNewSecondaryEmail] = useState('');
  const [isSecondaryOpen, setIsSecondaryOpen] = useState(false);

  const clearSecondaryEmail = () => {
    setNewSecondaryEmail('');
  };

  const handleSecondaryCancel = () => {
    if (newSecondaryEmail.length) {
      clearSecondaryEmail();
    } else {
      setIsSecondaryOpen(false);
    }
  };

  useEffect(() => {
    setIsSecondaryOpen(!!secondaryEmail);
  }, [secondaryEmail]);

  return (
    <div className="manage-email-box">
      <ManageEmailField
        email={primaryEmail}
        handleClose={handleClose}
        manageEmail={managePrimaryEmail}
      />
      {(primaryEmail && !isSecondaryOpen && !secondaryEmail) && (
        <div className="manage-email-box__button">
          <Button
            label="Add secondary email"
            mode="text"
            size="small"
            handleClick={() => setIsSecondaryOpen(true)}
            icon="bi bi-plus-circle"
          />
        </div>
      )}

      {(primaryEmail && (isSecondaryOpen || secondaryEmail)) && (
        <ManageEmailField
          emailType="secondary"
          email={secondaryEmail}
          swapEmails={swapEmails}
          handleClose={handleSecondaryCancel}
          manageEmail={manageSecondaryEmail}
        />
      )}
    </div>
  );
};
