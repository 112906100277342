import NetworkingInfoModal from './networking/networking';
import './tracker-info.scss';

interface TrackerInfoProps {
  title: string;
  activeState: string;
}
/**
 * Primary UI component for the modal that warns the user about moving to the next step
 */

export const TrackerInfo = ({ title, activeState }: TrackerInfoProps) => {
  const renderSwitch = () => {
    switch (activeState) {
      case 'Networking':
        return <NetworkingInfoModal />;
      default:
        return <NetworkingInfoModal />;
    }
  };
  return (
    <div className="tracker-info">
      <div className="tracker-info__title">{title}</div>
      <div className="tracker-info__content">{renderSwitch()}</div>
      <div className="tracker-info__footer" />
    </div>
  );
};
