import { signInWithCustomToken } from 'firebase/auth';
import {
  collection, getDocs, query,
  where,
} from 'firebase/firestore';
import { useState } from 'react';
import { Contact, UserConnectionsResponse } from '@/domains/core/contact/types';
import { useExtensionMessaging } from './use-extension-messaging';
import { getFirebaseUserToken, updateContactConnectionStatus } from '@/services/api/contact';
import { auth, db } from '@/services/firebase/client';
import { getNetworkingStatusByMessages } from '../helpers/contact';

export const useNetworkingStatuses = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    getContactConnectionStatus, checkLinkedInLogin, getUserLinkedInConnections,
  } = useExtensionMessaging();

  const fetchAllLinkedInMessages = async (contactIDs: string[], selfId: string) => {
    const token: string = await getFirebaseUserToken();
    await signInWithCustomToken(auth, token);
    const conversationsRef = collection(db, 'linkedInConversations');
    if (contactIDs.length) {
      const linkedInQuery = query(
        conversationsRef,
        where('userID', '==', selfId),
      );
      const messageSnapshot = await getDocs(linkedInQuery);

      if (!messageSnapshot.empty) {
        const messagesByContact: { [id: string]: any[]; } = {};

        // Loop through each conversation document
        await Promise.all(messageSnapshot.docs.map(async (doc) => {
          const docData = doc.data();
          const contactID = docData.toContactID; // Assuming 'toContactID' is available directly

          if (!contactIDs.includes(contactID)) {
            return;
          }
          // Retrieve messages from each conversation
          const messagesRef = collection(doc.ref, 'messages');
          const messagesSnapshot = await getDocs(query(messagesRef));

          // Collect messages for each contact
          messagesByContact[contactID] = messagesSnapshot.docs.map((msgDoc) => msgDoc.data());
        }));

        return messagesByContact;
      }
    }

    return {};
  };

  const fetchAllEmails = async (contactIDs: string[], selfId: string) => {
    const token: string = await getFirebaseUserToken();
    await signInWithCustomToken(auth, token);
    const conversations: { [id: string]: any[]; } = {};
    if (contactIDs.length) {
      await Promise.all(contactIDs.map(async (contactId) => {
        const conversationId = `${selfId}:${contactId}`;
        const contactEmails: any[] = [];
        const messagesRef = collection(db, 'outlookConversations', conversationId, 'messages');
        const queryForLatestMessage = query(messagesRef);
        const emailsSnapshot = await getDocs(queryForLatestMessage);

        if (!emailsSnapshot.empty) {
          emailsSnapshot.forEach(message => {
            if (message.exists()) {
              const data = message.data();
              contactEmails.push(data);
            }
          });
        }

        if (contactEmails.length) {
          conversations[contactId] = contactEmails;
        }
      }));
      return conversations;
    }

    return {};
  };

  const checkContactLinkedInConnectionStatus = async (contact: Contact, userLinkedInConnections: UserConnectionsResponse | undefined) => {
    let status = contact.linkedin_connection_status || 'notConnected';

    try {
      if (userLinkedInConnections) {
        const userConnected = userLinkedInConnections.connected
          .find((connection) => connection.url === contact.linkedin_url);
        const userPending = userLinkedInConnections.pending
          .find((connection) => connection.url === contact.linkedin_url);
        const connectedStatus = userConnected ? 'connected' : undefined;
        const pendingStatus = userPending ? 'pending' : undefined;

        status = connectedStatus || pendingStatus || 'notConnected';
      } else {
        status = await getContactConnectionStatus(contact.linkedin_url);
      }

      if (status !== contact.linkedin_connection_status) {
        await updateContactConnectionStatus(contact, status);
      }

      return status;
    } catch (error) {
      return status;
    }
  };

  const mapAllConnectionStatuses = async (contacts: Contact[], selfId: string) => {
    try {
      setIsLoading(true);
      const [emails, linkedInMessages] = await Promise.all([
        fetchAllEmails(contacts.map((contact) => contact.id), selfId),
        fetchAllLinkedInMessages(contacts.map((contact) => contact.id), selfId),
      ]);
      const notConnectedContacts = contacts.filter((contact) => contact.linkedin_connection_status !== 'connected');
      // Fetch user LinkedIn connections if the user has more than 15 contacts not connected with because getUserLinkedInConnections function can be slow
      const userLinkedInConnections: UserConnectionsResponse | undefined = notConnectedContacts.length > 15 ? await getUserLinkedInConnections() : undefined;
      const linkedInLoginStatus = await checkLinkedInLogin();

      const connectionStatuses: Contact[] = await Promise.all(contacts.map(async (contact) => {
        const linkedInMessagesForContact = linkedInMessages[contact.id] || [];
        const emailsForContact = emails[contact.id] || [];
        const networkingStatus = getNetworkingStatusByMessages(linkedInMessagesForContact, emailsForContact);
        let linkedInConnectionStatus = contact.linkedin_connection_status;

        if (linkedInConnectionStatus === 'connected') {
          linkedInConnectionStatus = 'connected';
        } else if (linkedInLoginStatus === 'User is logged into LinkedIn.') {
          linkedInConnectionStatus = await checkContactLinkedInConnectionStatus(contact, userLinkedInConnections);
        }

        return {
          ...contact,
          linkedin_connection_status: linkedInConnectionStatus,
          networking_status: networkingStatus,
        };
      }));

      return connectionStatuses;
    } catch (error) {
      return contacts;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchAllLinkedInMessages,
    fetchAllEmails,
    checkContactLinkedInConnectionStatus,
    mapAllConnectionStatuses,
    isLoading,
  };
};
