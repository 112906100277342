import { Badge, BadgeColor } from '@/components/badge/badge';
import { ConnectionStatus } from '../../types/contact';
import { Tooltip } from '@/components/tooltip/tooltip';

type StatusTagSize = 'small' | 'medium';

type Props = {
  status: ConnectionStatus;
  type: 'networking' | 'linkedin';
  size?: StatusTagSize;
  withTooltip?: boolean;
};

type StatusesType = {
  [key in 'networking' | 'linkedin']: {
    [K in ConnectionStatus]: {
      color: BadgeColor;
      text: {
        [size in StatusTagSize]: string;
      }
      tooltip?: string;
    }
  };
};

const statuses: StatusesType = {
  linkedin: {
    pending: {
      color: 'yellow',
      text: {
        medium: '⏳ Connection Pending',
        small: '⏳',
      },
      tooltip: 'LinkedIn connection request pending ',
    },
    connected: {
      color: 'light-green',
      text: {
        medium: '✅ Connected',
        small: '✅',
      },
      tooltip: 'Connected on LinkedIn',
    },
    notConnected: {
      color: 'light-red',
      text: {
        medium: '✖️ Not Connected',
        small: '✖️',
      },
      tooltip: 'Not connected on LinkedIn',
    },
  },
  networking: {
    pending: {
      color: 'yellow',
      text: {
        medium: 'Response Pending',
        small: '⏳',
      },
    },
    connected: {
      color: 'light-green',
      text: {
        medium: 'Networking',
        small: '✅',
      },
    },
    notConnected: {
      color: 'light-red',
      text: {
        medium: 'No Outreach Yet',
        small: '✖️',
      },
    },
  },
};

export const StatusTag = ({
  status,
  type,
  size = 'medium',
  withTooltip = false,
}: Props) => (
  <div className="contact-status-tag">
    <Tooltip position="top" withArrow label={statuses[type][status]?.tooltip || ''} disableHoverListener={!withTooltip || !statuses[type][status]?.tooltip}>
      <Badge color={statuses[type][status].color} label={statuses[type][status].text[size]} />
    </Tooltip>
  </div>
);
