import { DocumentToAddPayload, VideoLinkToAddPayload } from '@/domains/core/student/types';
import { http } from '@/services/api/http';

export const getAllDocuments = async (userID: string) => http.get(`/users/${userID}/documents`);

export const addDocument = async (document: DocumentToAddPayload) => http.post('/users/self/documents', document);

export const editDocument = async (documentID: string, document: DocumentToAddPayload) => http.patch(
  `/document/${documentID}`,
  document,
);

export const deleteDocument = async (documentID: string) => http.delete(`/documents/${documentID}`);

export const addVideoLink = async (videoLink: VideoLinkToAddPayload) => http.post('/users/self/links', videoLink);
