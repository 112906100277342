import { Tooltip } from '../tooltip/tooltip';
import './toggle-views.scss';

export type ToggleViewsOptions = {
  size: 'small' | 'large';
  selectedValue: number;
  emitSelectedValue: (value: number) => void;
  icons: string[];
  labels: string[];
  iconsSelected: string[];
};

export const ToggleViews = ({
  size, selectedValue = 0, emitSelectedValue, icons, iconsSelected, labels,
}: ToggleViewsOptions) => (
  <div className={`toggle-views toggle-views--${size} toggle-views--${selectedValue}`}>
    <div className="toggle-views__option" onClick={() => emitSelectedValue(0)}>
      <Tooltip label={labels[0]} position="top">
        {selectedValue === 0
          ? <i className={`bi bi-first-value ${iconsSelected[0]}`} />
          : <i className={`bi bi-first-value ${icons[0]}`} />}
      </Tooltip>
    </div>

    <div className="toggle-views__option" onClick={() => emitSelectedValue(1)}>
      <Tooltip label={labels[1]} position="top">
        {selectedValue === 1
          ? <i className={`bi bi-second-value ${iconsSelected[1]}`} />
          : <i className={`bi bi-second-value ${icons[1]}`} />}
      </Tooltip>
    </div>
  </div>
);
