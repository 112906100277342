import { TabsContent } from '@/components/tabs';
import {
  CompanyHeader,
  CompanyOverviewContainer,
  SavedJob,
} from '@/domains/core/company';
import { Company } from '../../types';
import { Contact } from '@/domains/core/contact/types';
import { Button } from '@/components/button/Button';

interface CompanyProps {
  company: Company;
  contacts: Contact[];
  goToLinkedinCompanyJobs: () => void;
}

export const NormalCompany = ({ company, contacts, goToLinkedinCompanyJobs }: CompanyProps) => (
  <div className="company-page">
    <CompanyHeader
      name={company.name}
      logo={company.logo_url}
      tabs={['Overview', 'Jobs']}
    />
    <div className="content">
      <TabsContent>
        <div data-tab="Overview">
          <CompanyOverviewContainer contacts={contacts} company={company} />
        </div>
        <div data-tab="Jobs">
          <div className="jobs-wrapper">
            {!company.jobs ? (
              <div className="jobs-not-found">
                <h3 className="jobs-not-found__title">No jobs saved yet</h3>
                <p className="jobs-not-found__subtitle">Looking for jobs at this company? Use the Chrome Extension to save jobs from Linkedin and be guided through the required steps to get your dream job.</p>
                <Button handleClick={goToLinkedinCompanyJobs} mode="primary" size="large" label="Take me to Linkedin" />
              </div>
            ) : (
              <div className="saved-jobs">
                <h2 className="saved-jobs__title">Saved jobs</h2>
                {company?.jobs.map((job: any) => (
                  <SavedJob title={job.title} location={job.location} companyID={`${company.id}`} companyName={company.name} />
                ))}
              </div>
            )}
          </div>

        </div>
      </TabsContent>
    </div>
  </div>
);
