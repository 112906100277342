import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import { Button } from '@/components/button/Button';
import { ModalContext } from '@/components/modal/modal-provider';
import { AddDocument, AddVideo } from '@/domains/generic/modals';
import { DocumentsResponse, DocumentToAddPayload } from '@/domains/core/student/types';
import { addDocument, addVideoLink } from '@/services/api/documents';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { Loader } from '@/components/loader/loader';
import { VideoLinkToAddPayload } from '@/domains/core/student/types/document';
import { WebSocketService, createWebSocketService } from '@/services/websocket/websocket';
import { getConfig } from '@/config';
import './styles/my-documents.scss';
import { DocumentTable } from '@/domains/core/student';

const config = getConfig();
const websocketUrl = `${config.websocketUrl}/ws/documents`;

const MyDocumentsPage = () => {
  const { documents: initialDocuments } = useLoaderData() as DocumentsResponse;
  const [documents, setDocuments] = useState(initialDocuments);
  const { openModal } = useContext(ModalContext) as any;
  const [wsService, setWsService] = useState<WebSocketService | null>(null);
  const { trackEvent } = useAnalytics();
  const revalidator = useRevalidator();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDocuments(initialDocuments);
  }, [initialDocuments]);

  const handleWebSocketMessage = useCallback((event: any) => {
    if (!event.data) return;

    const dataObj = JSON.parse(event.data);
    if (dataObj.payload && dataObj.payload.status && dataObj.payload.documentID) {
      setDocuments((currentDocuments) => currentDocuments.map((doc) => {
        if (doc.id === dataObj.payload.documentID) {
          return { ...doc, status: dataObj.payload.status, link: dataObj.payload.link };
        }
        return doc;
      }));

      if (wsService) {
        wsService.close();
        setWsService(null);
      }
    }
  }, [wsService]);

  const connectWebSocket = () => {
    const token = window.localStorage.getItem('token');
    if (token) {
      const newWsService = createWebSocketService(websocketUrl, token);
      newWsService.onMessage(handleWebSocketMessage);
      setWsService(newWsService);
    }
  };

  const handleAddDocument = async (document: DocumentToAddPayload) => {
    try {
      setIsLoading(true);
      await addDocument(document);
      trackEvent('Document Uploaded');
      connectWebSocket();
      revalidator.revalidate();
    } catch (error) {
      console.error('Error uploading document:', error);
    } finally {
      setTimeout(() => setIsLoading(false), 700);
    }
  };

  const handleAddVideoLink = async (videoLink: VideoLinkToAddPayload) => {
    try {
      setIsLoading(true);
      await addVideoLink(videoLink);
      trackEvent('Video Link Uploaded');
      revalidator.revalidate();
    } catch (error) {
      console.error('Error uploading video link:', error);
    } finally {
      setTimeout(() => setIsLoading(false), 700);
    }
  };

  const openAddDocumentModal = () => {
    openModal(<AddDocument handleSubmit={handleAddDocument} />, true);
  };
  const openAddVideoLinkModal = () => {
    openModal(<AddVideo handleSubmit={handleAddVideoLink} />, true);
  };

  return (
    <div className="my-documents">
      {isLoading && (
        <div id="loader-zone">
          <Loader />
        </div>
      )}
      <div className="my-documents__top">
        <div className="my-documents__header">
          <h2 className="my-documents__title">My Documents</h2>
          <p className="my-documents__subtitle">Upload any documents that are relevant to your job search.</p>
        </div>
        <div className="my-documents__upload-info">
          <div className="my-documents__actions">
            <Button
              label="Add document"
              icon="bi bi-filetype-doc"
              mode="primary"
              size="medium"
              outlined
              handleClick={openAddDocumentModal}
            />
            <Button
              label="Add video link"
              icon="bi bi-camera-video"
              mode="primary"
              size="medium"
              outlined
              handleClick={openAddVideoLinkModal}
            />
          </div>
        </div>
      </div>
      {!documents.length ? (
        <div className="my-documents__no-docs">
          <div className="my-documents__no-docs-image">🧑‍💻</div>
          <div className="my-documents__no-docs-title">You haven’t uploaded any documents yet</div>
          <div className="my-documents__no-docs-subtitle">Upload relevant files to keep everything organized and easily accessible for your job search.</div>
        </div>
      ) : (
        <DocumentTable documents={documents} />
      )}
    </div>
  );
};

export default MyDocumentsPage;
