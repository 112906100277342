import { useIdentities } from '@/services/queries/user';

export function useActiveEmail() {
  const { data: identities } = useIdentities();

  if (!identities || !identities.length) {
    return '';
  }

  // todo: replace when we allow to connect many inboxes at the same time
  return identities[0].email;
}
