import { useEffect, useState } from 'react';
import { Button } from '@/components/button/Button';
import { ContactCard } from '@/domains/core/contact';
import { SuggestedContacts } from '../suggested-contacts/suggested-contacts';

import './tracker-contacts-tab.scss';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { ContactTabStates } from '@/domains/core/tracker-data';
import { useNetworkingStatuses } from '@/services/hooks/use-networking-statuses';
import { useSelf } from '@/services/queries/user';

type TrackerContactTabProps = {
  contacts: any[];
  companyLinkedIn: string;
  companyId: string;
  companyName: string;
  updateContacts: () => void;
  tabState?: ContactTabStates;
  changeTab: (tab: string, state?: string) => void;
};

export const TrackerContactTab = ({
  contacts,
  companyLinkedIn,
  companyId,
  companyName,
  updateContacts,
  tabState,
  changeTab,
}: TrackerContactTabProps) => {
  const [showSuggestedContacts, setShowSuggestedContacts] = useState(!contacts.length);
  const [contactsWithStatus, setContactsWithStatus] = useState(contacts);
  const { trackEvent } = useAnalytics();
  const { data: self } = useSelf();
  const { mapAllConnectionStatuses } = useNetworkingStatuses();

  const handleGetSuggestedContacts = () => {
    trackEvent('Get Suggested Contacts Clicked');
    setShowSuggestedContacts(true);
  };

  const handleSeeMoreContactsClicked = () => {
    trackEvent('Linkedin See More Contacts Clicked');
  };

  useEffect(() => {
    if (tabState === 'addContact') {
      setShowSuggestedContacts(true);
      document.querySelector('.tracker-overlay')?.scrollTo({ top: 500, behavior: 'smooth' });
    }
  }, [tabState]);

  useEffect(() => {
    if (self && contacts.length) {
      mapAllConnectionStatuses(contacts, self.id).then((contactsResponse) => setContactsWithStatus(contactsResponse));
    }
  }, [contacts, self]);

  return (
    <div className="contacts-tab">
      {(contacts.length > 0) && (
        <div className="contacts-tab__saved-contacts">
          <h3 className="contacts-tab__title">
            Saved Contacts
          </h3>
          <div className="contacts-tab__container">
            {contactsWithStatus.map((contact) => (
              <ContactCard
                key={contact.id}
                id={contact.id}
                imageURL={contact.career_summary.basics.image}
                contactPosition={contact.current_position.title}
                contactName={`${contact.first_name} ${contact.last_name}`}
                networkingStatus={contact.networking_status || 'notConnected'}
                linkedInStatus={contact.linkedin_connection_status || 'notConnected'}
              />
            ))}
          </div>
          {!showSuggestedContacts && (
            <div className="contacts-tab__actions">
              <Button
                label="Get Suggested Contacts"
                icon="bi bi-arrow-counterclockwise"
                size="small"
                mode="primary"
                handleClick={handleGetSuggestedContacts}
                outlined
              />
            </div>
          )}
        </div>
      )}
      {(showSuggestedContacts) && (
        <SuggestedContacts
          tabState={tabState}
          changeTab={changeTab}
          updateContacts={updateContacts}
          handleSeeMoreContactsClicked={handleSeeMoreContactsClicked}
          companyLinkedIn={companyLinkedIn}
          companyId={companyId}
          savedContactsUrls={contacts.map((contact) => contact.linkedin_url)}
          companyName={companyName}
        />
      )}
    </div>
  );
};
