import { FilterNameType, FilterOption, Tile } from '@/domains/core/company/types';

export const prettifyCompanyOverview = (text: string) => {
  if (typeof text === 'string') {
    let newParagraph = '';

    text.split(/\s/).forEach((word) => {
      if (word.includes('.')) {
        newParagraph += `${word}\n\n\n`;
      } else {
        newParagraph += `${word} `;
      }
    });
    return newParagraph.trim();
  }

  return '';
};

export const sortByPosition = (unsortedTiles: Tile[]): Tile[] => unsortedTiles.sort((a, b) => {
  if (a.ordering > b.ordering) {
    return 1;
  } if (a.ordering < b.ordering) {
    return -1;
  }
  return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
});

export const filterTiles = (tiles: Tile[], filters: { [name in FilterNameType]: FilterOption[] }, searchQuery: string): Tile[] => {
  const filterNames = Object.keys(filters) as FilterNameType[];

  if (!Array.isArray(tiles)) {
    return [];
  }
  const filtered = tiles.filter((tile) => filterNames.every((filter) => {
    if (!tile.company_name.toLowerCase().includes(searchQuery.trim().toLowerCase())) {
      return false;
    }
    if (filters[filter].length === 0) {
      return true;
    }

    const filterValues = filters[filter].map((option) => option.value);

    switch (filter) {
      case 'industry':
        return filterValues.includes(tile.industry);
      case 'hype_level':
        return filterValues.includes(tile.rating);
      case 'contacts_saved':
        return (filterValues.includes(true) && tile.count_of_contacts > 0) || (filterValues.includes(false) && tile.count_of_contacts === 0);
      case 'jobs_saved':
        return (filterValues.includes(true) && tile.jobs.length > 0) || (filterValues.includes(false) && tile.jobs.length === 0);
      default:
        return true;
    }
  }));

  return filtered;
};

export const getAllSavedIndustries = (tiles: Tile[] = []): string[] => {
  if (!Array.isArray(tiles)) {
    return [];
  }
  const industries = tiles.filter(tile => tile.status !== 'archive').map((tile) => tile.industry);
  return Array.from(new Set(industries));
};

// pulse animation will be shown to prompt user to click on the tile for the first 5 companies (CareerOS is saved by default and does not count)
export const NUMBER_OF_COMPANIES_WITH_PULSE_ANIMATION = 6;

export function formatRange(range: string) {
  if (!range || range.trim() === '') {
    return '';
  }

  if (range.endsWith('+')) {
    const num = Number(range.slice(0, -1));
    return `${num.toLocaleString()}+`;
  }

  const rangeValues = range.split('-').map(num => Number(num));
  if (rangeValues.length === 2 && !Number.isNaN(rangeValues[0]) && !Number.isNaN(rangeValues[1])) {
    return rangeValues.map(num => num.toLocaleString()).join('-');
  }

  return range;
}
