import {
  ForwardedRef, forwardRef, useEffect, useRef, useState,
} from 'react';
import './contenteditable-field.scss';

function getTextSegments(element: Node | Element): { text: any; node: any; }[] {
  return Array.from(element.childNodes).flatMap((node) => {
    switch (node.nodeType) {
      case Node.TEXT_NODE:
        return [{ text: node.nodeValue, node }];
      case Node.ELEMENT_NODE:
        return getTextSegments(node);
      default:
        console.log(`Unexpected node type: ${node.nodeType}`);
        return [];
    }
  });
}

export interface FieldProps {
  placeholder: string;
  defaultValue?: string;
  handleChange?: (value: string) => void;
}

const renderContenteditableField = ({ handleChange, placeholder, defaultValue }: FieldProps, forwardedRef: ForwardedRef<HTMLDivElement>) => {
  const messageRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');

  const keyDownHandler: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault();
    }
  };

  const inputHandler: React.FormEventHandler<HTMLDivElement> = e => {
    setValue(e.currentTarget.innerText);
    if (handleChange) {
      handleChange(e.currentTarget.innerText);
    }
  };

  const restoreSelection = (absoluteAnchorIndex: number | null, absoluteFocusIndex: number | null) => {
    const sel = window.getSelection();
    if (!messageRef.current || !sel || !absoluteAnchorIndex || !absoluteFocusIndex) {
      return;
    }
    const textSegments = getTextSegments(messageRef.current);
    let anchorNode = messageRef.current;
    let anchorIndex = 0;
    let focusNode = messageRef.current;
    let focusIndex = 0;
    let currentIndex = 0;
    textSegments.every(({ text, node }) => {
      const startIndexOfNode = currentIndex;
      const endIndexOfNode = startIndexOfNode + text.length;
      if (startIndexOfNode <= absoluteAnchorIndex && absoluteAnchorIndex <= endIndexOfNode) {
        anchorNode = node;
        anchorIndex = absoluteAnchorIndex - startIndexOfNode;
      }
      if (startIndexOfNode <= absoluteFocusIndex && absoluteFocusIndex <= endIndexOfNode) {
        focusNode = node;
        focusIndex = absoluteFocusIndex - startIndexOfNode;
        return false;
      }
      currentIndex += text.length;
      return true;
    });

    sel.setBaseAndExtent(anchorNode, anchorIndex, focusNode, focusIndex);
  };

  useEffect(() => {
    if (forwardedRef && messageRef.current) {
      if (typeof forwardedRef === 'function') {
        forwardedRef(messageRef.current);
      } else {
        forwardedRef.current = messageRef.current;
      }
    }
  }, [forwardedRef]);

  useEffect(() => {
    if (!messageRef.current) {
      return;
    }

    restoreSelection(messageRef.current.innerText.length, messageRef.current.innerText.length);
  }, [value]);

  return (
    <div
      ref={messageRef}
      className="contenteditable-field"
      data-placeholder={placeholder}
      onInput={inputHandler}
      onKeyDown={keyDownHandler}
      contentEditable
    >
      {defaultValue}
    </div>
  );
};

export const ContenteditableField = forwardRef<HTMLDivElement, FieldProps>(renderContenteditableField);
