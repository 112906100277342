import { useEffect, useState } from 'react';
import { QuestType } from '@/domains/core/student/types';
import { OneLinerExplanation } from '../one-liner/one-liner';
import { PendingState } from '../pending-state/pending-state';
import { ConnectionStatus } from '../../types/contact';

type Props = {
  lastCompletedQuests: QuestType[];
  activeQuests: QuestType[];
  contactName: string;
  companyName: string;
  noMessageHistory: boolean;
  hasUserSentMessage: boolean;
  hasContactResponded: boolean;
  companyID: string;
  contactConnectionStatus: ConnectionStatus;
};

export const MessageBanner = ({
  lastCompletedQuests,
  activeQuests,
  companyName,
  contactName,
  noMessageHistory,
  hasUserSentMessage,
  hasContactResponded,
  companyID,
  contactConnectionStatus,
}: Props) => {
  const [oneLinerOpened, setOneLinerOpened] = useState(false);
  const [showPendindState, setShowPendingState] = useState(false);

  useEffect(() => {
    const pendingState = activeQuests.includes(QuestType.AddNewContact) || activeQuests.includes(QuestType.ScheduleCoffeeChat) || lastCompletedQuests.includes(QuestType.SendFollowUpEmail) || contactConnectionStatus !== 'connected';
    const oneLiner = noMessageHistory || activeQuests.includes(QuestType.SendFollowUpEmail) || activeQuests.includes(QuestType.ScheduleCoffeeChat) || lastCompletedQuests.includes(QuestType.ScheduleCoffeeChat);

    if (pendingState) {
      setShowPendingState(true);
    }

    if (oneLiner) {
      setOneLinerOpened(true);
    }
  }, [lastCompletedQuests, activeQuests]);

  return (
    <>
      {oneLinerOpened && (
        <OneLinerExplanation
          companyName={companyName}
          contactName={contactName}
          handleClose={() => setOneLinerOpened(false)}
          lastCompletedQuests={lastCompletedQuests}
          activeQuests={activeQuests}
          noMessageHistory={noMessageHistory}
        />
      )}
      {showPendindState && (
        <PendingState
          companyID={companyID}
          lastCompletedQuests={lastCompletedQuests}
          activeQuests={activeQuests}
          hasContactResponded={hasContactResponded}
          hasUserSentMessage={hasUserSentMessage}
        />
      )}
    </>
  );
};
