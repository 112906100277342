import { Accordion } from '@/components/accordion/accordion';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { TipModalProps } from '../types';
import '../tracker-tip.scss';
import { useAnalytics } from '@/services/hooks/use-analytics';

const NetworkingTipModal = ({ openTip }: TipModalProps) => {
  const tabs = ['Before', 'During', 'After', 'What Ifs'];
  const { trackEvent } = useAnalytics();

  const trackCopiedToClipboard = () => {
    trackEvent('Tracker Tip: Copied to Cliboard');
  };

  return (
    <div className="company-saved-info-modal">
      <div className="company-saved-info-modal">
        <TabsHeader
          tabs={tabs}
          defaultTab={tabs.includes(openTip.tab) ? openTip.tab : tabs[0]}
          withBorder
        />
        <TabsContent>
          <div data-tab="Before">
            <div className="tip-modal-accordions">
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Research the company and the contact">
                <div>
                  <p>
                    Spend about 15 minutes researching the company and your
                    conversation partner. Use investor relations pages,
                    headlines on the company website and blog posts to find out
                    what topics are currently relevant for the company.
                  </p>
                  <p>
                    Check out your contact’s Linkedin profile to identify some
                    common ground (e.g. you went to the same school, lived in
                    the same city or have previously worked for the same
                    employer).
                  </p>
                  <p>
                    Don’t worry if none of these apply. Just remember: if they
                    have agreed to a coffee chat, they are already willing to
                    help you!
                  </p>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Prepare for common questions">
                <div>
                  <p>
                    “The big 4” are questions that often come up in coffee chats
                    and you should be prepared to answer them confidently.
                  </p>
                  <p>The big 4 are:</p>
                  <ul>
                    <li>Tell me about yourself</li>
                    <li>Why do you want to work for this company?</li>
                    <li>Why do you want to work in this role?</li>
                    <li>Why do you want to work in this industry?</li>
                  </ul>
                  <p>
                    Check out the next tip for two frameworks that will help you
                    structure your answers!
                  </p>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Prepare your career story">
                <div>
                  <p>
                    You will want to tell a compelling and credible story about your
                    career so far and your goals. We recommend using our
                    {' '}
                    <strong>PASSion Model</strong>
                    {' '}
                    to
                    demonstrate your personal motivations.
                  </p>
                  <p>
                    PASSion stands for:
                    <ul>
                      <li>
                        <strong>P</strong>
                        ersonal and Professional Interest: start by showcasing a personal or professional interest in the field you are targeting (e.g. “I am fascinated by…”)
                      </li>
                      <li>
                        <strong>A</strong>
                        ptitude and Affinity: provide evidence of your interest in this field and share examples of how you have engaged with it (e.g. “I am actively involved in…”)
                      </li>
                      <li>
                        <strong>S</strong>
                        kill Set: highlight your skills and how they align with the role or industry (e.g. “I acquired XYZ skill during…”)
                      </li>
                      <li>
                        <strong>S</strong>
                        torytelling: craft a compelling story that ties all of the above together (e.g. “This background has prepared me to…”)
                      </li>
                    </ul>

                  </p>

                  <p>
                    Here is an example story using the PASSion Model:
                    <br />
                    “I have always had a deep personal interest (P - Personal Interest) in technology and innovation, even when I was a kid. While other kids were playing sports, I was fixing up computers  or coding mini programs on our school’s computers (A - Aptitude and Affinity). I then became proficient in multiple coding languages during my Computer Science degree (S - Skill Set). My fascination with technology and my constant following of the newest developments give me confidence that I can quickly gain expertise on any software product I might build or improve in the future (S - Storytelling).”

                  </p>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Prepare to explain transitions in your Resume">
                <div>
                  <p>
                    If you have previously made career transitions into other roles or industries, you can use our
                    {' '}
                    <strong>GROW</strong>
                    {' '}
                    framework to explain your transitions and turn them into a powerful show of personal motivation.
                  </p>
                  <p>
                    <strong>GROW</strong>
                    {' '}
                    stands for:
                    <ul>
                      <li>
                        <strong>G</strong>
                        rounding Experience: begin by explaining your foundational experience or
                        background (e.g. “My educational background in engineering…”)
                      </li>

                      <li>
                        <strong>R</strong>
                        ealizations and Reflections: share the realizations and reflections
                        that led to your transition (e.g. “As I progressed, I began to realize…”)
                      </li>

                      <li>
                        <strong>O</strong>
                        pportunity Pursued: explain the result of your reflections
                        (e.g. “Motivated by these insights, I decided to pursue…”)
                      </li>

                      <li>
                        <strong>W</strong>
                        hy: elaborate on why this transition aligns with your goals, aspirations and
                        long-term vision (e.g. “This transitions aligns with my long-term vision because…”)
                      </li>
                    </ul>
                  </p>
                  <p>
                    Here is an example of a student explaining their transition from engineering to consulting
                    in the renewable energy sector using the
                    {' '}
                    <strong>GROW</strong>
                    {' '}
                    model:
                    <br />
                    “My educational background in engineering helped me to build a strong foundation
                    in problem-solving and analytical thinking (G - Grounding Experience). However, as I progressed,
                    I realized that I wanted to apply that mindset to a broader range of challenges beyond the field,
                    in particular to complex business challenges (R - Realizations and Reflections).
                    Motivated by these insights, I decided to pursue a career in strategy consulting, starting with
                    a summer internship(O - Opportunity Pursued).Overall, this aligns with my long-term vision to
                    become an expert in the renewables sector, who can assess both the engineering and business
                    angle of such projects (W - Why).
                  </p>
                </div>
              </Accordion>
            </div>
          </div>
          <div data-tab="During">
            <div className="tip-modal-accordions">
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Small Talk">
                <div>
                  <p>
                    Ideally, small talk occurs naturally. However, some prompts
                    never fail to start a conversation:
                  </p>

                  <ul>
                    <li> “How is your day going so far?”</li>
                    <li>
                      “Where are you based? Are you working in the office or
                      remotely?”
                    </li>
                    <li>
                      “What did I catch you in the middle of? What are you
                      currently working on?”
                    </li>
                  </ul>
                  <p>
                    Check out the next tip for two frameworks that will help you
                    structure your answers!
                  </p>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Questions to ask: Current Trends, Requesting Advice, Exploring Resources">
                <div>
                  <p>
                    Coffee chats are your chance to get unique insights into your target industry, company and role.
                    Maximize your results by asking questions that are both flattering to your contact
                    (because they portray them as experts) and fun
                    (in that they can actually talk about their personal experience).
                  </p>
                  <p>
                    Here are some specific questions you can ask using our
                    {' '}
                    <strong>CAREER</strong>
                    {' '}
                    Compass framework:
                  </p>
                  <p>
                    <strong>Current Trends: explore what is currently shaping the industry</strong>
                  </p>
                  <ul>
                    <li>
                      “What trend is most impacting your business right now?”
                    </li>
                    <li>
                      “How has you sector changed most since you started?”,
                      “How do you predict your sector will change the most in the next years?”
                    </li>
                  </ul>
                  <p>
                    <strong>Acquiring Insights: seek personal insights from experts in the field</strong>
                  </p>
                  <ul>
                    <li>“What surprises you most about your job?”</li>
                    <li>
                      “What’s the best lesson you’ve learned so far on the job?”
                    </li>
                    <li>
                      “What’s been your best professional decision so far, and
                      why?”
                    </li>
                    <li>
                      “If you had to attribute your success to one skill or
                      trait, what would it be?”
                    </li>
                  </ul>
                  <p>
                    <strong>Requesting Advice: ask for tips and tricks</strong>
                  </p>
                  <ul>
                    <li>
                      “If you were me, what would you be doing right now to best
                      prepare for a career in this field?”
                    </li>
                    <li>
                      “What do you know now that you wish you knew when you were
                      in my position?
                    </li>
                    <li>
                      “If you were me, what would you be doing right now to
                      maximize your chance of breaking into this
                      industry/function?”
                    </li>
                    <li>
                      “If you had just been hired into this role, what’s the
                      most important thing you’d do in your first thirty days to
                      ensure you got off to the fastest start possible?”
                    </li>
                  </ul>
                  <p>
                    <strong>Exploring Resources: discover essential resources to stay informed</strong>
                  </p>
                  <ul>
                    <li>
                      “What resources should I be sure to look into next?”
                    </li>
                    <li>
                      “What next steps would you recommend for someone in my
                      situation?”
                    </li>
                    <li>
                      “Are there any conferences, industry websites, blogs,
                      newsletters, YouTube channels etc. that you find
                      particularly helpful?”
                    </li>
                    <li>
                      “What’s the most important 10 minutes of research you do
                      each week to stay current on your space?”
                    </li>
                  </ul>
                  <p>
                    <strong>Engaging Assignments: learn about impactful projects and assignments</strong>
                  </p>
                  <ul>
                    <li>“What’s been your favorite project so far?”</li>
                    <li>
                      “Which project of yours do you feel has the greatest
                      impact?”
                    </li>
                    <li>
                      “Has any particular type of project increased in
                      popularity recently at your organization?”
                    </li>
                  </ul>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Closing and next steps">
                <div>
                  <p>
                    A good closer for your coffee chat could be: “Thank you so
                    much for your time today, you have given me a lot to think
                    about. I will take a few days to reflect on everything you
                    have shared, but if I have any additional questions, is it
                    okay if I reach back out to you?”
                  </p>
                  <p>
                    Trust us, with good manners like this, it will be hard for
                    your contact to say no at this point.
                  </p>
                </div>
              </Accordion>
            </div>
          </div>
          <div data-tab="After">
            <div className="tip-modal-accordions">
              <Accordion handleCopied={trackCopiedToClipboard} copiable isOpened={openTip.tipName === 'Thank you note'} title="Thank you note">
                <div>
                  <p>Always follow up with a thank you note.</p>
                  <p>
                    For example: “Thank you so much for your time today. I
                    really enjoyed our conversation and your input is extremely
                    valuable for me at this stage of my career search. Hope to
                    catch up soon! Best regards.”
                  </p>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="Follow-up for referral">
                <div>
                  <p>
                    If the conversation went really well, you can get back to
                    the contact and ask for a referral to another company
                    insider:
                  </p>
                  <p>
                    “Thanks again for your time last week. Upon further
                    reflection, this is definitely something I’d like to pursue
                    further. How would you go about doing that if you were me?
                    For example, can you recommend someone I should speak to
                    next?”
                  </p>
                  <p>OR</p>
                  <p>
                    “Hi, I wanted to send you a quick update. I found the
                    insights you shared last month super helpful, especially
                    those on (...). Per your advice, I also started reading
                    (...) and I already feel more confident in my ability to
                    discuss trends impacting (...) today. Might you have any
                    additional suggestions or recommend someone I should speak
                    to next? Your insights are greatly appreciated and I will
                    certainly keep you posted on my progress. Best regards.”
                  </p>
                </div>
              </Accordion>
            </div>
          </div>
          <div data-tab="What Ifs">
            <div className="tip-modal-accordions">
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="What if my contact is time-constrained">
                <div>
                  <p>
                    If a contact opens the coffee chat with “I’m super busy and
                    only have a few minutes.”, it is often best to move straight
                    to the Advice questions.
                  </p>
                </div>
              </Accordion>
              <Accordion handleCopied={trackCopiedToClipboard} copiable title="What if my contact asks for a list of questions">
                <div>
                  <p>
                    No problem. Just focus on 3 questions that will give you the
                    most insights. Ideally, start with a question on Trends.
                    This will portray your contact as an expert and make them
                    more likely to give meaningful responses.
                  </p>
                </div>
              </Accordion>
            </div>
          </div>
        </TabsContent>
      </div>
    </div>
  );
};

export default withTabs(NetworkingTipModal);
