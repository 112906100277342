import { FilterOption } from '../../company/types/company';
import {
  contractStaticOptions,
  postedDateStaticOptions,
  workTypeStaticOptions,
} from '../components/job-filters/constants';
import { JobsFilterState } from '../types/job';

export const convertURLParamsToFilters = (
  filters: JobsFilterState,
  searchParams: URLSearchParams,
  staticOptions: { industriesOptions: FilterOption[], sizesOptions: FilterOption[] },
) => {
  const newFilters = { ...filters };
  const { industriesOptions, sizesOptions } = staticOptions;

  const mapOptions = (values: string[], options: FilterOption[]) => values
    .flatMap((value) => value.split(','))
    .map((value) => options.find((option) => option.value === value))
    .filter(Boolean) as FilterOption[];

  const mapLocationOptions = (locationStrings: string[]) => locationStrings
    .flatMap((locationString) => {
      try {
        const parsed = JSON.parse(locationString);
        const locations = Array.isArray(parsed) ? parsed : [parsed];
        return locations.map((loc) => {
          const parsedLoc = JSON.parse(loc);
          const name = parsedLoc.city
            ? `${parsedLoc.city}, ${parsedLoc.country}`
            : parsedLoc.country;
          return { name, value: JSON.stringify(parsedLoc) };
        });
      } catch (error) {
        return [];
      }
    })
    .filter(
      (loc) => loc !== null && typeof loc === 'object',
    ) as FilterOption[];

  const dateRange = searchParams.getAll('date_range');
  const workTypes = searchParams.getAll('work_modes');
  const contractTypes = searchParams.getAll('job_types');
  const selectedIndustries = searchParams.getAll('industries');
  const selectedSizes = searchParams.getAll('sizes');
  const selectedLocations = searchParams.getAll('locations');

  if (dateRange.length > 0) {
    newFilters.date_range = mapOptions(dateRange, postedDateStaticOptions);
  }

  if (workTypes.length > 0) {
    newFilters.work_modes = mapOptions(workTypes, workTypeStaticOptions);
  }

  if (contractTypes.length > 0) {
    newFilters.job_types = mapOptions(contractTypes, contractStaticOptions);
  }

  if (selectedIndustries.length > 0) {
    newFilters.industries = mapOptions(selectedIndustries, industriesOptions);
  }

  if (selectedSizes.length > 0) {
    newFilters.sizes = mapOptions(selectedSizes, sizesOptions);
  }

  if (selectedLocations.length > 0) {
    newFilters.locations = mapLocationOptions(selectedLocations);
  }

  return newFilters;
};
