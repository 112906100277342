import { MultiSelect } from '@/components/multi-select/multi-select';
import { FilterOption } from '@/domains/core/company/types';
import { JobsFilterState } from '../../types/job';

type Props = {
  filters: JobsFilterState;
  staticOptions: Record<string, FilterOption[]>;
  handleFilterChange: (key: string, value: FilterOption[]) => void;
};

export const JobsFiltersModal = ({
  filters,
  staticOptions,
  handleFilterChange,
}: Props) => {
  const {
    contract,
    postedDate,
    workType,
    industries,
    sizes,
  } = staticOptions;

  return (
    <div className="job-filters__filters-modal">
      <MultiSelect
        labelType="list"
        handleFiltering={(options: FilterOption[]) => handleFilterChange('job_types', options)}
        placeholder="Full-time"
        selected={filters.job_types}
        options={contract}
        size="full"
      />

      {/* <MultiSelect
              labelType="list"
              handleFiltering={(options: FilterOption[]) => handleFilterChange('job_language', options)}
              placeholder="Job ad language"
              selected={filters.job_language}
              options={jobLanguageStaticOptions}
              size="full"
            /> this will be commented out and wait for backend */}

      <MultiSelect
        labelType="list"
        handleFiltering={(options: FilterOption[]) => handleFilterChange('industries', options)}
        placeholder="Industry"
        selected={filters.industries}
        options={industries}
        size="full"
      />

      <MultiSelect
        labelType="list"
        handleFiltering={(options: FilterOption[]) => handleFilterChange('date_range', options)}
        placeholder="Date Posted"
        selected={filters.date_range}
        options={postedDate}
        size="full"
      />

      <MultiSelect
        labelType="list"
        handleFiltering={(options: FilterOption[]) => handleFilterChange('work_modes', options)}
        placeholder="Remote"
        selected={filters.work_modes}
        options={workType}
        size="full"
      />

      <MultiSelect
        labelType="list"
        handleFiltering={(options: FilterOption[]) => handleFilterChange('sizes', options)}
        placeholder="Company Size"
        selected={filters.sizes}
        options={sizes}
        width="fit-box"
        size="full"
      />
    </div>
  );
};
