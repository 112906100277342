import '../sidebar.scss';

export const IndustrySidebar = ({ industry, closeSidebar, companies }: any) => (
  <div className="industry-sidebar">
    <h2 className="industry-sidebar__title" onClick={closeSidebar}>
      {industry}
    </h2>
    <p className="industry-sidebar__subtitle">Companies with most student engagement</p>
    <div className="industry-sidebar__companies">
      <div className="industry-sidebar__companies__headers">
        <div className="industry-sidebar__companies__headers__head">Company</div>
        <div className="industry-sidebar__companies__headers__head"># of Students</div>
      </div>
      <div className="industry-sidebar__companies__list">
        {companies.sort((a: any, b: any) => (parseInt(a.count, 10) > parseInt(b.count, 10) ? -1 : 1)).map((company: any) => (
          <div key={company.company_id} className="industry-sidebar__companies__list__item">
            <div className="industry-sidebar__companies__list__item__company">
              <img src={company.logo_url} alt="company logo" />
              <span className="industry-sidebar__companies__list__item__company__title">{company.company_name}</span>
            </div>
            <div className="industry-sidebar__companies__list__item__students">
              {company.count}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
