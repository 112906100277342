import { useTrackerModal } from '@/services/hooks/use-tracker';
import { Provider } from './tracker-context';
import TrackerLoaderWrapper from './tracker-loader';

const TrackerProvider = ({ children }: any) => {
  const {
    openTracker, closeTracker, isTrackerModal, companyID, trackerTab,
  } = useTrackerModal();

  return (
    <Provider value={{
      openTracker, closeTracker, isTrackerModal, companyID, trackerTab,
    }}
    >
      <TrackerLoaderWrapper />
      {children}
    </Provider>
  );
};

export { TrackerProvider };
