import { Contact } from '@/domains/core/contact/types';

// generator for batch tasks
export function* batchTasks(
  sourceArray: Contact[],
  limit: number,
  callback: (contact: Contact) => Promise<void>,
) {
  for (let i = 0; i < sourceArray.length; i += limit) {
    yield sourceArray
      .slice(i, i + limit)
      .map((task) => callback.bind(null, task));
  }
}
