import { getSingleCompany } from '@/services/api/company';
import {
  deleteContact, getContact, patchContact,
} from '@/services/api/contact';
import { ContactPageResponse } from '../types';
import { getSelf } from '@/services/api/user';

export const deleteCompanyContact = async (contactID: string): Promise<any> => {
  await deleteContact(contactID);
  return { contactID };
};

export const contactLoader = async (request: any): Promise<ContactPageResponse> => {
  const { companyID, contactID } = request.params;

  const [company, contactsResponse, getSelfResponse] = await Promise.all([
    getSingleCompany(companyID),
    getContact(contactID),
    getSelf(),
  ]);

  return {
    companyIndustry: company.careeros_industry || company.industry,
    contact: contactsResponse || {},
    self: getSelfResponse,
  };
};

export async function updateContactEmails(
  id: string,
  email: string | null,
  secondary_email: string | null,
) {
  return patchContact({ id, email: email || null, secondary_email: secondary_email || null });
}
