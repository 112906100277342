import ExplorerBadge from '@/assets/images/Challenge Badge Explorer.png';
import ButterflyBadge from '@/assets/images/Challenge Badges Social Butterfly.png';
import NinjaBadge from '@/assets/images/Challenge Badges Networking Ninja.png';
import { ChallengeProgress } from '../challenge-progress/challenge-progress';

import './challenges-widget.scss';

export const ChallengesWidget = ({ progress }: any) => (
  <div className="challenges-widget">
    <h2 className="challenges-widget__title">
      Challenges
    </h2>
    <div className="challenges-widget__content">
      {progress?.saved_companies > 6
      && (
      <ChallengeProgress
        completedText="You earned the Career Explorer badge by saving 30 companies."
        progress={progress?.saved_companies || 0}
        badge={ExplorerBadge}
        maxProgress={30}
      >
        <b>Save 30 companies</b>
        {' '}
        to earn the Career Explorer Badge.
      </ChallengeProgress>
      )}
      {progress?.saved_contacts > 10
      && (
      <ChallengeProgress
        completedText="You earned the Social Butterfly badge by saving 50 contacts."
        progress={progress?.saved_contacts || 0}
        badge={ButterflyBadge}
        maxProgress={50}
      >
        <b>Save 50 contacts</b>
        {' '}
        to earn the Social Butterfly Badge.
      </ChallengeProgress>
      )}
      {progress?.sent_linkedin_invitations > 10
      && (
      <ChallengeProgress
        completedText="You earned the Networking Ninja badge by sending 50 Linkedin invitations."
        progress={progress?.sent_linkedin_invitations || 0}
        badge={NinjaBadge}
        maxProgress={50}
      >
        <b>Send 50 Linkedin invitations</b>
        {' '}
        to earn the Networking Ninja Badge.
      </ChallengeProgress>
      )}
    </div>
  </div>
);
